<p translate mat-dialog-title class="title">Work log details</p>
<mat-dialog-content>
  <div class="al-work-order-work-log">
    <div>
      <div class="field" ><span translate>Details</span> : </div>
      <div *ngIf="workOrderDescriptionLong" [innerHtml]="workOrderDescriptionLong"></div>
      <div *ngIf="!workOrderDescriptionLong" class="no-description">
        <span translate>No Details</span>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <div>
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="closeDialog()"
    >
      {{ "cancel" | translate }}
    </button>
  </div>
</mat-dialog-actions>
