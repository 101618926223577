<mat-card class="attachment-item">
  <div class="attachment-item-wrapper">
    <div class="attachment-icon">
      <ng-container *ngIf="isLocal; else elseIsNotLocal">
        <mat-icon>attachment</mat-icon>
      </ng-container>
      <ng-template #elseIsNotLocal>
        <mat-icon>cloud_queue</mat-icon>
      </ng-template>
    </div>
    <div class="attachment-title" [title]="attachment.filename" (click)="download()">
      <span>{{attachment.filename}}</span>
    </div>
    <div class="attachment-delete" *ngIf="isDeletable" (click)="delete()">
      <mat-icon>delete_outline</mat-icon>
    </div>
  </div>
</mat-card>
