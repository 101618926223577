import { Component, Input } from '@angular/core';

@Component({
  selector: 'al-navigation-location',
  templateUrl: './al-navigation-location.component.html',
  styleUrls: ['./al-navigation-location.component.scss'],
})
export class AlNavigationLocationComponent {
  @Input()
  public currentLocation!: string;
}
