<div class="work-order-summary">
  <mat-card class="work-order-summary-card">
    <div>
      <label translate>ID:</label>
      <span *ngIf="isChildFrom" class="work-order-parent">
        <a (click)="displayParent()">{{ getWorkOrderParentNumber }}</a> <span class="arrow">></span>
      </span>
      <span class="work-order-id">{{getWorkOrderNumber }}</span>
    </div>
    <div class="work-order-created-by">
      <label translate>Created by:</label>
      <a>{{ getWorkOrderLead }}</a>
    </div>
    <div class="work-order-type-priority">
      <mat-chip-list>
        <mat-chip class="work-order-type">{{ getWorkOrderType }}</mat-chip>
        <mat-chip class="work-order-priority">{{ getWorkOrderPriority }}</mat-chip>
      </mat-chip-list>
    </div>
    <div class="work-order-objective-end-date">
      <label translate>Objective end date:</label>
      <span>{{ getWorkOrderSchedDateFin | localizedDate }}</span>
    </div>
    <div class="work-order-start-date">
      <label translate>Start date:</label>
      <span>{{ getWorkOrderActStartDebut | localizedDate }}</span>
    </div>
    <div class="work-order-location">
      <label translate>Location:</label>
      <span>{{ getWorkOrderLocation }}</span>
    </div>
    <div class="work-order-equipment">
      <label translate>Equipment:</label>
      <a *ngIf="equipments" (click)="displayEquipment()">{{getWorkOrderAssetNumber}}</a>
      <span *ngIf="!equipments">{{getWorkOrderAssetNumber}}</span>
      <mat-icon style="width: 15px; height: 15px" *ngIf="!equipments && getWorkOrderAssetNumber"
        title="{{'data lacking in Datalake' | translate}}">
        error_outline
      </mat-icon>
    </div>
    <div>
<!--      <al-attachments [title]="'Attachment list'"></al-attachments>-->
      <al-attachments [title]="'Attachment list'|translate"></al-attachments>
    </div>
  </mat-card>
</div>
