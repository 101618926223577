export const MIME_TYPE_FOLDER = 'application/vnd.google-apps.folder';

export class FileInfo {
  public Blob!: File;

  public Id!: string | undefined;

  public MimeType!: string | undefined;

  public ModifiedTime!: Date | undefined;

  public Name!: string | undefined;

  public Progress!: number;

  public Size!: string | undefined;

  public static fromGoogleFile(file: gapi.client.drive.File): FileInfo {
    const fileInfo = new FileInfo();
    fileInfo.Id = file.id;
    fileInfo.MimeType = file.mimeType;
    fileInfo.ModifiedTime = file.modifiedTime
      ? new Date(file.modifiedTime)
      : undefined;
    fileInfo.Name = file.name;
    fileInfo.Size = file.size;
    return fileInfo;
  }

  public get Icon(): string {
    if (this.IsFolder) {
      return 'folder';
    }
    return 'file';
  }

  public get IsFolder(): boolean {
    return this.MimeType === MIME_TYPE_FOLDER;
  }

  public get ModifiedTimeText(): string {
    return `${this.ModifiedTime?.getDate()}.${
      this.ModifiedTime ? this.ModifiedTime.getMonth() + 1 : ''
    }.${this.ModifiedTime?.getFullYear()}`;
  }

  public get SizeText(): string {
    if (!this.Size) return '-';

    const size: number = parseInt(this.Size, 10);
    if (size < 1024 ** 1) {
      return size.toString();
    }
    if (size < 1024 ** 2) {
      return `${Math.floor(size / 1024 ** 1)} KB`;
    }
    if (size < 1024 ** 3) {
      return `${Math.floor(size / 1024 ** 2)} MB`;
    }
    if (size < 1024 ** 3) {
      return `${Math.floor(size / 1024 ** 3)} GB`;
    }
    return `${Math.floor(size / 1024 ** 4)} GB`;
  }
}
