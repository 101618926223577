import { JsonObject, JsonProperty } from 'json2typescript';

import { DateJsonConverter } from '../../json-converters/date-json-converter';
import { Attachment } from '../get/attachment.model';
import { PutRequest } from './put-request.model';

/**
 * This class represents Work Order model
 */
@JsonObject('QuickReportPut')
export class QuickReportPut extends PutRequest {
  @JsonProperty('actualFinish', DateJsonConverter, true)
  public actualFinish: Date | null = null;

  @JsonProperty('actualStart', DateJsonConverter, true)
  public actualStart: Date | null = null;

  @JsonProperty('assetNum', String, true)
  public assetNum: string | null = null;

  @JsonProperty('attachments', [Attachment], true)
  public attachments: Attachment[] = [];

  @JsonProperty('description', String, true)
  public description: string | null = null;

  @JsonProperty('descriptionLong', String, true)
  public descriptionLong: string | null = null;

  @JsonProperty('domain', String, true)
  public domain = '';

  @JsonProperty('duration', Number, true)
  public duration: number | null = null;

  @JsonProperty('failureAcceptable', Boolean, true)
  public failureAcceptable = false;

  @JsonProperty('jobType', String, true)
  public jobType = '';

  @JsonProperty('location', String, true)
  public location = '';

  @JsonProperty('parent', String, true)
  public parent = '';

  @JsonProperty('priority', String, true)
  public priority = '';

  @JsonProperty('remark', String, true)
  public remark = '';

  @JsonProperty('remarkDetails', String, true)
  public remarkDetails = '';

  @JsonProperty('siteId', String, true)
  public siteId = '';

  @JsonProperty('source', String, true)
  public source = '';

  @JsonProperty('type', String, true)
  public type = '';
}
