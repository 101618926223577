import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AlEnvironmentService } from '@al/environment';
import { map, mergeMap } from 'rxjs/operators';
import { Auth } from 'aws-amplify';

@Injectable()
export class SecurityHttpInterceptor implements HttpInterceptor {
  public constructor(private alEnvironmentService: AlEnvironmentService) {}

  public intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      map((session) => {
        return session ? session.getIdToken().getJwtToken() : '';
      }),
      mergeMap((token: string) =>
        next.handle(
          httpRequest.clone({
            setHeaders: {
              Authorization: `${token}`,
              runtime: 'angular2',
              'api-gateway': this.alEnvironmentService.env.apigwUrl,
            },
          })
        )
      )
    );
  }
}
