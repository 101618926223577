import { AlDialogModule } from '@al/dialog';
import { AlCreateSrModule } from '@al/create-sr';
import { AlNavigationModule } from '@al/navigation';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CreateSRComponent } from './create-sr.component';

@NgModule({
  declarations: [CreateSRComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AlCreateSrModule,
    AlNavigationModule,
    AlDialogModule,
    MatButtonModule,
  ],
})
export class CreateSRModule {}
