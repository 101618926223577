/**
 * this class simply load localstorage and return list of the generic T type
 */
import { AlJsonConvert } from '@al/json';

export class LocalStorageManager<T> {
  private alJsonConverter = new AlJsonConvert();

  public constructor(private cacheName: string) {}

  public clear(): void {
    localStorage.setItem(this.cacheName, JSON.stringify([]));
  }

  public load(): T[] {
    const cacheValue = localStorage.getItem(this.cacheName);
    if (cacheValue) {
      return JSON.parse(cacheValue);
    }
    return [];
  }

  public store(values: T[], classReference: any): void {
    localStorage.setItem(
      this.cacheName,
      JSON.stringify(
        this.alJsonConverter.serializeArray<any>(
          values as any[],
          classReference
        )
      )
    );
  }
}
