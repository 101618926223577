<!-- /*******************************************************************
#*  Copyright Air Liquide S.A.
#*  2020
#******************************************************************/-->

<mat-card class="card-slider-item" [ngClass]="{
    activated: isActivated,
    disabled: isDisabled
  }" [ngStyle]="style" *ngIf="isComponentReady">

  <div *ngIf="icon" class="card-slider-item-icon">
    <mat-icon *ngIf="isWebFontIcon()">{{ icon }}</mat-icon>
    <img *ngIf="isImageIcon()" [src]="icon" class="icon-image" [alt]="icon">
  </div>

  <div class="card-slider-item-text">
    <div *ngIf="count !== undefined && count !== null" class="card-count-text">
      {{ count | async}}
    </div>
    <ng-content></ng-content>
  </div>
</mat-card>
