import { QuickReportPut, QuickReportPutResponse } from '@al/entities';
import { QuickReportSyncService } from '@al/sync-services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuickReportService {
  public constructor(private sync: QuickReportSyncService) {}

  public create(request: QuickReportPut): Observable<QuickReportPutResponse> {
    return this.sync.create(request);
  }
}
