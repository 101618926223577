import { LocalStorageKey, Site, WorkOrderLoadingStatus } from '@al/entities';
import { AlOnlineService } from '@al/online';
import { AlSpinnerService } from '@al/spinner';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  SiteQuery,
  SiteService,
  WorkOrdersService,
} from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-site-selector',
  templateUrl: './al-site-selector.component.html',
  styleUrls: ['./al-site-selector.component.scss'],
})
export class AlSiteSelectorComponent implements OnInit, OnDestroy {
  @Input()
  public enabled: boolean;

  @Input()
  public isSelectable: boolean;

  public isOnline!: boolean;

  public isSynchronized!: boolean;

  public items: Site[];

  public get selectedItemName(): string {
    return this.selectedItem ? this.selectedItem.name : '';
  }

  public selectedItemUuid: UUID | null;

  public synchronizing = true;

  private broadcast = new BroadcastChannel('work-order-loading');

  private ngUnsubscribe = new Subject();

  private selectedItem!: Site;

  public constructor(
    private equipmentServices: EquipmentsService,
    private onLineService: AlOnlineService,
    private processGroupsService: ProcessGroupsService,
    private productionUnitsService: ProductionUnitsService,
    private siteService: SiteService,
    private siteQuery: SiteQuery,
    private synchroInfoService: SyncInfoService,
    private workOrdersService: WorkOrdersService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.enabled = false;
    this.isSelectable = false;
    this.isSynchronized = false;
    this.selectedItemUuid = null;
    this.items = [];
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.alSpinnerService.isWorkOrderProcessing
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isDisplayed) => {
        this.synchronizing = isDisplayed;
      });

    const uuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(uuid);
    this.siteQuery
      .selectAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (items) => {
          this.items = items;
          if (this.items.length > 0) {
            this.alSpinnerService.stopAssetProcess(uuid);
          }
        },
      });
    this.siteQuery.selectActive().subscribe({
      next: (site: Site) => {
        if (site != null) {
          this.selectedItem = site;
          this.selectedItemUuid = this.selectedItem.uuid;
        }
      },
    });

    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isOnline = res;
      });

    this.synchroInfoService.isSynchronized
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isSynchronized = res;
      });
  }

  public onValueChange(uuid: UUID): void {
    this.broadcast.postMessage({
      text: 0,
      duration: WorkOrderLoadingStatus.resetCOMP,
    });

    this.siteService.setActive(uuid);

    localStorage.removeItem(LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.PRODUCTION_UNIT_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.QUICK_REPORT_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE);

    localStorage.removeItem(LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_ROWSTAMP);
    localStorage.removeItem(
      LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_ROWSTAMP
    );
    localStorage.removeItem(
      LocalStorageKey.PRODUCTION_UNIT_SYNCHRO_LAST_ROWSTAMP
    );
    localStorage.removeItem(LocalStorageKey.QUICK_REPORT_SYNCHRO_LAST_ROWSTAMP);
    localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP);

    // si changement de site alors il faut vider le store
    this.workOrdersService.clear();
    this.workOrdersService
      .get()
      .pipe()
      .subscribe({
        error: (error) => {
          // TODO: Utiliser un logger global
          // eslint-disable-next-line no-console
          console.error('load by site Error', error);
          this.broadcast.postMessage({
            text: 0,
            duration: WorkOrderLoadingStatus.err,
          });
        },
      });

    const equipmentProcessUuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(equipmentProcessUuid);
    this.equipmentServices
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.alSpinnerService.stopAssetProcess(equipmentProcessUuid);
      });

    const processGroupProcessUuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(processGroupProcessUuid);
    this.processGroupsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.alSpinnerService.stopAssetProcess(processGroupProcessUuid);
      });

    const productionUnitProcessUuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(productionUnitProcessUuid);
    this.productionUnitsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.alSpinnerService.stopAssetProcess(productionUnitProcessUuid);
      });
  }
}
