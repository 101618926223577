import { ProcessGroup } from '@al/entities';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { ProcessGroupsStore, ProcessGroupState } from './process-group.store';

@Injectable({ providedIn: 'root' })
export class ProcessGroupsQuery extends QueryEntity<ProcessGroupState> {
  public processGroup = this.selectAll();

  public constructor(protected store: ProcessGroupsStore) {
    super(store);
  }

  public getProcessGroup(uuid: string): Observable<ProcessGroup | undefined> {
    return this.selectEntity(uuid);
  }
}
