import { NgModule } from '@angular/core';

import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
  name: 'eMaintenanceDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'attachments',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        {
          name: 'arrayBuffer',
          keypath: 'arrayBuffer',
          options: { unique: false },
        },
        {
          name: 'file',
          keypath: 'file',
          options: { unique: false },
        },
        {
          name: 'isUploaded',
          keypath: 'isUploaded',
          options: { unique: false },
        },
        {
          name: 'newFileName',
          keypath: 'newFileName',
          options: { unique: false },
        },
        {
          name: 'putRequestUuid',
          keypath: 'putRequestUuid',
          options: { unique: false },
        },
        {
          name: 'site',
          keypath: 'site',
          options: { unique: false },
        },
        {
          name: 'ticketId',
          keypath: 'ticketId',
          options: { unique: false },
        },
        {
          name: 'workOrderNumber',
          keypath: 'workOrderNumber',
          options: { unique: false },
        },
      ],
    },
  ],
};

@NgModule({
  declarations: [],
  imports: [NgxIndexedDBModule.forRoot(dbConfig)],
  exports: [],
})
export class AlUploadModule {}
