import { AlOnlineService } from '@al/online';
import { Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { AlRequestInformationDialogData } from '../model/al-request-information-dialog-data';
import { AlRequestInformationDialogDataQrPut } from '../model/al-request-information-dialog-data-qr-put';

@Component({
  selector: 'al-qr-put-information-dialog',
  templateUrl: './al-qr-put-information-dialog.component.html',
  styleUrls: [
    './al-common-information-dialog.component.scss',
    './al-qr-put-information-dialog.component.scss',
  ],
})
export class AlQrPutInformationDialogComponent {
  @Input()
  public data!: AlRequestInformationDialogData<AlRequestInformationDialogDataQrPut>;

  public isOnline!: boolean;

  public constructor(private onLineService: AlOnlineService) {
    this.onLineService.status.pipe(take(1)).subscribe((res) => {
      this.isOnline = res;
    });
  }
}
