import { RightActionEnum, RightTypeEnum, WorkOrder } from '@al/entities';
import { SessionRights } from '@al/session';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-work-order-dates',
  templateUrl: './al-work-order-dates.component.html',
  styleUrls: ['./al-work-order-dates.component.scss'],
})
export class AlWorkOrderDatesComponent implements OnInit, OnDestroy {
  @Input()
  public viewDetails = false;

  public form: FormGroup;

  public rights = new SessionRights();

  private ngUnsubscribe = new Subject();

  private workOrder!: WorkOrder;

  public constructor(
    private formBuilder: FormBuilder,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersService: WorkOrdersService
  ) {
    this.form = this.buildFormGroup();
  }

  public get actualFinishDateTime(): AbstractControl | null {
    return this.form.get('actualFinishDateTime');
  }

  public get actualStartDateTime(): AbstractControl | null {
    return this.form.get('actualStartDateTime');
  }

  public changeActualFinishDateTime(): void {
    this.checkDateValidators();
    const control = this.form.get('actualFinishDateTime');
    if (control?.value && control?.valid) {
      this.workOrdersService.updateActDateFin(
        this.workOrder,
        new Date(control.value)
      );
    }
  }

  public changeActualStartDateTime(): void {
    this.checkDateValidators();
    const control = this.form.get('actualStartDateTime');
    if (control?.value && control?.valid) {
      this.workOrdersService.updateActDateDebut(
        this.workOrder,
        new Date(control.value)
      );
    }
  }

  public isActualFinishDateTimeReadOnly(): boolean {
    return !!(this.isReadOnly() || !this.workOrder?.actDateDebut);
  }

  public isActualStartDateTimeReadOnly(): boolean {
    return this.isReadOnly() || !this.workOrder;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
          this.initFormGroup();
        }
      });
    this.initFormGroup();
  }

  public viewDetailsSwitch(): void {
    this.viewDetails = !this.viewDetails;
  }

  public wrongDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const actualStartDateTime: string | null = control.value;
      const actualFinishDateTime: string | null =
        control.parent?.value.actualFinishDateTime;
      return this.isValidDate(actualStartDateTime, actualFinishDateTime)
        ? { wrongDate: { value: control.value } }
        : null;
    };
  }

  public wrongFinishDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const actualStartDateTime: string | null =
        control.parent?.value.actualStartDateTime;
      const actualFinishDateTime: string | null = control.value;
      return this.isValidDate(actualStartDateTime, actualFinishDateTime)
        ? { wrongDate: { value: control.value } }
        : null;
    };
  }

  private buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      actualStartDateTime: this.formBuilder.control(null, [
        this.wrongDateValidator(),
      ]),
      actualFinishDateTime: this.formBuilder.control(null, [
        this.wrongFinishDateValidator(),
      ]),
    });
  }

  private checkDateValidators(): void {
    this.form.get('actualStartDateTime')?.updateValueAndValidity();
    this.form.get('actualFinishDateTime')?.updateValueAndValidity();
  }

  private convertDate(date: Date | null): string | null {
    if (date) {
      const y = date.getFullYear();
      const m = this.padLeft(date.getMonth() + 1);
      const d = this.padLeft(date.getDate());
      const time = new Date(date).toLocaleTimeString().substring(0, 5);
      return `${y}-${m}-${d}T${time}`;
    }
    return null;
  }

  private initFormGroup(): void {
    this.form
      .get('actualStartDateTime')
      ?.setValue(this.convertDate(this.workOrder.actDateDebut));

    this.form
      .get('actualFinishDateTime')
      ?.setValue(this.convertDate(this.workOrder.actDateFin));

    this.form.get('actualStartDateTime')?.markAsTouched();
    this.form.get('actualFinishDateTime')?.markAsTouched();

    this.checkDateValidators();
  }

  private isReadOnly(): boolean {
    return !this.rights.isAllowedAction(
      RightTypeEnum.WOTRACK,
      RightActionEnum.INSERT
    );
  }

  private isValidDate(
    actualStartDateTime: string | null,
    actualFinishDateTime: string | null
  ): boolean {
    let forbidden = false;
    if (actualStartDateTime && actualFinishDateTime) {
      const actualStartTime = new Date(actualStartDateTime).getTime();
      const actualFinishTime = new Date(actualFinishDateTime).getTime();
      forbidden = actualFinishTime <= actualStartTime;
    }
    return forbidden;
  }

  private padLeft(value: number): string {
    const pad = '00';
    return (pad + value).slice(-pad.length);
  }
}
