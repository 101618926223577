import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';

export class AlJsonConvert extends JsonConvert {
  public constructor() {
    super();
    this.ignorePrimitiveChecks = false;
    this.operationMode = OperationMode.ENABLE;
    this.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }
}
