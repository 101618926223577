import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  CalibrationStatus,
  Equipment,
  Task,
  TaskStatus,
  WorkOrder,
  WorkOrderPutResponse,
} from '@al/entities';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlWorkOrderTaskListService implements OnDestroy {
  public currentTask: Observable<Task | null>;

  public equipment: Observable<Equipment | null>;

  public hasReportedDate: Observable<boolean>;

  public isCompletionStarted: Observable<boolean>;

  public taskList: Observable<Task[]>;

  private currentTask$: BehaviorSubject<Task | null>;

  private equipment$: BehaviorSubject<Equipment | null>;

  private hasReportedDate$: BehaviorSubject<boolean>;

  private index: number;

  private isCompletionStarted$: BehaviorSubject<boolean>;

  private ngUnsubscribe = new Subject();

  private taskList$: BehaviorSubject<Task[]>;

  private workOrder: WorkOrder | null;

  public constructor(
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersService: WorkOrdersService
  ) {
    this.index = -1;

    this.currentTask$ = new BehaviorSubject<Task | null>(null);
    this.currentTask = this.currentTask$.asObservable();

    this.equipment$ = new BehaviorSubject<Equipment | null>(null);
    this.equipment = this.equipment$.asObservable();

    this.hasReportedDate$ = new BehaviorSubject<boolean>(false);
    this.hasReportedDate = this.hasReportedDate$.asObservable();

    this.isCompletionStarted$ = new BehaviorSubject<boolean>(false);
    this.isCompletionStarted = this.isCompletionStarted$.asObservable();

    this.taskList$ = new BehaviorSubject<Task[]>([]);
    this.taskList = this.taskList$.asObservable();

    this.workOrder = null;
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          const previousWorkOrder = this.workOrder;
          this.workOrder = res;
          if (
            previousWorkOrder &&
            previousWorkOrder.number !== this.workOrder.number
          ) {
            this.endCompletion();
          }

          if (this.workOrder && this.workOrder.actDateFin) {
            this.hasReportedDate$.next(true);
          } else {
            this.hasReportedDate$.next(false);
          }

          const taskList =
            this.workOrder && this.workOrder.tasks ? this.workOrder.tasks : [];
          this.taskList$.next(taskList);
        }
      });
  }

  public nextTask(): void {
    const task = this.getNextNotEndedTask();
    this.setCurrentTask(task);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public setCurrentTask(task: Task | null): void {
    this.currentTask$.next(task);
    this.index = this.getIndex(task);
  }

  public setDone(t: Task): void {
    const task = Object.assign(new Task(), t);
    task.isTaskSkipped = false;
    task.status = TaskStatus.COMP;
    task.endDate = new Date();
    const taskList: Task[] = Object.assign([], this.taskList$.value);
    const index = taskList.findIndex((item) => item.id === task.id);
    if (index > -1) {
      taskList[index] = task;
      this.taskList$.next(taskList);
      if (this.workOrder) {
        this.workOrdersService.setSkippedOrDone(this.workOrder, task);
      }
    }
  }

  public setMeasurement(
    t: Task,
    calibrationMeasure: number,
    calibrationStatus: CalibrationStatus,
    rebootAdj: boolean
  ): void {
    const task = Object.assign(new Task(), t);
    task.measurementValue = calibrationMeasure;
    task.measurementDate = new Date();
    task.status1 = calibrationStatus;
    if (calibrationStatus === CalibrationStatus.CHECKED) {
      task.status = TaskStatus.COMP;
    } else {
      task.status = TaskStatus.RLSD;
    }
    if (rebootAdj) {
      task.measurementAfterAdj = null;
      task.status2 = null;
    }
    const taskList: Task[] = Object.assign([], this.taskList$.value);
    const index = taskList.findIndex((item) => item.id === task.id);
    if (index > -1) {
      taskList[index] = task;
      this.taskList$.next(taskList);
      if (this.workOrder) {
        this.workOrdersService.setMeasure(this.workOrder, task, rebootAdj);
      }
    }
  }

  public setMeasurementAfterAdj(
    t: Task,
    calibrationMeasure: number,
    calibrationStatus: CalibrationStatus
  ): void {
    const task = Object.assign(new Task(), t);
    task.measurementAfterAdj = calibrationMeasure;
    task.measurementDate = new Date();
    task.status2 = calibrationStatus;
    task.status = TaskStatus.COMP;
    const taskList: Task[] = Object.assign([], this.taskList$.value);
    const index = taskList.findIndex((item) => item.id === task.id);
    if (index > -1) {
      taskList[index] = task;
      this.taskList$.next(taskList);
      if (this.workOrder) {
        this.workOrdersService.setMeasure(this.workOrder, task, true);
      }
    }
  }

  public setSkipped(t: Task): void {
    const task = Object.assign(new Task(), t);
    task.isTaskSkipped = true;
    task.status = TaskStatus.CAN;
    task.endDate = new Date();
    const taskList: Task[] = Object.assign([], this.taskList$.value);
    const index = taskList.findIndex((item) => item.id === task.id);
    if (index > -1) {
      taskList[index] = task;
      this.taskList$.next(taskList);
      if (this.workOrder) {
        this.workOrdersService.setSkippedOrDone(this.workOrder, task);
      }
    }
  }

  public startCompletion(): Observable<WorkOrderPutResponse | null> {
    this.isCompletionStarted$.next(true);
    this.nextTask();
    if (this.workOrder) {
      return this.workOrdersService.startCompletion(this.workOrder);
    }
    return new Observable<null>();
  }

  private endCompletion(): void {
    this.isCompletionStarted$.next(false);
    this.setCurrentTask(null);
  }

  private getCurrentTaskByIndex(index: number): Task | null {
    const task = this.taskList$.value[index];
    return task || null;
  }

  private getIndex(task: Task | null): number {
    return task ? this.taskList$.value.findIndex((t) => t.id === task.id) : -1;
  }

  private getNextNotEndedTask(): Task | null {
    const notEndedTasks: Task[] = this.taskList$.value
      .filter(
        (task) =>
          task.status !== TaskStatus.CAN &&
          task.status !== TaskStatus.CLOSE &&
          task.status !== TaskStatus.COMP
      )
      .sort((a, b) => a.id - b.id);
    return notEndedTasks.length > 0 ? notEndedTasks[0] : null;
  }
}
