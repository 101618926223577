import { LocalStorageKey } from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractSrHistoryService } from './abstract-sr-history.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSrCreateHistoryService extends AbstractSrHistoryService {
  public constructor() {
    super(LocalStorageKey.ATTACHMENT_CREATE_REQUEST_HISTORY);
  }
}
