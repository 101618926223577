import { AlOnlineService } from '@al/online';
import { Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { AlRequestInformationDialogDataFrPut } from '../model';
import { AlRequestInformationDialogData } from '../model/al-request-information-dialog-data';

@Component({
  selector: 'al-fr-put-information-dialog',
  templateUrl: './al-fr-put-information-dialog.component.html',
  styleUrls: [
    './al-common-information-dialog.component.scss',
    './al-fr-put-information-dialog.component.scss',
  ],
})
export class AlFrPutInformationDialogComponent {
  @Input()
  public data!: AlRequestInformationDialogData<AlRequestInformationDialogDataFrPut>;

  public isOnline!: boolean;

  public constructor(private onLineService: AlOnlineService) {
    this.onLineService.status.pipe(take(1)).subscribe((res) => {
      this.isOnline = res;
    });
  }
}
