import { Injectable } from '@angular/core';

import {
  GtmPut,
  LocalStorageKey,
  QuickReport,
  QuickReportPut,
  QuickReportPutResponse,
} from '@al/entities';
import { AlOnlineService } from '@al/online';
import { QuickReportRestService } from '@al/rest';

import {
  QuickReportCreateCache,
  QuickReportCreateHistoryService,
  QuickReportUpdateCache,
  QuickReportUpdateHistoryService,
} from '@al/cache';
import { AlIndexedDbService } from '@al/indexed-db';
import { AbstractSyncService } from './abstract-sync.service';
import { SyncInfoService } from './sync-info.service';
import { GtmSyncService } from './gtm-sync.service';

@Injectable({
  providedIn: 'root',
})
export class QuickReportSyncService extends AbstractSyncService<
  QuickReport,
  QuickReportPut,
  QuickReportPutResponse
> {
  public constructor(
    protected restService: QuickReportRestService,
    protected createCache: QuickReportCreateCache,
    protected updateCache: QuickReportUpdateCache,
    protected createCachedHistoryService: QuickReportCreateHistoryService,
    protected updateCachedHistoryService: QuickReportUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  protected fillGtmWithData(value: QuickReportPut): void {
    // find if existing previous gtm
    const gtm = this.gtmSyncService.getGtmTag(value.uuid);
    if (gtm) {
      gtm.asset = value.assetNum;
      gtm.event = 'QR_CREATION';
      gtm.jobType = value.jobType;
      gtm.location = value.location;
      gtm.onLine = this.onLineService.onLine();
      gtm.siteId = value.siteId;
      gtm.priority = value.priority.replace(/^P/, '');
      gtm.uuid = value.uuid;
      gtm.workType = value.type;
      gtm.EMV2Source = localStorage.getItem(LocalStorageKey.GTM_EMV2_SOURCE);
      this.gtmSyncService.saveGtmTag(gtm);
    }
  }

  // on rajoute les erre
  protected fillGtmWithResponse(
    gtm: GtmPut,
    value: QuickReportPutResponse
  ): void {
    const cachedGtm = this.gtmSyncService.getGtmTag(gtm.uuid);
    if (cachedGtm) {
      cachedGtm.number = value.workOrderNum;
      this.gtmSyncService.addGenericReponseInformation(cachedGtm, value);
    }
  }

  protected getNewResponse(): QuickReportPutResponse {
    return new QuickReportPutResponse();
  }
}
