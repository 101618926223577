/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */

import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { IconTypeEnum } from './icon-type.enum';
import { AbstractCard } from './kpi-card';

@Component({
  selector: 'al-card-slider-item',
  templateUrl: 'al-card-slider-item.component.html',
  styleUrls: ['al-card-slider-item.component.scss'],
})
export class AlCardSliderItemComponent implements OnInit {
  @Input()
  public activated = false;

  @Input()
  public card?: AbstractCard;

  @Input()
  public color?: string;

  @Input()
  public count?: Observable<number>;

  @Input()
  public icon?: string;

  @Input()
  public iconType: IconTypeEnum = IconTypeEnum.webFont;

  @Input()
  public isClickable = true;

  public isComponentReady = false;

  public get isActivated(): boolean {
    return this.activated && this.isClickable;
  }

  public get isDisabled(): boolean {
    return !this.isClickable;
  }

  public get style(): any {
    const style: any = {
      'border-style': 'solid',
      'border-width': '1px',
    };
    if (this.color) {
      style['border-color'] = this.color;
      if (this.activated && this.isClickable) {
        style['background-color'] = '#ffffff';
        style.color = this.color;
      } else {
        style['background-color'] = this.color;
        style.color = '#ffffff';
      }
    }
    return style;
  }

  public disable(): void {
    this.activated = false;
  }

  public enable(): void {
    this.activated = true;
  }

  public isImageIcon(): boolean {
    return this.iconType === IconTypeEnum.image;
  }

  public isWebFontIcon(): boolean {
    return this.iconType === IconTypeEnum.webFont;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.isComponentReady = true;
    }, 0);
  }

  public toggle(): void {
    this.activated = !this.activated;
  }
}
