import { JsonObject, JsonProperty } from 'json2typescript';

import { DateJsonConverter } from '../../json-converters/date-json-converter';
import { PutRequest } from './put-request.model';
import { Attachment } from '../get/attachment.model';

/**
 * This class represents Work Order model
 */
@JsonObject('ServiceRequestPut')
export class ServiceRequestPut extends PutRequest {
  @JsonProperty('affectedTo', String, true)
  public affectedTo = '';

  @JsonProperty('assetNum', String, true)
  public assetNum = '';

  @JsonProperty('attachments', [Attachment], true)
  public attachments: Attachment[] = [];

  @JsonProperty('description', String, true)
  public description = '';

  @JsonProperty('hierarchyPath', String, true)
  public hierarchyPath = '';

  @JsonProperty('location', String, true)
  public location = '';

  @JsonProperty('priority', String, true)
  public priority = '';

  @JsonProperty('reportedDate', DateJsonConverter, true)
  public reportedDate: Date | null = null;

  @JsonProperty('siteId', String, true)
  // public siteId: string | null = null;
  public siteId = '';

  @JsonProperty('summary', String, true)
  public summary = '';

  @JsonProperty('ticketId', Number, true)
  public ticketId: number | null = null;
}
