import { LocalStorageKey, UserRights } from '@al/entities';
import { AlJsonConvert } from '@al/json';
import { Injectable } from '@angular/core';
import { LocalStorageManager } from '@al/cache';

@Injectable({
  providedIn: 'root',
})
export class UserRightsCreateCache {
  private alJsonConvert: AlJsonConvert;

  private cache: UserRights[];

  private cacheName = LocalStorageKey.USER_RIGHTS;

  private storageManager: LocalStorageManager<UserRights>;

  public constructor() {
    this.alJsonConvert = new AlJsonConvert();
    this.storageManager = new LocalStorageManager<UserRights>(this.cacheName);
    this.cache = this.alJsonConvert.deserializeArray(
      this.storageManager.load(),
      UserRights
    );
  }

  public clear(): void {
    this.storageManager.clear();
  }

  public put(userRight: UserRights): void {
    const value = userRight;
    const obj = this.cache.find((f) => f.type === value.type);
    if (obj) {
      if (value.actions === undefined) {
        value.actions = [];
      }

      value.actions.forEach((element) => {
        if (!obj.actions?.includes(element)) {
          if (obj.actions === undefined) {
            obj.actions = [];
          }
          obj.actions.push(element);
        }
      });
      if (value.status === undefined) {
        value.status = [];
      }

      value.status.forEach((element) => {
        if (!obj.status?.includes(element)) {
          if (obj.status === undefined) {
            obj.status = [];
          }
          obj.status.push(element);
        }
      });
    } else {
      this.cache.push(value);
    }

    this.storageManager.store(this.cache, UserRights);
  }

  public values(): UserRights[] {
    return this.cache;
  }
}
