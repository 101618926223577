import {
  AttachmentWoCreateCache,
  AttachmentWoCreateHistoryService,
  AttachmentWoUpdateCache,
  AttachmentWoUpdateHistoryService,
} from '@al/cache';
import { WorkOrder, WorkOrderPut, WorkOrderPutResponse } from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlOnlineService } from '@al/online';
import { AttachmentWoRestService } from '@al/rest';
import { Injectable } from '@angular/core';
import { AbstractSyncService } from './abstract-sync.service';
import { GtmSyncService } from './gtm-sync.service';
import { SyncInfoService } from './sync-info.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentWoSyncService extends AbstractSyncService<
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse
> {
  public constructor(
    protected restService: AttachmentWoRestService,
    protected createCache: AttachmentWoCreateCache,
    protected updateCache: AttachmentWoUpdateCache,
    protected createCachedHistoryService: AttachmentWoCreateHistoryService,
    protected updateCachedHistoryService: AttachmentWoUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  protected getNewResponse(): WorkOrderPutResponse {
    return new WorkOrderPutResponse();
  }
}
