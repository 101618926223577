import { LocalStorageKey } from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractWoHistoryService } from './abstract-wo-history.service';

@Injectable({
  providedIn: 'root',
})
export class StartCompletionUpdateHistoryService extends AbstractWoHistoryService {
  public constructor() {
    super(LocalStorageKey.START_COMPLETION_UPDATE_REQUEST_HISTORY);
  }
}
