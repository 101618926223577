import { JsonObject, JsonProperty } from 'json2typescript';
import { PutResponse } from './put-response.model';

/**
 * This class represents Work Order model
 */
@JsonObject('ServiceRequestPutResponse')
export class ServiceRequestPutResponse extends PutResponse {
  @JsonProperty('ticketId', Number, true)
  public ticketId: number | null = null;
}
