import { JsonObject, JsonProperty } from 'json2typescript';
import { PutResponse } from './put-response.model';

@JsonObject('WorkOrderPutResponse')
export class WorkOrderPutResponse extends PutResponse {
  @JsonProperty('workOrderId', Number, true)
  public workOrderId = null;

  @JsonProperty('workOrderNum', String, true)
  public workOrderNum = '';
}
