import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LocalStorageKey,
  ProcessGroup,
  ProcessGroupPut,
  ProcessGroupPutResponse,
  Site,
} from '@al/entities';
import { AwsEndPoint } from '@al/environment';
import { Observable } from 'rxjs';
import { environment } from '../../../../app/src/environments/environment';
import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
// use abstract Put types due to the miss of put action on this kind of model
export class ProcessGroupsRestService extends AbstractRestService<
  ProcessGroup,
  ProcessGroupPut,
  ProcessGroupPutResponse
> {
  protected activeSite!: Site;

  protected cacheDelay: number | null = environment.cacheDelayAsset;

  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  public constructor(protected http: HttpClient) {
    super(http);
    this.keyLastDate = LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_ROWSTAMP;
  }

  public getFromSite(site: Site): Observable<ProcessGroup[]> {
    this.activeSite = site;
    return this.get();
  }

  protected get createEndPoint(): string {
    throw new Error('Method not implemented.');
  }

  protected get getEndPoint(): string {
    return this.activeSite
      ? `${AwsEndPoint.processGroupGet}/${this.activeSite.uuid}`
      : '';
  }

  protected get updateEndPoint(): string {
    throw new Error('Method not implemented.');
  }

  protected getClazz(): typeof ProcessGroup {
    return ProcessGroup;
  }

  protected getPutRequestClazz(): typeof ProcessGroupPut {
    return ProcessGroupPut;
  }

  protected getPutResponseClazz(): typeof ProcessGroupPutResponse {
    return ProcessGroupPutResponse;
  }

  protected updateLastSyncDate(): void {
    const now = new Date().getTime();
    const lastDate = localStorage.getItem(this.keyLastDate);
    const lastDateTime = new Date(
      lastDate ? parseInt(lastDate, 10) : 0
    ).getTime();
    if (now - lastDateTime > environment.cacheDelayAsset) {
      localStorage.setItem(this.keyLastDate, new Date().getTime().toString());
    }
  }
}
