import { QuickReportPut, ServiceRequestPut, WorkOrderPut } from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { SiteQuery, WorkOrdersQuery } from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractCommonComponent } from '../common/abstract-common.component';
import { PhotoEditorComponent } from '../photo-editor/photo-editor.component';

@Component({
  selector: 'al-photo',
  templateUrl: './al-photo.component.html',
  styleUrls: ['./al-photo.component.scss'],
})
export class AlPhotoComponent
  extends AbstractCommonComponent
  implements OnDestroy
{
  @Input()
  public maxFileSize: number | null = null;

  @Input()
  public maxPendingFileTotalSize: number | null = null;

  @Input()
  public putRequest!:
    | (QuickReportPut | ServiceRequestPut | WorkOrderPut)
    | null;

  public constructor(
    protected alIndexedDbService: AlIndexedDbService,
    public dialog: MatDialog,
    protected siteQuery: SiteQuery,
    protected syncInfoService: SyncInfoService,
    protected workOrdersQuery: WorkOrdersQuery
  ) {
    super(
      alIndexedDbService,
      dialog,
      siteQuery,
      syncInfoService,
      workOrdersQuery
    );
  }

  public editPhoto(newFileName: string, dialog: MatDialog): void {
    dialog.open(PhotoEditorComponent, {
      data: newFileName,
      height: '90vh',
      width: '80vw',
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
