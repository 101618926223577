import { AlReferenceData } from './model/al-reference-data.model';

export const WorkOrderJobTypeReference: ReadonlyArray<AlReferenceData> = [
  { type: 'J1', label: 'Inspection Peridique ESP' },
  { type: 'J2', label: 'Verification EIS' },
  { type: 'J3', label: 'Consignations/Deconsignations' },
  { type: 'J4', label: 'Large revision' },
  { type: 'J5', label: 'Small revision' },
  { type: 'J6', label: 'Work preparation' },
  { type: 'J7', label: 'Others' },
  { type: 'J8', label: 'Analyzer Inspection' },
  { type: 'J9', label: 'Others Test ( no EIS)' },
  { type: 'J10', label: 'EIQ Inspection' },
  { type: 'J11', label: 'Overhaul' },
  { type: 'J12', label: 'ESP Requalification' },
];
