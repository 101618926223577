import { JsonObject, JsonProperty } from 'json2typescript';
import { Get } from './get.model';

/**
 * This class represents Attachement model
 */
@JsonObject('Attachment')
export class Attachment extends Get {
  @JsonProperty('filename', String)
  public filename = '';

  @JsonProperty('url', String)
  public url = '';
}
