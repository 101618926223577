import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { SyncInfo } from './sync-info.model';

export interface SyncPendingInfoState
  extends EntityState<SyncInfo>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'sync-info-pending-state',
  idKey: 'uuid',
  resettable: true,
})
export class SyncPendingInfoStore extends EntityStore<SyncPendingInfoState> {
  public constructor() {
    super();
  }
}
