import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';

import { AlEnvironmentService, AwsEndPoint } from '@al/environment';
import { takeUntil } from 'rxjs/operators';
import { AlWorkboxService } from '@al/workbox';
import { Auth } from 'aws-amplify';
import { SessionService } from '../../../al-session/src/lib/state/session.service';

@Injectable({
  providedIn: 'root',
})
export class AlOnlineService implements OnDestroy {
  public mustRenewToken: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public status: Observable<boolean>;

  private isInternet: boolean;

  private isNetworkConnected: boolean;

  private ngUnsubscribe = new Subject();

  private offlineEvent!: Observable<Event>;

  private onlineEvent!: Observable<Event>;

  private status$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private subscriptions: Subscription[] = [];

  public constructor(
    private environmentService: AlEnvironmentService,
    private workboxService: AlWorkboxService,
    protected sessionService: SessionService,
    private http: HttpClient
  ) {
    this.status = this.status$.asObservable();

    this.isInternet = false;

    this.isNetworkConnected = navigator.onLine;
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(
      this.onlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.isNetworkConnected = true;
        this.checkOnlineRequest();
      })
    );
    this.subscriptions.push(
      this.offlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.isNetworkConnected = false;
        this.workboxService.isConnected(this.isNetworkConnected);

        // this.checkOnlineRequest();
      })
    );
    this.checkOnlineRequest();

    setInterval(() => this.checkOnlineRequest(), 30000);
  }

  public checkOnlineRequest(): void {
    Auth.currentSession()
      .then(() => {
        const headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('crossorigin', 'anonymous');
        const url =
          this.environmentService.env.apigwUrl + AwsEndPoint.healthCheck;
        if (this.isNetworkConnected) {
          this.http
            .get(url, {
              headers,
              observe: 'body',
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.isInternet = true;
                this.workboxService.isConnected(this.isInternet);

                this.status$.next(this.onLine());
              },
              error: () => {
                this.isInternet = false;
                this.workboxService.isConnected(this.isInternet);
                this.status$.next(this.onLine());
              },
            });
        } else {
          this.isInternet = false;
          this.status$.next(this.onLine());
        }
      })
      .catch((error) => {
        this.workboxService.isConnected(true);
        console.error(error);
      });
    // const headers = new HttpHeaders();
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('crossorigin', 'anonymous');
    // const url = this.environmentService.env.apigwUrl + AwsEndPoint.healthCheck;
    // if (this.isNetworkConnected ) {
    //   this.http
    //     .get(url, {
    //       headers,
    //       observe: 'body',
    //     })
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe({
    //       next: () => {
    //         this.isInternet = true;
    //         this.workboxService.isConnected(this.isInternet);
    //
    //         this.status$.next(this.onLine());
    //       },
    //       error: () => {
    //         this.isInternet = false;
    //         this.workboxService.isConnected(this.isInternet);
    //         this.status$.next(this.onLine());
    //       },
    //     });
    // } else {
    //   this.isInternet = false;
    //   this.status$.next(this.onLine());
    // }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onLine(): boolean {
    return this.isNetworkConnected && this.isInternet;
  }
}
