<p translate mat-dialog-title class="title">ADD WORKLOG</p>
<mat-dialog-content>
  <div class="al-work-order-work-log">
    <form [formGroup]="workLogFormGroup">
      <div>
        <mat-form-field class="form-field-width">
          <mat-label><span translate>Log Type</span></mat-label>
          <mat-select formControlName="logType">
            <mat-option *ngFor="let typeLog of typeLogList" [value]="typeLog">
              {{ typeLog }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field-width">
          <mat-label><span translate>Summary</span></mat-label>
          <input matInput formControlName="description" />
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field-width">
          <mat-label><span translate>Details</span></mat-label>
          <textarea formControlName="descriptionLong" matInput></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions  class="actions">
  <div>
    <button
      mat-raised-button
      (click)="closeDialog()"
      color="primary"
      type="button"
    >
      {{ "cancel" | translate }}
    </button>
    <button
      [disabled]="!workLogFormGroup.valid"
      mat-raised-button
      color="primary"
      (click)="saveWorkLog()"
    >
      {{ "save" | translate }}
    </button>
  </div>
</mat-dialog-actions>
