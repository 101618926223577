import { AlReferenceData } from './model/al-reference-data.model';

export const WorkOrderSourceReference: ReadonlyArray<AlReferenceData> = [
  {
    code: 'ANOMALY',
    display: 'ANOMALY',
    label:
      'Action required following the report of an anomaly (not yet an event) or a nearmiss',
  },
  {
    code: 'AUDIT',
    display: 'AUDIT',
    label:
      'Audits & inspections (by Group audit, authorities, customers, local government agencies, etc.)',
  },
  {
    code: 'BEST_PRACTICE',
    display: 'BEST_PRACTICE',
    label:
      'Action suggested by IN - BP type document or Bulletins (Group or Local)',
  },
  {
    code: 'CAPEX_PROJECT',
    display: 'CAPEX_PROJECT',
    label:
      'Action created as part of a large CAPEX Project (for small modifications : use MOC as source)',
  },
  {
    code: 'CRISIS_MANAGEMENT',
    display: 'CRISIS_MANAGEMENT',
    label:
      'Action required following a major accident (or simulation of) for crisis mngt readiness',
  },
  {
    code: 'CUSTOMER',
    display: 'CUSTOMER',
    label:
      'Action following a request from a customer and beneficial to Customer Satisfaction',
  },
  {
    code: 'EROUND',
    display: 'EROUND',
    label:
      'Action generated by the eROUND application following an observation or anomaly',
  },
  {
    code: 'EVENT',
    display: 'EVENT',
    label:
      'Action required following a site event (incident or accident or PSSI)',
  },
  {
    code: 'FLASH_COUNTRY',
    display: 'FLASH-COUNTRY',
    label: 'Action required by a flash issued by Cluster/Country',
  },
  {
    code: 'FLASH_LOCAL',
    display: 'FLASH-LOCAL',
    label:
      'Action required by a flash issued by local entities (Region, Basin, Site, Facility)',
  },
  {
    code: 'FLASH_WIM_OR_GROUP',
    display: 'FLASH-WIM_OR_GROUP',
    label: 'Action required by a flash issued by AL Group (SIS, WIM, etc)',
  },
  {
    code: 'IMS_DOC_GT_GP_ST_SP_',
    display: 'IMS_DOC(GT,GP_ST,SP)',
    label:
      'Mandatory action required by a Group GT, GP or Subsidiary ST, SP type document',
  },
  {
    code: 'L1_CARE',
    display: 'L1_CARE',
    label:
      'Instruction prescribed to maintenance in which a work order is created',
  },
  {
    code: 'LEGAL_REGULATORY',
    display: 'LEGAL_REGULATORY',
    label:
      'Mandatory action as part of a legal, regulatory or contractual requirement',
  },
  {
    code: 'MAINT_DEFERRAL',
    display: 'MAINT-DEFERRAL',
    label:
      'Action required as a consequence / risk mitigation of a postponed Maintenance (deferral)',
  },
  {
    code: 'MAINT_PM_ROUTE',
    display: 'MAINT-PM/ROUTE',
    label: 'Action automatically created by a PM / ROUTE',
  },
  {
    code: 'MAINT_PREDIC_CATCH',
    display: 'MAINT-PREDIC_CATCH',
    label:
      'Action following a catch from oil / vibration analysis, motor partial discharge, etc - Not from SIO.Predict',
  },
  {
    code: 'MAINT_ROADMAP',
    display: 'MAINT-ROADMAP',
    label:
      'Action decided as part of the annual maintenance strategy review or roadmap',
  },
  {
    code: 'MAINT_SIO_PREDICT',
    display: 'MAINT-SIO.PREDICT',
    label: 'Action following a catch from SIO.Predict',
  },
  {
    code: 'MAINT_TAR_CAPITALIZ',
    display: 'MAINT-TAR_CAPITALIZ',
    label: 'Action decided as the result of a TAR capitalization review',
  },
  {
    code: 'MGMT_REVIEW_DECISION',
    display: 'MGMT_REVIEW&DECISION',
    label: 'Action decided by local Management team or Technical Committee',
  },
  {
    code: 'MOC',
    display: 'MOC',
    label:
      'Action part of an MOC project (for major projects : use CAPEX PROJECT)',
  },
  {
    code: 'ON_CALL_LEVEL1',
    display: 'ON_CALL-LEVEL1',
    label:
      'Action following a call from the automated on-call system - Requiring recall to the site',
  },
  {
    code: 'ON_CALL_LEVEL2',
    display: 'ON_CALL-LEVEL2',
    label:
      'Action following a call from the automated on-call system - No recall to the site',
  },
  {
    code: 'ON_CALL_LEVEL3',
    display: 'ON_CALL-LEVEL3',
    label:
      'Action following a call from the automated on-call system - During working hours',
  },
  {
    code: 'PCMS_RISK_BASED_INS',
    display: 'PCMS(RISK-BASED_INS)',
    label:
      'Action required as the result of an Inspection (automatically created by PCMS)',
  },
  {
    code: 'RISK_ANALYSIS',
    display: 'RISK_ANALYSIS',
    label:
      'Action listed as the output of an ARA, FMEA, HAZOP or other formal risk analysis',
  },
  {
    code: 'SIO_CENTER_ELOGBOOK',
    display: 'SIO_CENTER/ELOGBOOK',
    label:
      'Action requested or required by the SIO CENTER for optimization or investigation purpose',
  },
  {
    code: 'STUDY_SUSTAINABILITY',
    display: 'STUDY-SUSTAINABILITY',
    label:
      'Action required following a Sustainability Study / contract renewal',
  },
  {
    code: 'STUDY_VULNERABILITY',
    display: 'STUDY-VULNERABILITY',
    label: 'Requirements from a Vulnerability Study',
  },
];
