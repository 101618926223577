<div class="al-work-order-work-log-list">
    <div class="header">
      <h1 translate>WORK LOGS:</h1>
      <span class="completedCount">{{workLogCount}}</span>

      <ng-container *ngIf="viewDetails; else elseTemplate">
        <span class="details" (click)="toggleViewDetails()" translate>Hide details</span>
      </ng-container>
      <ng-template #elseTemplate>
        <span class="details" (click)="toggleViewDetails()" translate>View details</span>
      </ng-template>

      <span class="spacer"></span>



    <button (click)="startNewWorkLog()" class="button" mat-raised-button color="primary">{{'ADD WORKLOG'|translate}}</button>

    </div>
    <div class="content" [ngClass]="{ show: viewDetails, hide: !viewDetails }">
     <!--  <al-work-order-task *ngFor="let task of taskList;let index = index;" [workOrder]="workOrder" [index]="index"
        [task]="task">
      </al-work-order-task> -->
      <!-- <al-work-order-work-log></al-work-order-work-log> -->
      <div class="al-work-order-list">

        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
         <!--- Note that these columns can be defined in any order.
               The actual rendered columns are set as a property on the row definition" -->

         <!--/ WO Id /-->
         <ng-container matColumnDef="createdBy">
           <mat-header-cell *matHeaderCellDef class="id">
            {{'Created By' | translate }}
           </mat-header-cell>
           <mat-cell *matCellDef="let element" data-label="Created By" class="id">
             {{ element.createdBy }}
           </mat-cell>
         </ng-container>

         <!--/ WO Short description /-->
         <ng-container matColumnDef="createDate">
           <mat-header-cell *matHeaderCellDef class="description">
            {{'Create Date' | translate }}
           </mat-header-cell>
           <mat-cell *matCellDef="let element" data-label="Create Date" class="description">
             {{ element.createDate | localizedDate :'short'  }}
           </mat-cell>
         </ng-container>

         <!--/ Equipment /-->
         <ng-container matColumnDef="type">
           <mat-header-cell *matHeaderCellDef class="asset-num">
            {{'Log Type' | translate }}
           </mat-header-cell>
           <mat-cell *matCellDef="let element" data-label="Type" class="asset-num">
             {{element.logType}}
           </mat-cell>
         </ng-container>

         <!--/ Location /-->
         <ng-container matColumnDef="description">
           <mat-header-cell *matHeaderCellDef class="location">
            {{'Summary' | translate}}
           </mat-header-cell>
           <mat-cell *matCellDef="let element" data-label="Summary" class="location">
             <div (click)="openDetailsLongDescription(element)" class="overflow-text click-text">{{ element.description }}</div>
           </mat-cell>
         </ng-container>
         <mat-header-row
         data-label="Filters"
         *matHeaderRowDef="displayedColumns; sticky: true"
       ></mat-header-row>
          <mat-row
           *matRowDef="let row; columns: displayedColumns"
         ></mat-row>
       </mat-table>
     </div>

    </div>
  </div>
