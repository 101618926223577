import { JsonObject, JsonProperty } from 'json2typescript';
import { PutRequest } from './put-request.model';

@JsonObject('GtmPut')
export class GtmPut extends PutRequest {
  @JsonProperty('EMV2_SOURCE', String, true)
  public EMV2Source: string | null = null;

  @JsonProperty('ASSET', String, true)
  public asset: string | null = null;

  @JsonProperty('event', String, true)
  public event: string | null = null;

  @JsonProperty('JOBTYPE_CODE', String, true)
  public jobType: string | null = null;

  @JsonProperty('LOCATION', String)
  public location: string | null = null;

  @JsonProperty('NB_LINKS_ATTACHED', Number, true)
  public nbLinkAttached: number | null = 0;

  @JsonProperty('NB_MAXIMO_ATTEMPTS', [String], true)
  public nbMaximoAttempts: string[] = [];

  @JsonProperty('NUMBER', String, true)
  public number: string | null = null;

  @JsonProperty('ON_LINE', Boolean, true)
  public onLine: boolean | null = null;

  @JsonProperty('PRIORITY', String, true)
  public priority: string | null = null;

  @JsonProperty('SENT_AFTER_SYNC_ERR_CORRECTION', Boolean, true)
  public sendAfterNbCorrection: boolean | null = false;

  @JsonProperty('SITE_ID', String)
  public siteId: string | null = null;

  @JsonProperty('WITH_FAILURE_REPORT', Boolean, true)
  public withFailureReport: boolean | null = null;

  @JsonProperty('WITH_WO_CLOSURE', Boolean, true)
  public withWoClosure: boolean | null = null;

  @JsonProperty('WORKTYPE_CODE', String, true)
  public workType: string | null = null;
}
