import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { SessionRights } from '@al/session';

import {
  LocalStorageConfiguration,
  RightActionEnum,
  RightStatusEnum,
  RightTypeEnum,
  TaskStatus,
  WorkOrder,
  WorkOrderPutResponse,
  WorkOrderStatus,
} from '@al/entities';
import {
  AlRequestInformationDialogComponent,
  AlRequestInformationDialogData,
  AlRequestInformationDialogDataWoComp,
} from '@al/request-information-dialog';
import { AlGtmService } from '@al/gtm';
import { AlWorkOrderFailureReportService } from '@al/work-order-failure-report';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AlSpinnerService } from '@al/spinner';
import { UUID } from 'angular2-uuid';
import { AlWorkOrderService } from './al-work-order.service';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss'],
})
export class WorkOrderComponent implements OnDestroy {
  @ViewChild('cancelDialog')
  public cancelDialog!: TemplateRef<any>;

  @ViewChild('subNCloseDialog')
  public subNCloseDialog!: TemplateRef<any>;

  @ViewChild('submitDialog')
  public submitDialog!: TemplateRef<any>;

  public errors: { param: object; text: string }[] = [];

  public rights = new SessionRights();

  public workOrder!: WorkOrder;

  private closeOrComp!: string;

  private dialogRef!: MatDialogRef<any, any>;

  private failureReportIsModified: boolean;

  private ngUnsubscribe = new Subject();

  private woTypeMandatory = ['M13', 'M31', 'M32'];

  public constructor(
    public dialog: MatDialog,
    public gtmService: AlGtmService,
    private router: Router,
    private alSpinnerService: AlSpinnerService,
    private alWorkOrderFailureReportService: AlWorkOrderFailureReportService,
    private alWorkOrdersService: AlWorkOrderService,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersService: WorkOrdersService
  ) {
    this.gtmService.setWorkOrderAsSource();
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
          if (this.workOrder.tasks.length === 0) {
            this.workOrdersService.setActDateDebut(this.workOrder, new Date());
          }
        }
      });

    this.failureReportIsModified = false;
    this.alWorkOrderFailureReportService.failureReportIsModified.subscribe(
      (failureReportIsModified) => {
        this.failureReportIsModified = failureReportIsModified;
      }
    );
  }

  public get canCloseWorkOrder(): boolean {
    if (this.workOrder && this.workOrder.status === WorkOrderStatus.COMP) {
      return true;
    }
    return false;
  }

  /**
   * Retourne true si toutes les tasks sont skipped or done (=== dernière task skipped or done)
   */

  public get canSubmitWorkOrder(): boolean {
    if (this.workOrder && this.workOrder.status === WorkOrderStatus.RLSD) {
      const taskCount = this.workOrder.tasks.length;
      const endTaskCount = this.workOrder.tasks.filter(
        (task) =>
          task.status === TaskStatus.COMP || task.status === TaskStatus.CAN
      ).length;
      if (taskCount === endTaskCount) {
        return true;
      }
    }
    return false;
  }

  public get childWOCount(): number {
    return this.workOrder.childwo.length || 0;
  }

  /**
   * retourne true si startCompletion === true
   */
  public get displaySubmitWorkOrder(): boolean {
    if (this.canSubmitOrCloseWO()) {
      if (this.workOrder && this.workOrder.actDateDebut) {
        return true;
      }
      if (this.workOrder && this.workOrder.tasks.length === 0) {
        return true;
      }
    }
    return false;
  }

  public get getWorkOrderDescription(): string {
    return this.workOrder && this.workOrder.description
      ? this.workOrder.description
      : '';
  }

  public get getWorkOrderStatus(): string {
    return this.workOrder && this.workOrder.status ? this.workOrder.status : '';
  }

  public get haveFailureReport(): boolean {
    const testVal = this.workOrder;
    return testVal.failureMarkDescription !== null;
  }

  public get isFRMandatory(): boolean {
    if (this.workOrder && this.woTypeMandatory.includes(this.workOrder.type)) {
      if (this.haveFailureReport) {
        return true;
      }
      return false;
    }
    return true;
  }

  public closeDialog(isConfirmed: boolean): void {
    this.dialogRef.close(isConfirmed);
  }

  public disableButton(type: string): boolean {
    if (type === 'WO') {
      return this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      );
    }
    if (type === 'QR') {
      return this.rights.isAllowedAction(
        RightTypeEnum.QUICKREP,
        RightActionEnum.INSERT
      );
    }
    return false;
  }

  public disableButtonClose(): boolean {
    return (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) &&
      this.rights.isAllowedStatus(
        RightTypeEnum.WOTRACK,
        RightStatusEnum.CLOSE
      ) &&
      (this.canSubmitWorkOrder || this.canCloseWorkOrder) &&
      this.isFRMandatory
    );
  }

  public disableButtonSubmit(): boolean {
    return (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) &&
      this.rights.isAllowedStatus(
        RightTypeEnum.WOTRACK,
        RightStatusEnum.COMP
      ) &&
      this.canSubmitWorkOrder &&
      this.isFRMandatory
    );
  }

  public isParentWO(): boolean {
    if (
      this.workOrder &&
      this.workOrder.childwo &&
      this.workOrder.childwo.length > 0
    ) {
      return true;
    }
    return false;
  }

  public navigateTo(target: string) {
    if (this.failureReportIsModified) {
      this.dialogRef = this.dialog.open(this.cancelDialog);
      this.dialogRef.afterClosed().subscribe((e) => {
        if (e) {
          this.router.navigate([target], { state: { inherit: true } });
        }
      });
    } else {
      this.router.navigate([target], { state: { inherit: true } });
    }
  }

  public nextWorkOrder(): void {
    if (this.failureReportIsModified) {
      this.dialogRef = this.dialog.open(this.cancelDialog);
      this.dialogRef.afterClosed().subscribe((e) => {
        if (e) {
          this.workOrdersService.setNextActive();
        }
      });
    } else {
      this.workOrdersService.setNextActive();
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public previousWorkOrder(): void {
    if (this.failureReportIsModified) {
      this.dialogRef = this.dialog.open(this.cancelDialog);
      this.dialogRef.afterClosed().subscribe((e) => {
        if (e) {
          this.workOrdersService.setPrevActive();
        }
      });
    } else {
      this.workOrdersService.setPrevActive();
    }
  }

  public returnHome(): void {
    if (this.failureReportIsModified) {
      this.dialogRef = this.dialog.open(this.cancelDialog);
      this.dialogRef.afterClosed().subscribe((e) => {
        if (e) {
          this.router.navigate(['/']);
        }
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  public subCloseDialog(): void {
    this.errors = [];
    this.errors.push({
      param: {
        count: this.childWOCount,
      },
      text: 'Warning: this work order is a parent WO and has {{ count }} child work orders',
    });
    this.dialogRef = this.dialog.open(this.subNCloseDialog);
    this.dialogRef.afterClosed().subscribe((e) => {
      if (e) {
        this.submitAndCloseWorkOrder();
      }
    });
  }

  public subDialog(): void {
    this.errors = [];
    this.errors.push({
      param: {
        count: this.childWOCount,
      },
      text: 'Warning: this work order is a parent WO and has {{ count }} child work orders',
    });
    this.dialogRef = this.dialog.open(this.submitDialog);
    this.dialogRef.afterClosed().subscribe((e) => {
      if (e) {
        this.submitWorkOrder();
      }
    });
  }

  public submitAndCloseWorkOrder(): void {
    this.submitWO('close', this.alWorkOrdersService.submitAndClose());
  }

  public submitWorkOrder(): void {
    this.submitWO('comp', this.alWorkOrdersService.submit());
  }

  private buildDialogInformation(
    res: WorkOrderPutResponse
  ): AlRequestInformationDialogData<AlRequestInformationDialogDataWoComp> {
    const { type, error, siteId, status, workOrderId, workOrderNum } = res;
    return {
      title: 'updateWorkOrder',
      type: 'woCompClose',
      information: {
        action: type,
        compOrClosed: this.closeOrComp,
        error: error ?? undefined,
        siteId,
        status,
        workOrderId: workOrderId ?? undefined,
        workOrderNum,
      },
    };
  }

  private canSubmitOrCloseWO(): boolean {
    if (this.isParentWO()) {
      return this.isSupervisor();
    }
    return true;
  }

  private displayCreation(data: WorkOrderPutResponse): void {
    AlRequestInformationDialogComponent.open<
      WorkOrderPutResponse,
      AlRequestInformationDialogDataWoComp
    >(data, this.dialog, this.buildDialogInformation.bind(this))
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (data.status !== 'NOT_OK') {
          this.router.navigate(['/']);
        }
      });
  }

  private isSupervisor(): boolean {
    const userId = localStorage.getItem(LocalStorageConfiguration.USER_ID);
    const supervisorId = this.workOrder.superviseur;
    if (userId && supervisorId && userId === supervisorId) {
      return true;
    }
    return false;
  }

  private submitWO(
    closeOrComp: string,
    obs: Observable<WorkOrderPutResponse | null>
  ): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(uuid);
    this.closeOrComp = closeOrComp;
    obs
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: WorkOrderPutResponse | null) => {
        if (response) {
          this.displayCreation(response);
        }
        this.alSpinnerService.stopAssetProcess(uuid);
      });
  }
}
