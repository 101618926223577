<div class="al-work-order-task-list">
  <div class="header">
    <h1 translate>TASK LIST:</h1>
    <span class="completedCount">{{completedTaskCount}}/{{taskCount}}</span>

    <ng-container *ngIf="viewDetails; else elseTemplate">
      <span class="details" (click)="toggleViewDetails()" translate>Hide details</span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="details" (click)="toggleViewDetails()" translate>View details</span>
    </ng-template>

    <span class="spacer"></span>

    <button *ngIf="canStartCompletion()" [disabled]="isStartCompletionDisabled()" (click)="startCompletion()"
      class="button" mat-raised-button color="primary">{{'START COMPLETION'|translate}}</button>

  </div>
  <div class="content" [ngClass]="{ show: viewDetails, hide: !viewDetails }">
    <al-work-order-task *ngFor="let task of taskList;let index = index;" [workOrder]="workOrder" [index]="index"
      [task]="task">
    </al-work-order-task>
  </div>
</div>
