import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { AlDialogModule } from '@al/dialog';
import { AlNavigationModule } from '@al/navigation';
import { AlQuickReportModule } from '@al/quick-report';

import { QuickReportComponent } from './quick-report.component';

@NgModule({
  declarations: [QuickReportComponent],
  imports: [
    AlQuickReportModule,
    TranslateModule,
    AlNavigationModule,
    CommonModule,
    AlDialogModule,
    MatButtonModule,
  ],
})
export class QuickReportModule {}
