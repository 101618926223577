import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { AlSessionLoginComponent } from './al-session-login/al-session-login.component';
import { AlSessionCasComponent } from './al-session-cas/al-session-cas.component';

@NgModule({
  declarations: [AlSessionCasComponent, AlSessionLoginComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [AlSessionCasComponent, AlSessionLoginComponent],
})
export class AlSessionModule {}
