import { JsonObject, JsonProperty } from 'json2typescript';
import { Attachment } from './attachment.model';
import { WorkOrder } from './work-order.model';
import { RootCause } from './code-and-description/root-cause.model';
import { Get } from './get.model';

/**
 * This class represents Failure Report model
 */
@JsonObject('FailureReport')
export class FailureReport extends Get {
  @JsonProperty('attachments', [Attachment], true)
  public attachments: Attachment[] = [];

  @JsonProperty('detail', String, true)
  public detail = '';

  @JsonProperty('endDate', Date, true)
  public endDate: Date | null = null;

  @JsonProperty('failureAcceptable', Boolean, true)
  public failureAcceptable = false;

  @JsonProperty('failureClassId', String, true)
  public failureClassId = '';

  @JsonProperty('failureDate', Date, true)
  public failureDate: Date | null = null;

  @JsonProperty('rootCauseId', RootCause, true)
  public rootCauseId: RootCause | null = null;

  @JsonProperty('startDate', String, true)
  public startDate: Date | null = null;

  @JsonProperty('summary', String, true)
  public summary = '';

  @JsonProperty('workOrder', WorkOrder, true)
  public workOrder: WorkOrder | null = null;
}
