/**
 * Three status must be implemented to qualify the calibration measures:
 * a. CHECKED : status displayed if the measure correspond to the expected value in Maximo
 * b. ADJUSTMENT : status displayed if (standard input - Tolerance) < measure > standard input
 *             OR standard input < measure > (standard input + Tolerance)
 * c. ALARM : status displayed if measure < (standard input - Tolerance)
 */
export enum CalibrationStatus {
  ADJUSTMENT = 'ADJUSTMENT',
  ALARM = 'ALARM',
  CHECKED = 'CHECKED',
}
