import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  Get,
  GtmPut,
  LocalStorageConfiguration,
  PutRequest,
  PutResponse,
} from '@al/entities';
import { AlOnlineService } from '@al/online';
import { AbstractRestService } from '@al/rest';
import { map, take, takeUntil } from 'rxjs/operators';

import { AbstractHistoryService, HistoryElement, LocalCache } from '@al/cache';
import { AlIndexedDbService } from '@al/indexed-db';
import { SyncInfoService } from './sync-info.service';
import { GtmSyncService } from './gtm-sync.service';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractSyncService<
  M extends Get,
  P extends PutRequest,
  R extends PutResponse
> implements OnDestroy
{
  protected TAG = 'AbstractSyncService>>>';

  private ngUnsubscribe = new Subject();

  private ngUnsubscribeFromUpload = new Subject();

  public constructor(
    protected restService: AbstractRestService<M, P, R>,
    protected createCache: LocalCache<P>,
    protected updateCache: LocalCache<P>,
    protected createCachedHistoryService: AbstractHistoryService<P, R>,
    protected updateCachedHistoryService: AbstractHistoryService<P, R>,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {}

  public create(value: P): Observable<R> {
    // dans tous les cas on cree le GTM en cache avec l'id du work, si il n'existe pas
    // on ou off line il faut le creer
    this.gtmSyncService.getGtmTag(value.uuid);
    // mise a jour du gtm tag avec les données de 'value'
    this.fillGtmWithData(value);
    // sauvegarde du tag modifié
    if (this.onLineService.onLine()) {
      // do the request no need to cache on online mode
      return this.restService.create(value).pipe(
        map((res) => {
          // mise a jour de l'objet en gtm avec la reponse
          this.fillGtmWithResponse(
            this.gtmSyncService.getGtmTag(value.uuid),
            res
          );
          return res;
        })
      );
    }

    this.createCache.put(value);
    this.syncInfoService.initStoreFromCache();
    return this.getOffLineResponse();
  }

  // le get fait appel au au rest via le package sync
  // pas d'appel direct du package rest sans passer par al-sync-helper
  public get(reload: boolean = false): Observable<M[]> {
    return this.restService.get(reload).pipe(
      map((data: M[]) => {
        return data;
      })
    );
  }

  public getCacheValue(): M[] {
    return [];
  }

  public getPendingCount(): number {
    const pendingCreateCache = this.createCache.values().length;
    const pendingUpdateCache = this.updateCache.values().length;
    return pendingCreateCache + pendingUpdateCache;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public pushCreateCache(): Observable<Boolean> {
    const obs = new BehaviorSubject<Boolean>(false);
    if (this.createCache.values().length === 0) {
      obs.next(true);
    } else {
      this.sendPushCreateCacheRequests(this.createCache.values(), 0, obs);
    }
    return obs.asObservable();
  }

  public pushUpdateCache(): Observable<Boolean> {
    const obs = new BehaviorSubject<Boolean>(false);
    if (this.updateCache.values().length === 0) {
      obs.next(true);
    } else {
      this.sendPushUpdateCacheRequests(this.updateCache.values(), 0, obs);
    }
    return obs.asObservable();
  }

  public update(value: P): Observable<R> {
    this.gtmSyncService.getGtmTag(value.uuid);
    this.fillGtmWithData(value);

    if (this.onLineService.onLine()) {
      // do the request no need to cache on online mode
      return this.restService.update(value).pipe(
        map((res) => {
          // mise a jour de l'objet en gtm avec la reponse
          this.fillGtmWithResponse(
            this.gtmSyncService.getGtmTag(value.uuid),
            res
          );
          return res;
        })
      );
    }
    this.updateCache.put(value);
    this.syncInfoService.initStoreFromCache();
    return this.getOffLineResponse();
  }

  protected addOffLineGtm(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected fillGtmWithData(value: P): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected fillGtmWithResponse(tag: GtmPut, res: R): void {}

  /**
   * Return specific offline response if necessary
   */
  protected getOffLineResponse(): Observable<R> {
    const response = this.getNewResponse();
    response.status = 'PENDING';
    response.siteId = <string>(
      localStorage.getItem(LocalStorageConfiguration.SITE_KEY)
    );
    return new Observable<R>((observer) => observer.next(response));
  }

  private finalizeUpdate(
    createCacheValues: any[],
    count: number,
    obs: BehaviorSubject<Boolean>,
    response: any
  ): void {
    // TODO: vérifier la gestion des erreurs et du cache
    this.createCachedHistoryService.put(
      new HistoryElement<P, R>(createCacheValues[count], response)
    );
    this.createCache.remove(createCacheValues[count]);
    this.syncInfoService.initStoreFromCache();

    this.gtmSyncService.addGenericReponseInformation(
      this.gtmSyncService.getGtmTag(createCacheValues[count].uuid),
      response
    );

    if (Object.is(createCacheValues.length - 1, count)) {
      obs.next(true);
      this.ngUnsubscribeFromUpload.next();
      this.ngUnsubscribeFromUpload.complete();
    } else {
      this.ngUnsubscribeFromUpload.next();
      this.ngUnsubscribeFromUpload.complete();
      this.sendPushCreateCacheRequests(createCacheValues, count + 1, obs);
    }
  }

  private sendPushCreateCacheRequests(
    createCacheValues: any[],
    count: number,
    obs: BehaviorSubject<Boolean>
  ) {
    this.restService
      .create(createCacheValues[count])
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (
            createCacheValues[count] &&
            createCacheValues[count].uuid &&
            response &&
            response.workOrderNum
          ) {
            this.alIndexedDbService
              .updateWorkOrderNumber(
                createCacheValues[count].uuid,
                response.workOrderNum
              )
              .pipe(takeUntil(this.ngUnsubscribeFromUpload))
              .subscribe((res) => {
                if (res === true) {
                  this.finalizeUpdate(createCacheValues, count, obs, response);
                }
              });
          } else if (
            createCacheValues[count] &&
            createCacheValues[count].uuid &&
            response &&
            response.ticketId
          ) {
            this.alIndexedDbService
              .updateTicketId(createCacheValues[count].uuid, response.ticketId)
              .pipe(takeUntil(this.ngUnsubscribeFromUpload))
              .subscribe((res) => {
                if (res === true) {
                  this.finalizeUpdate(createCacheValues, count, obs, response);
                }
              });
          } else {
            this.finalizeUpdate(createCacheValues, count, obs, response);
          }
        },
        error: (error) => {
          // FIXME: gérer les code 500 de Maximo ici
          this.createCachedHistoryService.put(
            new HistoryElement<P, R>(createCacheValues[count], error)
          );
          this.createCache.remove(createCacheValues[count]);
          this.syncInfoService.initStoreFromCache();

          // si erreur on enrichi le gtm
          if (Object.is(createCacheValues.length - 1, count)) {
            obs.next(true);
          } else {
            this.sendPushCreateCacheRequests(createCacheValues, count + 1, obs);
          }
        },
        complete: () => {
          this.createCache.remove(createCacheValues[count]);
          this.syncInfoService.initStoreFromCache();
        },
      });
  }

  private sendPushUpdateCacheRequests(
    updateCacheValues: any[],
    count: number,
    obs: BehaviorSubject<Boolean>
  ) {
    this.restService
      .update(updateCacheValues[count])
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          // TODO: verifier la gestion des erreurs et du cache
          this.updateCachedHistoryService.put(
            new HistoryElement<P, R>(updateCacheValues[count], response)
          );
          this.updateCache.remove(updateCacheValues[count]);
          this.syncInfoService.initStoreFromCache();
          this.gtmSyncService.addGenericReponseInformation(
            this.gtmSyncService.getGtmTag(updateCacheValues[count].uuid),
            response
          );
          if (Object.is(updateCacheValues.length - 1, count)) {
            obs.next(true);
          } else {
            this.sendPushUpdateCacheRequests(updateCacheValues, count + 1, obs);
          }
        },
        // FIX ME : gerer les code 500 de minimo ici
        error: (error) => {
          this.updateCachedHistoryService.put(
            new HistoryElement<P, any>(updateCacheValues[count], error)
          );
          this.updateCache.remove(updateCacheValues[count]);
          this.syncInfoService.initStoreFromCache();
          if (Object.is(updateCacheValues.length - 1, count)) {
            obs.next(true);
          } else {
            this.sendPushUpdateCacheRequests(updateCacheValues, count + 1, obs);
          }
        },
        complete: () => {
          this.updateCache.remove(updateCacheValues[count]);
          this.syncInfoService.initStoreFromCache();
        },
      });
  }

  protected abstract getNewResponse(): R;
}
