import { AlAssetsTreeComponent } from '@al/assets-tree';
import {
  PutResponse,
  QuickReportPut,
  QuickReportPutResponse,
  ServiceRequestPut,
  ServiceRequestPutResponse,
  WorkOrderPut,
  WorkOrderPutResponse,
} from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlReference } from '@al/reference';
import { AlRequestInformationDialogDataInformation } from '@al/request-information-dialog';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  SiteQuery,
} from '@al/state';
import { SyncInfo } from '@al/sync-services';
import { TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class AlCommonComponent {
  public readonly DESCRIPTION_MAX_LENGTH = 150;

  public readonly LONG_DESCRIPTION_MAX_LENGTH = 850;

  public assetObject!: any;

  public form: FormGroup;

  public locationObject!: any;

  public references!: AlReference;

  public synchroInfo!: SyncInfo | undefined;

  protected ngUnsubscribe = new Subject();

  public abstract formGroupDirective: FormGroupDirective | undefined;

  public abstract putRequest:
    | (QuickReportPut | ServiceRequestPut | WorkOrderPut)
    | null;

  protected abstract reloaded:
    | (QuickReportPut | ServiceRequestPut | WorkOrderPut)
    | null;

  public constructor(
    public dialog: MatDialog,
    protected alIndexedDbService: AlIndexedDbService,
    protected router: Router,
    private fb: FormBuilder,
    private processGroupService: ProcessGroupsService,
    private productionUnitsService: ProductionUnitsService,
    private equipmentsService: EquipmentsService,
    private siteQuery: SiteQuery
  ) {
    this.form = this.fb.group({});
  }

  public cancel(): void {
    if (this.putRequest && this.putRequest.uuid) {
      this.alIndexedDbService.removeWithUuid(this.putRequest.uuid);
    }
    this.router.navigate(['/']);
  }

  public clearLocation(): void {
    this.locationObject = null;
    this.assetObject = null;
    this.form.get('location')?.setValue('');
    this.form.get('asset')?.setValue('');
  }

  public displayTree(): void {
    const dialogRef = this.dialog.open(AlAssetsTreeComponent, {
      width: '75vw',
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result) {
          if (result.locationPU) {
            this.locationObject = result.locationPU;
            this.form.get('location')?.setValue(this.displayProperty());
          } else {
            this.locationObject = result.locationPG;
            this.form.get('location')?.setValue(this.displayProperty());
          }
          if (result.asset) {
            this.assetObject = result.asset;
            this.form.get('asset')?.setValue(this.displayProperty());
          } else {
            this.form.get('asset')?.setValue('');
          }
        }
      });
  }

  public openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  protected displayProperty(): string {
    if (this.assetObject) {
      return `${this.assetObject.number} :  ${this.assetObject.description}`;
    }
    if (this.locationObject.type === 'PG') {
      return `${this.locationObject.number} :  ${this.locationObject.name}`;
    }
    if (this.locationObject.type === 'PU') {
      return `${this.locationObject.id} :  ${this.locationObject.name}`;
    }
    return '';
  }

  protected getAsset(): string {
    if (this.assetObject) {
      return this.assetObject.number ? `${this.assetObject.number}` : '';
    }
    return '';
  }

  protected getLocation(): string {
    if (this.locationObject) {
      if (this.locationObject.type === 'PG') {
        return this.locationObject.number
          ? `${this.locationObject.number}`
          : '';
      }
      if (this.locationObject.type === 'PU') {
        return this.locationObject.id ? `${this.locationObject.id}` : '';
      }
    }
    return '';
  }

  protected getSiteId(): string {
    const activeSite = this.siteQuery.getActive();
    if (activeSite) {
      const { maximoId } = activeSite;
      return maximoId;
    }
    return '';
  }

  protected initLocation(): void {
    if (this.reloaded && this.reloaded.location) {
      this.processGroupService
        .getByNumber(this.reloaded.location.toString())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (processGroup) => {
            if (processGroup) {
              this.locationObject = processGroup;
              this.form.get('location')?.setValue(this.displayProperty());
            }
          },
          error: (error) => {
            // TODO: Utiliser un logger global
            // eslint-disable-next-line no-console
            console.error(error);
          },
        });

      this.productionUnitsService
        .getById(this.reloaded.location.toString())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (productionUnit) => {
            if (productionUnit) {
              this.locationObject = productionUnit;
              this.form.get('location')?.setValue(this.displayProperty());
            }
          },
          error: (error) => {
            // TODO: Utiliser un logger global
            // eslint-disable-next-line no-console
            console.error(error);
          },
        });
      if (this.reloaded.assetNum) {
        this.equipmentsService
          .getById(this.reloaded.assetNum.toString())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (asset) => {
              if (asset) {
                this.assetObject = asset;
                this.form.get('asset')?.setValue(this.displayProperty());
              }
            },
            error: (error) => {
              // TODO: Utiliser un logger global
              // eslint-disable-next-line no-console
              console.error(error);
            },
          });
      }
    }
  }

  protected resetForm(): void {
    this.synchroInfo = undefined;
    this.reloaded = null;
    this.putRequest = null;
    this.putRequest = this.getNewPutRequest();
    setTimeout(() => {
      this.formGroupDirective?.resetForm();
      this.initFormGroup();
      this.alIndexedDbService.change.next();
    }, 0);
  }

  protected abstract buildDialogInformation(
    res: PutResponse
  ): AlRequestInformationDialogDataInformation;

  protected abstract buildFormGroup(): FormGroup;

  protected abstract displayCreation(
    data:
      | QuickReportPutResponse
      | ServiceRequestPutResponse
      | WorkOrderPutResponse
  ): void;

  protected abstract getNewPutRequest():
    | QuickReportPut
    | ServiceRequestPut
    | WorkOrderPut;

  protected abstract initFormGroup(): void;
}
