import { LocalStorageConfiguration, LocalStorageKey } from '@al/entities';
import { AlEnvironmentService } from '@al/environment';
import { AlIndexedDbService, dbConfig } from '@al/indexed-db';
import { AlOnlineService } from '@al/online';
import { AlSessionService } from '@al/session';
import { AlSpinnerService } from '@al/spinner';
import {
  DateSelectorService,
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  SiteService,
  WorkOrdersService,
  WorkOrdersStore,
} from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Auth } from 'aws-amplify';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppService } from './app.service';
import { LoginErrorEnum } from './login/login-error.enum';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public isOnline: boolean;

  public title: string;

  private currentUrl: string;

  private isLoggedIn$: boolean;

  private ngUnsubscribe = new Subject();

  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private alSessionService: AlSessionService,
    private appService: AppService,
    private dateSelectorService: DateSelectorService,
    private dbService: NgxIndexedDBService,
    private equipmentsService: EquipmentsService,
    private gtmService: GoogleTagManagerService,
    private onLineService: AlOnlineService,
    private processGroupsService: ProcessGroupsService,
    private productionUnitsService: ProductionUnitsService,
    private router: Router,
    private siteService: SiteService,
    private synchroInfoService: SyncInfoService,
    private titleService: Title,
    private translateService: TranslateService,
    private workOrderService: WorkOrdersService,
    private workOrderStore: WorkOrdersStore,
    private alSpinnerService: AlSpinnerService,
    private alIndexedDbService: AlIndexedDbService
  ) {
    this.alIndexedDbService.change
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.synchroInfoService.initStoreFromCache();
      });
    this.dbService.createObjectStore(dbConfig.objectStoresMeta[0]);

    this.isOnline = false;
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isOnline = res;
      });

    this.title = this.alEnvironmentService.env.title;
    this.titleService.setTitle(this.title);

    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: any) => {
        this.currentUrl = event.urlAfterRedirects;
        this.navigationGtmEvent(this.currentUrl);
      });

    this.isLoggedIn$ = false;

    window.addEventListener('message', (message: MessageEvent<any>) => {
      if (!this.isSameOrigin(message.origin, window.location.href)) {
        return;
      }
      this.appService.parseMessage(message);
      if (message.data?.key === 'activated') {
        this.appService.setServiceWorkerState(true);
      }
    });

    Auth.currentSession()
      .then((session) => {
        const idTokenDecoded =
          this.alSessionService.setIdTokenToLocalStorage(session);

        if (idTokenDecoded.isMaximoHealthy) {
          if (idTokenDecoded.isActiveInMaximo && idTokenDecoded.hasMaximo) {
            if (idTokenDecoded.hasMaximo && idTokenDecoded.hasSites) {
              this.isLoggedIn$ = true;
              // TODO: Add Callback content

              if (idTokenDecoded.defaultSite) {
                this.alSessionService.setAccessTokenToLocalStorage(session);
                this.alSessionService.setUserIdToLocalStorage(session);
                this.alSessionService.setDefaultSiteToLocalStorage(session);
                this.alSessionService.setUserRightsToCache(session);
                this.alSessionService.setLanguage(session);

                this.initStaticStores();

                this.initUserValues();

                this.loadEquipment();
                this.loadPG();
                this.loadPU();
                this.loadBT();
              } else {
                localStorage.clear();
                localStorage.setItem(
                  LocalStorageConfiguration.USER_ID,
                  LoginErrorEnum.SITE_MISSING
                );
                this.router.navigate(['/']);
              }
            } else {
              this.handleLocalStorageLogOut();
            }
          } else {
            localStorage.setItem(
              LocalStorageConfiguration.USER_ID,
              LoginErrorEnum.MAXIMO_NOT_ENABLED
            );
            Auth.signOut().then(() => {
              this.handleLocalStorageLogOut(LoginErrorEnum.MAXIMO_NOT_ENABLED);
            });
          }
        } else {
          localStorage.setItem(
            LocalStorageConfiguration.USER_ID,
            LoginErrorEnum.MAXIMO_DOWN
          );
          Auth.signOut().then(() => {
            this.handleLocalStorageLogOut(LoginErrorEnum.MAXIMO_DOWN);
          });
        }
      })
      .catch(() => {
        this.handleLocalStorageLogOut();
      });
  }

  public get isDashboard(): boolean {
    const regexp = /^\/(dashboard)/;
    return regexp.test(this.currentUrl);
  }

  public get isLoggedIn(): boolean {
    const regexp = /^\/(login|logout)/;
    return this.isLoggedIn$ && !regexp.test(this.currentUrl);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public openHelp(): void {
    window.open(
      'https://urldefense.com/v3/__https:/drive.google.com/drive/u/0/folders/16HrFzVvJQ0OSH9TKO5zN0sz85o-nBovf__;!!GDyMDYbfvpeH7A!BKeJ-BQetjIENDlrzD7KUCB8QU9WqsSM6NngM6UyZqn8RNOwBQUuBP1J3Uvs9C7d_wo$'
    );
  }

  private clearLocalStorageOnLogout(): void {
    const keyToKeep: string[] = [
      LocalStorageConfiguration.SITE_KEY,
      LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_DATE,
      LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_DATE,
      LocalStorageKey.PRODUCTION_UNIT_SYNCHRO_LAST_DATE,
    ];
    const localStorageLength = localStorage.length;
    const keyToRemove: string[] = [];
    for (let i = 0; i < localStorageLength; i += 1) {
      const key = localStorage.key(i);
      if (key && keyToKeep.indexOf(key) === -1) {
        keyToRemove.push(key);
      }
    }
    keyToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  private handleLocalStorageLogOut(error_reason?: LoginErrorEnum): void {
    this.clearLocalStorageOnLogout();
    localStorage.setItem(
      LocalStorageConfiguration.USER_ID,
      error_reason ?? LoginErrorEnum.LOGIN_FAILED
    );
    this.router.navigate(['/']);
  }

  private initFilterFromLocalStorage(
    localKey: LocalStorageConfiguration
  ): void {
    const item = localStorage.getItem(localKey);
    if (item && localKey !== 'role' && localKey !== 'gotCalibration') {
      this.workOrderService.setFilter(localKey, item);
    }
    if (item && localKey === 'role') {
      this.workOrderService.setFilterRole(localKey, item);
    }
    if (item && localKey === 'gotCalibration') {
      this.workOrderService.setFilterCalibration(localKey, item);
    }
    if (item && localKey === 'assetNum') {
      this.workOrderService.setFilterAssetNum(localKey, item);
    }
  }

  private initFilters() {
    // set filters on 'standard' filtered column
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_NUMBER);
    this.initFilterFromLocalStorage(
      LocalStorageConfiguration.FILTER_DESCRIPTION
    );
    this.initFilterFromLocalStorage(
      LocalStorageConfiguration.FILTER_ASSET_NUMBER
    );
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_LOCATION);
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_TYPE);
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_PRIORITY);
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_ROLE);
    this.initFilterFromLocalStorage(
      LocalStorageConfiguration.FILTER_GOTCALIBRATION
    );

    // set filter on the unique date column
    const dateFilter = localStorage.getItem(
      LocalStorageConfiguration.FILTER_TARGET_DATE
    );
    if (dateFilter) {
      const targetCompDate = new Date(dateFilter);
      this.workOrderService.setTargetEndDateFilter(
        LocalStorageConfiguration.FILTER_TARGET_DATE,
        targetCompDate
      );
    }
  }

  private initStaticStores(): void {
    this.siteService.populate();
    this.dateSelectorService.populate();
  }

  private initUserValues(): void {
    const idToken = localStorage.getItem(
      LocalStorageConfiguration.ID_TOKEN_DECODED
    );
    if (idToken) {
      const idTokenDecoded = JSON.parse(idToken);
      this.alSessionService.initSessionStore(idTokenDecoded);
    }
    this.siteService.setActiveFromLocalStorage();
    this.dateSelectorService.setActiveFromLocalStorage();
    const language = localStorage.getItem(
      LocalStorageConfiguration.LANGUAGE_KEY
    );
    if (language) {
      this.translateService.setDefaultLang(language);
      this.translateService.use(language);
    } else {
      this.translateService.setDefaultLang('fr');
    }
  }

  private isSameOrigin(origin: string, target: string): boolean {
    const originUrl = document.createElement('a');
    originUrl.href = origin;
    const targetUrl = document.createElement('a');
    targetUrl.href = target;
    return originUrl.baseURI === targetUrl.baseURI;
  }

  private loadBT(): void {
    this.workOrderService
      .get()
      .pipe()
      .subscribe({
        next: () => {
          this.setActiveWorkOrder();
          this.initFilters();
          this.synchroInfoService.initStoreFromCache();
        },
        error: (error) => {
          // TODO: Utiliser un logger global
          // eslint-disable-next-line no-console
          console.error('intitial load Error WO', error);
        },
      });

    setInterval(
      () =>
        this.workOrderService
          .get()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(),
      60 * 60 * 1000
    );
  }

  private loadEquipment(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(uuid);
    this.equipmentsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.alSpinnerService.stopAssetProcess(uuid);
          const item = localStorage.getItem('assetNum');
          if (item) {
            this.workOrderService.setFilterAssetNum('assetNum', item);
          }
        },
        error: (error) => {
          this.alSpinnerService.stopAssetProcess(uuid);
          // TODO: Utiliser un logger global
          // eslint-disable-next-line no-console
          console.error('intitial load Error Equipments', error);
          this.alSpinnerService.launchSnackBar(
            "your Assets didn't load, please reload page",
            null
          );
        },
      });
  }

  private loadPG(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(uuid);
    this.processGroupsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.alSpinnerService.stopAssetProcess(uuid);
        },
        error: (error) => {
          this.alSpinnerService.stopAssetProcess(uuid);
          // eslint-disable-next-line no-console
          console.error('intitial load Error: Progress Groups', error);
        },
      });
  }

  private loadPU(): void {
    const uuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(uuid);
    this.productionUnitsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.alSpinnerService.stopAssetProcess(uuid);
        },
        error: (error) => {
          this.alSpinnerService.stopAssetProcess(uuid);
          // eslint-disable-next-line no-console
          console.error('intitial load Error: Production Units', error);
        },
      });
  }

  private navigationGtmEvent(currentUrl: string): void {
    const gtmTag = {
      event: 'page',
      pageUrl: currentUrl,
    };
    this.gtmService.pushTag(gtmTag);
  }

  private setActiveWorkOrder(): void {
    const currentWorkOrder = localStorage.getItem(
      LocalStorageConfiguration.CURRENT_WORK_ORDER_ID
    );
    if (currentWorkOrder) {
      this.workOrderStore.setActive(currentWorkOrder);
    }
  }
}
