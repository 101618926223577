import {
  FailureReportCreateCache,
  FailureReportCreateHistoryService,
  FailureReportUpdateCache,
  FailureReportUpdateHistoryService,
} from '@al/cache';
import {
  FailureReport,
  FailureReportPutResponse,
  FailureReportRequestPut,
  WorkOrderPutResponse,
} from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlOnlineService } from '@al/online';
import { FailureReportRestService } from '@al/rest';
import { Injectable } from '@angular/core';

import { AbstractSyncService } from './abstract-sync.service';
import { GtmSyncService } from './gtm-sync.service';
import { SyncInfoService } from './sync-info.service';

@Injectable({
  providedIn: 'root',
})
export class FailureReportSyncService extends AbstractSyncService<
  FailureReport,
  FailureReportRequestPut,
  FailureReportPutResponse
> {
  public constructor(
    protected restService: FailureReportRestService,
    protected createCache: FailureReportCreateCache,
    protected updateCache: FailureReportUpdateCache,
    protected createCachedHistoryService: FailureReportCreateHistoryService,
    protected updateCachedHistoryService: FailureReportUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  protected getNewResponse(): WorkOrderPutResponse {
    return new WorkOrderPutResponse();
  }
}
