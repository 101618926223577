<div class="work-order-loading">
  <span *ngIf="btLoadedDisplayed">
    <ng-container *ngIf="!isLoadingErr; else loadErr">
      <div class="spinner">
        <span *ngIf="isLoading">
          <mat-spinner [diameter]="40">
          </mat-spinner>
        </span>
        <span class="numberOfWo">
          {{numberOfWo + numberOfWoComp}} {{ "WO loaded" | translate }}
        </span>
      </div>
    </ng-container>
    <ng-template #loadErr>
      <div class="spinner err">
        <span class="numberOfWo">
          {{ "your WO didn't load, please reload page" | translate }}
        </span>
      </div>
    </ng-template>
  </span>
</div>
