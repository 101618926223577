import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { AttachmentState, AttachmentStore } from './attachments.store';

@Injectable({ providedIn: 'root' })
export class AttachmentsQuery extends QueryEntity<AttachmentState> {
  public equipmentFirst = this.selectFirst();

  public equipments = this.selectAll();

  public constructor(protected store: AttachmentStore) {
    super(store);
  }
}
