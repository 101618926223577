<div class="al-work-order-description" *ngIf="displayDesc">
  <div class="content" [ngClass]="{'line-clamp': !seeMore}">
    <p [innerHTML]="firstString"></p>
  </div>
  <div *ngIf="displaySeeMore">
    <ng-container *ngIf="seeMore; else elseTemplate">
      <p [innerHTML]="seeMoreString"></p>
      <span translate *ngIf="seeMore" class="link" (click)="seeMoreSwitch()">Hide</span>
    </ng-container>
    <ng-template #elseTemplate>
      <span translate *ngIf="!seeMore" class="link" (click)="seeMoreSwitch()">See more</span>
    </ng-template>
  </div>
</div>
