import { JsonObject, JsonProperty } from 'json2typescript';
import { RightActionEnum } from '../enums/right-action.enum';
import { RightTypeEnum } from '../enums/right-type.enum';
import { RightStatusEnum } from '../enums/right-status.enum';

/**
 * This class represents UserRights model
 */
@JsonObject('UserRights')
export class UserRights {
  @JsonProperty('actions')
  public actions?: RightActionEnum[] = [];

  @JsonProperty('status')
  public status?: RightStatusEnum[] = [];

  @JsonProperty('type')
  public type?: RightTypeEnum = RightTypeEnum.QUICKREP;
}
