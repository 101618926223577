import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { WorkOrder } from '@al/entities';
import { WorkOrdersQuery } from '@al/state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'al-navigation-button',
  templateUrl: './al-navigation-button.component.html',
  styleUrls: ['./al-navigation-button.component.scss'],
})
export class AlNavigationButtonComponent implements OnInit, OnDestroy {
  /**
   * output event emitter
   */
  @Output()
  public eventEmitterClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Icon to display : only material svg
   */
  @Input()
  public icon!: string;

  /**
   * Icon position
   */
  @Input()
  public iconFirst: boolean;

  @Input()
  public text!: string;

  public currentID!: string;

  public firstId!: number;

  public lastId!: number;

  public selected: boolean;

  private ngUnsubscribe = new Subject();

  public constructor(private workOrdersQuery: WorkOrdersQuery) {
    this.iconFirst = true;
    this.selected = false;
  }

  public click(): void {
    this.select();
    this.eventEmitterClick.emit(10);
    setTimeout(() => {
      this.unselect();
    }, 250);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.workOrdersQuery
      .selectFirst()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.firstId = res.id;
        }
      });
    this.workOrdersQuery
      .selectLast()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.lastId = res.id;
        }
      });
  }

  /**
   * Change value use for style binding
   */
  public select(): void {
    this.selected = true;
  }

  public unselect(): void {
    this.selected = false;
  }
}
