import { AlReferenceData } from './model/al-reference-data.model';

export const RootCauseReference: ReadonlyArray<AlReferenceData> = [
  { enumKey: 'RC_11', rootCause: '1.1', label: 'Improper Design' },
  { enumKey: 'RC_12', rootCause: '1.2', label: 'Improper Capacity' },
  { enumKey: 'RC_13', rootCause: '1.3', label: 'Improper Material' },
  { enumKey: 'RC_14', rootCause: '1.4', label: 'Change error' },
  { enumKey: 'RC_21', rootCause: '2.1', label: 'Fabrication Error' },
  { enumKey: 'RC_22', rootCause: '2.2', label: 'Installation Error' },
  { enumKey: 'RC_31', rootCause: '3.1', label: 'Off-Design Service' },
  { enumKey: 'RC_32', rootCause: '3.2', label: 'Operating Error' },
  { enumKey: 'RC_33', rootCause: '3.3', label: 'Maintenance Error' },
  { enumKey: 'RC_34', rootCause: '3.4', label: 'Expected Wear and Tear' },
  {
    enumKey: 'RC_41',
    rootCause: '4.1',
    label: 'Failure related to procedures, doc etc.',
  },
  { enumKey: 'RC_42', rootCause: '4.2', label: 'Maintenance Management Error' },
  { enumKey: 'RC_8', rootCause: '8', label: 'External cause' },
  { enumKey: 'RC_80', rootCause: '8.0', label: 'External cause' },
];
