import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PutRequest, PutResponse, Get, LocalStorageKey } from '@al/entities';
import { AlJsonConvert } from '@al/json';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractRestService<
  T extends Get,
  U extends PutRequest,
  V extends PutResponse
> {
  protected atJsonConvert: AlJsonConvert;

  protected abstract cacheDelay: number | null;

  protected abstract keyLastDate: LocalStorageKey;

  protected abstract keyLastRowstamp: LocalStorageKey;

  public constructor(protected http: HttpClient) {
    this.atJsonConvert = new AlJsonConvert();
  }

  protected abstract get createEndPoint(): string;

  protected abstract get getEndPoint(): string;

  protected abstract get updateEndPoint(): string;

  public create(item: U): Observable<V> {
    return this.http.put<V>(
      this.createEndPoint,
      this.atJsonConvert.serializeObject(item, this.getPutRequestClazz())
    );
  }

  public get(refresh = false): Observable<T[]> {
    const httpHeaders = this.getSynchroHeaders(refresh);

    return this.http
      .get<T[]>(this.getEndPoint, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(
        map((data: HttpResponse<T[]>) => {
          this.updateLocalStorageWithHeaders(data);

          const items = this.atJsonConvert.deserializeArray<T>(
            data.body || [],
            this.getClazz()
          );

          this.updateLastSyncDate();

          return items;
        })
      );
  }

  public update(item: U): Observable<V> {
    return this.http.put<V>(
      this.updateEndPoint,
      this.atJsonConvert.serializeObject(item, this.getPutRequestClazz())
    );
  }

  private getSynchroHeaders(refresh = false): HttpHeaders {
    let previousRowstamp: string | null = localStorage.getItem(
      this.keyLastRowstamp
    );
    if (!previousRowstamp) {
      previousRowstamp = '0';
    }

    let previousTimestamp: string | null = localStorage.getItem(
      this.keyLastDate
    );
    if (!previousTimestamp || refresh) {
      previousTimestamp = '-1';
    }

    if (this.cacheDelay !== null) {
      const lastSyncTimestamp = new Date(
        parseInt(previousTimestamp, 10)
      ).getTime();
      const nowTimestamp = new Date().getTime();
      // if more than hour is spend
      if (nowTimestamp - lastSyncTimestamp > this.cacheDelay) {
        previousRowstamp = '0';
      }
    }

    return new HttpHeaders({
      rowstamp: previousRowstamp,
      sync: previousTimestamp,
    });
  }

  private updateLocalStorageWithHeaders(entities: any): void {
    // get rowstamp and store it in localStorage
    const rowstamp: string | null = entities.headers.get('rowstamp');
    if (rowstamp !== undefined && rowstamp !== null) {
      const previousRowstamp =
        localStorage.getItem(
          LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP
        ) || '0';
      const parsedRowstamp = parseInt(rowstamp, 10);
      const parsedPreviousRowstamp = parseInt(previousRowstamp, 10);
      if (parsedRowstamp >= parsedPreviousRowstamp) {
        localStorage.setItem(
          LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP,
          rowstamp
        );
      }
    } else {
      localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP);
    }

    if (this.keyLastRowstamp) {
      const timestamp: string | null = entities.headers.get('timestamp');
      if (timestamp !== undefined && timestamp !== null) {
        const previousTimestamp =
          localStorage.getItem(this.keyLastRowstamp) || '0';
        const parsedTimestamp = parseInt(timestamp, 10);
        const parsedPreviousTimestamp = parseInt(previousTimestamp, 10);
        if (parsedTimestamp >= parsedPreviousTimestamp) {
          localStorage.setItem(this.keyLastRowstamp, timestamp);
        }
      } else {
        localStorage.removeItem(this.keyLastRowstamp);
      }
    }
  }

  protected abstract getClazz(): any;

  protected abstract getPutRequestClazz(): any;

  protected abstract getPutResponseClazz(): any;

  protected abstract updateLastSyncDate(): void;
}
