import { Injectable } from '@angular/core';
import { Task, TaskPut, TaskPutResponse } from '@al/entities';
import { TaskRestService } from '@al/rest';
import { AlOnlineService } from '@al/online';
import {
  TaskCreateCache,
  TaskCreateHistoryService,
  TaskUpdateCache,
  TaskUpdateHistoryService,
} from '@al/cache';
import { AlIndexedDbService } from '@al/indexed-db';
import { AbstractSyncService } from './abstract-sync.service';
import { SyncInfoService } from './sync-info.service';
import { GtmSyncService } from './gtm-sync.service';

@Injectable({
  providedIn: 'root',
})
export class TaskSyncService extends AbstractSyncService<
  Task,
  TaskPut,
  TaskPutResponse
> {
  public constructor(
    protected restService: TaskRestService,
    protected createCache: TaskCreateCache,
    protected updateCache: TaskUpdateCache,
    protected createCachedHistoryService: TaskCreateHistoryService,
    protected updateCachedHistoryService: TaskUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  protected getNewResponse(): TaskPutResponse {
    return new TaskPutResponse();
  }
}
