import { Routes } from '@angular/router';

import { WorkOrderComponent } from './work-order.component';

export const routes: Routes = [
  {
    path: '',
    component: WorkOrderComponent,
  },
];
