import { Routes } from '@angular/router';

import { CreateSRComponent } from './create-sr.component';

export const routes: Routes = [
  {
    path: '',
    component: CreateSRComponent,
  },
];
