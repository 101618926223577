import { WorkOrder } from '@al/entities';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { WorkOrdersState, WorkOrdersStore } from './work-orders.store';

@Injectable({ providedIn: 'root' })
export class WorkOrdersQuery extends QueryEntity<WorkOrdersState> {
  public workOrders = this.selectAll();

  public constructor(protected store: WorkOrdersStore) {
    super(store);
  }

  public getProducts(
    term: string,
    sortBy: keyof WorkOrder
  ): Observable<WorkOrder[]> {
    return this.selectAll({
      sortBy,
      filterBy: (entity) => entity.description.toLowerCase().includes(term),
    });
  }
}
