import { NgModule } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AlAssetsTreeComponent } from './al-assets-tree.component';

@NgModule({
  declarations: [AlAssetsTreeComponent],
  imports: [
    MatTreeModule,
    MatIconModule,
    MatProgressBarModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatButtonModule,
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [AlAssetsTreeComponent],
})
export class AlAssetsTreeModule {}
