import { GtmSyncService } from '@al/sync-services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlGtmService {
  public isOnline!: boolean;

  public constructor(private gtmSynchroService: GtmSyncService) {}

  public setDashboardAsSource(): void {
    this.gtmSynchroService.setDashboardAsSource();
  }

  public setWorkOrderAsSource(): void {
    this.gtmSynchroService.setWorkOrderAsSource();
  }
}
