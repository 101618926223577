import { RootCause, TaskType } from '@al/entities';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TasksListsState extends EntityState<RootCause, TaskType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tasks-lists' })
export class TasksListsStore extends EntityStore<TasksListsState> {
  public constructor() {
    super();
  }
}
