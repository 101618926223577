import { AlReferenceData } from './model/al-reference-data.model';

export const DomainReference: ReadonlyArray<AlReferenceData> = [
  {
    domain: 'EFFICIENCY',
    display: 'EFFICIENCY',
    label: 'Activities with the primary purpose of improving Efficiency',
  },
  {
    domain: 'ENVIRONMENT',
    display: 'ENVIRONMENT',
    label:
      'Activities with the primary purpose of improving the Environmental performance',
  },
  {
    domain: 'MAINTENANCE',
    display: 'MAINTENANCE',
    label:
      'Activities with the primary purpose of maintaining assets at their base level of performance',
  },
  {
    domain: 'QUALITY',
    display: 'QUALITY',
    label: 'Activities with the primary purpose of improving product Quality',
  },
  {
    domain: 'REGULATORY',
    display: 'REGULATORY',
    label:
      'Activities with the primary purpose of meeting Regulatory evolutions (local or country authorities)',
  },
  {
    domain: 'RELIABILITY',
    display: 'RELIABILITY',
    label:
      'Activities with the primary purpose of improving Reliability and/or Availability',
  },
  {
    domain: 'SAFETY_OCUPATIONAL_HEALTH',
    display: 'SAFETY-OCUPATIONAL&HEALTH',
    label:
      'Activities with the primary purpose of improving Occupational Health & Safety',
  },
  {
    domain: 'SAFETY_PROCESS_SAFETY',
    display: 'SAFETY-PROCESS SAFETY',
    label: 'Activities with the primary purpose of improving Process Safety',
  },
  {
    domain: 'SAFETY_PROD_FOOD_PHARMA_',
    display: 'SAFETY-PROD(FOOD&PHARMA)',
    label: 'Activities with the primary purpose of improving Product Safety',
  },
  {
    domain: 'SECURITY_CYBER',
    display: 'SECURITY-CYBER',
    label: 'Activities with the primary purpose of improving Cybersecurity',
  },
  {
    domain: 'SECURITY_SITE',
    display: 'SECURITY-SITE',
    label:
      'Activities with the primary purpose of improving Security (not safety)',
  },
  {
    domain: 'SUSTAINABILITY_INTEGRITY',
    display: 'SUSTAINABILITY/INTEGRITY',
    label:
      'Activities with the primary purpose of ensuring asset integrity or increasing significantly its lifetime',
  },
];
