import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { AlSpinnerModule } from '@al/spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TranslateModule } from '@ngx-translate/core';
import { AlPipeModule } from '@al/pipe';
import { AlWorkOrderListComponent } from './al-work-order-list.component';
import { TableColumnHeaderFilteringComponent } from './table-column-header-filtering/table-column-header-filtering.component';

@NgModule({
  declarations: [AlWorkOrderListComponent, TableColumnHeaderFilteringComponent],
  imports: [
    AlPipeModule,
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    AlSpinnerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
  ],
  exports: [AlWorkOrderListComponent],
})
export class AlWorkOrderListModule {}
