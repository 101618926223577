<div class="file-renaming">
  <form [formGroup]="form" (submit)="submitUpload()">
      <mat-form-field>
        <mat-label></mat-label>
        <span *ngIf="prefix" matPrefix></span>
        <input type="text" formControlName="newFileName" matInput>
        <span *ngIf="extension" matSuffix>.{{extension}}</span>
      </mat-form-field>

    <div class="actions">
      <button mat-raised-button color="primary" type="button" (click)="cancelUpload()">
        {{ "cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
        {{ "submit" | translate }}
      </button>
    </div>
  </form>
</div>
