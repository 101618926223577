import { LocalStorageKey, WorkOrder } from '@al/entities';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderCache extends LocalCache<WorkOrder> {
  public constructor() {
    super(LocalStorageKey.WORK_ORDER_CACHE);
  }

  public put(value: WorkOrder): void {
    // remove previous cache element for this Task
    const cacheFiltered = this.cache.filter((cache: WorkOrder) => {
      return !(cache.number === value.number);
    });
    this.cache = cacheFiltered;
    this.cache.push({ ...value, uuid: UUID.UUID() });
    this.storageManager.store(this.cache, this.getClazz());
  }

  protected getClazz(): typeof WorkOrder {
    return WorkOrder;
  }
}
