import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlSpinnerService {
  public isAssetProcessing: Observable<boolean>;

  public isWorkOrderProcessing: Observable<boolean>;

  public message: Observable<string>;

  public snackBarIsDisplayed: Observable<{
    duration: number | null;
    text: string;
  }>;

  private $isAssetProcessing: BehaviorSubject<boolean>;

  private $isWorkOrderProcessing: BehaviorSubject<boolean>;

  private $message: BehaviorSubject<string>;

  private $snackBarIsDisplayed: BehaviorSubject<{
    duration: number | null;
    text: string;
  }>;

  private assetProcessList: string[] = [];

  private workOrderProcessList: string[] = [];

  public constructor() {
    this.$isWorkOrderProcessing = new BehaviorSubject<boolean>(false);
    this.isWorkOrderProcessing = this.$isWorkOrderProcessing.asObservable();

    this.$isAssetProcessing = new BehaviorSubject<boolean>(false);
    this.isAssetProcessing = this.$isAssetProcessing.asObservable();

    this.$message = new BehaviorSubject<string>('');
    this.message = this.$message.asObservable();

    // INFO: SNACKBAR pour afficher les message d'erreur (et de succès)
    // TODO: A déplacer dans le GlobalLogger (A créer)
    this.$snackBarIsDisplayed = new BehaviorSubject<{
      duration: number | null;
      text: string;
    }>({
      duration: null,
      text: '',
    });
    this.snackBarIsDisplayed = this.$snackBarIsDisplayed.asObservable();
  }

  public launchSnackBar(text: string, duration: number | null): void {
    this.$snackBarIsDisplayed.next({ text, duration });
  }

  public startAssetProcess(processId: string, message?: string): void {
    if (!this.assetProcessList.includes(processId)) {
      this.assetProcessList.push(processId);
      this.$isAssetProcessing.next(true);
    }
    this.$message.next(message || '');
  }

  public startWorkOrderProcess(processId: string) {
    if (!this.workOrderProcessList.includes(processId)) {
      this.workOrderProcessList.push(processId);
      this.$isWorkOrderProcessing.next(true);
    }
  }

  public stopAssetProcess(processId: string): void {
    if (this.assetProcessList.includes(processId)) {
      const index = this.assetProcessList.indexOf(processId);
      if (index > -1) {
        this.assetProcessList.splice(index, 1);
      }
    }
    if (this.assetProcessList.length === 0) {
      this.$isAssetProcessing.next(false);
    }
  }

  public stopWorkOrderProcess(processId: string) {
    if (this.workOrderProcessList.includes(processId)) {
      const index = this.workOrderProcessList.indexOf(processId);
      if (index > -1) {
        this.workOrderProcessList.splice(index, 1);
      }
    }
    if (this.workOrderProcessList.length === 0) {
      this.$isWorkOrderProcessing.next(false);
    }
  }
}
