import { UUID } from 'angular2-uuid';

import { JsonObject, JsonProperty } from 'json2typescript';

import { DateJsonConverter } from '../../json-converters/date-json-converter';
import { CalibrationStatus } from '../../enums/calibration-status.enum';
import { TaskStatus } from '../../enums/task-status.enum';
import { TaskType } from '../../enums/task-type.enum';
import { Get } from './get.model';

@JsonObject('Task')
export class Task extends Get {
  @JsonProperty('deltaAsFound', String)
  public deltaAsFound = '';

  @JsonProperty('deltaAsFoundPc', String)
  public deltaAsFoundPc = '';

  @JsonProperty('deltaAsLeft', String)
  public deltaAsLeft = '';

  @JsonProperty('deltaAsLeftPc', String)
  public deltaAsLeftPc = '';

  @JsonProperty('description', String)
  public description = '';

  @JsonProperty('endDate', DateJsonConverter)
  public endDate: Date | null = null;

  @JsonProperty('id', Number)
  public id = 0;

  @JsonProperty('isTaskSkipped', Boolean, true)
  public isTaskSkipped = false;

  @JsonProperty('measurePoint', String)
  public measurePoint = '';

  @JsonProperty('measurementAfterAdj', Number)
  public measurementAfterAdj: number | null = null;

  @JsonProperty('measurementDate', DateJsonConverter)
  public measurementDate: Date | null = null;

  @JsonProperty('measurementValue', Number)
  public measurementValue: number | null = null;

  @JsonProperty('meterName', String)
  public meterName = '';

  @JsonProperty('meterType', String)
  public meterType = '';

  @JsonProperty('name', String)
  public name = '';

  @JsonProperty('standardCertId', String)
  public standardCertId = '';

  @JsonProperty('standardInput', Number)
  public standardInput: number | null = null;

  @JsonProperty('standardNumero', String)
  public standardNumero = '';

  @JsonProperty('standardPhyId', String)
  public standardPhyId = '';

  @JsonProperty('startDate', DateJsonConverter)
  public startDate: Date | null = null;

  @JsonProperty('status', String, true)
  public status: TaskStatus = TaskStatus.RLSD;

  @JsonProperty('status1', String)
  public status1: CalibrationStatus | null = null;

  @JsonProperty('status2', String)
  public status2: CalibrationStatus | null = null;

  public get taskType(): TaskType {
    return this.standardInput ? TaskType.calibration : TaskType.standard;
  }

  @JsonProperty('tolerance', Number)
  public tolerance: number | null = null;

  @JsonProperty('uncertainty', Number)
  public uncertainty: number | null = null;

  @JsonProperty('unit', String, true)
  public unit = '';

  @JsonProperty('wol4', String)
  public wol4 = '';

  public uuid = UUID.UUID();
}
