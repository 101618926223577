<div class="al-equipment">
  <div class="equipment-header">
    <span class="title">{{'Equipment infos' | translate}}</span>
    <button mat-icon-button class="closeButton" (click)="closeDialog()">
      <mat-icon aria-label="close">close</mat-icon>
    </button>
  </div>
  <div class="equipment">
    <al-equipment-details [equipment]="equipment" [location]="location"></al-equipment-details>
  </div>
</div>
