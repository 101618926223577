export enum LocalStorageConfiguration {
  ID_TOKEN = 'idToken',
  ID_TOKEN_DECODED = 'idTokenDecoded',
  ACCESS_TOKEN = 'accessToken',
  ACCESS_TOKEN_DECODED = 'accessTokenDecoded',

  // Identifiant de l'utilisateur courant (ex. : NICOLAS.BOURGEOIS)
  USER_ID = 'userId',

  // Langue sélectionnée par l'utilisateur (ex. : en)
  LANGUAGE_KEY = 'languageKey',

  // Site courant (ex. : LI-FR-FOSM)
  SITE_KEY = 'siteKey',

  // Filtre de date courant (ex. : 0-1-2-3)
  FILTER_DATE = 'filterDate',

  // Filtre de "statut" des Works Orders courant (ex. : RSLD)
  FILTER_STATUS = 'filterStatus',

  // Filtre des Works Orders: colonne "number"
  FILTER_NUMBER = 'number',
  // Filtre des Works Orders: colonne "description"
  FILTER_DESCRIPTION = 'description',
  // Filtre des Works Orders: colonne "equipment"
  FILTER_ASSET_NUMBER = 'assetNum',
  // Filtre des Works Orders: colonne "location"
  FILTER_LOCATION = 'location',
  // Filtre des Works Orders: colonne "objective end date"
  FILTER_TARGET_DATE = 'targetCompDate',
  // Filtre des Works Orders: colonne "Work order type"
  FILTER_TYPE = 'type',
  // Filtre des Works Orders: colonne "Priority"
  FILTER_PRIORITY = 'priority',
  // Filtre des Works Orders: colonne "Role"
  FILTER_ROLE = 'role',
  // Filtre des Works Orders: colonne "gotCalibration"
  FILTER_GOTCALIBRATION = 'gotCalibration',

  // Work Order courant, id dans le store (ex. : 25789178)
  CURRENT_WORK_ORDER_ID = 'currentWorkOrderId',
}
