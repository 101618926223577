<div class="al-session-login">
  <form class="form" [formGroup]="form" (submit)="submit()">
    <mat-form-field class="full-width">
      <mat-label translate>Login</mat-label>
      <input matInput type="text" value="" formControlName="login">
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label translate>Password</mat-label>
      <input matInput type="password" value="" formControlName="password">
    </mat-form-field>
    <div class="button-row">
      <button mat-raised-button color="primary" translate>
        Login
      </button>
    </div>
  </form>
</div>
