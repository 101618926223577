import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AlMaximoSynchronizationComponent } from './al-maximo-synchronization.component';

@NgModule({
  declarations: [AlMaximoSynchronizationComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule],
  exports: [AlMaximoSynchronizationComponent],
})
export class AlMaximoSynchronizationModule {}
