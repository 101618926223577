import { RightActionEnum, RightTypeEnum } from '@al/entities';
import { SessionRights } from '@al/session';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-al-creation',
  templateUrl: './al-creation.component.html',
  styleUrls: ['./al-creation.component.scss'],
})
export class AlCreationComponent {
  public creationTargets: ReadonlyArray<{
    labelKey: string;
    target: string;
  }> = [
    { labelKey: 'work-order', target: '/create-bt' },
    { labelKey: 'service-request', target: '/create-sr' },
    { labelKey: 'quick-report', target: '/quick-report' },
  ];

  public rights = new SessionRights();

  public constructor(
    public dialogRef: MatDialogRef<AlCreationComponent>,
    private router: Router
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public disableButton(key: string): boolean {
    switch (key) {
      case 'work-order':
        return this.rights.isAllowedAction(
          RightTypeEnum.WOTRACK,
          RightActionEnum.INSERT
        );
      case 'service-request':
        return this.rights.isAllowedAction(
          RightTypeEnum.SR,
          RightActionEnum.INSERT
        );
      case 'quick-report':
        return this.rights.isAllowedAction(
          RightTypeEnum.QUICKREP,
          RightActionEnum.INSERT
        );
      default:
        return false;
    }
  }

  public navigateTo(target: string) {
    this.router.navigate([target]);
    this.dialogRef.close();
  }
}
