import { Equipment } from '@al/entities';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { EquipmentsStore, EquipmentState } from './equipments.store';

@Injectable({ providedIn: 'root' })
export class EquipmentsQuery extends QueryEntity<EquipmentState> {
  public equipments = this.selectAll();

  public constructor(protected store: EquipmentsStore) {
    super(store);
  }

  public getEquipment(uuid: string): Observable<Equipment | undefined> {
    return this.selectEntity(uuid);
  }
}
