import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  SyncPendingInfoStore,
  SyncPendingInfoState,
} from './sync-pending-info.store';

@Injectable({ providedIn: 'root' })
export class SyncPendingInfoQuery extends QueryEntity<SyncPendingInfoState> {
  public constructor(protected store: SyncPendingInfoStore) {
    super(store);
  }
}
