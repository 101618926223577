import { AlOnlineService } from '@al/online';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlRequestInformationDialogDataWoPut } from '../model';
import { AlRequestInformationDialogData } from '../model/al-request-information-dialog-data';

@Component({
  selector: 'al-wo-put-information-dialog',
  templateUrl: './al-wo-put-information-dialog.component.html',
  styleUrls: [
    './al-common-information-dialog.component.scss',
    './al-wo-put-information-dialog.component.scss',
  ],
})
export class AlWoPutInformationDialogComponent {
  @Input()
  public data!: AlRequestInformationDialogData<AlRequestInformationDialogDataWoPut>;

  public isOnline!: boolean;

  private ngUnsubscribe = new Subject();

  public constructor(private onLineService: AlOnlineService) {
    this.onLineService.status.pipe(take(1)).subscribe((res) => {
      this.isOnline = res;
    });
  }
}
