import { AlSiteSummaryModule } from '@al/site-summary';
import { AlWorkOrderListModule } from '@al/work-order-list';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardComponent } from './dashboard.component';
import { AlCreationComponent } from './al-creation/al-creation.component';

@NgModule({
  declarations: [DashboardComponent, AlCreationComponent],
  imports: [
    AlSiteSummaryModule,
    AlWorkOrderListModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
  ],
})
export class DashboardModule {}
