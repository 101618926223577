import { UUID } from 'angular2-uuid';
import { DateJsonConverter, HasUuid } from '@al/entities';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('HistoryElement')
export class HistoryElement<P, R> extends HasUuid {
  @JsonProperty('request')
  public request: P;

  @JsonProperty('response')
  public response: R;

  @JsonProperty('responseDate', DateJsonConverter)
  public responseDate!: Date;

  public constructor(request: P, response: R) {
    super();
    this.response = response;
    this.request = request;
    this.uuid = UUID.UUID();
    this.responseDate = new Date();
  }
}
