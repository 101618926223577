import { JsonObject, JsonProperty } from 'json2typescript';
import { HasUuid } from '../../has-uuid';

@JsonObject('PutResponse')
export abstract class PutResponse extends HasUuid {
  @JsonProperty('error', String)
  public error = '';

  @JsonProperty('siteId', String)
  public siteId = '';

  @JsonProperty('status', String)
  public status = '';

  @JsonProperty('type', String)
  public type = '';
}
