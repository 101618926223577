import { Component } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'al-session-cas',
  templateUrl: './al-session-cas.component.html',
  styleUrls: ['./al-session-cas.component.scss'],
})
export class AlSessionCasComponent {
  public connect(): void {
    Auth.federatedSignIn();
  }
}
