import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AlPipeModule } from '@al/pipe';
import { AlAttachmentsModule } from '@al/attachments';
import { AlWorkOrderSummaryComponent } from './al-work-order-summary.component';

@NgModule({
  declarations: [AlWorkOrderSummaryComponent],
  imports: [
    AlAttachmentsModule,
    AlPipeModule,
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    TranslateModule,
  ],
  exports: [AlWorkOrderSummaryComponent],
})
export class AlWorkOrderSummaryModule {}
