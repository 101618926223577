export enum RightStatusEnum {
  RLSD = 'RLSD',
  CLOSE = 'CLOSE',
  COMP = 'COMP',
  TOPLAN = 'TOPLAN',
  CLOSED = 'CLOSED',
  INPROG = 'INPROG',
  NEW = 'NEW',
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  RESOLVED = 'RESOLVED',
}
