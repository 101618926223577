import { AlSpinnerService } from '@al/spinner';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  WorkOrdersService,
} from '@al/state';
import { AlSyncHelperService } from '@al/sync-helper';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlSyncService {
  public constructor(
    private alSpinnerService: AlSpinnerService,
    private equipmentServices: EquipmentsService,
    private processGroupsService: ProcessGroupsService,
    private productionUnitsService: ProductionUnitsService,
    private syncHelperService: AlSyncHelperService,
    private workOrdersService: WorkOrdersService
  ) {}

  public pushBeforeLogout(): Observable<boolean> {
    const obs = new BehaviorSubject<boolean>(false);
    this.syncHelperService.pushBeforeLogout().subscribe((res) => {
      obs.next(res);
    });
    return obs.asObservable();
  }

  public sync(): void {
    this.syncHelperService.sync(this.workOrdersService.get(true));

    const equipmentProcessUuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(equipmentProcessUuid);
    this.equipmentServices.get().subscribe(() => {
      this.alSpinnerService.stopAssetProcess(equipmentProcessUuid);
    });

    const processGroupProcessUuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(processGroupProcessUuid);
    this.processGroupsService.get().subscribe(() => {
      this.alSpinnerService.stopAssetProcess(processGroupProcessUuid);
    });

    const productionUnitProcessUuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(productionUnitProcessUuid);
    this.productionUnitsService.get().subscribe(() => {
      this.alSpinnerService.stopAssetProcess(productionUnitProcessUuid);
    });
  }
}
