import { Equipment, ProcessGroup, ProductionUnit } from '@al/entities';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DynamicFlatNode } from './al-assets-dynamic-flat-node';
import { Returnable } from './al-assets-returnable';

export class CustomTreeControl extends FlatTreeControl<DynamicFlatNode> {
  public returnable!: Returnable;

  private allNodes: DynamicFlatNode[] = [];

  public addParents(node: DynamicFlatNode): DynamicFlatNode[] {
    const parent = this.getParent(node);
    if (
      node.item instanceof ProductionUnit &&
      this.returnable.locationPU === undefined
    ) {
      this.returnable.locationPU = node.item;
      this.allNodes.push(node);
    }
    if (node.item instanceof ProcessGroup) {
      this.returnable.locationPG = node.item;
      this.allNodes.push(node);
    }
    if (node.item instanceof Equipment && this.returnable.asset === undefined) {
      this.returnable.asset = node.item;
      this.allNodes.push(node);
    }
    if (parent && this.getLevel(parent) > 1) {
      this.addParents(parent);
    }
    return this.allNodes;
  }

  public expandParents(node: DynamicFlatNode) {
    const parent = this.getParent(node);
    if (parent) {
      this.expand(parent);
    }

    if (parent && this.getLevel(parent) > 1) {
      this.expandParents(parent);
    }
  }

  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  public getParent(node: DynamicFlatNode): DynamicFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel <= 1) {
      return null;
    }

    const startIndex = this.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i -= 1) {
      const currentNode = this.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

    return node;
  }

  public removeParents(): void {
    this.returnable = new Returnable();
    this.allNodes = [];
  }
}
