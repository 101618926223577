import { Injectable } from '@angular/core';
import { AppSession } from './sessions/app.session';
import { AppRepository } from './repositories/app.repository';

@Injectable({
  providedIn: 'root',
})
export class AppContext {
  public constructor(
    private appRepository: AppRepository,
    private appSession: AppSession
  ) {}

  public get Repository(): AppRepository {
    return this.appRepository;
  }

  public get Session(): AppSession {
    return this.appSession;
  }
}
