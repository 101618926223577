<div class="dialog-content-information">
  <ng-container *ngIf="isOnline" [ngSwitch]="data.information.status">
    <ng-container *ngSwitchCase="'OK'">
      <div>
        <span translate> srCreationSuccessful </span>
      </div>
      <div>
        <label translate> ticketNumber </label>
        <span>{{ data.information.ticketId }}</span>
      </div>
      <div>
        <label translate> site </label>
        <span>{{ data.information.siteId }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'NOT_OK'">
      <div>
        <span translate>srCreationFailure</span>
      </div>
      <div>
        <span>{{ data.information.error }}</span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isOnline">
    <div>
      <span translate
        >Your request has been saved and will be sent to Maximo during the next
        online synchronization</span
      >
    </div>
  </ng-container>
</div>
