import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  ProductionUnitsStore,
  ProductionUnitState,
} from './production-unit.store';

@Injectable({ providedIn: 'root' })
export class ProductionUnitsQuery extends QueryEntity<ProductionUnitState> {
  public productionUnit = this.selectAll();

  public constructor(protected store: ProductionUnitsStore) {
    super(store);
  }
}
