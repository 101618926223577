<div class="equipment-details">
  <div class="equipment-row">
    <label translate>Equipment number: </label>
    <span *ngIf="equipment">{{equipment.number}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Parent: </label>
    <span *ngIf="equipment">{{parentLabel}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Location: </label>
    <span *ngIf="equipment">{{location}}</span>
    <mat-icon *ngIf="equipment">place</mat-icon>
  </div>
  <div class="equipment-row">
    <label translate>Process tag: </label>
    <span *ngIf="equipment">{{equipment.processTag}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Description: </label>
    <span *ngIf="equipment">{{equipment.description}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Manufacturer: </label>
    <span *ngIf="equipment">{{equipment.manufacturer}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Original Manufacturer (OEM): </label>
    <span *ngIf="equipment">{{equipment.originalEquipmentManufacturer}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Asset Model: </label>
    <span *ngIf="equipment">{{equipment.model}}</span>
  </div>
  <div class="equipment-row">
    <label translate>Serial: </label>
    <span *ngIf="equipment">{{equipment.serialNumber}}</span>
  </div>
</div>
