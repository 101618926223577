import { ProcessGroup, Site } from '@al/entities';
import { ProcessGroupSyncService } from '@al/sync-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProcessGroupsQuery } from './process-group.query';
import { ProcessGroupState, ProcessGroupsStore } from './process-group.store';
import { SiteService } from './site.service';

@Injectable({ providedIn: 'root' })
export class ProcessGroupsService {
  private TAG = 'ProcessGroupsService>>>';

  private filtersProcessGroup: AkitaFiltersPlugin<ProcessGroupState>;

  public constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private processGroupQuery: ProcessGroupsQuery,
    private processGroupStore: ProcessGroupsStore,
    private processGroupSynchroService: ProcessGroupSyncService
  ) {
    this.filtersProcessGroup = new AkitaFiltersPlugin<ProcessGroupState>(
      this.processGroupQuery
    );
  }

  public get(): Observable<void> {
    return this.processGroupSynchroService
      .getFromSite(
        this.siteService.getActive() ? this.siteService.getActive() : new Site()
      )
      .pipe(
        map((data: ProcessGroup[]) => {
          this.processGroupStore.reset();
          this.processGroupStore.set(data);
        })
      );
  }

  public getActive(): ProcessGroup | undefined {
    return this.processGroupQuery.getActive();
  }

  public getAll(): Observable<ProcessGroup[]> {
    return this.processGroupQuery.selectAll();
  }

  public getByNumber(ids: string | null): Observable<ProcessGroup | undefined> {
    // return this.processGroupQuery.selectEntity(
    //   (entity: ProcessGroup) => entity.number === ids
    // );
    return this.processGroupQuery.selectEntity((entity: ProcessGroup) => {
      return entity.number === ids;
    });
  }

  public getByParent(id: string | null): Observable<ProcessGroup[]> {
    return this.processGroupQuery.selectAll({
      filterBy: (index) => index.productionUnitId === id,
    });
  }

  public getCount(): Observable<number> {
    return this.processGroupQuery.selectCount();
  }

  public hasEntity(id: string | null): boolean {
    return this.processGroupQuery.hasEntity(
      (entity: ProcessGroup) => entity.productionUnitId === id
    );
  }

  public setActive(id: ID): void {
    this.processGroupStore.setActive(id);
  }
}
