import { Injectable } from '@angular/core';
import { LocalStorageKey, TaskPut } from '@al/entities';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class TaskCreateCache extends LocalCache<TaskPut> {
  public constructor() {
    super(LocalStorageKey.TASK_CREATE_CACHE);
  }

  protected getClazz(): typeof TaskPut {
    return TaskPut;
  }
}
