import { AlReferenceData } from './model/al-reference-data.model';

export const FailureCodeReference: ReadonlyArray<AlReferenceData> = [
  { enumKey: 'FC_1_01', failureCode: '1-FC-01', label: 'Adsorber - General' },
  {
    enumKey: 'FC_1_02',
    failureCode: '1-FC-02',
    label: 'Air conditioning - General',
  },
  { enumKey: 'FC_1_03', failureCode: '1-FC-03', label: 'Analyzer - General' },
  { enumKey: 'FC_1_031', failureCode: '1-FC-031', label: 'Building' },
  { enumKey: 'FC_1_032', failureCode: '1-FC-032', label: 'Basin' },
  { enumKey: 'FC_1_033', failureCode: '1-FC-033', label: 'Battery' },
  { enumKey: 'FC_1_034', failureCode: '1-FC-034', label: 'Bullgear' },
  { enumKey: 'FC_1_035', failureCode: '1-FC-035', label: 'Bundle' },
  { enumKey: 'FC_1_036', failureCode: '1-FC-036', label: 'Burner' },
  { enumKey: 'FC_1_037', failureCode: '1-FC-037', label: 'Bus bar' },
  { enumKey: 'FC_1_038', failureCode: '1-FC-038', label: 'Cable' },
  { enumKey: 'FC_1_04', failureCode: '1-FC-04', label: 'Capacitor - General' },
  { enumKey: 'FC_1_042', failureCode: '1-FC-042', label: 'Casing' },
  { enumKey: 'FC_1_043', failureCode: '1-FC-043', label: 'Catalyst' },
  { enumKey: 'FC_1_045', failureCode: '1-FC-045', label: 'Charger' },
  { enumKey: 'FC_1_047', failureCode: '1-FC-047', label: 'Column / Tower' },
  {
    enumKey: 'FC_1_05',
    failureCode: '1-FC-05',
    label: 'Combustion engine - General',
  },
  { enumKey: 'FC_1_055', failureCode: '1-FC-055', label: 'Compressor' },
  {
    enumKey: 'FC_1_06',
    failureCode: '1-FC-06',
    label: 'Compressor - Centrifugal',
  },
  { enumKey: 'FC_1_07', failureCode: '1-FC-07', label: 'Compressor - Screw' },
  { enumKey: 'FC_1_08', failureCode: '1-FC-08', label: 'Compressor - Piston' },
  { enumKey: 'FC_1_08', failureCode: '1-FC-08', label: 'Compressors - Piston' },
  { enumKey: 'FC_1_082', failureCode: '1-FC-082', label: 'Condenser' },
  { enumKey: 'FC_1_085', failureCode: '1-FC-085', label: 'Contactor' },
  {
    enumKey: 'FC_1_09',
    failureCode: '1-FC-09',
    label: 'Control Panel - General',
  },
  {
    enumKey: 'FC_1_10',
    failureCode: '1-FC-10',
    label: 'Control system - General',
  },
  { enumKey: 'FC_1_101', failureCode: '1-FC-101', label: 'Controller' },
  { enumKey: 'FC_1_102', failureCode: '1-FC-102', label: 'Corrosion coupon' },
  { enumKey: 'FC_1_103', failureCode: '1-FC-103', label: 'Coupling' },
  { enumKey: 'FC_1_104', failureCode: '1-FC-104', label: 'Data acquisition' },
  { enumKey: 'FC_1_105', failureCode: '1-FC-105', label: 'Demister' },
  {
    enumKey: 'FC_1_106',
    failureCode: '1-FC-106',
    label: 'Diesel generator set',
  },
  { enumKey: 'FC_1_107', failureCode: '1-FC-107', label: 'Dryer' },
  { enumKey: 'FC_1_108', failureCode: '1-FC-108', label: 'Dumper' },
  {
    enumKey: 'FC_1_11',
    failureCode: '1-FC-11',
    label: 'Electric Generators - General',
  },
  { enumKey: 'FC_1_110', failureCode: '1-FC-110', label: 'Ejector' },
  { enumKey: 'FC_1_112', failureCode: '1-FC-112', label: 'Electrical device' },
  {
    enumKey: 'FC_1_12',
    failureCode: '1-FC-12',
    label: 'Electrical motor - Pump',
  },
  {
    enumKey: 'FC_1_13',
    failureCode: '1-FC-13',
    label: 'Electrical motors - Compressor',
  },
  {
    enumKey: 'FC_1_14',
    failureCode: '1-FC-14',
    label: 'Electrical motors - General',
  },
  {
    enumKey: 'FC_1_145',
    failureCode: '1-FC-145',
    label: 'Electrical Network / panel',
  },
  {
    enumKey: 'FC_1_15',
    failureCode: '1-FC-15',
    label: 'Electrical power cut-off device',
  },
  { enumKey: 'FC_1_152', failureCode: '1-FC-152', label: 'Electronic card' },
  { enumKey: 'FC_1_154', failureCode: '1-FC-154', label: 'Exchanger' },
  { enumKey: 'FC_1_155', failureCode: '1-FC-155', label: 'Exciter' },
  { enumKey: 'FC_1_157', failureCode: '1-FC-157', label: 'Expander/Turbine' },
  { enumKey: 'FC_1_16', failureCode: '1-FC-16', label: 'Fan/Blower' },
  {
    enumKey: 'FC_1_16',
    failureCode: '1-FC-16',
    label: 'Fan / Ventilator - general',
  },
  { enumKey: 'FC_1_17', failureCode: '1-FC-17', label: 'Filter - General' },
  {
    enumKey: 'FC_1_18',
    failureCode: '1-FC-18',
    label: 'Fire & Gas detectors - General',
  },
  {
    enumKey: 'FC_1_19',
    failureCode: '1-FC-19',
    label: 'Fire & Gas detectors - Smoke/Combustion',
  },
  { enumKey: 'FC_1_20', failureCode: '1-FC-20', label: 'Flare - General' },
  {
    enumKey: 'FC_1_202',
    failureCode: '1-FC-202',
    label: 'Frequency converter',
  },
  {
    enumKey: 'FC_1_21',
    failureCode: '1-FC-21',
    label: 'Gas Turbine - General',
  },
  { enumKey: 'FC_1_215', failureCode: '1-FC-215', label: 'Gearbox' },
  { enumKey: 'FC_1_216', failureCode: '1-FC-216', label: 'Grounding system' },
  { enumKey: 'FC_1_217', failureCode: '1-FC-217', label: 'Guide Vane' },
  {
    enumKey: 'FC_1_22',
    failureCode: '1-FC-22',
    label: 'Heat exchanger - Fin fan',
  },
  {
    enumKey: 'FC_1_23',
    failureCode: '1-FC-23',
    label: 'Heat Exchanger - General',
  },
  {
    enumKey: 'FC_1_24',
    failureCode: '1-FC-24',
    label: 'Heat exchanger - Plate - Conventional',
  },
  {
    enumKey: 'FC_1_25',
    failureCode: '1-FC-25',
    label: 'Heat exchanger - Shell and Tube',
  },
  {
    enumKey: 'FC_1_26',
    failureCode: '1-FC-26',
    label: 'Heater & Boiler - General',
  },
  { enumKey: 'FC_1_261', failureCode: '1-FC-261', label: 'Lighting circuit' },
  { enumKey: 'FC_1_263', failureCode: '1-FC-263', label: 'Load adjuster' },
  { enumKey: 'FC_1_264', failureCode: '1-FC-264', label: 'Loading station' },
  { enumKey: 'FC_1_266', failureCode: '1-FC-266', label: 'Marker' },
  { enumKey: 'FC_1_268', failureCode: '1-FC-268', label: 'Membrane' },
  { enumKey: 'FC_1_27', failureCode: '1-FC-27', label: 'Mixer - General' },
  { enumKey: 'FC_1_275', failureCode: '1-FC-275', label: 'Oil circuit' },
  { enumKey: 'FC_1_277', failureCode: '1-FC-277', label: 'Permeator' },
  { enumKey: 'FC_1_28', failureCode: '1-FC-28', label: 'Pipe - General' },
  {
    enumKey: 'FC_1_285',
    failureCode: '1-FC-285',
    label: 'Potential test probe',
  },
  {
    enumKey: 'FC_1_29',
    failureCode: '1-FC-29',
    label: 'Process sensors - Flow',
  },
  {
    enumKey: 'FC_1_30',
    failureCode: '1-FC-30',
    label: 'Process sensors - General',
  },
  {
    enumKey: 'FC_1_31',
    failureCode: '1-FC-31',
    label: 'Process sensors - Level',
  },
  {
    enumKey: 'FC_1_32',
    failureCode: '1-FC-32',
    label: 'Process sensors - Pressure',
  },
  {
    enumKey: 'FC_1_33',
    failureCode: '1-FC-33',
    label: 'Process sensors - Temperature',
  },
  { enumKey: 'FC_1_34', failureCode: '1-FC-34', label: 'Pump - Centrifugal' },
  { enumKey: 'FC_1_35', failureCode: '1-FC-35', label: 'Pump - General' },
  { enumKey: 'FC_1_36', failureCode: '1-FC-36', label: 'Pump - Piston' },
  { enumKey: 'FC_1_37', failureCode: '1-FC-37', label: 'Reactor - General' },
  { enumKey: 'FC_1_372', failureCode: '1-FC-372', label: 'Refractory' },
  {
    enumKey: 'FC_1_38',
    failureCode: '1-FC-38',
    label: 'Refrigerating unit - General',
  },
  { enumKey: 'FC_1_382', failureCode: '1-FC-382', label: 'Relay' },
  { enumKey: 'FC_1_384', failureCode: '1-FC-384', label: 'Resistance' },
  { enumKey: 'FC_1_386', failureCode: '1-FC-386', label: 'Rotor' },
  { enumKey: 'FC_1_388', failureCode: '1-FC-388', label: 'Safety equipment' },
  { enumKey: 'FC_1_39', failureCode: '1-FC-39', label: 'Self - General' },
  { enumKey: 'FC_1_392', failureCode: '1-FC-392', label: 'Shell' },
  { enumKey: 'FC_1_40', failureCode: '1-FC-40', label: 'Silencer - General' },
  { enumKey: 'FC_1_402', failureCode: '1-FC-402', label: 'Start-up device' },
  { enumKey: 'FC_1_404', failureCode: '1-FC-404', label: 'Stator' },
  { enumKey: 'FC_1_406', failureCode: '1-FC-406', label: 'Transformer' },
  {
    enumKey: 'FC_1_41',
    failureCode: '1-FC-41',
    label: 'Transformer - Power transformer',
  },
  { enumKey: 'FC_1_42', failureCode: '1-FC-42', label: 'Tube - General' },
  {
    enumKey: 'FC_1_43',
    failureCode: '1-FC-43',
    label: 'Turboexpander - Gas processing',
  },
  {
    enumKey: 'FC_1_44',
    failureCode: '1-FC-44',
    label: 'Turboexpander - Gas treatment',
  },
  {
    enumKey: 'FC_1_45',
    failureCode: '1-FC-45',
    label: 'Uninterruptible power supply',
  },
  { enumKey: 'FC_1_46', failureCode: '1-FC-46', label: 'Valve - General' },
  { enumKey: 'FC_1_462', failureCode: '1-FC-462', label: 'Vane' },
  { enumKey: 'FC_1_464', failureCode: '1-FC-464', label: 'Vaporizer' },
  {
    enumKey: 'FC_1_47',
    failureCode: '1-FC-47',
    label: 'Vessel - Destillation column',
  },
  { enumKey: 'FC_1_47', failureCode: '1-FC-47', label: 'Vessel - Cryogenic' },
  { enumKey: 'FC_1_48', failureCode: '1-FC-48', label: 'Vessel - General' },
  {
    enumKey: 'FC_1_49',
    failureCode: '1-FC-49',
    label: 'Valve - Pressure Safety',
  },
  { enumKey: 'FC_1_490', failureCode: '1-FC-490', label: 'Water-trap' },
  { enumKey: 'FC_1_495', failureCode: '1-FC-495', label: 'Water treatment' },
  { enumKey: 'FC_1_497', failureCode: '1-FC-497', label: 'Weighting balance' },
  {
    enumKey: 'FC_1_50',
    failureCode: '1-FC-50',
    label: 'Valve - Thermal Safety',
  },
  {
    enumKey: 'FC_1_500',
    failureCode: '1-FC-500',
    label: 'Iron separator?Coal?',
  },
  {
    enumKey: 'FC_1_501',
    failureCode: '1-FC-501',
    label: 'Coal milling machine',
  },
  {
    enumKey: 'FC_1_502',
    failureCode: '1-FC-502',
    label: 'Belt conveyor?Coal?',
  },
  {
    enumKey: 'FC_1_503',
    failureCode: '1-FC-503',
    label: 'Vibrating feeder?Coal?',
  },
  {
    enumKey: 'FC_1_504',
    failureCode: '1-FC-504',
    label: 'Screw conveyor?Coal?',
  },
  { enumKey: 'FC_1_505', failureCode: '1-FC-505', label: 'Screw valve?Coal?' },
  {
    enumKey: 'FC_1_506',
    failureCode: '1-FC-506',
    label: 'Eletric fiber separator?Coal?',
  },
  {
    enumKey: 'FC_1_507',
    failureCode: '1-FC-507',
    label: 'Electro-hydraulic three-pass splitting (Coal)',
  },
  {
    enumKey: 'FC_1_508',
    failureCode: '1-FC-508',
    label: 'Slag crusher (Coal)',
  },
  {
    enumKey: 'FC_1_509',
    failureCode: '1-FC-509',
    label: 'Slag bath Scraper conveyer',
  },
  {
    enumKey: 'FC_1_510',
    failureCode: '1-FC-510',
    label: 'Vibratory dewatering screen',
  },
  {
    enumKey: 'FC_1_511',
    failureCode: '1-FC-511',
    label: 'Subsider scraper (Coal)',
  },
  {
    enumKey: 'FC_1_512',
    failureCode: '1-FC-512',
    label: 'Filter machine?Coal slag?',
  },
  {
    enumKey: 'FC_1_513',
    failureCode: '1-FC-513',
    label: 'Electric flap valve?Coal?',
  },
  {
    enumKey: 'FC_1_514',
    failureCode: '1-FC-514',
    label: 'Activation coal feeder',
  },
  {
    enumKey: 'FC_1_515',
    failureCode: '1-FC-515',
    label: 'Screening and crushing machine?Coal?',
  },
  {
    enumKey: 'FC_1_516',
    failureCode: '1-FC-516',
    label: 'Electric hydraulic three-way change valve (Coal)',
  },
  {
    enumKey: 'FC_1_517',
    failureCode: '1-FC-517',
    label: 'Electric plow tripper (Coal)',
  },
  { enumKey: 'FC_1_518', failureCode: '1-FC-518', label: 'Gasifier vessel' },
  {
    enumKey: 'FC_1_519',
    failureCode: '1-FC-519',
    label: 'Inert gas generator',
  },
  { enumKey: 'FC_1_520', failureCode: '1-FC-520', label: 'Venturi scrubbers' },
  { enumKey: 'FC_1_521', failureCode: '1-FC-521', label: 'Sludge scraper' },
  {
    enumKey: 'FC_1_522',
    failureCode: '1-FC-522',
    label: 'Plate and frame filter press',
  },
  {
    enumKey: 'FC_1_523',
    failureCode: '1-FC-523',
    label: 'Electric sludge funnel',
  },
  { enumKey: 'FC_1_524', failureCode: '1-FC-524', label: 'Electric skimmer' },
  { enumKey: 'FC_1_525', failureCode: '1-FC-525', label: 'Membrane element' },
  {
    enumKey: 'FC_1_526',
    failureCode: '1-FC-526',
    label: 'Ultrafiltration equipment',
  },
  { enumKey: 'FC_1_529', failureCode: '1-FC-529', label: 'Steam generation' },
  { enumKey: 'FC_1_530', failureCode: '1-FC-530', label: 'Dosing Pump' },
  {
    enumKey: 'PB_2_005',
    failureCode: '2-PB-005',
    label: 'Abnormal analysis results',
  },
  {
    enumKey: 'PB_2_01',
    failureCode: '2-PB-01',
    label: 'Abnormal instrument reading',
  },
  {
    enumKey: 'PB_2_015',
    failureCode: '2-PB-015',
    label: 'Abnormal Metallographic results',
  },
  { enumKey: 'PB_2_02', failureCode: '2-PB-02', label: 'Adsorbing deficiency' },
  { enumKey: 'PB_2_022', failureCode: '2-PB-022', label: 'Alarm' },
  { enumKey: 'PB_2_029', failureCode: '2-PB-029', label: 'Blocked' },
  { enumKey: 'PB_2_03', failureCode: '2-PB-03', label: 'Breakage (problem)' },
  { enumKey: 'PB_2_04', failureCode: '2-PB-04', label: 'Breakdown' },
  {
    enumKey: 'PB_2_042',
    failureCode: '2-PB-042',
    label: 'Building infrastructure damage',
  },
  { enumKey: 'PB_2_044', failureCode: '2-PB-044', label: 'Burning' },
  {
    enumKey: 'PB_2_05',
    failureCode: '2-PB-05',
    label: 'Communication problem',
  },
  {
    enumKey: 'PB_2_06',
    failureCode: '2-PB-06',
    label: 'Configuration problem',
  },
  { enumKey: 'PB_2_061', failureCode: '2-PB-061', label: 'Color' },
  {
    enumKey: 'PB_2_062',
    failureCode: '2-PB-062',
    label: 'Contamination (problem)',
  },
  {
    enumKey: 'PB_2_063',
    failureCode: '2-PB-063',
    label: 'Corrosion (problem)',
  },
  { enumKey: 'PB_2_07', failureCode: '2-PB-07', label: 'CPU problem' },
  { enumKey: 'PB_2_071', failureCode: '2-PB-071', label: 'Deflection' },
  { enumKey: 'PB_2_072', failureCode: '2-PB-072', label: 'Degraded operating' },
  { enumKey: 'PB_2_08', failureCode: '2-PB-08', label: 'Delayed operation' },
  { enumKey: 'PB_2_084', failureCode: '2-PB-084', label: "Doesn't print" },
  { enumKey: 'PB_2_085', failureCode: '2-PB-085', label: "Doesn't weight" },
  { enumKey: 'PB_2_09', failureCode: '2-PB-09', label: 'Erratic output' },
  { enumKey: 'PB_2_10', failureCode: '2-PB-10', label: 'External Leakage' },
  {
    enumKey: 'PB_2_11',
    failureCode: '2-PB-11',
    label: 'External leakage - Fuel',
  },
  {
    enumKey: 'PB_2_12',
    failureCode: '2-PB-12',
    label: 'External leakage - Process medium',
  },
  {
    enumKey: 'PB_2_13',
    failureCode: '2-PB-13',
    label: 'External leakage - Utility Medium',
  },
  {
    enumKey: 'PB_2_14',
    failureCode: '2-PB-14',
    label: 'External leakage utilities',
  },
  {
    enumKey: 'PB_2_15',
    failureCode: '2-PB-15',
    label: 'Fail to close on demand',
  },
  {
    enumKey: 'PB_2_16',
    failureCode: '2-PB-16',
    label: 'Fail to function on demand',
  },
  {
    enumKey: 'PB_2_17',
    failureCode: '2-PB-17',
    label: 'Fail to open on demand',
  },
  { enumKey: 'PB_2_18', failureCode: '2-PB-18', label: 'Fail to regulate' },
  {
    enumKey: 'PB_2_19',
    failureCode: '2-PB-19',
    label: 'Fail to start on demand',
  },
  {
    enumKey: 'PB_2_20',
    failureCode: '2-PB-20',
    label: 'Fail to stop on demand',
  },
  {
    enumKey: 'PB_2_21',
    failureCode: '2-PB-21',
    label: 'Failure to function on demand',
  },
  {
    enumKey: 'PB_2_22',
    failureCode: '2-PB-22',
    label: 'Faulty output frequency',
  },
  {
    enumKey: 'PB_2_23',
    failureCode: '2-PB-23',
    label: 'Faulty output voltage',
  },
  { enumKey: 'PB_2_24', failureCode: '2-PB-24', label: 'Filtering deficiency' },
  { enumKey: 'PB_2_242', failureCode: '2-PB-242', label: 'Fissure' },
  { enumKey: 'PB_2_244', failureCode: '2-PB-244', label: 'Flash' },
  { enumKey: 'PB_2_245', failureCode: '2-PB-245', label: 'Fog' },
  { enumKey: 'PB_2_246', failureCode: '2-PB-246', label: 'Fouling (problem)' },
  { enumKey: 'PB_2_25', failureCode: '2-PB-25', label: 'Fail to synchronize' },
  { enumKey: 'PB_2_255', failureCode: '2-PB-255', label: 'Furniture problem' },
  { enumKey: 'PB_2_26', failureCode: '2-PB-26', label: 'High Output' },
  {
    enumKey: 'PB_2_27',
    failureCode: '2-PB-27',
    label: 'High output - unknown reading',
  },
  {
    enumKey: 'PB_2_27',
    failureCode: '2-PB-27',
    label: 'High output, unknown reading',
  },
  {
    enumKey: 'PB_2_27',
    failureCode: '2-PB-27',
    label: 'High output; unknown reading',
  },
  { enumKey: 'PB_2_28', failureCode: '2-PB-28', label: 'High temperature' },
  { enumKey: 'PB_2_281', failureCode: '2-PB-281', label: 'High Level' },
  {
    enumKey: 'PB_2_282',
    failureCode: '2-PB-282',
    label: 'High levels of condensate',
  },
  { enumKey: 'PB_2_283', failureCode: '2-PB-283', label: 'High Pressure' },
  {
    enumKey: 'PB_2_284',
    failureCode: '2-PB-284',
    label: 'High Oil consumption',
  },
  { enumKey: 'PB_2_29', failureCode: '2-PB-29', label: 'I/O card problem' },
  { enumKey: 'PB_2_295', failureCode: '2-PB-295', label: 'Ice' },
  { enumKey: 'PB_2_30', failureCode: '2-PB-30', label: 'IHM problem' },
  {
    enumKey: 'PB_2_31',
    failureCode: '2-PB-31',
    label: 'Insufficient heat transfer',
  },
  { enumKey: 'PB_2_312', failureCode: '2-PB-312', label: 'Insulation Default' },
  { enumKey: 'PB_2_32', failureCode: '2-PB-32', label: 'Internal leakage' },
  {
    enumKey: 'PB_2_322',
    failureCode: '2-PB-322',
    label: 'Intermittent operation',
  },
  { enumKey: 'PB_2_324', failureCode: '2-PB-324', label: 'Leakage (problem)' },
  { enumKey: 'PB_2_326', failureCode: '2-PB-326', label: 'Loss of Efficiency' },
  { enumKey: 'PB_2_328', failureCode: '2-PB-328', label: 'Loss of Function' },
  { enumKey: 'PB_2_33', failureCode: '2-PB-33', label: 'Loss of redundancy' },
  { enumKey: 'PB_2_335', failureCode: '2-PB-335', label: 'Low Level' },
  { enumKey: 'PB_2_336', failureCode: '2-PB-336', label: 'Low Pressure' },
  { enumKey: 'PB_2_34', failureCode: '2-PB-34', label: 'Low Output' },
  {
    enumKey: 'PB_2_35',
    failureCode: '2-PB-35',
    label: 'Low output; unknown reading',
  },
  {
    enumKey: 'PB_2_35',
    failureCode: '2-PB-35',
    label: 'Low Output, Unknown reading',
  },
  {
    enumKey: 'PB_2_35',
    failureCode: '2-PB-35',
    label: 'Low Output - Unknown reading',
  },
  { enumKey: 'PB_2_355', failureCode: '2-PB-355', label: 'Malfunction' },
  { enumKey: 'PB_2_36', failureCode: '2-PB-36', label: 'Material problem' },
  {
    enumKey: 'PB_2_37',
    failureCode: '2-PB-37',
    label: 'Minor in-service problem',
  },
  {
    enumKey: 'PB_2_38',
    failureCode: '2-PB-38',
    label: 'Minor in-service problems',
  },
  { enumKey: 'PB_2_39', failureCode: '2-PB-39', label: 'Mixing deficiency' },
  { enumKey: 'PB_2_40', failureCode: '2-PB-40', label: 'No output' },
  {
    enumKey: 'PB_2_402',
    failureCode: '2-PB-402',
    label: 'No air conditionning',
  },
  { enumKey: 'PB_2_404', failureCode: '2-PB-404', label: 'No drinking water' },
  { enumKey: 'PB_2_406', failureCode: '2-PB-406', label: 'No IT network' },
  { enumKey: 'PB_2_408', failureCode: '2-PB-408', label: 'No power supply' },
  { enumKey: 'PB_2_41', failureCode: '2-PB-41', label: 'Noise' },
  {
    enumKey: 'PB_2_412',
    failureCode: '2-PB-412',
    label: 'Oil thickening or discoloration',
  },
  { enumKey: 'PB_2_42', failureCode: '2-PB-42', label: 'Other problem' },
  { enumKey: 'PB_2_425', failureCode: '2-PB-425', label: 'Out of order' },
  {
    enumKey: 'PB_2_43',
    failureCode: '2-PB-43',
    label: 'Overheating (problem)',
  },
  { enumKey: 'PB_2_432', failureCode: '2-PB-432', label: 'Overflow' },
  { enumKey: 'PB_2_44', failureCode: '2-PB-44', label: 'Parameter deviation' },
  { enumKey: 'PB_2_445', failureCode: '2-PB-445', label: 'Pilot lamp burnt' },
  { enumKey: 'PB_2_45', failureCode: '2-PB-45', label: 'Plugged / Choked' },
  { enumKey: 'PB_2_46', failureCode: '2-PB-46', label: 'Plugged/choked' },
  { enumKey: 'PB_2_47', failureCode: '2-PB-47', label: 'Program error' },
  {
    enumKey: 'PB_2_47',
    failureCode: '2-PB-47',
    label: 'Program error (problem)',
  },
  { enumKey: 'PB_2_475', failureCode: '2-PB-475', label: 'Smell' },
  { enumKey: 'PB_2_476', failureCode: '2-PB-476', label: 'Smoke' },
  { enumKey: 'PB_2_477', failureCode: '2-PB-477', label: 'Sparks' },
  { enumKey: 'PB_2_48', failureCode: '2-PB-48', label: 'Software problem' },
  {
    enumKey: 'PB_2_49',
    failureCode: '2-PB-49',
    label: 'Spurious high level alarm signal',
  },
  {
    enumKey: 'PB_2_50',
    failureCode: '2-PB-50',
    label: 'Spurious low level alarm signal',
  },
  { enumKey: 'PB_2_51', failureCode: '2-PB-51', label: 'Spurious operation' },
  { enumKey: 'PB_2_52', failureCode: '2-PB-52', label: 'Spurious stop' },
  {
    enumKey: 'PB_2_53',
    failureCode: '2-PB-53',
    label: 'Structural deficiency',
  },
  { enumKey: 'PB_2_533', failureCode: '2-PB-533', label: 'Surge' },
  {
    enumKey: 'PB_2_54',
    failureCode: '2-PB-54',
    label: 'Transmission deficiency',
  },
  { enumKey: 'PB_2_55', failureCode: '2-PB-55', label: 'Unknown problem' },
  {
    enumKey: 'PB_2_56',
    failureCode: '2-PB-56',
    label: 'Ventilation deficiency',
  },
  { enumKey: 'PB_2_57', failureCode: '2-PB-57', label: 'Very low output' },
  { enumKey: 'PB_2_58', failureCode: '2-PB-58', label: 'Vibration (problem)' },
  {
    enumKey: 'CA_3_002',
    failureCode: '3-CA-002',
    label: 'Air conditionner failure',
  },
  { enumKey: 'CA_3_005', failureCode: '3-CA-005', label: 'Bad configuration' },
  { enumKey: 'CA_3_006', failureCode: '3-CA-006', label: 'Bad connection' },
  { enumKey: 'CA_3_007', failureCode: '3-CA-007', label: 'Battery defective' },
  { enumKey: 'CA_3_01', failureCode: '3-CA-01', label: 'Blockage / Plugged' },
  { enumKey: 'CA_3_02', failureCode: '3-CA-02', label: 'Breakage (cause)' },
  { enumKey: 'CA_3_03', failureCode: '3-CA-03', label: 'burst' },
  { enumKey: 'CA_3_04', failureCode: '3-CA-04', label: 'Cavitation' },
  {
    enumKey: 'CA_3_05',
    failureCode: '3-CA-05',
    label: 'Clearance / alignment failure',
  },
  { enumKey: 'CA_3_06', failureCode: '3-CA-06', label: 'Clogged up' },
  { enumKey: 'CA_3_07', failureCode: '3-CA-07', label: 'Combined causes' },
  { enumKey: 'CA_3_08', failureCode: '3-CA-08', label: 'common mode failure' },
  { enumKey: 'CA_3_09', failureCode: '3-CA-09', label: 'Contamination' },
  {
    enumKey: 'CA_3_09',
    failureCode: '3-CA-09',
    label: 'Contamination (cause)',
  },
  { enumKey: 'CA_3_10', failureCode: '3-CA-10', label: 'Control failure' },
  { enumKey: 'CA_3_11', failureCode: '3-CA-11', label: 'CorroEarth' },
  { enumKey: 'CA_3_12', failureCode: '3-CA-12', label: 'Corrosion' },
  {
    enumKey: 'CA_3_121',
    failureCode: '3-CA-121',
    label: 'Corrosion (external)',
  },
  {
    enumKey: 'CA_3_122',
    failureCode: '3-CA-122',
    label: 'Corrosion (internal)',
  },
  { enumKey: 'CA_3_13', failureCode: '3-CA-13', label: 'Crack' },
  { enumKey: 'CA_3_14', failureCode: '3-CA-14', label: 'Creep' },
  { enumKey: 'CA_3_15', failureCode: '3-CA-15', label: 'Deformation' },
  { enumKey: 'CA_3_16', failureCode: '3-CA-16', label: 'Drilling' },
  {
    enumKey: 'CA_3_17',
    failureCode: '3-CA-17',
    label: 'Earth / isolation fault',
  },
  {
    enumKey: 'CA_3_17',
    failureCode: '3-CA-17',
    label: 'Earth / insulation fault',
  },
  {
    enumKey: 'CA_3_18',
    failureCode: '3-CA-18',
    label: 'Electrical failure - general',
  },
  {
    enumKey: 'CA_3_182',
    failureCode: '3-CA-182',
    label: 'Electrical power cut-off device failure',
  },
  {
    enumKey: 'CA_3_184',
    failureCode: '3-CA-184',
    label: 'Electronic card failure',
  },
  { enumKey: 'CA_3_19', failureCode: '3-CA-19', label: 'Erosion' },
  {
    enumKey: 'CA_3_20',
    failureCode: '3-CA-20',
    label: 'Excitation deficiency',
  },
  {
    enumKey: 'CA_3_21',
    failureCode: '3-CA-21',
    label: 'External influence - general',
  },
  { enumKey: 'CA_3_215', failureCode: '3-CA-215', label: 'Fan failure' },
  { enumKey: 'CA_3_22', failureCode: '3-CA-22', label: 'Fatigue' },
  {
    enumKey: 'CA_3_23',
    failureCode: '3-CA-23',
    label: 'Faulty power / voltage',
  },
  {
    enumKey: 'CA_3_24',
    failureCode: '3-CA-24',
    label: 'Faulty signal / indication / alarm',
  },
  {
    enumKey: 'CA_3_242',
    failureCode: '3-CA-242',
    label: 'Flow sampling failure',
  },
  { enumKey: 'CA_3_243', failureCode: '3-CA-243', label: 'Fouling (cause)' },
  { enumKey: 'CA_3_244', failureCode: '3-CA-244', label: 'Freezing' },
  {
    enumKey: 'CA_3_245',
    failureCode: '3-CA-245',
    label: 'Freezing impulsion lines',
  },
  { enumKey: 'CA_3_246', failureCode: '3-CA-246', label: 'Furniture broken' },
  { enumKey: 'CA_3_247', failureCode: '3-CA-247', label: 'gas vector failure' },
  { enumKey: 'CA_3_248', failureCode: '3-CA-248', label: 'heater failure' },
  { enumKey: 'CA_3_249', failureCode: '3-CA-249', label: 'Hub failure' },
  {
    enumKey: 'CA_3_25',
    failureCode: '3-CA-25',
    label: 'Instrument failure - general',
  },
  { enumKey: 'CA_3_26', failureCode: '3-CA-26', label: 'Insuation deficiency' },
  {
    enumKey: 'CA_3_27',
    failureCode: '3-CA-27',
    label: 'Insulation deficiency',
  },
  { enumKey: 'CA_3_275', failureCode: '3-CA-275', label: 'Lamp failure' },
  { enumKey: 'CA_3_28', failureCode: '3-CA-28', label: 'Leakage (cause)' },
  { enumKey: 'CA_3_28', failureCode: '3-CA-28', label: 'Leakage' },
  { enumKey: 'CA_3_29', failureCode: '3-CA-29', label: 'Looseness' },
  { enumKey: 'CA_3_292', failureCode: '3-CA-292', label: 'Loose connection' },
  {
    enumKey: 'CA_3_30',
    failureCode: '3-CA-30',
    label: 'Material failure - general',
  },
  {
    enumKey: 'CA_3_31',
    failureCode: '3-CA-31',
    label: 'Mechanical failure - general',
  },
  {
    enumKey: 'CA_3_32',
    failureCode: '3-CA-32',
    label: 'Misc. External influences',
  },
  {
    enumKey: 'CA_3_33',
    failureCode: '3-CA-33',
    label: 'Miscellaneous - general',
  },
  { enumKey: 'CA_3_34', failureCode: '3-CA-34', label: 'No power / voltage' },
  {
    enumKey: 'CA_3_35',
    failureCode: '3-CA-35',
    label: 'No signal / indication / alarm',
  },
  { enumKey: 'CA_3_36', failureCode: '3-CA-36', label: 'Oil leakage' },
  { enumKey: 'CA_3_37', failureCode: '3-CA-37', label: 'Open circuit' },
  { enumKey: 'CA_3_38', failureCode: '3-CA-38', label: 'Other cause' },
  { enumKey: 'CA_3_39', failureCode: '3-CA-39', label: 'Out of adjustment' },
  { enumKey: 'CA_3_391', failureCode: '3-CA-391', label: 'Out of date' },
  {
    enumKey: 'CA_3_395',
    failureCode: '3-CA-395',
    label: 'Output circuit breaker failure',
  },
  { enumKey: 'CA_3_40', failureCode: '3-CA-40', label: 'Overheating (cause)' },
  { enumKey: 'CA_3_405', failureCode: '3-CA-405', label: 'PLC failure' },
  { enumKey: 'CA_3_41', failureCode: '3-CA-41', label: 'Plugging' },
  {
    enumKey: 'CA_3_413',
    failureCode: '3-CA-413',
    label: 'Pressure sampling failure',
  },
  { enumKey: 'CA_3_414', failureCode: '3-CA-414', label: 'Printer failure' },
  {
    enumKey: 'CA_3_415',
    failureCode: '3-CA-415',
    label: 'Program error (cause)',
  },
  { enumKey: 'CA_3_418', failureCode: '3-CA-418', label: 'Relay failure' },
  {
    enumKey: 'CA_3_419',
    failureCode: '3-CA-419',
    label: 'Server out of order',
  },
  { enumKey: 'CA_3_42', failureCode: '3-CA-42', label: 'Short circuiting' },
  { enumKey: 'CA_3_43', failureCode: '3-CA-43', label: 'Software failure' },
  {
    enumKey: 'CA_3_435',
    failureCode: '3-CA-435',
    label: 'Static switch out of order',
  },
  { enumKey: 'CA_3_44', failureCode: '3-CA-44', label: 'Sticking' },
  {
    enumKey: 'CA_3_445',
    failureCode: '3-CA-445',
    label: 'Temperature sampling failure',
  },
  { enumKey: 'CA_3_45', failureCode: '3-CA-45', label: 'Thermal point' },
  {
    enumKey: 'CA_3_455',
    failureCode: '3-CA-455',
    label: 'Transmitter failure',
  },
  { enumKey: 'CA_3_46', failureCode: '3-CA-46', label: 'Unknown cause' },
  { enumKey: 'CA_3_47', failureCode: '3-CA-47', label: 'Vibration (cause)' },
  { enumKey: 'CA_3_475', failureCode: '3-CA-475', label: 'Water line broken' },
  { enumKey: 'CA_3_48', failureCode: '3-CA-48', label: 'Wear' },
  { enumKey: 'CA_3_657', failureCode: '3-CA-657', label: 'Corrosion' },
  {
    enumKey: 'CA_3_658',
    failureCode: '3-CA-658',
    label: 'Corrosion - crevices',
  },
  {
    enumKey: 'CA_3_658',
    failureCode: '3-CA-658',
    label: 'Corrosion - crevices',
  },
  {
    enumKey: 'CA_3_659',
    failureCode: '3-CA-659',
    label: 'Corrosion - Flow accelerated corrosion (FAC)',
  },
  {
    enumKey: 'CA_3_659',
    failureCode: '3-CA-659',
    label: 'Corrosion - Flow accelerated corrosion (FAC)',
  },
  {
    enumKey: 'CA_3_660',
    failureCode: '3-CA-660',
    label: 'Corrosion - Galvanic',
  },
  {
    enumKey: 'CA_3_660',
    failureCode: '3-CA-660',
    label: 'Corrosion - Galvanic',
  },
  {
    enumKey: 'CA_3_661',
    failureCode: '3-CA-661',
    label: 'Corrosion - Low pH or High pH',
  },
  {
    enumKey: 'CA_3_661',
    failureCode: '3-CA-661',
    label: 'Corrosion - Low pH or High pH',
  },
  {
    enumKey: 'CA_3_662',
    failureCode: '3-CA-662',
    label: 'Corrosion - Pitting',
  },
  {
    enumKey: 'CA_3_662',
    failureCode: '3-CA-662',
    label: 'Corrosion - Pitting',
  },
  {
    enumKey: 'CA_3_663',
    failureCode: '3-CA-663',
    label: 'Corrosion - Stress cracking corrosion',
  },
  {
    enumKey: 'CA_3_663',
    failureCode: '3-CA-663',
    label: 'Corrosion - Stress cracking corrosion',
  },
  {
    enumKey: 'CA_3_664',
    failureCode: '3-CA-664',
    label: 'Corrosion - Structural Integrity',
  },
  {
    enumKey: 'CA_3_664',
    failureCode: '3-CA-664',
    label: 'Corrosion - Structural Integrity',
  },
  {
    enumKey: 'CA_3_665',
    failureCode: '3-CA-665',
    label: 'Corrosion - Suspended materials / Erosion',
  },
  {
    enumKey: 'CA_3_665',
    failureCode: '3-CA-665',
    label: 'Corrosion - Suspended materials / Erosion',
  },
  {
    enumKey: 'CA_3_666',
    failureCode: '3-CA-666',
    label: 'Corrosion - Thinning',
  },
  {
    enumKey: 'CA_3_666',
    failureCode: '3-CA-666',
    label: 'Corrosion - Thinning',
  },
  {
    enumKey: 'CA_3_667',
    failureCode: '3-CA-667',
    label: 'Corrosion - Under deposit corrosion',
  },
  {
    enumKey: 'CA_3_667',
    failureCode: '3-CA-667',
    label: 'Corrosion - Under deposit corrosion',
  },
  {
    enumKey: 'CA_3_668',
    failureCode: '3-CA-668',
    label: 'Corrosion - Boiler tube failure',
  },
  {
    enumKey: 'CA_3_668',
    failureCode: '3-CA-668',
    label: 'Corrosion - Boiler tube failure',
  },
  {
    enumKey: 'CA_3_669',
    failureCode: '3-CA-669',
    label: 'Corrosion - Microbiologically induced',
  },
  {
    enumKey: 'CA_3_672',
    failureCode: '3-CA-672',
    label: 'Plugged - Oily material',
  },
  {
    enumKey: 'CA_3_673',
    failureCode: '3-CA-673',
    label: 'Plugged - Suspended solids and packing materials',
  },
  { enumKey: 'CA_3_674', failureCode: '3-CA-674', label: 'Plugged' },
  {
    enumKey: 'CA_3_675',
    failureCode: '3-CA-675',
    label: 'Plugged - Biofouling and vegetation',
  },
  {
    enumKey: 'CA_3_676',
    failureCode: '3-CA-676',
    label: 'Plugged - Corrosion products',
  },
  {
    enumKey: 'CA_3_677',
    failureCode: '3-CA-677',
    label: 'Plugged - Mineral Scale',
  },
  {
    enumKey: 'CA_3_678',
    failureCode: '3-CA-678',
    label: 'Plugged - Wood, suspended solids',
  },
];
