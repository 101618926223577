import { Injectable } from '@angular/core';
import { LocalStorageKey, TaskPut } from '@al/entities';
import { UUID } from 'angular2-uuid';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class TaskUpdateCache extends LocalCache<TaskPut> {
  public constructor() {
    super(LocalStorageKey.TASK_UPDATE_CACHE);
  }

  public put(value: TaskPut): void {
    // remove previous cache element for this Task
    const cacheFiltered = this.cache.filter((cache: TaskPut) => {
      return !(cache.number === value.number && cache.taskId === value.taskId);
    });
    this.cache = cacheFiltered;
    this.cache.push({ ...value, uuid: UUID.UUID() });
    this.storageManager.store(this.cache, this.getClazz());
  }

  protected getClazz(): typeof TaskPut {
    return TaskPut;
  }
}
