import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-al-work-order-work-log-description',
  templateUrl: './al-work-order-work-log-description.component.html',
  styleUrls: ['./al-work-order-work-log-description.component.scss'],
})
export class AlWorkOrderWorkLogDescriptionComponent implements OnInit {
  public workOrderDescriptionLong: string | null;
  constructor(
    private matDialogRef: MatDialogRef<AlWorkOrderWorkLogDescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {descriptionLong: string}  ) {
    this.workOrderDescriptionLong = null;
  }

  public ngOnInit(): void {
    if(this.data.descriptionLong){
      this.workOrderDescriptionLong = this.data.descriptionLong.replace('<!-- RICH TEXT -->', '');
    }
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }
}
