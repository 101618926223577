<div class="al-work-order-list">
   <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!--/ WO Id /-->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="id">
        <al-table-column-header-filtering
          field="number"
          fieldType="input"
          title="{{'WO Id' | translate }}"
          *ngIf='displayFilters'
          ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="WO Id" class="id">
        {{ element.number }}
      </mat-cell>
    </ng-container>

    <!--/ WO Short description /-->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="description">
        <al-table-column-header-filtering
          field="description"
          fieldType="input"
          title="{{'WO Short description' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="WO Short description" class="description">
        {{ element.description }}
      </mat-cell>
    </ng-container>

    <!--/ Equipment /-->
    <ng-container matColumnDef="assetNum">
      <mat-header-cell *matHeaderCellDef class="asset-num">
        <al-table-column-header-filtering
          field="assetNum"
          fieldType="input"
          title="{{'Equipment' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Equipment" class="asset-num">
        {{ getProcessTag(element) }}
      </mat-cell>
    </ng-container>

    <!--/ Location /-->
    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef class="location">
        <al-table-column-header-filtering
          field="location"
          fieldType="input"
          title="{{'Location' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Location" class="location">
        {{ element.location }}
      </mat-cell>
    </ng-container>

    <!--/ Objective end date /-->
    <ng-container matColumnDef="objectiveEndDate">
      <mat-header-cell *matHeaderCellDef class="objective-end-date">
        <al-table-column-header-filtering
          field="targetCompDate"
          fieldType="datePicker"
          title="{{'Objective End Date' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Objective End Date" class="objective-end-date">
        <span *ngIf="element.schedDateFin">
         {{  element.schedDateFin  | localizedDate :'shortDate' }}
        </span>
        <span *ngIf="!element.schedDateFin">
         {{ element.targetCompDate| localizedDate :'shortDate' }}
        </span>
       </mat-cell>
    </ng-container>

    <!--/ Work type /-->
    <ng-container matColumnDef="workType">
      <mat-header-cell *matHeaderCellDef class="work-type">
        <al-table-column-header-filtering
          field="type"
          fieldType="dropDown"
          title="{{'Work Type' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Work Type" class="work-type">
        <mat-chip-list>
          <mat-chip> {{ element.type }} </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <!--/ Priority /-->
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef class="priority">
        <al-table-column-header-filtering
          field="priority"
          fieldType="dropDown"
          title="{{'Priority' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Priority" class="priority">
        <mat-chip-list>
          <mat-chip> {{'Priority' | translate}} {{ element.priority }} </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <!--/ Role /-->
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef class="role">
        <al-table-column-header-filtering
          field="role"
          fieldType="dropDown"
          title="{{'Role' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Role" class="role">
        <mat-chip-list>
          <mat-chip> {{ roleDisplay(element) | translate }} </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <!--/ gotCalibration /-->
    <ng-container matColumnDef="gotCalibration">
      <mat-header-cell *matHeaderCellDef class="gotCalibration">
        <al-table-column-header-filtering
          field="gotCalibration"
          fieldType="dropDown"
          title="{{'Calibration' | translate }}"
          *ngIf='displayFilters'
        ></al-table-column-header-filtering>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="gotCalibration" class="gotCalibration">
        <p style="margin-left: 2em;"><mat-checkbox disabled [checked]="checkForCalibration(element)"></mat-checkbox></p>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      data-label="Filters"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="navigateSummary(row)"
    ></mat-row>
  </mat-table>
</div>
