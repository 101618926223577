<div class="login">
  <div class="wrapper">
    <img src="assets/images/logo_airliquide.png" alt="Air Liquide">
    <h1>{{ title }}</h1>
    <div class="subtitle">
      <ng-container *ngIf="isServiceWorkerReady; else elseIsNotReady">
        <ng-container *ngIf="previousLoginFailed; else elseTemplate">
          <span class="error" translate *ngIf="errorType === loginErrorEnum.MAXIMO_DOWN">
            Login failed, maximo server are down. Please retry later
          </span>
          <span class="error" translate *ngIf="errorType === loginErrorEnum.LOGIN_FAILED">
            Login failed, check your account
          </span>
          <span class="error" translate *ngIf="errorType === loginErrorEnum.SITE_MISSING">
            No default site, check your account
          </span>
          <span class="error" translate *ngIf="errorType === loginErrorEnum.MAXIMO_NOT_ENABLED">
            User does not have access to Maximo or is not active in Maximo
          </span>
        </ng-container>
        <ng-template #elseTemplate>
          <span class="text" translate>Log in with</span>
        </ng-template>
      </ng-container>
      <ng-template #elseIsNotReady>
        <div class="spinner-wrapper">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
        <span class="text" translate>Application is loading</span>
      </ng-template>
      <div class="horizontal-separator"></div>
    </div>
    <al-session-cas *ngIf="isServiceWorkerReady"></al-session-cas>
  </div>
</div>
