import {
  LocalStorageConfiguration,
  LocalStorageKey,
  RightActionEnum,
  RightStatusEnum,
  RightTypeEnum,
  UserRights,
} from '@al/entities';
import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccessToken } from './al-session-cas/access-token';
import { IdToken, IdTokenCognito } from './al-session-cas/id-token';
import { UserRightsCreateCache } from './caches/user-rights-create.cache';
import { SessionService } from './state/session.service';

@Injectable({
  providedIn: 'root',
})
export class AlSessionService {
  public constructor(private sessionService: SessionService) {}

  public getAccessToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      switchMap((session) =>
        from(session ? session.getAccessToken().getJwtToken() : '')
      )
    );
  }

  public getIdToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      switchMap((session) =>
        from(session ? session.getIdToken().getJwtToken() : '')
      )
    );
  }

  public initSessionStore(idTokenDecoded: IdToken): void {
    this.sessionService.setImageUrl(idTokenDecoded.picture);
    this.sessionService.setName(
      idTokenDecoded.firstname,
      idTokenDecoded.lastname
    );
    this.sessionService.setEmail(idTokenDecoded.email);
    this.sessionService.setToken(idTokenDecoded['custom:kiteAccessToken']);
  }

  public setAccessTokenToLocalStorage(session: CognitoUserSession): void {
    if (session) {
      const accessTokenDecoded = jwt_decode<AccessToken>(
        session.getAccessToken().getJwtToken()
      );
      localStorage.setItem(
        LocalStorageConfiguration.ACCESS_TOKEN,
        session.getAccessToken().getJwtToken()
      );
      localStorage.setItem(
        LocalStorageConfiguration.ACCESS_TOKEN_DECODED,
        JSON.stringify(accessTokenDecoded)
      );
    }
  }

  public setDefaultSiteToLocalStorage(session: CognitoUserSession): void {
    if (session) {
      const idTokenCognitoDecoded = jwt_decode<IdTokenCognito>(
        session.getIdToken().getJwtToken()
      );
      const idTokenDecoded = this.convertIdTokenCognitoToIdToken(
        idTokenCognitoDecoded
      );
      const siteKey = localStorage.getItem(LocalStorageConfiguration.SITE_KEY);
      if (!siteKey || siteKey !== idTokenDecoded.defaultSite) {
        localStorage.removeItem(LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_DATE);
        localStorage.removeItem(
          LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_DATE
        );
        localStorage.removeItem(
          LocalStorageKey.PRODUCTION_UNIT_SYNCHRO_LAST_DATE
        );
        localStorage.setItem(
          LocalStorageConfiguration.SITE_KEY,
          idTokenDecoded.defaultSite
        );
      }
    }
  }

  public setIdTokenToLocalStorage(session: CognitoUserSession): IdToken {
    const idTokenCognitoDecoded = jwt_decode<IdTokenCognito>(
      session.getIdToken().getJwtToken()
    );

    const idTokenDecoded = this.convertIdTokenCognitoToIdToken(
      idTokenCognitoDecoded
    );

    localStorage.setItem(
      LocalStorageConfiguration.ID_TOKEN,
      session.getIdToken().getJwtToken()
    );
    localStorage.setItem(
      LocalStorageConfiguration.ID_TOKEN_DECODED,
      JSON.stringify(idTokenDecoded)
    );

    return idTokenDecoded;
  }

  public setLanguage(session: CognitoUserSession): void {
    if (session) {
      const idTokenCongitoDecoded = jwt_decode<IdTokenCognito>(
        session.getIdToken().getJwtToken()
      );
      const idTokenDecoded = this.convertIdTokenCognitoToIdToken(
        idTokenCongitoDecoded
      );

      const LANGUAGES_LIST = [
        'nl',
        'en',
        'fr',
        'id',
        'it',
        'ja',
        'ko',
        'pt',
        'es',
        'th',
        'vi',
      ];
      const currentLanguage = idTokenDecoded.language.toLowerCase();
      if (LANGUAGES_LIST.includes(currentLanguage)) {
        localStorage.setItem(
          LocalStorageConfiguration.LANGUAGE_KEY,
          `${currentLanguage}`
        );
      } else {
        localStorage.setItem(LocalStorageConfiguration.LANGUAGE_KEY, `en`);
      }
    }
  }

  public setUserIdToLocalStorage(session: CognitoUserSession): void {
    if (session) {
      const idTokenCognitoDecoded = jwt_decode<IdTokenCognito>(
        session.getIdToken().getJwtToken()
      );

      const idTokenDecoded = this.convertIdTokenCognitoToIdToken(
        idTokenCognitoDecoded
      );

      localStorage.setItem(
        LocalStorageConfiguration.USER_ID,
        `${idTokenDecoded.id}`
      );
    }
  }

  public setUserRightsToCache(session: CognitoUserSession): void {
    if (session) {
      const userRightsCreateCache = new UserRightsCreateCache();
      const idTokenCognitoDecoded = jwt_decode<IdTokenCognito>(
        session.getIdToken().getJwtToken()
      );

      const idTokenDecoded = this.convertIdTokenCognitoToIdToken(
        idTokenCognitoDecoded
      );

      const rightsJson = JSON.parse(idTokenDecoded.rights);
      if (rightsJson.WOTRACK) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.WOTRACK;
        toPush.actions = rightsJson.WOTRACK.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.WOTRACK.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        userRightsCreateCache.put(toPush);
      }
      if (rightsJson.QUICKREP) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.QUICKREP;
        toPush.actions = rightsJson.QUICKREP.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.QUICKREP.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        userRightsCreateCache.put(toPush);
      }
      if (rightsJson.SR) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.SR;
        toPush.actions = rightsJson.SR.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.SR.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        if (toPush.actions?.length && toPush.actions?.length > 0) {
          userRightsCreateCache.put(toPush);
        }
      }
      if (rightsJson.CREATESR) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.SR;
        toPush.actions = rightsJson.CREATESR.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.CREATESR.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        if (toPush.actions?.length && toPush.actions?.length > 0) {
          userRightsCreateCache.put(toPush);
        }
      }
    }
  }

  private convertIdTokenCognitoToIdToken(
    idTokenCognito: IdTokenCognito
  ): IdToken {
    return {
      ...idTokenCognito,
      hasApplications: idTokenCognito.hasApplications === 'true',
      hasMaximo: idTokenCognito.hasMaximo === 'true',
      email_verified: idTokenCognito.email_verified === 'true',
      hasSites: idTokenCognito.hasSites === 'true',
      isActiveInMaximo: idTokenCognito.isActiveInMaximo === 'true',
      isMaximoHealthy: idTokenCognito.isMaximoHealthy === 'true',
    };
  }
}
