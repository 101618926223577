import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
  EquipmentsQuery,
  ProcessGroupsQuery,
  ProductionUnitsQuery,
} from '@al/state';

import { Subject } from 'rxjs';
import { Equipment, ProcessGroup, ProductionUnit } from '@al/entities';
import { DynamicFlatNode } from './al-assets-dynamic-flat-node';
import { DynamicDataSource } from './al-assets-dynamic-data-source';
import { CustomTreeControl } from './al-assets-custom-tree';

@Component({
  selector: 'al-assets-tree',
  templateUrl: './al-assets-tree.component.html',
  styleUrls: ['./al-assets-tree.component.scss'],
})
export class AlAssetsTreeComponent implements OnDestroy {
  @ViewChild('search') public searchInput: any;

  public activeNode: DynamicFlatNode[];

  public dataSource: DynamicDataSource;

  public treeControl: CustomTreeControl;

  private ngUnsubscribe = new Subject();

  public constructor(
    public dialogRef: MatDialogRef<AlAssetsTreeComponent>,
    private productionUnitsQuery: ProductionUnitsQuery,
    private processGroupsQuery: ProcessGroupsQuery,
    private equipmentsQuery: EquipmentsQuery
  ) {
    this.activeNode = [];
    this.treeControl = new CustomTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(
      this.treeControl,
      this.productionUnitsQuery,
      this.processGroupsQuery,
      this.equipmentsQuery
    );
  }

  public clear(): void {
    this.searchInput.nativeElement.value = '';
    this.dataSource.filterNodes('');
  }

  public filterNodes(event: Event): void {
    const text = (event.target as HTMLInputElement).value;
    this.dataSource.filterNodes(text);
  }

  public isEquipment(node: DynamicFlatNode): boolean {
    return node.item instanceof Equipment;
  }

  public isProcessGroup(node: DynamicFlatNode): boolean {
    return node.item instanceof ProcessGroup;
  }

  public isProductionUnit(node: DynamicFlatNode): boolean {
    return node.item instanceof ProductionUnit;
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.dataSource.data = [];
    // this.treeData = [];
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public select(node: DynamicFlatNode): void {
    this.treeControl.removeParents();
    this.activeNode = this.treeControl.addParents(node);
  }

  public sendData(): void {
    this.dialogRef.close(this.treeControl.returnable);
  }

  private getLevel = (node: DynamicFlatNode) => node.level;

  private isExpandable = (node: DynamicFlatNode) => node.expandable;
}
