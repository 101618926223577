import { Routes } from '@angular/router';

import { CreateBTComponent } from './create-bt.component';

export const routes: Routes = [
  {
    path: '',
    component: CreateBTComponent,
  },
];
