import { GtmPut, LocalStorageKey } from '@al/entities';
import { Injectable } from '@angular/core';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class GtmCache extends LocalCache<GtmPut> {
  public constructor() {
    super(LocalStorageKey.GTM_CACHE);
  }

  public put(value: any): void {
    // remove previous cache element for this Gtm
    const cacheFiltered = this.cache.filter((cache: GtmPut) => {
      return !(cache.uuid === value.uuid);
    });
    this.cache = cacheFiltered;
    // this.cache.push({ ...value });
    this.cache.push(value);
    this.storageManager.store(this.cache, this.getClazz());
  }

  protected getClazz(): typeof GtmPut {
    return GtmPut;
  }
}
