import { Injectable } from '@angular/core';
import { FileRepository } from './file.repository';

@Injectable({
  providedIn: 'root',
})
export class AppRepository {
  public constructor(private fileRepository: FileRepository) {}

  public get File(): FileRepository {
    return this.fileRepository;
  }
}
