import {
  LocalStorageKey,
  QuickReportPut,
  QuickReportPutResponse,
} from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class QuickReportCreateHistoryService extends AbstractHistoryService<
  QuickReportPut,
  QuickReportPutResponse
> {
  public constructor() {
    super(LocalStorageKey.QUICK_REPORT_CREATE_REQUEST_HISTORY);
  }
}
