import { GtmRestService } from '@al/rest';
import { GtmCache } from '@al/cache';
import { GtmPut, LocalStorageKey } from '@al/entities';
import { UUID } from 'angular2-uuid';
import { AlOnlineService } from '@al/online';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GtmSyncService {
  public constructor(
    private rest: GtmRestService,
    private cache: GtmCache,
    protected onLineService: AlOnlineService
  ) {}

  /**
   * ajout des infos generiques sur les erreurs et les messages
   * @param gmt
   * @param value
   */
  public addGenericReponseInformation(gtm: GtmPut, value: any): void {
    const cachedGtm = this.getGtmTag(gtm.uuid);
    // si mise a jour not ok
    // on met a jour le cache avec l'erreur remonté
    if (value.status === 'NOT_OK') {
      cachedGtm.sendAfterNbCorrection = true;
      cachedGtm.nbMaximoAttempts.push(value.error);
      this.saveGtmTag(cachedGtm);
    } else {
      if (gtm.event === 'WO_CREATION' && value.workOrderNum) {
        cachedGtm.number = value.workOrderNum;
      }
      this.sendGtm(cachedGtm);
    }
  }

  /**
   * recherche si le cache a deja un GTM pour l'uuid en cours
   * @param uuid
   */
  public deleteGtmTagByUuid(uuid: UUID): void {
    this.cache.removeByUuid(uuid);
  }

  /**
   * recherche si le cache a deja un GTM pour l'uuid en cours
   * @param uuid
   */
  public getGtmTag(uuid: UUID): GtmPut {
    const previousGtmCache = this.cache.get(uuid);
    if (previousGtmCache) {
      return previousGtmCache;
    }
    const gtm = new GtmPut();
    gtm.uuid = uuid;
    return gtm;
  }

  public getSource(): string {
    return <string>localStorage.getItem(LocalStorageKey.GTM_EMV2_SOURCE);
  }

  /**
   * recherche si le cache a deja un GTM pour l'uuid en cours
   * @param uuid
   */
  public saveGtmTag(gtm: GtmPut): void {
    this.cache.put(gtm);
  }

  public sendGtm(gtm: GtmPut): void {
    // on line : on appel le service rest
    if (this.onLineService.onLine()) {
      this.rest.push(gtm).then(() => {
        this.cache.removeByUuid(gtm.uuid);
      });
    }
  }

  public setDashboardAsSource(): void {
    localStorage.setItem(LocalStorageKey.GTM_EMV2_SOURCE, 'DASHBOARD');
  }

  public setWorkOrderAsSource(): void {
    localStorage.setItem(LocalStorageKey.GTM_EMV2_SOURCE, 'EXISTING_WO');
  }
}
