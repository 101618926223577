import { LocalStorageConfiguration, Site } from '@al/entities';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { SiteQuery } from './site.query';
import { SiteStore } from './site.store';

@Injectable({ providedIn: 'root' })
export class SiteService {
  public constructor(
    private siteStore: SiteStore,
    private siteQuery: SiteQuery
  ) {}

  public getActive(): Site | undefined {
    return this.siteQuery.getActive();
  }

  public populate(): void {
    const idTokenDecoded = localStorage.getItem('idTokenDecoded');
    if (idTokenDecoded) {
      const parsedIdTokenDecoded = JSON.parse(idTokenDecoded);
      if (parsedIdTokenDecoded.sites) {
        const sitesInToken = JSON.parse(parsedIdTokenDecoded.sites);
        if (Array.isArray(sitesInToken)) {
          sitesInToken.forEach((site: string) => {
            this.siteStore.upsert(site, {
              maximoId: site,
              name: site,
              uuid: site,
            });
          });

          if (localStorage.getItem(LocalStorageConfiguration.SITE_KEY)) {
            this.siteStore.setActive(
              localStorage.getItem(LocalStorageConfiguration.SITE_KEY)
            );
          }
        }
      }
    }
  }

  public setActive(uuid: UUID): void {
    this.siteStore.setActive(uuid.toString());
    localStorage.setItem(LocalStorageConfiguration.SITE_KEY, uuid.toString());
  }

  public setActiveFromLocalStorage(): void {
    const item = localStorage.getItem(LocalStorageConfiguration.SITE_KEY);
    if (item) {
      this.setActive(item);
    }
  }
}
