import { JsonObject, JsonProperty } from 'json2typescript';

import { DateJsonConverter } from '../../json-converters/date-json-converter';

import { Attachment } from './attachment.model';
import { Site } from './site.model';
import { Task } from './task-model';

import { WorkOrderStatus } from '../../enums/work-order-status.enum';
import { Get } from './get.model';
import { WorkLog } from './work-log.model';

/**
 * This class represents Work Order model
 */
@JsonObject('WorkOrder')
export class WorkOrder extends Get {
  /**
   * Correspond à `actstart` au niveau de l'interface MX.
   * Doit être mis à jour quand on commence un BT.
   */
  @JsonProperty('actDateDebut', DateJsonConverter)
  public actDateDebut: Date | null = null;

  /**
   * Correspond a `actfinish` au niveau de l'interface MX.
   * Doit être mis à jour quand on passe le BT à `COMP`.
   */
  @JsonProperty('actDateFin', DateJsonConverter)
  public actDateFin: Date | null = null;

  @JsonProperty('assetDescription', String)
  public assetDescription = '';

  @JsonProperty('assetNum', String)
  public assetNum = '';

  /**
   * Remplacé par `assetnum` (on oublie)
   */
  @JsonProperty('assetProcessTag', String)
  public assetProcessTag = '';

  @JsonProperty('attachments', [Attachment])
  public attachments: Attachment[] = [];

  @JsonProperty('childwo', [WorkOrder], true)
  public childwo: WorkOrder[] = [];

  @JsonProperty('description', String)
  public description = '';

  @JsonProperty('descriptionLong', String)
  public descriptionLong = '';

  /**
   * Sert uniquement à la création d'un Quick Report.
   * Pour le BT pas besoin.
   */
  @JsonProperty('estDur', Number, true)
  public estDur: number | null = null;

  @JsonProperty('estTask', String)
  public estTask = '';

  /**
   * Doit être mappé avec le champ `failureremark_description` de l'interface MX.
   * Contient le corps du Failure Report.
   */
  @JsonProperty('failureAcceptable', Boolean, true)
  public failureAcceptable = false;

  /**
   * Doit être mappé avec le champ `failurecode` de l'interface MX.
   * Utile pour Failure Report.
   */
  @JsonProperty('failureCode', String)
  public failureCode: string | null = null;

  @JsonProperty('failureDate', DateJsonConverter)
  public failureDate: Date | null = null;

  @JsonProperty('failureMarkDescription', String, true)
  public failureMarkDescription: string | null = null;

  @JsonProperty('failureMarkDescriptionLong', String, true)
  public failureMarkDescriptionLong: string | null = null;

  @JsonProperty('id', Number)
  public id = 0;

  @JsonProperty('isEmaintenance', Boolean, false)
  public isEmaintenance: boolean | undefined = false;

  @JsonProperty('lead', String)
  public lead = '';

  /**
   * Doit être redondant avec le champ `lead`.
   * On doit pouvoir le laisser tomber.
   */
  @JsonProperty('leadUid', String)
  public leadUid = '';

  /**
   * Ne sert pas finalement.
   * On se base sur champ `location` déjà mappé.
   */
  @JsonProperty('localisationDescription', String)
  public localisationDescription = '';

  @JsonProperty('location', String)
  public location = '';

  @JsonProperty('number', String)
  public number = '';

  @JsonProperty('parent', String)
  public parent = '';

  @JsonProperty('pmNum', String, false)
  public pmNum: string | undefined = 'undefined';

  @JsonProperty('priority', String)
  public priority = '';

  /**
   * Se base sur le champ `group_process_id` de l'interface MX.
   */
  @JsonProperty('productionUnit', String)
  public productionUnit = '';

  @JsonProperty('rootCause', String, true)
  public rootCause: string | null = null;

  @JsonProperty('schedDateDebut', DateJsonConverter, true)
  public schedDateDebut: Date | null = null;

  @JsonProperty('schedDateFin', DateJsonConverter, true)
  public schedDateFin: Date | null = null;

  /**
   * Pas forcement renvoyé dans l'objet WO, mais se base sur le site sélectionné par l'utilisateur.
   */
  @JsonProperty('site', Site, true)
  public site: Site | null = null;

  @JsonProperty('siteId', String)
  public siteId = '';

  @JsonProperty('status', String)
  public status: WorkOrderStatus | null = null;

  /**
   * Est remonté par le WO wget et on s'en sert pour récupérer uniquement nos propre WO (filtre = moi en lead ou superviseur).
   * Pour la création on gère via le token côté back, donc plus vraiment la peine de la prendre en compte.
   */
  @JsonProperty('superviseur', String)
  public superviseur = '';

  @JsonProperty('targetCompDate', DateJsonConverter)
  public targetCompDate: Date | null = null;

  @JsonProperty('targetStartDate', DateJsonConverter)
  public targetStartDate: Date | null = null;

  @JsonProperty('tasks', [Task])
  public tasks: Task[] = [];

  @JsonProperty('type', String)
  public type = '';

  @JsonProperty('workLogs', [WorkLog])
  public workLogs: WorkLog[] = [];
}
