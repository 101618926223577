import { Attachment } from '@al/entities';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface AttachmentState extends EntityState<Attachment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'attachments' })
export class AttachmentStore extends EntityStore<AttachmentState> {
  public constructor() {
    super();
  }
}
