import { AlReferenceData } from './model/al-reference-data.model';

export const ClassStructureReference: ReadonlyArray<AlReferenceData> = [
  {
    CLASSSTRUCTUREID: '2016596',
    label: 'Water Treatment',
  },
  {
    CLASSSTRUCTUREID: 'AS-1000',
    label: 'Actuator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1010',
    label: 'Adapter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020',
    label: 'Adsorber',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-A',
    label: 'Adsorber - Concentric desiccation / decarbonatation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-B',
    label: 'Adsorber - Decarbonatation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-C',
    label: 'Adsorber - Horizontal bed desiccation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-D',
    label: 'Adsorber - Layered desiccation / decarbonatation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-E',
    label: 'Adsorber - PSA',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-F',
    label: 'Adsorber - Radial bed desiccation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1020-G',
    label: 'Adsorber - VSA',
  },
  {
    CLASSSTRUCTUREID: 'AS-1030',
    label: 'Air conditioning',
  },
  {
    CLASSSTRUCTUREID: 'AS-1035',
    label: 'Air Vent',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040',
    label: 'Analyzer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-A',
    label: 'Analyzer - Capacitive',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-B',
    label: 'Analyzer - Catharometrique',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-C',
    label: 'Analyzer - Chromatographic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-D',
    label: 'Analyzer - Colorimetric',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-E',
    label: 'Analyzer - Condensation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-F',
    label: 'Analyzer - Electrochemical',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-G',
    label: 'Analyzer - Electrolytic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-H',
    label: 'Analyzer - Infrared',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-I',
    label: 'Analyzer - Ionization',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-J',
    label: 'Analyzer - Luminescence',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-J10',
    label: 'Analyzer - Moisture',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-K',
    label: 'Analyzer - Paramagnetic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-L',
    label: 'Analyzer - Photometric',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-L10',
    label: 'Analyzer - PH',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-M',
    label: 'Analyzer - Quartz cristal',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-N',
    label: 'Analyzer - Thermal conductivity',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-O',
    label: 'Analyzer - Ultraviolet',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-P',
    label: 'Analyzer - Zirconia cell',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-Q',
    label: 'Analyzer - Non dispersive infrared absorption',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-R',
    label: 'Analyzer - Electromagnetic induced plasma cell',
  },
  {
    CLASSSTRUCTUREID: 'AS-1040-S',
    label: 'Analyzer - Electrochimic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1045',
    label: 'Anti-Foam Unit',
  },
  {
    CLASSSTRUCTUREID: 'AS-1050',
    label: 'Bar',
  },
  {
    CLASSSTRUCTUREID: 'AS-1055',
    label: 'Basin',
  },
  {
    CLASSSTRUCTUREID: 'AS-1060',
    label: 'Battery',
  },
  {
    CLASSSTRUCTUREID: 'AS-1061',
    label: 'Battery Charger',
  },
  {
    CLASSSTRUCTUREID: 'AS-1070',
    label: 'Bearing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1075',
    label: 'Belt',
  },
  {
    CLASSSTRUCTUREID: 'AS-1080',
    label: 'Boiler',
  },
  {
    CLASSSTRUCTUREID: 'AS-1080-A',
    label: 'Boiler - Hot water',
  },
  {
    CLASSSTRUCTUREID: 'AS-1080-B',
    label: 'Boiler - Steam',
  },
  {
    CLASSSTRUCTUREID: 'AS-1080-C',
    label: 'Boiler - Vertical bath brazed heat exchanger',
  },
  {
    CLASSSTRUCTUREID: 'AS-1090',
    label: 'Bolt',
  },
  {
    CLASSSTRUCTUREID: 'AS-1095',
    label: 'Block Burner',
  },
  {
    CLASSSTRUCTUREID: 'AS-1096',
    label: 'Brake',
  },
  {
    CLASSSTRUCTUREID: 'AS-1096-B',
    label: 'Brake - Booster',
  },
  {
    CLASSSTRUCTUREID: 'AS-1096-O',
    label: 'Brake - Oil Brake',
  },
  {
    CLASSSTRUCTUREID: 'AS-1100',
    label: 'Bullgear',
  },
  {
    CLASSSTRUCTUREID: 'AS-1110',
    label: 'Bundle',
  },
  {
    CLASSSTRUCTUREID: 'AS-1120',
    label: 'Burner',
  },
  {
    CLASSSTRUCTUREID: 'AS-1130',
    label: 'Bursting Disc',
  },
  {
    CLASSSTRUCTUREID: 'AS-1140',
    label: 'Bus bar',
  },
  {
    CLASSSTRUCTUREID: 'AS-1140-D',
    label: 'Bus bar - Beton bar',
  },
  {
    CLASSSTRUCTUREID: 'AS-1140-S',
    label: 'Bus bar - Standard',
  },
  {
    CLASSSTRUCTUREID: 'AS-1150',
    label: 'Bushing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1150-E',
    label: 'Bushing - Electrical',
  },
  {
    CLASSSTRUCTUREID: 'AS-1155',
    label: 'Cabinet',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160',
    label: 'Cable',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160-A',
    label: 'Cable - Armed',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160-F',
    label: 'Cable - Optic fiber',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160-H',
    label: 'Cable - Heat Tracing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160-O',
    label: 'Cable - Oil',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160-P',
    label: 'Cable - PVC',
  },
  {
    CLASSSTRUCTUREID: 'AS-1160-X',
    label: 'Cable - XLPE',
  },
  {
    CLASSSTRUCTUREID: 'AS-1170',
    label: 'Capacitor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1180',
    label: 'Capacity',
  },
  {
    CLASSSTRUCTUREID: 'AS-1190',
    label: 'Cartridge',
  },
  {
    CLASSSTRUCTUREID: 'AS-1190-T',
    label: 'Cartridge - Cryogenic Turbine',
  },
  {
    CLASSSTRUCTUREID: 'AS-1200',
    label: 'Casing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210',
    label: 'Catalyst',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-A',
    label: 'Catalyst - Auto thermal reforming (ATR)',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-C',
    label: 'Catalyst - Chlorine removal',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-D',
    label: 'Catalyst - Desulfurisation (ZNO)',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-H',
    label: 'Catalyst - HT shift catalyst',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-I',
    label: 'Catalyst - Hydrogenation COMO',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-J',
    label: 'Catalyst - Hydrogenation NIMO',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-K',
    label: 'Catalyst - Hydrogenation+desulfurisation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-L',
    label: 'Catalyst - LT shift catalyst',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-M',
    label: 'Catalyst - Methanation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-N',
    label: 'Catalyst - MT shift catalyst',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-P',
    label: 'Catalyst - Pre-reforming',
  },
  {
    CLASSSTRUCTUREID: 'AS-1210-R',
    label: 'Catalyst - Reforming',
  },
  {
    CLASSSTRUCTUREID: 'AS-1220',
    label: 'Cathodic protection',
  },
  {
    CLASSSTRUCTUREID: 'AS-1220-G',
    label: 'Cathodic protection - Galvanic anode',
  },
  {
    CLASSSTRUCTUREID: 'AS-1220-I',
    label: 'Cathodic protection - Impressed current',
  },
  {
    CLASSSTRUCTUREID: 'AS-1230',
    label: 'Charger',
  },
  {
    CLASSSTRUCTUREID: 'AS-1240',
    label: 'Clip',
  },
  {
    CLASSSTRUCTUREID: 'AS-1250',
    label: 'Electrical device - Coil',
  },
  {
    CLASSSTRUCTUREID: 'AS-1260',
    label: 'Collar',
  },
  {
    CLASSSTRUCTUREID: 'AS-1270',
    label: 'Collector',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280',
    label: 'Column / Tower',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-A',
    label: 'Column / Tower - Cooling water',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-AC',
    label: 'Column / Tower - Activated Carbon Tower',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-B',
    label: 'Column / Tower - Cryogenic mixte',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-C',
    label: 'Column / Tower - Cryogenic with packing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-D',
    label: 'Column / Tower - Cryogenic with plates',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-E',
    label: 'Column / Tower - Non cryogenic mixte',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-F',
    label: 'Column / Tower - Non cryogenic with packing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-G',
    label: 'Column / Tower - Non cryogenic with plates',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-H',
    label: 'Column / Tower - Stripping',
  },
  {
    CLASSSTRUCTUREID: 'AS-1280-I',
    label: 'Column / Tower - Packing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1285',
    label: 'Communication system',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290',
    label: 'Compressor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-A',
    label: 'Compressor - Axial',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-B',
    label: 'Compressor - Axial / centrifugal',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-C',
    label: 'Compressor - Centrifugal',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-D',
    label: 'Compressor - Liquid Ring',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-E',
    label: 'Compressor - Diaphragm',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-F',
    label: 'Compressor - Reciprocating',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-G',
    label: 'Compressor - Roots',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-H',
    label: 'Compressor - Screw',
  },
  {
    CLASSSTRUCTUREID: 'AS-1290-I',
    label: 'Compressor - Sliding Vane',
  },
  {
    CLASSSTRUCTUREID: 'AS-1295',
    label: 'Compressor Assembly',
  },
  {
    CLASSSTRUCTUREID: 'AS-1300',
    label: 'Condenser',
  },
  {
    CLASSSTRUCTUREID: 'AS-1310',
    label: 'Connecting Rod',
  },
  {
    CLASSSTRUCTUREID: 'AS-1320',
    label: 'Connector',
  },
  {
    CLASSSTRUCTUREID: 'AS-1330',
    label: 'Contactor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1335',
    label: 'Controller',
  },
  {
    CLASSSTRUCTUREID: 'AS-1340',
    label: 'Control panel',
  },
  {
    CLASSSTRUCTUREID: 'AS-1350',
    label: 'Control system',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360',
    label: 'Cooler',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-A',
    label: 'Cooler - Atmospheric',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-B',
    label: 'Cooler - Cooling tower air / air',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-C',
    label: 'Cooler - Cooling tower air / water',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-D',
    label: 'Cooler - Water in shell double plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-E',
    label: 'Cooler - Water in shell simple plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-F',
    label: 'Cooler - Water in tubes double plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-G',
    label: 'Cooler - Water in tubes simple plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-H',
    label: 'Cooler - Oil in shell simple plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-I',
    label: 'Cooler - Tubes and shell',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-J',
    label: 'Cooler - Tubes and fin',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-K',
    label: 'Cooler - Fin-plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1360-L',
    label: 'Cooler - Brazed aluminium',
  },
  {
    CLASSSTRUCTUREID: 'AS-1370',
    label: 'Corrosion coupon',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380',
    label: 'Coupling',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380-E',
    label: 'Coupling - Geared',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380-F',
    label: 'Coupling - Flexible',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380-G',
    label: 'Coupling - Greased',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380-L',
    label: 'Coupling - Lubricated',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380-R',
    label: 'Coupling - Rigid',
  },
  {
    CLASSSTRUCTUREID: 'AS-1380-S',
    label: '',
  },
  {
    CLASSSTRUCTUREID: 'AS-1385',
    label: 'Coupling Hub',
  },
  {
    CLASSSTRUCTUREID: 'AS-1390',
    label: 'Cover',
  },
  {
    CLASSSTRUCTUREID: 'AS-1400',
    label: 'Crankshaft',
  },
  {
    CLASSSTRUCTUREID: 'AS-1404',
    label: 'Cylinder Head',
  },
  {
    CLASSSTRUCTUREID: 'AS-1405',
    label: 'Cylinder Block',
  },
  {
    CLASSSTRUCTUREID: 'AS-1406',
    label: 'Cylinder Liner',
  },
  {
    CLASSSTRUCTUREID: 'AS-1410',
    label: 'Crosshead',
  },
  {
    CLASSSTRUCTUREID: 'AS-1415',
    label: 'Cyclonic separator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1420',
    label: 'Data acquisition',
  },
  {
    CLASSSTRUCTUREID: 'AS-1430',
    label: 'Demister',
  },
  {
    CLASSSTRUCTUREID: 'AS-1434',
    label: 'Diaphragm',
  },
  {
    CLASSSTRUCTUREID: 'AS-1434-C',
    label: 'Diaphragm - Compressor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1434-P',
    label: 'Diaphragm - Pump',
  },
  {
    CLASSSTRUCTUREID: 'AS-1440',
    label: 'Diesel generator set',
  },
  {
    CLASSSTRUCTUREID: 'AS-1450',
    label: 'Disc',
  },
  {
    CLASSSTRUCTUREID: 'AS-1455',
    label: 'Distribution Panel',
  },
  {
    CLASSSTRUCTUREID: 'AS-1457',
    label: 'Dosing Unit',
  },
  {
    CLASSSTRUCTUREID: 'AS-1460',
    label: 'Dryer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1470',
    label: 'Dumper',
  },
  {
    CLASSSTRUCTUREID: 'AS-1480',
    label: 'Ejector',
  },
  {
    CLASSSTRUCTUREID: 'AS-1490',
    label: 'Electrical Actuator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1495',
    label: 'Electrical circuit',
  },
  {
    CLASSSTRUCTUREID: 'AS-1495-G',
    label: 'Electrical circuit - Grounding Grid',
  },
  {
    CLASSSTRUCTUREID: 'AS-1495-L',
    label: 'Electrical circuit - Lightning Grid',
  },
  {
    CLASSSTRUCTUREID: 'AS-1500',
    label: 'Electrical device',
  },
  {
    CLASSSTRUCTUREID: 'AS-1510',
    label: 'Electrical motor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1510-D',
    label: 'Electrical motor - Direct current',
  },
  {
    CLASSSTRUCTUREID: 'AS-1510-I',
    label: 'Electrical motor - Induction',
  },
  {
    CLASSSTRUCTUREID: 'AS-1510-S',
    label: 'Electrical motor - Synchronous',
  },
  {
    CLASSSTRUCTUREID: 'AS-1510-V',
    label: 'Electrical motor - Variable-speed',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520',
    label: 'Electrical power cut-off device',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-B',
    label: 'Electrical power cut-off device - Busbar section isolator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-C',
    label: 'Electrical power cut-off device - Circuit-breaker',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-CO',
    label: 'Electrical power cut-off device - Commutator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-D',
    label: 'Electrical power cut-off device - Contactor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-E',
    label: 'Electrical power cut-off device - Current limitor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-F',
    label: 'Electrical power cut-off device - Disconnecting switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-G',
    label: 'Electrical power cut-off device - Fuse',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-I',
    label: 'Electrical power cut-off device - Interruptor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-L',
    label: 'Electrical power cut-off device - Lightning arrestor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-N',
    label: 'Electrical power cut-off device - Neutral closing relay',
  },
  {
    CLASSSTRUCTUREID: 'AS-1520-S',
    label: 'Electrical power cut-off device - Slave switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530',
    label: 'Electronic card',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-A',
    label: 'Electronic card - Analogic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-B',
    label: 'Electronic card - Communication',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-C',
    label: 'Electronic card - CPU',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-L',
    label: 'Electronic card - Logic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-M',
    label: 'Electronic card - Mother Board',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-P',
    label: 'Electronic card - Power supply',
  },
  {
    CLASSSTRUCTUREID: 'AS-1530-R',
    label: 'Electronic card - Relay',
  },
  {
    CLASSSTRUCTUREID: 'AS-1540',
    label: 'Exchanger',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550',
    label: 'Exchanger - Cryogenic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-A',
    label: 'Exchanger - Cryogenic - Alumina wafer regenerator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-B',
    label: 'Exchanger - Cryogenic - Atmospheric',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-C',
    label: 'Exchanger - Cryogenic - Auxilary bath vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-D',
    label: 'Exchanger - Cryogenic - Auxilary film vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-E',
    label: 'Exchanger - Cryogenic - Auxilary long tubes vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-F',
    label: 'Exchanger - Cryogenic - Boiler',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-G',
    label: 'Exchanger - Cryogenic - Catalytic conversion',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-H',
    label: 'Exchanger - Cryogenic - Cold revex',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-I',
    label: 'Exchanger - Cryogenic - Condenser',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-J',
    label: 'Exchanger - Cryogenic - Cooler',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-K',
    label: 'Exchanger - Cryogenic - Cycle',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-L',
    label: 'Exchanger - Cryogenic - Liquefier',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-M',
    label: 'Exchanger - Cryogenic - Main bath vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-N',
    label: 'Exchanger - Cryogenic - Main film vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-O',
    label: 'Exchanger - Cryogenic - Main fixed',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-P',
    label: 'Exchanger - Cryogenic - Main long tubes vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-Q',
    label: 'Exchanger - Cryogenic - Mixte technology vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-R',
    label: 'Exchanger - Cryogenic - Stone regenerator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-S',
    label: 'Exchanger - Cryogenic - Sub-cooler',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-T',
    label: 'Exchanger - Cryogenic - Evaporator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-U',
    label: 'Exchanger - Cryogenic - Shell,Tubular exchanger',
  },
  {
    CLASSSTRUCTUREID: 'AS-1550-V',
    label: 'Exchanger - Cryogenic - Warm revex',
  },
  {
    CLASSSTRUCTUREID: 'AS-1560',
    label: 'Exchanger - Non cryogenic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1560-A',
    label: 'Exchanger - Non cryogenic - Boiler',
  },
  {
    CLASSSTRUCTUREID: 'AS-1560-B',
    label: 'Exchanger - Non cryogenic - Condenser',
  },
  {
    CLASSSTRUCTUREID: 'AS-1560-C',
    label: 'Exchanger - Non cryogenic - Heat recuperator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1560-D',
    label: 'Exchanger - Non cryogenic - Evaporator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1570',
    label: 'Exciter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1570-B',
    label: 'Exciter - Brushless',
  },
  {
    CLASSSTRUCTUREID: 'AS-1570-S',
    label: 'Exciter - Static',
  },
  {
    CLASSSTRUCTUREID: 'AS-1580',
    label: 'Expander',
  },
  {
    CLASSSTRUCTUREID: 'AS-1590',
    label: 'Expander - Cryogenic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1600',
    label: 'Facility',
  },
  {
    CLASSSTRUCTUREID: 'AS-1610',
    label: 'Fan',
  },
  {
    CLASSSTRUCTUREID: 'AS-1610-A',
    label: 'Fan - Combustion Air',
  },
  {
    CLASSSTRUCTUREID: 'AS-1610-B',
    label: 'Fan - Cooling Tower',
  },
  {
    CLASSSTRUCTUREID: 'AS-1610-C',
    label: 'Fan - Flux Gas Fan',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620',
    label: 'Filter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-B',
    label: 'Filter - Bag',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-C',
    label: 'Filter - Candle',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-D',
    label: 'Filter - Cartridge',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-G',
    label: 'Filter - Cone',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-M',
    label: 'Filter - Metal grid',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-P',
    label: 'Filter - Plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-S',
    label: 'Filter - Sand',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-U',
    label: 'Filter - Rolling filter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1620-Y',
    label: 'Filter - Y Strainer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1630',
    label: 'Flange',
  },
  {
    CLASSSTRUCTUREID: 'AS-1632',
    label: 'Flare tip',
  },
  {
    CLASSSTRUCTUREID: 'AS-1635',
    label: 'Fly wheel',
  },
  {
    CLASSSTRUCTUREID: 'AS-1640',
    label: 'Frequency converter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1650',
    label: 'Galvanic anode',
  },
  {
    CLASSSTRUCTUREID: 'AS-1660',
    label: 'Gasket',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670',
    label: 'Gasket - General',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670-B',
    label: 'Gasket - Bonnet',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670-FF',
    label: 'Gasket - Full Face',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670-OR',
    label: 'Gasket - Octogonal Ring',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670-OV',
    label: 'Gasket - Oval',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670-PL',
    label: 'Gasket - Plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1670-SW',
    label: 'Gasket - Spiral Wound',
  },
  {
    CLASSSTRUCTUREID: 'AS-1680',
    label: 'Gasket - Pipe',
  },
  {
    CLASSSTRUCTUREID: 'AS-1690',
    label: 'Gearbox',
  },
  {
    CLASSSTRUCTUREID: 'AS-1690-M',
    label: 'Gearbox - Multiplication',
  },
  {
    CLASSSTRUCTUREID: 'AS-1690-P',
    label: 'Gearbox - Parallel train',
  },
  {
    CLASSSTRUCTUREID: 'AS-1690-Q',
    label: 'Gearbox - Planetary',
  },
  {
    CLASSSTRUCTUREID: 'AS-1700',
    label: 'Generator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1700-E',
    label: 'Generator - Exciter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1700-F',
    label: 'Generator - Fequency converter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1700-G',
    label: 'Generator - Generator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1700-O',
    label: 'Generator - Over-synchronous',
  },
  {
    CLASSSTRUCTUREID: 'AS-1700-T',
    label: 'Generator - Turning',
  },
  {
    CLASSSTRUCTUREID: 'AS-1705',
    label: 'Grid',
  },
  {
    CLASSSTRUCTUREID: 'AS-1705-T',
    label: 'Grid - Tube',
  },
  {
    CLASSSTRUCTUREID: 'AS-1710',
    label: 'Ground bed',
  },
  {
    CLASSSTRUCTUREID: 'AS-1720',
    label: 'Grounding system',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-A',
    label: 'Group Coolers',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-BU',
    label: 'Group Electrical Back-up Supply',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-DC',
    label: 'Group Driver-Compressor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-DP',
    label: 'Group Driver-Pump',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-E1',
    label: 'Group Electrical circuit - Line HV',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-E2',
    label: 'Group Electrical circuit - MV Switchgear',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-F',
    label: 'Group Filters',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-FS',
    label: 'Group Fire and Gas System',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-I',
    label: 'Group Instrumentation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-MC',
    label: '',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-MP',
    label: '',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-P',
    label: 'Group Pipes',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-T',
    label: 'Group Turbine',
  },
  {
    CLASSSTRUCTUREID: 'AS-1723-V',
    label: 'Group Valves',
  },
  {
    CLASSSTRUCTUREID: 'AS-1725',
    label: 'Guide Vane',
  },
  {
    CLASSSTRUCTUREID: 'AS-1730',
    label: 'Heater',
  },
  {
    CLASSSTRUCTUREID: 'AS-1730-A',
    label: 'Heater - Atmospheric',
  },
  {
    CLASSSTRUCTUREID: 'AS-1730-E',
    label: 'Heater - Electrical',
  },
  {
    CLASSSTRUCTUREID: 'AS-1730-F',
    label: 'Heater - Flame',
  },
  {
    CLASSSTRUCTUREID: 'AS-1730-H',
    label: 'Heater - Hot water',
  },
  {
    CLASSSTRUCTUREID: 'AS-1730-S',
    label: 'Heater - Steam',
  },
  {
    CLASSSTRUCTUREID: 'AS-1740',
    label: 'Hose',
  },
  {
    CLASSSTRUCTUREID: 'AS-1750',
    label: 'Housing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1760',
    label: 'Ignitor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1770',
    label: 'Impeller',
  },
  {
    CLASSSTRUCTUREID: 'AS-1780',
    label: 'Impressed current station',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785',
    label: 'Installation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-B',
    label: 'Installation - building',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-F1',
    label: 'Installation - fence',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-F2',
    label: 'Installation - fire protection',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-F3',
    label: 'Installation - fire water',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-G',
    label: 'Installation - gate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-M',
    label: 'Installation - metallic structure',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-O',
    label: 'Installation - overhead crane',
  },
  {
    CLASSSTRUCTUREID: 'AS-1785-R',
    label: 'Installation - road',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790',
    label: 'Instrumentation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-A',
    label: 'Instrumentation - Flame Sensor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-A1',
    label: 'Instrumentation - Digital Pressure Indicator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-A2',
    label: 'Instrumentation - Flow switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-B',
    label: 'Instrumentation - Flowrate transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-C',
    label: 'Instrumentation - Flowmeter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-D',
    label: 'Instrumentation - Gas detector',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-D1',
    label: 'Instrumentation - Level sensor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-D2',
    label: 'Instrumentation - Level switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-DI',
    label: 'Instrumentation - Level Indicator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-E',
    label: 'Instrumentation - Level transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-F',
    label: 'Instrumentation - Loading',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-F2',
    label: 'Instrumentation - Positioner',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-G',
    label: 'Instrumentation - Position transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-G2',
    label: 'Instrumentation - Power Meter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-H',
    label: 'Instrumentation - Press. transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-I',
    label: 'Instrumentation - Pressure gauge',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-J',
    label: 'Instrumentation - Pressure switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-K',
    label: 'Instrumentation - Protection Switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-K5',
    label: 'Instrumentation - Recorder',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-L',
    label: 'Instrumentation - Resistor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-M',
    label: 'Instrumentation - Regulator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-N',
    label: 'Instrumentation - Speed transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-N1',
    label: 'Instrumentation - Speed Indicator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-N2',
    label: 'Instrumentation - Speed Probe',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-N5',
    label: 'Instrumentation - Switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-O',
    label: 'Instrumentation - Temperature Probe',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-O0',
    label: 'Instrumentation - Temperature Indicator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-O1',
    label: 'Instrumentation - Temperature switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-O2',
    label: 'Instrumentation - Temperature transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-O3',
    label: 'Instrumentation - Test gauge',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-O4',
    label: 'Instrumentation - Totalizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-P',
    label: 'Instrumentation - Transistor',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-Q',
    label: 'Instrumentation - Vibration probe',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-Q0',
    label: 'Instrumentation - Vibration Indicator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-Q1',
    label: 'Instrumentation - Vibration switch',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-Q2',
    label: 'Instrumentation - Vibration transmitter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-R',
    label: 'Instrumentation - Voltmeter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1790-T',
    label: 'Instrumentation - Transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1800',
    label: 'IT material',
  },
  {
    CLASSSTRUCTUREID: 'AS-1800-N',
    label: 'IT material - Industrial network',
  },
  {
    CLASSSTRUCTUREID: 'AS-1800-W',
    label: 'IT material - Industrial Workstation',
  },
  {
    CLASSSTRUCTUREID: 'AS-1810',
    label: 'Key',
  },
  {
    CLASSSTRUCTUREID: 'AS-1810-R',
    label: 'Key - Ring',
  },
  {
    CLASSSTRUCTUREID: 'AS-1820',
    label: 'Lantern',
  },
  {
    CLASSSTRUCTUREID: 'AS-1830',
    label: 'Lighting circuit',
  },
  {
    CLASSSTRUCTUREID: 'AS-1840',
    label: 'Load adjuster',
  },
  {
    CLASSSTRUCTUREID: 'AS-1850',
    label: 'Loading station',
  },
  {
    CLASSSTRUCTUREID: 'AS-1850-A',
    label: 'Loading station - Alumina',
  },
  {
    CLASSSTRUCTUREID: 'AS-1850-S',
    label: 'Loading station - Stabilization',
  },
  {
    CLASSSTRUCTUREID: 'AS-1850-U',
    label: 'Loading station - Unloading',
  },
  {
    CLASSSTRUCTUREID: 'AS-1860',
    label: 'Marker',
  },
  {
    CLASSSTRUCTUREID: 'AS-1870',
    label: 'Membrane',
  },
  {
    CLASSSTRUCTUREID: 'AS-1875',
    label: 'Meter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1875-C',
    label: 'Meter - Cycles Meter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1875-F',
    label: 'Meter - Flowmeter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1875-P',
    label: 'Meter - Power Meter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1875-R',
    label: 'Meter - Running Hours Meter',
  },
  {
    CLASSSTRUCTUREID: 'AS-1880',
    label: 'Mixer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1883',
    label: 'Multivariable Predictive Control (MVPC)',
  },
  {
    CLASSSTRUCTUREID: 'AS-1885',
    label: 'Nozzle',
  },
  {
    CLASSSTRUCTUREID: 'AS-1890',
    label: 'Nut',
  },
  {
    CLASSSTRUCTUREID: 'AS-1900',
    label: 'Oil circuit',
  },
  {
    CLASSSTRUCTUREID: 'AS-1900-A',
    label: 'Oil Circuit - Automatic Greaser',
  },
  {
    CLASSSTRUCTUREID: 'AS-1900-D',
    label: 'Oil circuit -  Drip Oilers',
  },
  {
    CLASSSTRUCTUREID: 'AS-1900-L',
    label: 'Oil circuit - Lubrication',
  },
  {
    CLASSSTRUCTUREID: 'AS-1905',
    label: 'Packing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1905-R',
    label: 'Packing - Rod',
  },
  {
    CLASSSTRUCTUREID: 'AS-1910',
    label: 'Permeator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1920',
    label: 'Pin',
  },
  {
    CLASSSTRUCTUREID: 'AS-1930',
    label: 'Pipe',
  },
  {
    CLASSSTRUCTUREID: 'AS-1930-A',
    label: 'Pipe - Piece',
  },
  {
    CLASSSTRUCTUREID: 'AS-1935',
    label: 'Pipeline',
  },
  {
    CLASSSTRUCTUREID: 'AS-1940',
    label: 'Piston',
  },
  {
    CLASSSTRUCTUREID: 'AS-1945',
    label: 'Piston Rod',
  },
  {
    CLASSSTRUCTUREID: 'AS-1950',
    label: 'Plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-1960',
    label: 'Plug',
  },
  {
    CLASSSTRUCTUREID: 'AS-1970',
    label: 'Potental test probe',
  },
  {
    CLASSSTRUCTUREID: 'AS-1975',
    label: 'Power Supply',
  },
  {
    CLASSSTRUCTUREID: 'AS-1980',
    label: 'Uninterruptible Power Supply',
  },
  {
    CLASSSTRUCTUREID: 'AS-1985',
    label: 'Protection Relay',
  },
  {
    CLASSSTRUCTUREID: 'AS-1985-B',
    label: 'Protection Relay - Relay Buchholz',
  },
  {
    CLASSSTRUCTUREID: 'AS-1985-D',
    label: 'Protection Relay - DGPT2',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990',
    label: 'Pump',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-A',
    label: 'Pump - Agitator',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-B',
    label: 'Pump - Centrifugal',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-B2',
    label: 'Pump - Cryogenic',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-B3',
    label: 'Pump - Cryogenic - Centrifugal',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-B4',
    label: 'Pump - Cryogenic - Piston',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-B5',
    label: 'Pump - Diffusion',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-C',
    label: 'Pump - Gear type',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-D',
    label: 'Pump - Liquid-ring',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-E',
    label: 'Pump - Dosing',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-F',
    label: 'Pump - Reciprocating',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-G',
    label: 'Pump - Roots',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-H',
    label: 'Pump - Trailer',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-I',
    label: 'Pump - Screw',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-J',
    label: 'Pump - Vane',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-K',
    label: 'Pump - Vapour-jet',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-L',
    label: 'Pump - Membrane',
  },
  {
    CLASSSTRUCTUREID: 'AS-1990-M',
    label: 'Pump - Piston',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000',
    label: 'Reactor',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-B',
    label: 'Reactor - Catalytic',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-C',
    label: 'Reactor - CO converter',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-D',
    label: 'Reactor - Deoxo',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-E',
    label: 'Reactor - Desulfuration',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-F',
    label: 'Reactor - Hydrogenator',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-G',
    label: 'Reactor - Oxydor',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-H',
    label: 'Reactor - Packing',
  },
  {
    CLASSSTRUCTUREID: 'AS-2000-I',
    label: 'Reactor - Reformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2005',
    label: 'reformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2010',
    label: 'Reference electrode',
  },
  {
    CLASSSTRUCTUREID: 'AS-2020',
    label: 'Refractory',
  },
  {
    CLASSSTRUCTUREID: 'AS-2030',
    label: 'Refrigerating Unit',
  },
  {
    CLASSSTRUCTUREID: 'AS-2030-A',
    label: 'Refrigerating Unit - Ammonia',
  },
  {
    CLASSSTRUCTUREID: 'AS-2030-C',
    label: 'Refrigerating Unit - Chlorofluorocarbon',
  },
  {
    CLASSSTRUCTUREID: 'AS-2030-F',
    label: 'Refrigerating Unit - Freon',
  },
  {
    CLASSSTRUCTUREID: 'AS-2040',
    label: 'Relay',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050',
    label: 'Relief valve',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-B',
    label: 'Relief valve - Bursting disc',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-BP',
    label: 'Relief valve - Pressure Bursting disc',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-BV',
    label: 'Relief valve - Vacuum Bursting disc',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-K',
    label: 'Repair kit Valve',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-L',
    label: 'Relief valve - Pressure',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-P',
    label: 'Relief valve - Pressure and Vacuum',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-T',
    label: 'Relief valve - Thermic expansion',
  },
  {
    CLASSSTRUCTUREID: 'AS-2050-V',
    label: 'Relief valve - Vacuum',
  },
  {
    CLASSSTRUCTUREID: 'AS-2055',
    label: 'Resistance',
  },
  {
    CLASSSTRUCTUREID: 'AS-2057',
    label: 'Ring',
  },
  {
    CLASSSTRUCTUREID: 'AS-2057-P',
    label: 'Ring - Piston',
  },
  {
    CLASSSTRUCTUREID: 'AS-2057-PR',
    label: 'Ring - Piston Rider',
  },
  {
    CLASSSTRUCTUREID: 'AS-2060',
    label: 'Rod',
  },
  {
    CLASSSTRUCTUREID: 'AS-2070',
    label: 'Roller',
  },
  {
    CLASSSTRUCTUREID: 'AS-2080',
    label: 'Rotor',
  },
  {
    CLASSSTRUCTUREID: 'AS-2090',
    label: 'Safety facility',
  },
  {
    CLASSSTRUCTUREID: 'AS-2090-S',
    label: 'Safety - smoke detector',
  },
  {
    CLASSSTRUCTUREID: 'AS-2100',
    label: 'Screw',
  },
  {
    CLASSSTRUCTUREID: 'AS-2100-B',
    label: 'Screw - Block',
  },
  {
    CLASSSTRUCTUREID: 'AS-2110',
    label: 'Scrubber',
  },
  {
    CLASSSTRUCTUREID: 'AS-2120',
    label: 'Seal',
  },
  {
    CLASSSTRUCTUREID: 'AS-2122',
    label: 'Seal Gas',
  },
  {
    CLASSSTRUCTUREID: 'AS-2130',
    label: 'Self',
  },
  {
    CLASSSTRUCTUREID: 'AS-2130-F',
    label: 'Self - Filtering',
  },
  {
    CLASSSTRUCTUREID: 'AS-2130-M',
    label: 'Self - Motor starting',
  },
  {
    CLASSSTRUCTUREID: 'AS-2140',
    label: 'Separator',
  },
  {
    CLASSSTRUCTUREID: 'AS-2150',
    label: 'Shaft',
  },
  {
    CLASSSTRUCTUREID: 'AS-2160',
    label: 'Shaft - General',
  },
  {
    CLASSSTRUCTUREID: 'AS-2170',
    label: 'Shaft - Pinion',
  },
  {
    CLASSSTRUCTUREID: 'AS-2179',
    label: '',
  },
  {
    CLASSSTRUCTUREID: 'AS-2180',
    label: 'Shell',
  },
  {
    CLASSSTRUCTUREID: 'AS-2190',
    label: 'Shim',
  },
  {
    CLASSSTRUCTUREID: 'AS-2190-R',
    label: 'Shim - Ring',
  },
  {
    CLASSSTRUCTUREID: 'AS-2200',
    label: 'Sight glass',
  },
  {
    CLASSSTRUCTUREID: 'AS-2210',
    label: 'Silencer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2215',
    label: 'Spacer Coupling',
  },
  {
    CLASSSTRUCTUREID: 'AS-2220',
    label: 'Spring',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230',
    label: 'Stack - Flare',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-B',
    label: 'Stack - Flare - Flare - Cold with forced convection',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-C',
    label: 'Stack - Flare - Flare - Cold with natural convection',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-G',
    label: 'Stack - Flare - Flare - Gaz static seal',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-H',
    label: 'Stack - Flare - Flare - Hot with forced convection',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-I',
    label: 'Stack - Flare - Flare - Hot with natural convection',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-L',
    label: 'Stack - Flare - Flare - Liquid gun',
  },
  {
    CLASSSTRUCTUREID: 'AS-2230-M',
    label: 'Stack - Flare - Flare - Liquid purging',
  },
  {
    CLASSSTRUCTUREID: 'AS-2240',
    label: 'Start-up device',
  },
  {
    CLASSSTRUCTUREID: 'AS-2240-A',
    label: 'Start-up device - Autotransformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2240-C',
    label: 'Start-up device - Capacitor bank',
  },
  {
    CLASSSTRUCTUREID: 'AS-2240-F',
    label: 'Start-up device - Frequency converter',
  },
  {
    CLASSSTRUCTUREID: 'AS-2240-O',
    label: 'Start-up device - Self',
  },
  {
    CLASSSTRUCTUREID: 'AS-2240-S',
    label: 'Start-up device - Softstarter',
  },
  {
    CLASSSTRUCTUREID: 'AS-2250',
    label: 'Stator',
  },
  {
    CLASSSTRUCTUREID: 'AS-2260',
    label: 'Storage',
  },
  {
    CLASSSTRUCTUREID: 'AS-2260-A',
    label: 'Storage - Double wall perlite/N2',
  },
  {
    CLASSSTRUCTUREID: 'AS-2260-B',
    label: 'Storage - Double wall perlite/vaccum',
  },
  {
    CLASSSTRUCTUREID: 'AS-2260-F',
    label: 'Storage - Flat bottom',
  },
  {
    CLASSSTRUCTUREID: 'AS-2260-S',
    label: 'Storage - Simple wall',
  },
  {
    CLASSSTRUCTUREID: 'AS-2260-T',
    label: 'Storage - Triple wall perlite/N2',
  },
  {
    CLASSSTRUCTUREID: 'AS-2270',
    label: 'Stud',
  },
  {
    CLASSSTRUCTUREID: 'AS-2273',
    label: 'On-Load Tap Changers',
  },
  {
    CLASSSTRUCTUREID: 'AS-2274',
    label: '',
  },
  {
    CLASSSTRUCTUREID: 'AS-2275',
    label: 'Thermal Insulation',
  },
  {
    CLASSSTRUCTUREID: 'AS-2280',
    label: 'Transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2280-A',
    label: 'Transformer - Auto-transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2280-B',
    label: 'Transformer - Block transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2280-D',
    label: 'Transformer - Distribution Transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2280-I',
    label: 'Transformer - Instrument transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2280-P',
    label: 'Transformer - Power transformer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2285',
    label: 'Check Valve',
  },
  {
    CLASSSTRUCTUREID: 'AS-2285-D',
    label: 'Check Valve - Dual flap',
  },
  {
    CLASSSTRUCTUREID: 'AS-2285-L',
    label: 'Check Valve - Lift',
  },
  {
    CLASSSTRUCTUREID: 'AS-2285-S',
    label: 'Check Valve - Single flap',
  },
  {
    CLASSSTRUCTUREID: 'AS-2290',
    label: 'Tube',
  },
  {
    CLASSSTRUCTUREID: 'AS-2300',
    label: 'Turbine',
  },
  {
    CLASSSTRUCTUREID: 'AS-2300-B',
    label: 'Turbine - Back pressure steam',
  },
  {
    CLASSSTRUCTUREID: 'AS-2300-C',
    label: 'Turbine - Condensation steam',
  },
  {
    CLASSSTRUCTUREID: 'AS-2300-D',
    label: 'Turbine - Derivative gas',
  },
  {
    CLASSSTRUCTUREID: 'AS-2300-I',
    label: 'Turbine - Industrial gas',
  },
  {
    CLASSSTRUCTUREID: 'AS-2305',
    label: 'Utilities',
  },
  {
    CLASSSTRUCTUREID: 'AS-2305-I',
    label: 'Utilities - Instrument Air',
  },
  {
    CLASSSTRUCTUREID: 'AS-2305-N',
    label: 'Utilities - Nitrogen',
  },
  {
    CLASSSTRUCTUREID: 'AS-2305-W',
    label: 'Utilities - Water',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310',
    label: 'Valve',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-A',
    label: 'Valve - Anti-surge / recycling',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-B',
    label: 'Valve - Automatic bleeder',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-C',
    label: 'Valve - Cryogenic',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-CA',
    label: 'Valve - Cryogenic - Anti surge / Recycling',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-CB',
    label: 'Valve - Cryogenic - Automatic bleeder',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-CM',
    label: 'Valve - Cryogenic - Manual',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-CO',
    label: 'Valve - Cryogenic - On/off',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-CR',
    label: 'Valve - Cryogenic - Regulation',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-E',
    label: 'Valve - Electrically energized',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-F',
    label: 'Valve - Manual',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-G',
    label: 'Valve - On/off',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-H',
    label: 'Valve - Oxygen',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-I',
    label: 'Valve - PSA',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-J',
    label: 'Valve - Purification unit',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-K',
    label: 'Valve - Regulation',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-L',
    label: 'Valve - Regulator / expansion valve',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-M',
    label: 'Valve - Specified explosive atmosphere',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-N',
    label: 'Valve - Steam or fluid >200°C',
  },
  {
    CLASSSTRUCTUREID: 'AS-2310-O',
    label: 'Valve - Stop valve / double block an bleed',
  },
  {
    CLASSSTRUCTUREID: 'AS-2315',
    label: 'Valve Ball',
  },
  {
    CLASSSTRUCTUREID: 'AS-2320',
    label: 'Valve head',
  },
  {
    CLASSSTRUCTUREID: 'AS-2330',
    label: 'Valve plate',
  },
  {
    CLASSSTRUCTUREID: 'AS-2340',
    label: 'Valve seat',
  },
  {
    CLASSSTRUCTUREID: 'AS-2350',
    label: 'Vane',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360',
    label: 'Vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360-A',
    label: 'Vaporizer - Atmospheric',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360-C',
    label: 'Vaporizer - Barrel',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360-D',
    label: 'Vaporizer - Bath Counter Flow',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360-H',
    label: 'Vaporizer - Hot water bath',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360-I',
    label: 'Vaporizer - Immersed flame',
  },
  {
    CLASSSTRUCTUREID: 'AS-2360-P',
    label: 'Vaporizer - Pool',
  },
  {
    CLASSSTRUCTUREID: 'AS-2370',
    label: 'Vessel',
  },
  {
    CLASSSTRUCTUREID: 'AS-2370-P',
    label: 'Vessel - Pulsation Damper',
  },
  {
    CLASSSTRUCTUREID: 'AS-2380',
    label: 'Washer',
  },
  {
    CLASSSTRUCTUREID: 'AS-2390',
    label: 'Water trap',
  },
  {
    CLASSSTRUCTUREID: 'AS-2400',
    label: 'Water treatment',
  },
  {
    CLASSSTRUCTUREID: 'AS-2410',
    label: 'WeightBridge',
  },
  {
    CLASSSTRUCTUREID: 'FA-1000',
    label: 'LI ASU Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1001',
    label: 'LI COGEN Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1002',
    label: 'HYCO Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1003',
    label: 'LI PIPE Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1004',
    label: 'LI Onsite Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1005',
    label: 'LI IM Fleet',
  },
  {
    CLASSSTRUCTUREID: 'FA-1006',
    label: 'LI IM FIELD SERVICE TECHNICIAN',
  },
  {
    CLASSSTRUCTUREID: 'FA-1007',
    label: 'LI IM Filling Center',
  },
  {
    CLASSSTRUCTUREID: 'FA-1008',
    label: 'Liquefier',
  },
  {
    CLASSSTRUCTUREID: 'FA-1009',
    label: 'LI CO2 Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1010',
    label: 'LI PSA Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1012',
    label: 'Backup Vaporization Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-1015',
    label: 'LI VSA Product unit',
  },
  {
    CLASSSTRUCTUREID: 'FA-9999',
    label: 'LI New Activity (other)',
  },
  {
    CLASSSTRUCTUREID: 'LO-1000',
    label: 'Air Pre-Cooling',
  },
  {
    CLASSSTRUCTUREID: 'LO-1001',
    label: 'Air Purification',
  },
  {
    CLASSSTRUCTUREID: 'LO-1002',
    label: 'Argon production',
  },
  {
    CLASSSTRUCTUREID: 'LO-1003',
    label: 'Blow Down',
  },
  {
    CLASSSTRUCTUREID: 'LO-10033',
    label: 'Cathodic Protection',
  },
  {
    CLASSSTRUCTUREID: 'LO-10036',
    label: 'Circuit',
  },
  {
    CLASSSTRUCTUREID: 'LO-1004',
    label: 'CO Production',
  },
  {
    CLASSSTRUCTUREID: 'LO-1005',
    label: 'CO2 Production',
  },
  {
    CLASSSTRUCTUREID: 'LO-1006',
    label: 'CO2 Removal',
  },
  {
    CLASSSTRUCTUREID: 'LO-10066',
    label: 'Combustion lines',
  },
  {
    CLASSSTRUCTUREID: 'LO-1007',
    label: 'Compression',
  },
  {
    CLASSSTRUCTUREID: 'LO-1008',
    label: 'Condensate',
  },
  {
    CLASSSTRUCTUREID: 'LO-10082',
    label: 'Condensate Stripping',
  },
  {
    CLASSSTRUCTUREID: 'LO-10084',
    label: 'Convection zone of SMR',
  },
  {
    CLASSSTRUCTUREID: 'LO-1009',
    label: 'Cycle',
  },
  {
    CLASSSTRUCTUREID: 'LO-10095',
    label: 'Cylinder Filling System',
  },
  {
    CLASSSTRUCTUREID: 'LO-1010',
    label: 'Demineralized water production',
  },
  {
    CLASSSTRUCTUREID: 'LO-1011',
    label: 'Deriming',
  },
  {
    CLASSSTRUCTUREID: 'LO-1012',
    label: 'Desulfurization',
  },
  {
    CLASSSTRUCTUREID: 'LO-1013',
    label: 'Dispatching',
  },
  {
    CLASSSTRUCTUREID: 'LO-1014',
    label: 'Distillation',
  },
  {
    CLASSSTRUCTUREID: 'LO-1015',
    label: 'Electricity',
  },
  {
    CLASSSTRUCTUREID: 'LO-1016',
    label: 'Expansion',
  },
  {
    CLASSSTRUCTUREID: 'LO-1017',
    label: 'Facility',
  },
  {
    CLASSSTRUCTUREID: 'LO-1018',
    label: 'Feed Preparation',
  },
  {
    CLASSSTRUCTUREID: 'LO-1019',
    label: 'Filling station',
  },
  {
    CLASSSTRUCTUREID: 'LO-101910',
    label: 'Filling ramp',
  },
  {
    CLASSSTRUCTUREID: 'LO-1020',
    label: 'Flare',
  },
  {
    CLASSSTRUCTUREID: 'LO-10205',
    label: 'Gas & Fire Protection',
  },
  {
    CLASSSTRUCTUREID: 'LO-1021',
    label: 'Gas Cooling',
  },
  {
    CLASSSTRUCTUREID: 'LO-10213',
    label: 'Gas distribution',
  },
  {
    CLASSSTRUCTUREID: 'LO-10214',
    label: 'Gas Dryer',
  },
  {
    CLASSSTRUCTUREID: 'LO-10215',
    label: 'Gas Heating',
  },
  {
    CLASSSTRUCTUREID: 'LO-1022',
    label: 'Gas treatment',
  },
  {
    CLASSSTRUCTUREID: 'LO-1023',
    label: 'H2 Production',
  },
  {
    CLASSSTRUCTUREID: 'LO-1024',
    label: 'He/Ne Production',
  },
  {
    CLASSSTRUCTUREID: 'LO-10243',
    label: 'Isolation station',
  },
  {
    CLASSSTRUCTUREID: 'LO-1025',
    label: 'Kr/Xe Production',
  },
  {
    CLASSSTRUCTUREID: 'LO-1026',
    label: 'LIN System',
  },
  {
    CLASSSTRUCTUREID: 'LO-1027',
    label: 'Liquefaction',
  },
  {
    CLASSSTRUCTUREID: 'LO-10272',
    label: 'Liquid & effluent',
  },
  {
    CLASSSTRUCTUREID: 'LO-10273',
    label: 'Liquid treatment',
  },
  {
    CLASSSTRUCTUREID: 'LO-10275',
    label: 'Membrane',
  },
  {
    CLASSSTRUCTUREID: 'LO-1028',
    label: 'Miscellaneous',
  },
  {
    CLASSSTRUCTUREID: 'LO-102810',
    label: 'Mixal',
  },
  {
    CLASSSTRUCTUREID: 'LO-1029',
    label: 'Monitoring',
  },
  {
    CLASSSTRUCTUREID: 'LO-10294',
    label: 'Pipeline',
  },
  {
    CLASSSTRUCTUREID: 'LO-10294-1',
    label: 'Pipeline - interconnexion centre',
  },
  {
    CLASSSTRUCTUREID: 'LO-10294-2',
    label: 'Pipeline - Metering Station',
  },
  {
    CLASSSTRUCTUREID: 'LO-10294-3',
    label: 'Pipeline - Metering system',
  },
  {
    CLASSSTRUCTUREID: 'LO-10294-4',
    label: 'Pipeline - Network cabinet',
  },
  {
    CLASSSTRUCTUREID: 'LO-10295',
    label: 'POX',
  },
  {
    CLASSSTRUCTUREID: 'LO-10296',
    label: 'Pre Reforming',
  },
  {
    CLASSSTRUCTUREID: 'LO-10297',
    label: 'Process Fluid cooling',
  },
  {
    CLASSSTRUCTUREID: 'LO-1030',
    label: 'Purification',
  },
  {
    CLASSSTRUCTUREID: 'LO-1031',
    label: 'Reforming',
  },
  {
    CLASSSTRUCTUREID: 'LO-1032',
    label: 'Refregirating Unit',
  },
  {
    CLASSSTRUCTUREID: 'LO-1033',
    label: 'Safety',
  },
  {
    CLASSSTRUCTUREID: 'LO-1034',
    label: 'Separation',
  },
  {
    CLASSSTRUCTUREID: 'LO-10345',
    label: 'Shift',
  },
  {
    CLASSSTRUCTUREID: 'LO-10346',
    label: 'Soot Scrubbing',
  },
  {
    CLASSSTRUCTUREID: 'LO-1035',
    label: 'Start-up system',
  },
  {
    CLASSSTRUCTUREID: 'LO-1036',
    label: 'Steam generation',
  },
  {
    CLASSSTRUCTUREID: 'LO-1037',
    label: 'Storage',
  },
  {
    CLASSSTRUCTUREID: 'LO-1038',
    label: 'Supervision',
  },
  {
    CLASSSTRUCTUREID: 'LO-1039',
    label: 'Supply',
  },
  {
    CLASSSTRUCTUREID: 'LO-10395',
    label: 'Turbine',
  },
  {
    CLASSSTRUCTUREID: 'LO-1040',
    label: 'Utilities',
  },
  {
    CLASSSTRUCTUREID: 'LO-1040-A',
    label: 'Utilities - Air instrument system',
  },
  {
    CLASSSTRUCTUREID: 'LO-1040-N',
    label: 'Utilities - Nitrogen',
  },
  {
    CLASSSTRUCTUREID: 'LO-1040-W',
    label: 'Utilities - Water',
  },
  {
    CLASSSTRUCTUREID: 'LO-1041',
    label: 'Vaporization',
  },
  {
    CLASSSTRUCTUREID: 'LO-104110',
    label: 'VSA Vaccuum pump',
  },
  {
    CLASSSTRUCTUREID: 'LO-1042',
    label: 'Waste',
  },
  {
    CLASSSTRUCTUREID: 'LO-1043',
    label: 'Water cooling',
  },
  {
    CLASSSTRUCTUREID: 'RE-01',
    label: 'Traitement et tranfert de gaz',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-01',
    label: 'Compresseur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-02',
    label: 'Pompe',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-03',
    label: 'Surpresseur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-04',
    label: 'Turbine',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-05',
    label: 'Boîte froide',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-06',
    label: 'Canalisation',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-07',
    label: 'Générateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-08',
    label: 'Épurateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-09',
    label: 'Deoxo',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-10',
    label: 'Filtre (récipient)',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-11',
    label: 'Séparateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-12',
    label: 'Gazomètre',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-13',
    label: 'Trémie',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-14',
    label: 'Échangeur de chaleur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-15',
    label: 'Absorbeur/Adsorbeur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-16',
    label: 'Sécheur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-17',
    label: 'Vaporiseur de liquide',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-18',
    label: 'Presse à glace sèche',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-19',
    label: 'Machine à pastilles de glace sèche',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-20',
    label: 'Pompe à vide',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-21',
    label: 'Colonne de distillation',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-22',
    label: 'Rebouilleur/Condenseur principal',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-23',
    label: 'Rampe de remplissage de bouteilles',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-24',
    label: 'Récipient sous pression',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-25',
    label: 'Four',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-26',
    label: 'Chaudière vapeur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-27',
    label: 'Armoire à gaz',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-28',
    label: 'Blower',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-29',
    label: "Unité d'eau déminéralisée",
  },
  {
    CLASSSTRUCTUREID: 'RE-01-30',
    label: 'Electrolyseur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-31',
    label: 'Purification de tête',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-32',
    label: 'PSA',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-33',
    label: 'Réacteur',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-34',
    label: 'Reformer',
  },
  {
    CLASSSTRUCTUREID: 'RE-01-35',
    label: 'Autre traitement ou transfert de gaz',
  },
  {
    CLASSSTRUCTUREID: 'RE-02',
    label: 'Auxiliaire',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-01',
    label: 'Equipement / système électrique',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-02',
    label: 'Equipement / système non électrique',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-03',
    label: 'Tour de refroidissement',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-04',
    label: 'Système de contrôle du procédé / DCS',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-05',
    label: 'Poste de comptage',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-06',
    label: 'Ventilateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-07',
    label: 'Chaudière à récupération de chaleur',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-08',
    label: 'Instrumentation',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-09',
    label: "Unité MDEA d'élimination du CO2",
  },
  {
    CLASSSTRUCTUREID: 'RE-02-10',
    label: 'Membranes',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-11',
    label: 'Moteur',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-12',
    label: 'Transformateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-13',
    label: 'Vannes',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-14',
    label: 'Onduleur',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-15',
    label: 'Analyseurs',
  },
  {
    CLASSSTRUCTUREID: 'RE-02-16',
    label: 'Autre équipement auxiliaire',
  },
  {
    CLASSSTRUCTUREID: 'RE-03',
    label: 'Outils',
  },
  {
    CLASSSTRUCTUREID: 'RE-03-01',
    label: 'Outils fixes',
  },
  {
    CLASSSTRUCTUREID: 'RE-03-02',
    label: 'Outils portables',
  },
  {
    CLASSSTRUCTUREID: 'RE-03-03',
    label: 'Autre outil',
  },
  {
    CLASSSTRUCTUREID: 'RE-04',
    label: 'Récipents mobiles de gaz ou de liquide',
  },
  {
    CLASSSTRUCTUREID: 'RE-04-01',
    label: 'Bouteille',
  },
  {
    CLASSSTRUCTUREID: 'RE-04-02',
    label: 'Cadre/Rack de bouteilles',
  },
  {
    CLASSSTRUCTUREID: 'RE-04-03',
    label: 'Récipient cryogénique',
  },
  {
    CLASSSTRUCTUREID: 'RE-04-04',
    label: 'Fût',
  },
  {
    CLASSSTRUCTUREID: 'RE-04-05',
    label: 'Réserve liquide patient',
  },
  {
    CLASSSTRUCTUREID: 'RE-04-06',
    label: 'Autre récipient mobile',
  },
  {
    CLASSSTRUCTUREID: 'RE-05',
    label: 'Récipient de stockage',
  },
  {
    CLASSSTRUCTUREID: 'RE-05-01',
    label: 'Réservoir de stockage non cryogénique',
  },
  {
    CLASSSTRUCTUREID: 'RE-05-02',
    label: 'Réservoir de stockage cryogénique',
  },
  {
    CLASSSTRUCTUREID: 'RE-05-03',
    label: 'Autre récipient de stockage',
  },
  {
    CLASSSTRUCTUREID: 'RE-06',
    label: 'Équipement de transport',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-01',
    label: 'Wagon citerne',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-02',
    label: 'Camion/remorque (transport de bouteilles)',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-03',
    label: 'Camion/remorque (gaz liquéfié)',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-04',
    label: 'Semi-remorque cigares',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-05',
    label: 'Tracteur',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-06',
    label: 'Véhicule tiers fermé',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-07',
    label: 'Camionnette de service',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-08',
    label: 'Automobile',
  },
  {
    CLASSSTRUCTUREID: 'RE-06-09',
    label: 'Autre équipement de transport',
  },
  {
    CLASSSTRUCTUREID: 'RE-07',
    label: 'Équipement de manutention',
  },
  {
    CLASSSTRUCTUREID: 'RE-07-01',
    label: 'Chariot élévateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-07-02',
    label: 'Grue / Equipement de levage (tout type)',
  },
  {
    CLASSSTRUCTUREID: 'RE-07-03',
    label: 'Autre équipement de manutention',
  },
  {
    CLASSSTRUCTUREID: 'RE-08',
    label: "Équipement d'application",
  },
  {
    CLASSSTRUCTUREID: 'RE-08-01',
    label: 'Surgélation',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-02',
    label: 'Equipement de soudage',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-03',
    label: 'Équipement analytique et de diagnostic',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-04',
    label: 'Appareil médical',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-05',
    label: 'Médical : Concentrateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-06',
    label: 'Médical : CPAP',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-07',
    label: 'Médical : Ventilateur',
  },
  {
    CLASSSTRUCTUREID: 'RE-08-08',
    label: "Autre équipement d'application",
  },
  {
    CLASSSTRUCTUREID: 'RE09',
    label: 'Non pertinent',
  },
  {
    CLASSSTRUCTUREID: 'RE10',
    label: 'Autre',
  },
  {
    CLASSSTRUCTUREID: 'RE11',
    label: 'Non identifié',
  },
  {
    CLASSSTRUCTUREID: 'SR-1000',
    label: 'DCS PLC',
  },
  {
    CLASSSTRUCTUREID: 'SR-1001',
    label: 'Electrical',
  },
  {
    CLASSSTRUCTUREID: 'SR-1002',
    label: 'Facility',
  },
  {
    CLASSSTRUCTUREID: 'SR-1003',
    label: 'Instrumentation',
  },
  {
    CLASSSTRUCTUREID: 'SR-1004',
    label: 'Mechanical',
  },
  {
    CLASSSTRUCTUREID: 'SR-1005',
    label: 'Service',
  },
  {
    CLASSSTRUCTUREID: 'SR-1006',
    label: 'DCS PLC - Bus',
  },
  {
    CLASSSTRUCTUREID: 'SR-10062',
    label: 'DCS PLC - Communication card',
  },
  {
    CLASSSTRUCTUREID: 'SR-1007',
    label: 'DCS PLC - Controller',
  },
  {
    CLASSSTRUCTUREID: 'SR-10072',
    label: 'DCS PLC - CPU card',
  },
  {
    CLASSSTRUCTUREID: 'SR-1008',
    label: 'DCS PLC - I/O Card',
  },
  {
    CLASSSTRUCTUREID: 'SR-10085',
    label: 'DCS PLC - On Call System',
  },
  {
    CLASSSTRUCTUREID: 'SR-10086',
    label: 'DCS PLC - printer',
  },
  {
    CLASSSTRUCTUREID: 'SR-1009',
    label: 'DCS PLC - Station supervision',
  },
  {
    CLASSSTRUCTUREID: 'SR-1009-1',
    label: 'Station supervision - fire system',
  },
  {
    CLASSSTRUCTUREID: 'SR-1010',
    label: 'Electrical - Alternator Generator',
  },
  {
    CLASSSTRUCTUREID: 'SR-1011',
    label: 'Electrical - Battery',
  },
  {
    CLASSSTRUCTUREID: 'SR-1012',
    label: 'Electrical - Bundle',
  },
  {
    CLASSSTRUCTUREID: 'SR-1013',
    label: 'Electrical - Bus',
  },
  {
    CLASSSTRUCTUREID: 'SR-1014',
    label: 'Electrical - Bus Bar',
  },
  {
    CLASSSTRUCTUREID: 'SR-1015',
    label: 'Electrical - Bus PTs',
  },
  {
    CLASSSTRUCTUREID: 'SR-1016',
    label: 'Electrical - Bushings',
  },
  {
    CLASSSTRUCTUREID: 'SR-1017',
    label: 'Electrical - Cabinet',
  },
  {
    CLASSSTRUCTUREID: 'SR-1018',
    label: 'Electrical - Cable',
  },
  {
    CLASSSTRUCTUREID: 'SR-1019',
    label: 'Electrical - Capacitors',
  },
  {
    CLASSSTRUCTUREID: 'SR-1020',
    label: 'Electrical - CCVT',
  },
  {
    CLASSSTRUCTUREID: 'SR-1021',
    label: 'Electrical - Charger',
  },
  {
    CLASSSTRUCTUREID: 'SR-1022',
    label: 'Electrical - Circuit breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1022-2',
    label: 'Electrical - Circuit breaker - Gasket',
  },
  {
    CLASSSTRUCTUREID: 'SR-1023',
    label: 'Electrical - Coil',
  },
  {
    CLASSSTRUCTUREID: 'SR-1023-2',
    label: 'Electrical - Coil - Closing Coil',
  },
  {
    CLASSSTRUCTUREID: 'SR-1023-5',
    label: 'Electrical - Coil - Tripping Coil',
  },
  {
    CLASSSTRUCTUREID: 'SR-1024',
    label: 'Electrical - Connector',
  },
  {
    CLASSSTRUCTUREID: 'SR-1025',
    label: 'Electrical - Contactor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1026',
    label: 'Electrical - Converter',
  },
  {
    CLASSSTRUCTUREID: 'SR-1027',
    label: 'Electrical - CPU',
  },
  {
    CLASSSTRUCTUREID: 'SR-1028',
    label: 'Electrical - CTs',
  },
  {
    CLASSSTRUCTUREID: 'SR-1029',
    label: 'Electrical - Current Limiting Rea',
  },
  {
    CLASSSTRUCTUREID: 'SR-1030',
    label: 'Electrical - Diode',
  },
  {
    CLASSSTRUCTUREID: 'SR-1031',
    label: 'Electrical - Breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1032',
    label: 'Electrical - Electrical component',
  },
  {
    CLASSSTRUCTUREID: 'SR-1033',
    label: 'Electrical - Electrode',
  },
  {
    CLASSSTRUCTUREID: 'SR-1034',
    label: 'Electrical - Electrolyte',
  },
  {
    CLASSSTRUCTUREID: 'SR-1035',
    label: 'Electrical - Excitation',
  },
  {
    CLASSSTRUCTUREID: 'SR-1036',
    label: 'Electrical - Frequency Converter',
  },
  {
    CLASSSTRUCTUREID: 'SR-1037',
    label: 'Electrical - Fuse',
  },
  {
    CLASSSTRUCTUREID: 'SR-1038',
    label: 'Electrical - Grounding Neutral Re',
  },
  {
    CLASSSTRUCTUREID: 'SR-1039',
    label: 'Electrical - High Yard Bus Struct',
  },
  {
    CLASSSTRUCTUREID: 'SR-1040',
    label: 'Electrical - Insulant insulation',
  },
  {
    CLASSSTRUCTUREID: 'SR-1041',
    label: 'Electrical - insulator controller',
  },
  {
    CLASSSTRUCTUREID: 'SR-1042',
    label: 'Electrical - Keyphasor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1043',
    label: 'Electrical - Lightning Arrestor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1044',
    label: 'Electrical - Lightning Conductor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1045',
    label: 'Electrical - Line Fuses',
  },
  {
    CLASSSTRUCTUREID: 'SR-1046',
    label: 'Electrical - Line Reactors',
  },
  {
    CLASSSTRUCTUREID: 'SR-1047',
    label: 'Electrical - Liquid Filled Transf',
  },
  {
    CLASSSTRUCTUREID: 'SR-1048',
    label: 'Electrical - Load adapter',
  },
  {
    CLASSSTRUCTUREID: 'SR-1049',
    label: 'Electrical - Module',
  },
  {
    CLASSSTRUCTUREID: 'SR-1050',
    label: 'Electrical - Motor',
  },
  {
    CLASSSTRUCTUREID: 'SR-10505',
    label: 'Electrical - Oil',
  },
  {
    CLASSSTRUCTUREID: 'SR-1051',
    label: 'Electrical - PLC',
  },
  {
    CLASSSTRUCTUREID: 'SR-1052',
    label: 'Electrical - Porcelain Insulators',
  },
  {
    CLASSSTRUCTUREID: 'SR-1053',
    label: 'Electrical - Power Supply',
  },
  {
    CLASSSTRUCTUREID: 'SR-1054',
    label: 'Electrical - Printer',
  },
  {
    CLASSSTRUCTUREID: 'SR-1055',
    label: 'Electrical - PTs',
  },
  {
    CLASSSTRUCTUREID: 'SR-1056',
    label: 'Electrical - Reactor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1057',
    label: 'Electrical - Rectifier',
  },
  {
    CLASSSTRUCTUREID: 'SR-1058',
    label: 'Electrical - Regulator',
  },
  {
    CLASSSTRUCTUREID: 'SR-1059',
    label: 'Electrical - Relay Electromechanic',
  },
  {
    CLASSSTRUCTUREID: 'SR-10591',
    label: 'Electrical - Relay',
  },
  {
    CLASSSTRUCTUREID: 'SR-1060',
    label: 'Electrical - Relay Solid state',
  },
  {
    CLASSSTRUCTUREID: 'SR-1061',
    label: 'Electrical - Resistor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1062',
    label: 'Electrical - Rotor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1062-2',
    label: 'Electrical - Rotor - Ring/Brushes',
  },
  {
    CLASSSTRUCTUREID: 'SR-1063',
    label: 'Electrical - Starter',
  },
  {
    CLASSSTRUCTUREID: 'SR-1064',
    label: 'Electrical - Stator',
  },
  {
    CLASSSTRUCTUREID: 'SR-1064-4',
    label: 'Electrical - Stator - Magnetic core',
  },
  {
    CLASSSTRUCTUREID: 'SR-1064-7',
    label: 'Electrical - Stator - Slot wedge',
  },
  {
    CLASSSTRUCTUREID: 'SR-1065',
    label: 'Electrical - Surge Capacitors',
  },
  {
    CLASSSTRUCTUREID: 'SR-1066',
    label: 'Electrical - Switch',
  },
  {
    CLASSSTRUCTUREID: 'SR-1067',
    label: 'Electrical - Switchgear Bus',
  },
  {
    CLASSSTRUCTUREID: 'SR-1068',
    label: 'Electrical - Switchgear Controls',
  },
  {
    CLASSSTRUCTUREID: 'SR-1069',
    label: 'Electrical - Terminal Board',
  },
  {
    CLASSSTRUCTUREID: 'SR-1070',
    label: 'Electrical - Transformer',
  },
  {
    CLASSSTRUCTUREID: 'SR-1070-1',
    label: 'Electrical - Transformer - Cooler',
  },
  {
    CLASSSTRUCTUREID: 'SR-1070-2',
    label: 'Electrical - Transformer - protection relay',
  },
  {
    CLASSSTRUCTUREID: 'SR-1070-3',
    label: 'Electrical - transformer - tap changer',
  },
  {
    CLASSSTRUCTUREID: 'SR-1071',
    label: 'Electrical - UPS',
  },
  {
    CLASSSTRUCTUREID: 'SR-1072',
    label: 'Electrical - Vacuum Interrupters',
  },
  {
    CLASSSTRUCTUREID: 'SR-1073',
    label: 'Electrical - VFD',
  },
  {
    CLASSSTRUCTUREID: 'SR-1074',
    label: 'Electrical - Wave Traps',
  },
  {
    CLASSSTRUCTUREID: 'SR-1075',
    label: 'Breaker - Air Breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1076',
    label: 'Breaker - Gas Circuit Breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1077',
    label: 'Breaker - Oil Circuit Breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1078',
    label: 'Breaker - Switchgear Breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1079',
    label: 'Breaker - Vacuum Breaker',
  },
  {
    CLASSSTRUCTUREID: 'SR-1080',
    label: 'Facility - Air Conditioning Sys',
  },
  {
    CLASSSTRUCTUREID: 'SR-1081',
    label: 'Facility - Basement Foundation',
  },
  {
    CLASSSTRUCTUREID: 'SR-1082',
    label: 'Facility - Building',
  },
  {
    CLASSSTRUCTUREID: 'SR-10825',
    label: 'Facility - Door',
  },
  {
    CLASSSTRUCTUREID: 'SR-10826',
    label: 'Facility - Duckboard',
  },
  {
    CLASSSTRUCTUREID: 'SR-1083',
    label: 'Facility - Fence',
  },
  {
    CLASSSTRUCTUREID: 'SR-1084',
    label: 'Facility - Foot board',
  },
  {
    CLASSSTRUCTUREID: 'SR-1085',
    label: 'Facility - Framework',
  },
  {
    CLASSSTRUCTUREID: 'SR-1086',
    label: 'Facility - Grey Water - Process',
  },
  {
    CLASSSTRUCTUREID: 'SR-1087',
    label: 'Facility - IT',
  },
  {
    CLASSSTRUCTUREID: 'SR-1088',
    label: 'Facility - Ladder',
  },
  {
    CLASSSTRUCTUREID: 'SR-1089',
    label: 'Facility - Lighting',
  },
  {
    CLASSSTRUCTUREID: 'SR-1090',
    label: 'Facility - Natural gas Fuel gas',
  },
  {
    CLASSSTRUCTUREID: 'SR-1091',
    label: 'Facility - Network',
  },
  {
    CLASSSTRUCTUREID: 'SR-1092',
    label: 'Facility - Road',
  },
  {
    CLASSSTRUCTUREID: 'SR-1093',
    label: 'Facility - Roof',
  },
  {
    CLASSSTRUCTUREID: 'SR-10935',
    label: 'Facility - Safety Shower',
  },
  {
    CLASSSTRUCTUREID: 'SR-1094',
    label: 'Facility - Sewerage',
  },
  {
    CLASSSTRUCTUREID: 'SR-1095',
    label: 'Facility - Telephone communicat',
  },
  {
    CLASSSTRUCTUREID: 'SR-1096',
    label: 'Facility - Video - camera',
  },
  {
    CLASSSTRUCTUREID: 'SR-1097',
    label: 'Facility - Video - monitor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1098',
    label: 'Facility - Water',
  },
  {
    CLASSSTRUCTUREID: 'SR-1099',
    label: 'Facility - Weight scales truck',
  },
  {
    CLASSSTRUCTUREID: 'SR-1100',
    label: 'IT - IT LAN Cable',
  },
  {
    CLASSSTRUCTUREID: 'SR-1101',
    label: 'IT - IT LAN PC',
  },
  {
    CLASSSTRUCTUREID: 'SR-1102',
    label: 'IT - IT LAN server',
  },
  {
    CLASSSTRUCTUREID: 'SR-1103',
    label: 'IT - IT Local Area Networ',
  },
  {
    CLASSSTRUCTUREID: 'SR-1104',
    label: 'Instrumentation - Actuator',
  },
  {
    CLASSSTRUCTUREID: 'SR-1105',
    label: 'Instrumentation - Amplifier',
  },
  {
    CLASSSTRUCTUREID: 'SR-1106',
    label: 'Instrumentation - Analyzer',
  },
  {
    CLASSSTRUCTUREID: 'SR-1106-2',
    label: 'Instrumentation - Analyzer - Cells',
  },
  {
    CLASSSTRUCTUREID: 'SR-1107',
    label: 'Instrumentation - Annubar',
  },
  {
    CLASSSTRUCTUREID: 'SR-1108',
    label: 'Instrumentation - Cabinet Panel',
  },
  {
    CLASSSTRUCTUREID: 'SR-1109',
    label: 'Instrumentation - Calculator',
  },
  {
    CLASSSTRUCTUREID: 'SR-1110',
    label: 'Instrumentation - Communication card',
  },
  {
    CLASSSTRUCTUREID: 'SR-1111',
    label: 'Instrumentation - control card',
  },
  {
    CLASSSTRUCTUREID: 'SR-1112',
    label: 'Instrumentation - Control gas Air N2',
  },
  {
    CLASSSTRUCTUREID: 'SR-1113',
    label: 'Instrumentation - Cylinder',
  },
  {
    CLASSSTRUCTUREID: 'SR-1114',
    label: 'Instrumentation - Gauge',
  },
  {
    CLASSSTRUCTUREID: 'SR-1115',
    label: 'Instrumentation - Horn, Buzzer',
  },
  {
    CLASSSTRUCTUREID: 'SR-1116',
    label: 'Instrumentation - meter',
  },
  {
    CLASSSTRUCTUREID: 'SR-1117',
    label: 'Instrumentation - network',
  },
  {
    CLASSSTRUCTUREID: 'SR-11175',
    label: 'Instrumentation - Piping',
  },
  {
    CLASSSTRUCTUREID: 'SR-1118',
    label: 'Instrumentation - Positioner',
  },
  {
    CLASSSTRUCTUREID: 'SR-1119',
    label: 'Instrumentation - probe',
  },
  {
    CLASSSTRUCTUREID: 'SR-1120',
    label: 'Instrumentation - Recorder',
  },
  {
    CLASSSTRUCTUREID: 'SR-1121',
    label: 'Instrumentation - Regulator',
  },
  {
    CLASSSTRUCTUREID: 'SR-1122',
    label: 'Instrumentation - Relay',
  },
  {
    CLASSSTRUCTUREID: 'SR-1123',
    label: 'Instrumentation - Sensor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1123-1',
    label: 'instrumentation - sensor - fire system',
  },
  {
    CLASSSTRUCTUREID: 'SR-11235',
    label: 'Instrumentation - Solenoïd valve',
  },
  {
    CLASSSTRUCTUREID: 'SR-1124',
    label: 'Instrumentation - Switch',
  },
  {
    CLASSSTRUCTUREID: 'SR-1125',
    label: 'Instrumentation - transmitter',
  },
  {
    CLASSSTRUCTUREID: 'SR-1126',
    label: 'Instrumentation - watch dog - detector',
  },
  {
    CLASSSTRUCTUREID: 'SR-1127',
    label: 'Mechanical - actuator',
  },
  {
    CLASSSTRUCTUREID: 'SR-11272',
    label: 'Mechanical - Adsorbant',
  },
  {
    CLASSSTRUCTUREID: 'SR-11275',
    label: 'Mechanical - Anchor bolt',
  },
  {
    CLASSSTRUCTUREID: 'SR-1128',
    label: 'Mechanical - ASU',
  },
  {
    CLASSSTRUCTUREID: 'SR-1129',
    label: 'Mechanical - Bearing',
  },
  {
    CLASSSTRUCTUREID: 'SR-1130',
    label: 'Mechanical - Belt',
  },
  {
    CLASSSTRUCTUREID: 'SR-1131',
    label: 'Mechanical - Blower',
  },
  {
    CLASSSTRUCTUREID: 'SR-1132',
    label: 'Mechanical - Boiler',
  },
  {
    CLASSSTRUCTUREID: 'SR-1133',
    label: 'Mechanical - Bridge',
  },
  {
    CLASSSTRUCTUREID: 'SR-11334',
    label: 'Mechanical - Bundle',
  },
  {
    CLASSSTRUCTUREID: 'SR-11335',
    label: 'Mechanical - Burner',
  },
  {
    CLASSSTRUCTUREID: 'SR-11335-1',
    label: 'Mechanical - Burner - flame detection',
  },
  {
    CLASSSTRUCTUREID: 'SR-11335-2',
    label: 'Mechanical - Burner - gun',
  },
  {
    CLASSSTRUCTUREID: 'SR-11335-3',
    label: 'Mechanical - Burner - injector',
  },
  {
    CLASSSTRUCTUREID: 'SR-1134',
    label: 'Mechanical - Cartridge',
  },
  {
    CLASSSTRUCTUREID: 'SR-1135',
    label: 'Mechanical - Catalyst',
  },
  {
    CLASSSTRUCTUREID: 'SR-1136',
    label: 'Mechanical - Compressor',
  },
  {
    CLASSSTRUCTUREID: 'SR-1136-2',
    label: 'Mechanical - Compressor - basement',
  },
  {
    CLASSSTRUCTUREID: 'SR-1137',
    label: 'Mechanical - connecting rod',
  },
  {
    CLASSSTRUCTUREID: 'SR-1138',
    label: 'Mechanical - Controlling device',
  },
  {
    CLASSSTRUCTUREID: 'SR-1139',
    label: 'Mechanical - Heat Exchanger / Cooler',
  },
  {
    CLASSSTRUCTUREID: 'SR-1139-5',
    label: 'Mechanical - Heat Exchanger / Cooler - fin',
  },
  {
    CLASSSTRUCTUREID: 'SR-1140',
    label: 'Mechanical - Cooling Tower',
  },
  {
    CLASSSTRUCTUREID: 'SR-1140-2',
    label: 'Mechanical - Cooling Tower - Basin',
  },
  {
    CLASSSTRUCTUREID: 'SR-1141',
    label: 'Mechanical - Coupling',
  },
  {
    CLASSSTRUCTUREID: 'SR-1142',
    label: 'Mechanical - Crank shaft',
  },
  {
    CLASSSTRUCTUREID: 'SR-1143',
    label: 'Mechanical - Cross head',
  },
  {
    CLASSSTRUCTUREID: 'SR-1144',
    label: 'Mechanical - Demister',
  },
  {
    CLASSSTRUCTUREID: 'SR-1145',
    label: 'Mechanical - Deriming',
  },
  {
    CLASSSTRUCTUREID: 'SR-1146',
    label: 'Mechanical - Diffuser',
  },
  {
    CLASSSTRUCTUREID: 'SR-1147',
    label: 'Mechanical - Distributor',
  },
  {
    CLASSSTRUCTUREID: 'SR-11472',
    label: 'Mechanical - Emergency Diesel set',
  },
  {
    CLASSSTRUCTUREID: 'SR-11479',
    label: 'Mechanical - Exchanger',
  },
  {
    CLASSSTRUCTUREID: 'SR-1148',
    label: 'Mechanical - Expander',
  },
  {
    CLASSSTRUCTUREID: 'SR-1149',
    label: 'Mechanical - Fan',
  },
  {
    CLASSSTRUCTUREID: 'SR-1150',
    label: 'Mechanical - Fasterner',
  },
  {
    CLASSSTRUCTUREID: 'SR-1151',
    label: 'Mechanical - Filter',
  },
  {
    CLASSSTRUCTUREID: 'SR-11513',
    label: 'Mechanical - Flange',
  },
  {
    CLASSSTRUCTUREID: 'SR-1152',
    label: 'Mechanical - Gasket',
  },
  {
    CLASSSTRUCTUREID: 'SR-1153',
    label: 'Mechanical - Gear box',
  },
  {
    CLASSSTRUCTUREID: 'SR-1154',
    label: 'Mechanical - Guidevane',
  },
  {
    CLASSSTRUCTUREID: 'SR-1155',
    label: 'Mechanical - Heater',
  },
  {
    CLASSSTRUCTUREID: 'SR-1156',
    label: 'Mechanical - Hose',
  },
  {
    CLASSSTRUCTUREID: 'SR-1157',
    label: 'Mechanical - Impeller',
  },
  {
    CLASSSTRUCTUREID: 'SR-1158',
    label: 'Mechanical - Insulation',
  },
  {
    CLASSSTRUCTUREID: 'SR-1159',
    label: 'Mechanical - Lubricating Oil Syst',
  },
  {
    CLASSSTRUCTUREID: 'SR-1160',
    label: 'Mechanical - Mechanic Driving Com',
  },
  {
    CLASSSTRUCTUREID: 'SR-11601',
    label: 'Mechanical - Nitrogen Purge',
  },
  {
    CLASSSTRUCTUREID: 'SR-11602',
    label: 'Mechanical - oil circuit',
  },
  {
    CLASSSTRUCTUREID: 'SR-11603',
    label: 'Mechanical - oil scraper',
  },
  {
    CLASSSTRUCTUREID: 'SR-1161',
    label: 'Mechanical - Packing for process',
  },
  {
    CLASSSTRUCTUREID: 'SR-1162',
    label: 'Mechanical - packing for sealing',
  },
  {
    CLASSSTRUCTUREID: 'SR-11621',
    label: 'Mechanical - perlite',
  },
  {
    CLASSSTRUCTUREID: 'SR-11622',
    label: 'Mechanical - Permeator',
  },
  {
    CLASSSTRUCTUREID: 'SR-11622-2',
    label: 'Mechanical - Permeator - Membrane',
  },
  {
    CLASSSTRUCTUREID: 'SR-11624',
    label: 'Mechanical - pilot flame',
  },
  {
    CLASSSTRUCTUREID: 'SR-1163',
    label: 'Mechanical - pipeline also piping',
  },
  {
    CLASSSTRUCTUREID: 'SR-1164',
    label: 'Mechanical - Piping',
  },
  {
    CLASSSTRUCTUREID: 'SR-1164-1',
    label: 'Mechanical - Piping - rack/support',
  },
  {
    CLASSSTRUCTUREID: 'SR-1165',
    label: 'Mechanical - Piston',
  },
  {
    CLASSSTRUCTUREID: 'SR-1166',
    label: 'Mechanical - Piston Rod',
  },
  {
    CLASSSTRUCTUREID: 'SR-1167',
    label: 'Mechanical - PSD PSE rupture disc',
  },
  {
    CLASSSTRUCTUREID: 'SR-1168',
    label: 'Mechanical - Process Safety Valve',
  },
  {
    CLASSSTRUCTUREID: 'SR-1169',
    label: 'Mechanical - Pulley',
  },
  {
    CLASSSTRUCTUREID: 'SR-1170',
    label: 'Mechanical - Pump',
  },
  {
    CLASSSTRUCTUREID: 'SR-11703',
    label: 'Mechanical - Purge System',
  },
  {
    CLASSSTRUCTUREID: 'SR-1171',
    label: 'Mechanical - Radiator Exchanger',
  },
  {
    CLASSSTRUCTUREID: 'SR-11711',
    label: 'Mechanical - Refractory',
  },
  {
    CLASSSTRUCTUREID: 'SR-1172',
    label: 'Mechanical - Rotor',
  },
  {
    CLASSSTRUCTUREID: 'SR-11722',
    label: 'Mechanical - Safety Valve',
  },
  {
    CLASSSTRUCTUREID: 'SR-11722-1',
    label: 'Mechanical - safety valve - flange',
  },
  {
    CLASSSTRUCTUREID: 'SR-11722-2',
    label: 'Mechanical - safety valve - spring',
  },
  {
    CLASSSTRUCTUREID: 'SR-1173',
    label: 'Mechanical - Seal',
  },
  {
    CLASSSTRUCTUREID: 'SR-1173-1',
    label: 'Mechanical - Seal - Gas',
  },
  {
    CLASSSTRUCTUREID: 'SR-1173-2',
    label: 'Mechanical - Seal - Labyrinth',
  },
  {
    CLASSSTRUCTUREID: 'SR-1174',
    label: 'Mechanical - Security component',
  },
  {
    CLASSSTRUCTUREID: 'SR-1175',
    label: 'Mechanical - Shaft',
  },
  {
    CLASSSTRUCTUREID: 'SR-1176',
    label: 'Mechanical - Sheave',
  },
  {
    CLASSSTRUCTUREID: 'SR-1177',
    label: 'Mechanical - Shell',
  },
  {
    CLASSSTRUCTUREID: 'SR-11772',
    label: 'Mechanical - Shock Absorber',
  },
  {
    CLASSSTRUCTUREID: 'SR-11775',
    label: 'Mechanical - Sleeve',
  },
  {
    CLASSSTRUCTUREID: 'SR-1178',
    label: 'Mechanical - Frame component',
  },
  {
    CLASSSTRUCTUREID: 'SR-1179',
    label: 'Mechanical - Tank',
  },
  {
    CLASSSTRUCTUREID: 'SR-1179-1',
    label: 'mechanical - tank - basement',
  },
  {
    CLASSSTRUCTUREID: 'SR-1179-2',
    label: 'mechanical - tank - retention pit',
  },
  {
    CLASSSTRUCTUREID: 'SR-1180',
    label: 'Mechanical - Tracing',
  },
  {
    CLASSSTRUCTUREID: 'SR-1181',
    label: 'Mechanical - Turbine',
  },
  {
    CLASSSTRUCTUREID: 'SR-1182',
    label: 'Mechanical - Valve',
  },
  {
    CLASSSTRUCTUREID: 'SR-1182-1',
    label: 'Mechanical - Valve - body',
  },
  {
    CLASSSTRUCTUREID: 'SR-1182-2',
    label: 'Mechanical - Valve - seat',
  },
  {
    CLASSSTRUCTUREID: 'SR-1182-3',
    label: 'Mechanical - Valve - stem',
  },
  {
    CLASSSTRUCTUREID: 'SR-1183',
    label: 'Mechanical - Vaporizer',
  },
  {
    CLASSSTRUCTUREID: 'SR-11832',
    label: 'Mechanical - Weighting balance',
  },
  {
    CLASSSTRUCTUREID: 'SR-11832-5',
    label: 'Mechanical - Weighting balance - slab',
  },
  {
    CLASSSTRUCTUREID: 'SR-1184',
    label: 'Service - Engineering',
  },
  {
    CLASSSTRUCTUREID: 'SR-1185',
    label: 'Service - Laboratories',
  },
  {
    CLASSSTRUCTUREID: 'SR-1186',
    label: 'Service - Maintenance',
  },
  {
    CLASSSTRUCTUREID: 'SR-1187',
    label: 'Service - Operation',
  },
  {
    CLASSSTRUCTUREID: 'SR-1188',
    label: 'Service - QSE',
  },
  {
    CLASSSTRUCTUREID: 'SR-1189',
    label: 'Switch - Air Switches',
  },
  {
    CLASSSTRUCTUREID: 'SR-1190',
    label: 'Switch - Gas Circuit Switcher',
  },
  {
    CLASSSTRUCTUREID: 'SR-1191',
    label: 'Switch - Transfer Switch',
  },
  {
    CLASSSTRUCTUREID: 'SR-1200',
    label: 'Pipeline',
  },
  {
    CLASSSTRUCTUREID: 'SR-1201',
    label: 'Cabine',
  },
  {
    CLASSSTRUCTUREID: 'SR-2000',
    label: 'Actions Plan / Action',
  },
  {
    CLASSSTRUCTUREID: 'SR-2001',
    label: 'Actions Plan',
  },
  {
    CLASSSTRUCTUREID: 'SR-2002',
    label: 'Action',
  },
];
