import { Attachment } from '@al/entities';
import { AlEnvironmentService } from '@al/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID, Order } from '@datorama/akita';
import { AkitaFilter, AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AttachmentsQuery } from './attachments.query';
import { AttachmentStore, AttachmentState } from './attachments.store';

@Injectable({ providedIn: 'root' })
export class AttachmentsService {
  private filtersAttachment: AkitaFiltersPlugin<AttachmentState>;

  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private http: HttpClient,
    private attachmentQuery: AttachmentsQuery,
    private attachmentStore: AttachmentStore
  ) {
    this.filtersAttachment = new AkitaFiltersPlugin<AttachmentState>(
      this.attachmentQuery
    );
  }

  public add(attachment: Attachment): void {
    this.attachmentStore.add(attachment);
  }

  public get(): Observable<Attachment[]> {
    const endpoint = `${this.alEnvironmentService.env.apiUrl}attachments`;
    const request = this.http.get<Attachment[]>(endpoint).pipe(
      tap((entities) => {
        this.attachmentStore.set(entities);
      })
    );
    return this.attachmentQuery.getHasCache() === false
      ? request
      : new Observable<Attachment[]>();
  }

  public getAttachments(workOrderUuid: string): Observable<Attachment[]> {
    const endpoint = `${this.alEnvironmentService.env.apiUrl}attachments/workorder/${workOrderUuid}`;
    const request = this.http.get<Attachment[]>(endpoint).pipe(
      tap((entities) => {
        this.attachmentStore.set(entities);
      })
    );
    return this.attachmentQuery.getHasCache() === false
      ? request
      : new Observable<Attachment[]>();
  }

  public getById(id: string): Observable<Attachment> {
    const endpoint = `${
      this.alEnvironmentService.env.apiUrl
    }attachments/${id.toString()}`;
    const request = this.http.get<Attachment>(endpoint).pipe(
      tap((entities) => {
        this.attachmentStore.add(entities);
      })
    );
    return this.attachmentQuery.getHasCache() === false
      ? request
      : new Observable<Attachment>();
  }

  public getFilterValue(id: string): any | null {
    return this.filtersAttachment.getFilterValue(id);
  }

  public getSortValue(): string | null {
    const sortValue = this.filtersAttachment.getSortValue();
    if (!sortValue) {
      return '+title';
    }
    const order = sortValue.sortByOrder === Order.ASC ? '+' : '-';
    return sortValue.sortBy ? order + sortValue.sortBy : '+title';
  }

  public remove(id: ID): void {
    this.attachmentStore.remove(id);
  }

  public removeAllFilter(): void {
    this.filtersAttachment.clearFilters();
  }

  public removeFilter(id: string): void {
    this.filtersAttachment.removeFilter(id);
  }

  public selectAll(): Observable<Attachment[]> {
    return this.filtersAttachment.selectAllByFilters() as Observable<
      Attachment[]
    >;
  }

  public selectFilters(): Observable<AkitaFilter<AttachmentState>[]> {
    return this.filtersAttachment.selectFilters();
  }

  public setFilter(filter: AkitaFilter<AttachmentState>): void {
    this.filtersAttachment.setFilter(filter);
  }

  public setOrderBy(by: any, order: string | '+' | '-'): void {
    this.filtersAttachment.setSortBy({
      sortBy: by,
      sortByOrder: order === '+' ? Order.ASC : Order.DESC,
    });
  }

  public update(id: ID, attachment: Partial<Attachment>): void {
    this.attachmentStore.update(id, attachment);
  }
}
