import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AlGtmService } from '@al/gtm';
import { AlCreationComponent } from './al-creation/al-creation.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy {
  public isSmall: boolean;

  private ngUnsubscribe = new Subject();

  public constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    public gtmService: AlGtmService
  ) {
    this.gtmService.setDashboardAsSource();
    this.isSmall = false;
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(
        map(({ matches }) => matches),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((isSmall) => {
        this.isSmall = isSmall;
      });
  }

  public displayCreation(): void {
    this.dialog.open(AlCreationComponent, {});
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
