import { WorkOrder } from '@al/entities';
import { WorkOrdersQuery } from '@al/state';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { WorkLog } from 'projects/al-entities/src/lib/dto/get/work-log.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlWorkOrderWorkLogListService } from './al-work-order-work-log-list.service';

@Component({
  selector: 'al-work-order-work-log',
  templateUrl: './al-work-order-work-log.component.html',
  styleUrls: ['./al-work-order-work-log.component.scss'],
})
export class AlWorkOrderWorkLogComponent implements OnInit {
  public dataSource = new MatTableDataSource<WorkLog>([]);

  public isLoading: boolean;

  public displayedColumns = [
    'createdBy',
    'createDate',
    'type',
    'descriptionLong',
  ];
  private ngUnsubscribe = new Subject();

  public typeLogList = [
    'APPTNOTE',
    'CLIENTNOTE',
    'UPDATE',
    'REPLACEMENT',
    'WORK',
  ];

  public workLogFormGroup = new FormGroup({});

  constructor(
    private alWorkOrderWorkLogListService: AlWorkOrderWorkLogListService,
    public matDialogRef: MatDialogRef<AlWorkOrderWorkLogComponent>
  ) {
    this.isLoading = true;
  }

  public ngOnInit(): void {
    this.initFormGroup();
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public saveWorkLog(): void {
    if (this.workLogFormGroup.valid) {
      const newWorkLog = new WorkLog();
      const userId = localStorage.getItem('userId');
      newWorkLog.createdBy = userId ? userId : '';
      newWorkLog.createDate = new Date();
      newWorkLog.description = this.workLogFormGroup.get('description')?.value;
      newWorkLog.descriptionLong =
        this.workLogFormGroup.get('descriptionLong')?.value;
      newWorkLog.logType = this.workLogFormGroup.get('logType')?.value;
      newWorkLog.classWorkLog = 'WORKORDER';
      this.alWorkOrderWorkLogListService.addWorkLogToWorkOrder(newWorkLog);
      this.workLogFormGroup.reset();
      this.closeDialog();
    }
  }

  private initFormGroup(): void {
    this.workLogFormGroup = new FormGroup({
      logType: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      descriptionLong: new FormControl(),
    });
  }
}
