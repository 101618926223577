import { AlEnvironmentService } from '@al/environment';
import { Injectable } from '@angular/core';
import { FileInfo, MIME_TYPE_FOLDER } from '../../models/file-info';

declare let UploaderForGoogleDrive: any;

@Injectable({
  providedIn: 'root',
})
export class FileRepository {
  public constructor(private alEnvironmentService: AlEnvironmentService) {}

  public async create(parentId: string, folderName: string): Promise<any> {
    const folder = {
      name: folderName,
      mimeType: MIME_TYPE_FOLDER,
      parents: [parentId],
    };
    const res = await gapi.client.drive.files.create({
      resource: folder,
      fields: 'id, name, mimeType, modifiedTime, size',
    });
    return FileInfo.fromGoogleFile(res.result);
  }

  public delete(fileId: string): gapi.client.Request<void> {
    return gapi.client.drive.files.delete({
      fileId,
    });
  }

  public getFiles(folderId: string): Promise<any[]> {
    return gapi.client.drive.files
      .list({
        pageSize: 100,
        fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, size)',
        q: `'${folderId}' in parents and trashed = false`,
      })
      .then((res) => {
        const files: FileInfo[] = [];
        if (res && res.result && res.result.files) {
          res.result.files.forEach((file) =>
            files.push(FileInfo.fromGoogleFile(file))
          );
        }
        return files;
      });
  }

  public importFile(
    parentId: string,
    file: FileInfo,
    blob: Blob,
    onError: any,
    onComplete: any,
    onProgress: any
  ): void {
    const contentType = blob.type || 'application/octet-stream';

    const metadata = {
      name: file.Name,
      mimeType: contentType,
      driveId: this.alEnvironmentService.env.googleDrive,
      parents: parentId ? [parentId] : [],
    };

    if (
      gapi &&
      gapi.auth2 &&
      gapi.auth2.getAuthInstance() &&
      gapi.auth2.getAuthInstance().currentUser &&
      gapi.auth2.getAuthInstance().currentUser.get() &&
      gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse() &&
      gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse()
        .access_token
    ) {
      const token = gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse().access_token;

      const uploader = new UploaderForGoogleDrive({
        file: blob,
        token,
        metadata,
        onError,
        onComplete,
        onProgress,
        params: {
          supportsAllDrives: true,
          supportsTeamDrives: true,
          convert: false,
          ocr: false,
        },
      });

      uploader.upload();
    }
  }
}
