import { ServiceRequestPut, ServiceRequestPutResponse } from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class AbstractSrHistoryService extends AbstractHistoryService<
  ServiceRequestPut,
  ServiceRequestPutResponse
> {}
