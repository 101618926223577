import { JsonProperty } from 'json2typescript';
import { Code } from './code.model';

/**
 * CodeAndDescription with generic typed Code
 */

export abstract class CodeAndLabel<T> extends Code<T> {
  @JsonProperty('label', String, true)
  public label = '';
}
