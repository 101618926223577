import { EnvironmentOptions } from '@al/environment';

export const environment: EnvironmentOptions = {
  production: false,
  development: false,
  title: 'e-Maintenance (master)',

  apiUrl: 'http://localhost:3100/',
  apigwUrl: 'https://jzvmpbnql1.execute-api.eu-west-1.amazonaws.com/master/',

  acgUserPoolDomain:
    'eapps-auth-develop-users.auth.eu-west-1.amazoncognito.com',
  acgUserPoolLogoutEndpoint: '/logout',
  acgUserPoolClientId: '6jf300epbltlthl79jpc9nv3ol',
  acgUserPoolId: 'eu-west-1_9HRItXMMX',
  acgUserPoolClientCallback: '/callback',
  // # GTM
  gtmContainerId: 'GTM-WRLM727',

  cacheDelayAsset: 900000,
  cacheDelayWorkOrder: 0,

  canAddFile: true,
  canAddPhoto: true,

  googleClientId:
    '53352395356-h4g53aelc6qou8aki9787v1khjh8ii6f.apps.googleusercontent.com',
  googleDrive: '0AN8IT5ZnaWzzUk9PVA',
  googleDriveFolderAutomaticMapping: false,
  googleDriveFolderMap: {
    'LI-FR-BELL': '1x8LUXaWGg4XO5G0jSnvFErZ5mih6ths2',
    'LI-FR-FOSM': '1Ebu8Nwq-o0bkECyzeCgUx-3fOlNP9bcq',
    'LI-FR-SAND': '1q7MsJNr0fNV_MtYtFdc1yL3C4WAGxYA3',
  },
};
