import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';
import { AlNavigationButtonComponent } from './al-navigation-button.component';
import { AlNavigationLocationComponent } from './al-navigation-location.component';
import { AlNavigationComponent } from './al-navigation.component';

@NgModule({
  declarations: [
    AlNavigationComponent,
    AlNavigationButtonComponent,
    AlNavigationLocationComponent,
  ],
  imports: [CommonModule, MatIconModule, TranslateModule],
  exports: [
    AlNavigationComponent,
    AlNavigationButtonComponent,
    AlNavigationLocationComponent,
  ],
})
export class AlNavigationModule {}
