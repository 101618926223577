import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GtmPut } from '@al/entities';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GtmRestService {
  public constructor(private gtmService: GoogleTagManagerService) {}

  public push(tag: GtmPut): Promise<void> {
    const data = {
      SITE_ID: tag.siteId,
      LOCATION: tag.location,
      ASSET: tag.asset,
      PRIORITY: tag.priority,
      ONLINE: tag.onLine,
      SENT_AFTER_SYNC_ERR_CORRECTION: tag.sendAfterNbCorrection,
      NB_MAXIMO_ATTEMPTS: tag.nbMaximoAttempts,
      NB_LINKS_ATTACHED: tag.nbLinkAttached,
      EMV2_SOURCE: tag.EMV2Source,
      NUMBER: tag.number,
      WITH_FAILURE_REPORT: tag.withFailureReport,
      WITH_WO_CLOSURE: tag.withWoClosure,
      WORKTYPE_CODE: tag.workType,
      JOBTYPE_CODE: tag.jobType,
    };
    const gtmTag = {
      event: tag.event,
      data,
    };
    return this.gtmService.pushTag(gtmTag);
  }

  protected getClazz(): typeof GtmPut {
    return GtmPut;
  }
}
