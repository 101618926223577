import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlWorkOrderListModule } from '@al/work-order-list';
import { AlAttachmentsModule } from '@al/attachments';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { AlEquipmentDetailsComponent } from './al-equipment-details.component';
import { AlEquipmentComponent } from './al-equipment.component';

@NgModule({
  declarations: [AlEquipmentComponent, AlEquipmentDetailsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    AlWorkOrderListModule,
    AlAttachmentsModule,
    MatTabsModule,
    TranslateModule,
    MatButtonModule,
  ],
  exports: [AlEquipmentComponent],
})
export class AlEquipmentModule {}
