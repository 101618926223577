import { Equipment, ProcessGroup, ProductionUnit } from '@al/entities';

export class DynamicFlatNode {
  public constructor(
    public item: Equipment | ProductionUnit | ProcessGroup,
    public level = 1,
    public expandable = false,
    public isLoading = false
  ) {}
}
