import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs';

import { AlOnlineService } from '@al/online';
import { AlSyncHelperService } from '@al/sync-helper';
import { filter, takeUntil } from 'rxjs/operators';
import { AlSyncService } from '@al/sync';
import { SyncInfoService } from '@al/sync-services';

@Component({
  selector: 'al-maximo-synchronization',
  templateUrl: './al-maximo-synchronization.component.html',
  styleUrls: ['./al-maximo-synchronization.component.scss'],
})
export class AlMaximoSynchronizationComponent implements OnInit, OnDestroy {
  public displaySync = true;

  public isSynchroValid!: boolean;

  private currentUrl: string;

  private isOnline!: boolean;

  private ngUnsubscribe = new Subject();

  public get isDisabled(): boolean {
    const regexp = /^\/(synchrosummary)/;
    return !this.isOnline || regexp.test(this.currentUrl);
  }

  public get itemsLength(): number {
    return this.synchroHelperService.getSynchroElementCount();
  }

  public get minutes(): number {
    return this.itemsLength ? 0 : this.synchroHelperService.getTime();
  }

  public constructor(
    private alSyncService: AlSyncService,
    private onLineService: AlOnlineService,
    private router: Router,
    private synchroHelperService: AlSyncHelperService,
    private synchroInfoService: SyncInfoService
  ) {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: any) => {
        this.currentUrl = event.urlAfterRedirects;
      });
  }

  public gotToSynchroSummary(): void {
    if (this.displaySync) {
      this.router.navigate(['/', 'synchrosummary']);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.shouldDisplay(this.router.url);
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        this.shouldDisplay(val.urlAfterRedirects);
      }
    });

    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: boolean) => {
        this.isOnline = res;
        if (res === true && this.minutes >= 60) {
          this.alSyncService.sync();
        }
      });

    this.synchroInfoService
      .getErrorsCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: number) => {
        this.isSynchroValid = count === 0;
      });
  }

  public refresh(): void {
    this.alSyncService.sync();
  }

  private shouldDisplay(url: string): void {
    if (
      url === '/create-bt' ||
      url === '/create-sr' ||
      url === '/quick-report'
    ) {
      this.displaySync = false;
    } else {
      this.displaySync = true;
    }
  }
}
