import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { WorkOrder } from '@al/entities';
import { WorkOrdersQuery } from '@al/state';

@Component({
  selector: 'al-work-order-description',
  templateUrl: 'al-work-order-description.component.html',
  styleUrls: ['al-work-order-description.component.scss'],
})
export class AlWorkOrderDescriptionComponent implements OnDestroy {
  public displayDesc = true;

  public displaySeeMore = true;

  public firstString = '';

  public seeMore: boolean;

  public seeMoreString = '';

  public workOrder!: WorkOrder;

  private htmlText = document.createElement('textarea');

  private ngUnsubscribe = new Subject();

  public constructor(private workOrdersQuery: WorkOrdersQuery) {
    this.seeMore = false;
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;

          if (this.workOrder.descriptionLong !== null) {
            this.displayDesc = true;
            this.htmlText.innerHTML = this.workOrder.descriptionLong;

            this.firstString = '';
            this.seeMoreString = '';

            let divFirst = true;
            const divCounter = this.htmlText.value.indexOf('</div>');
            const breakCounter = this.htmlText.value.indexOf('<br />');
            if (breakCounter !== -1) {
              if (divCounter !== -1 && divCounter > breakCounter) {
                divFirst = false;
              }
            }

            if (divFirst) {
              this.setStrings('</div>');
            } else {
              this.setStrings('<br />');
            }
          } else {
            this.seeMore = false;
            this.displayDesc = false;
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public seeMoreSwitch(): void {
    this.seeMore = !this.seeMore;
  }

  public get getWorkOrderAssetDescription(): string {
    return this.workOrder && this.workOrder.description
      ? this.workOrder.description
      : '';
  }

  public get getWorkOrderSeeMoreString(): string {
    return this.workOrder && this.workOrder.descriptionLong
      ? this.htmlText.value
      : '';
  }

  private setStrings(type: string): void {
    const regex = /(?<=<!--).*(?=-->)/g;
    let displayableString = this.htmlText.value.replace(regex, '');
    displayableString = displayableString.replace('<!--', '');
    displayableString = displayableString.replace('-->', '');
    displayableString = displayableString.trim();
    const ogString = displayableString.split(type);
    if (ogString.length <= 1) {
      this.seeMore = false;
      this.displaySeeMore = false;
    } else {
      this.displaySeeMore = true;
    }
    const i = 0;
    this.firstString = `${ogString[i]}${type}`;
    ogString.splice(0, 1);
    const filtered = ogString.filter(Boolean);
    if (filtered.length > 0) {
      this.displaySeeMore = true;
      filtered.forEach((str: string) => {
        if (type === '</div>') {
          if (str.match(/<div>/g)) {
            this.seeMoreString = `${this.seeMoreString}${str}</div>`;
          } else {
            this.seeMoreString = `${this.seeMoreString}${str}`;
          }
        }
        if (type === '<br />') {
          this.seeMoreString = `${this.seeMoreString}${str}<br />`;
        }
      });
    } else {
      this.displaySeeMore = false;
    }
  }
}
