import { DateSelectorItem, LocalStorageConfiguration } from '@al/entities';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';

import { DateSelectorStore } from './date-selector.store';
import { DateSelectorQuery } from './date-selector.query';

@Injectable({ providedIn: 'root' })
export class DateSelectorService {
  public constructor(
    private store: DateSelectorStore,
    private query: DateSelectorQuery
  ) {}

  public getActive(): DateSelectorItem | undefined {
    return this.query.getActive();
  }

  public populate(): void {
    this.store.upsertMany([
      { uuid: '0-1-2-3', label: 'SEMAINE' },
      { uuid: '1-2-3-4', label: 'MOIS' },
      { uuid: '2-3-4-5', label: 'MOIS_SUIVANT' },
    ]);
  }

  public setActive(key: UUID): void {
    this.store.setActive(key);
    localStorage.setItem(LocalStorageConfiguration.FILTER_DATE, key.toString());
  }

  public setActiveFromLocalStorage(): void {
    const item = localStorage.getItem(LocalStorageConfiguration.FILTER_DATE);
    if (item) {
      this.setActive(item);
    } else {
      this.setActive('0-1-2-3');
    }
  }
}
