import { UUID } from 'angular2-uuid';
import { PutResponse } from '@al/entities';
import { HistoryCache } from './history.cache';
import { HistoryElement } from './history.element';

export abstract class AbstractHistoryService<P, R extends PutResponse> {
  protected cache: HistoryCache<P, R>;

  protected constructor(private cacheName: string) {
    this.cache = new HistoryCache<P, R>(this.cacheName);
  }

  public get(uuid: UUID): HistoryElement<P, R> | null {
    return this.cache.get(uuid);
  }

  public put(synchroResponse: HistoryElement<P, R>): void {
    this.cache.put({ ...synchroResponse, uuid: UUID.UUID() });
  }

  // public remove(synchroResponse: HistoryElement<P, R>): void {
  //   this.cache.removeByUuid(synchroResponse.uuid);
  // }

  public removeByUuid(uuid: UUID): void {
    this.cache.removeByUuid(uuid);
  }

  public values(): HistoryElement<P, R>[] {
    return this.cache.values();
  }
}
