/*
 * Public API Surface of al-state
 */

export * from './lib/al-state.module';

/**
 * # STATES
 * - query
 * - service
 * - store
 */
// TODO: Pourquoi pas de stores associés aux states suivants : attachments, equipments, tasks-lists.
export * from './lib/attachments.query';
export * from './lib/attachments.service';
export * from './lib/date-selector.query';
export * from './lib/date-selector.service';
export * from './lib/date-selector.store';
export * from './lib/equipments.query';
export * from './lib/equipments.service';
export * from './lib/equipments.store';
export * from './lib/failure-report.service';
export * from './lib/process-group.query';
export * from './lib/process-group.service';
export * from './lib/process-group.store';
export * from './lib/production-unit.query';
export * from './lib/production-unit.service';
export * from './lib/production-unit.store';
export * from './lib/site.query';
export * from './lib/site.service';
export * from './lib/site.store';
export * from './lib/tasks.query';
export * from './lib/tasks.store';
export * from './lib/tasks-lists.query';
export * from './lib/tasks-lists.service';
export * from './lib/work-orders.query';
export * from './lib/work-orders.service';
export * from './lib/work-orders.store';

// TODO: VERIFIER SI CES ELEMENTS SONT AU BON ENDROIT
export * from './lib/service-request.service';
export * from './lib/quick-report.service';
