import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Credentials } from '../credentials';
import { SessionService } from '../state/session.service';

@Component({
  selector: 'al-session-login',
  templateUrl: './al-session-login.component.html',
  styleUrls: ['./al-session-login.component.scss'],
})
export class AlSessionLoginComponent implements OnDestroy {
  public form: FormGroup;

  private ngUnsubscribe = new Subject();

  public constructor(
    private fb: FormBuilder,
    private router: Router,
    private sessionService: SessionService
  ) {
    this.form = this.fb.group({
      login: this.fb.control('', Validators.required),
      password: this.fb.control('', Validators.required),
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public submit(): void {
    this.sessionService
      .login(this.form.value as Credentials)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }
}
