import { Injectable } from '@angular/core';
import { LocalStorageKey, TaskPut, TaskPutResponse } from '@al/entities';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class TaskCreateHistoryService extends AbstractHistoryService<
  TaskPut,
  TaskPutResponse
> {
  public constructor() {
    super(LocalStorageKey.TASK_CREATE_REQUEST_HISTORY);
  }
}
