import { UUID } from 'angular2-uuid';
import { HasUuid } from '@al/entities';
import { AlJsonConvert } from '@al/json';
import { LocalStorageManager } from './local-storage.manager';

export abstract class LocalCache<T extends HasUuid> {
  protected cache: T[];

  protected storageManager: LocalStorageManager<T>;

  private alJsonConvert: AlJsonConvert;

  public constructor(private cacheName: string) {
    this.alJsonConvert = new AlJsonConvert();

    this.storageManager = new LocalStorageManager<T>(this.cacheName);
    this.cache = this.alJsonConvert.deserializeArray(
      this.storageManager.load(),
      this.getClazz()
    );
  }

  public clear(): void {
    this.cache = [];
    this.storageManager.store(this.cache, this.getClazz());
  }

  public get(uuid: UUID): T | null {
    return this.cache.find((obj) => obj.uuid === uuid) || null;
  }

  public put(value: T): void {
    // previent la perte d'un uuid
    // uuid set uniquement si vide
    if (value.uuid === '') {
      this.cache.push({ ...value, uuid: UUID.UUID() });
    } else {
      // on enleve l'ancienne
      this.removeByUuid(value.uuid);
      // on remet la nouvelle
      this.cache.push({ ...value });
    }
    this.storageManager.store(this.cache, this.getClazz());
  }

  public reload(): void {
    this.cache = this.alJsonConvert.deserializeArray(
      this.storageManager.load(),
      this.getClazz()
    );
  }

  public remove(value: T): void {
    this.cache = this.cache.filter((obj) => obj.uuid !== value.uuid);
    this.storageManager.store(this.cache, this.getClazz());
  }

  public removeByUuid(uuid: UUID): void {
    this.cache = this.cache.filter((obj) => obj.uuid !== uuid);
    this.storageManager.store(this.cache, this.getClazz());
  }

  public values(): T[] {
    return this.cache;
  }

  protected abstract getClazz(): any;
}
