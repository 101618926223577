import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { AuthConfig } from '@eur/reset-password';
import { environment } from '../../../app/src/environments/environment';

@Component({
  selector: 'al-account-reset-password',
  templateUrl: 'al-account-reset-password-dialog.component.html',
})
export class AlAccountResetPasswordDialogComponent {
  public closeDialogCallback = this.closeDialog.bind(this);

  public cognitoConfig: AuthConfig = new AuthConfig({
    userPoolId: environment.acgUserPoolId,
    userPoolWebClientId: environment.acgUserPoolClientId,
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    public dialogRef: MatDialogRef<AlAccountResetPasswordDialogComponent>
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
