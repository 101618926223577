import {
  FailureReportPutResponse,
  FailureReportRequestPut,
  LocalStorageKey,
} from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class FailureReportCreateHistoryService extends AbstractHistoryService<
  FailureReportRequestPut,
  FailureReportPutResponse
> {
  public constructor() {
    super(LocalStorageKey.FAILURE_REPORT_CREATE_REQUEST_HISTORY);
  }

  public clearWorkOrderFromHistory(woNum: string): void {
    this.values()
      .filter((value) => value.response.workOrderNum === woNum)
      .forEach((value) => {
        this.removeByUuid(value.uuid);
      });

    this.values()
      .filter((value) => value.request.number === woNum)
      .forEach((value) => {
        this.removeByUuid(value.uuid);
      });
  }
}
