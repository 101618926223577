import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DateSelectorState, DateSelectorStore } from './date-selector.store';

@Injectable({ providedIn: 'root' })
export class DateSelectorQuery extends QueryEntity<DateSelectorState> {
  public sites = this.selectAll();

  public constructor(protected store: DateSelectorStore) {
    super(store);
  }
}
