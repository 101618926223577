import { Component, Input } from '@angular/core';
import { AlRequestInformationDialogDataWoComp } from '../model';
import { AlRequestInformationDialogData } from '../model/al-request-information-dialog-data';

@Component({
  selector: 'al-wo-comp-information-dialog',
  templateUrl: './al-wo-comp-information-dialog.component.html',
  styleUrls: [
    './al-common-information-dialog.component.scss',
    './al-wo-comp-information-dialog.component.scss',
  ],
})
export class AlWoCompInformationDialogComponent {
  @Input()
  public data!: AlRequestInformationDialogData<AlRequestInformationDialogDataWoComp>;
}
