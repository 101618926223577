import { WorkOrdersQuery } from '@al/state';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'al-file-renaming',
  templateUrl: './file-renaming.component.html',
  styleUrls: ['./file-renaming.component.scss'],
})
export class FileRenamingComponent implements OnInit {
  public extension: string | null;

  public form: FormGroup;

  public newFileName: string;

  public prefix: string | null;

  public constructor(
    public dialogRef: MatDialogRef<FileRenamingComponent>,
    private workOrdersQuery: WorkOrdersQuery,
    @Inject(MAT_DIALOG_DATA) public originalFileName: string
  ) {
    this.prefix = null;
    this.extension = null;
    this.newFileName = '';
    this.form = new FormGroup({
      newFileName: new FormControl(this.newFileName, [Validators.required]),
    });
  }

  public cancelUpload(): void {
    this.dialogRef.close();
  }

  public ngOnInit(): void {
    const fileNameArray = this.originalFileName.split('.');
    if (fileNameArray.length > 1) {
      this.extension = fileNameArray[fileNameArray.length - 1];
      fileNameArray.pop();
      this.newFileName = fileNameArray.join('.');
    } else {
      this.newFileName = this.originalFileName;
      this.extension = null;
    }
    const activeWorkOrder = this.workOrdersQuery.getActive();
    if (activeWorkOrder) {
      this.form
        .get('newFileName')
        ?.setValue(`${activeWorkOrder.number}_${this.newFileName}`);
    } else {
      this.form.get('newFileName')?.setValue(this.newFileName);
    }
  }

  public submitUpload(): void {
    const fileName = this.extension
      ? `${this.form.get('newFileName')?.value}.${this.extension}`
      : this.form.get('newFileName')?.value;
    this.dialogRef.close(fileName);
  }
}
