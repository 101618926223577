import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AttachmentCategory, Equipment } from '@al/entities';
import { EquipmentsQuery, ProcessGroupsQuery } from '@al/state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'al-equipment',
  templateUrl: 'al-equipment.component.html',
  styleUrls: ['al-equipment.component.scss'],
})
export class AlEquipmentComponent implements OnInit, OnDestroy {
  @Input()
  public uuid!: string;

  @Input()
  public workOrderUuid!: string;

  public category = AttachmentCategory.equipment;

  public equipment!: Equipment;

  public location: string | null | undefined;

  private ngUnsubscribe = new Subject();

  public constructor(
    private equipmentsQuery: EquipmentsQuery,
    private processGroupQuery: ProcessGroupsQuery,
    public dialogRef: MatDialogRef<AlEquipmentComponent>
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.equipmentsQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: Equipment | undefined) => {
        if (res) {
          this.equipment = res;
        }
      });

    this.location = this.processGroupQuery.getEntity(
      this.equipment.processGroupId
    )?.name;
  }
}
