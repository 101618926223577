import { JsonObject, JsonProperty } from 'json2typescript';
import { Get } from './get.model';

/**
 * This class represents ProductionUnit model
 */
@JsonObject('ProductionUnit')
export class ProductionUnit extends Get {
  // Id maximo? en plus de l'uuid qui sera notre clef technique?
  @JsonProperty('id', String, true)
  public id = '';

  @JsonProperty('name', String, true)
  public name: string | null = null;

  @JsonProperty('type', String, true)
  public type: string | null = null;
}
