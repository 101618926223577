<div class="dialog-content-information">
  <ng-container [ngSwitch]="data.information.status">
    <ng-container *ngSwitchCase="'OK'">
      <div *ngIf="data.information.compOrClosed === 'comp'">
        <span translate>Your WO has been completed</span>
      </div>
      <div *ngIf="data.information.compOrClosed === 'close'">
        <span translate>Your WO has been closed</span>
      </div>
      <div>
        <label translate> woNumber </label>
        <span>{{ data.information.workOrderNum }}</span>
      </div>
      <div>
        <label translate> site </label>
        <span>{{ data.information.siteId }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'NOT_OK'">
      <div>
        <span translate>woUpdateFailure</span>
      </div>
      <div>
        <span>{{ data.information.error }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
