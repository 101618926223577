import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';

import { Equipment } from '@al/entities';

export interface EquipmentState extends EntityState<Equipment>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'equipments', idKey: 'number', resettable: true })
export class EquipmentsStore extends EntityStore<EquipmentState> {
  public constructor() {
    super();
  }
}
