import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';

import { TranslateModule } from '@ngx-translate/core';
import { AlPipeModule } from '@al/pipe';

import { AlWorkOrderChildrenListComponent } from './al-work-order-children-list.component';

@NgModule({
  declarations: [AlWorkOrderChildrenListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    AlPipeModule,
  ],
  exports: [AlWorkOrderChildrenListComponent],
})
export class AlWorkOrderChildrenListModule {}
