<!--/*******************************************************************
#*  Copyright Air Liquide S.A.
#*  2020
#******************************************************************/ -->
<div (window:resize)="onResize()" (touchcancel)="onTouchCancel()" (touchmove)="onTouchMove($event)"
  (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd()" (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp()" [ngClass]="{ 'on-slide': onSlide }" class="card-slider">
  <div class="card-slider-wrapper">
    <ng-content></ng-content>
  </div>
  <button #arrowLeft class="arrow-left" (click)="slideLeft()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button #arrowRight class="arrow-right" (click)="slideRight()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
