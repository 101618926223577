import { JsonObject, JsonProperty } from 'json2typescript';
import { Attachment } from './attachment.model';
import { Get } from './get.model';

/**
 * This class represents Work Order model
 */
@JsonObject('ServiceRequest')
export class ServiceRequest extends Get {
  @JsonProperty('affectedPersonId', String, true)
  public affectedPersonId = '';

  @JsonProperty('assetNum', String, true)
  public assetNum = '';

  @JsonProperty('assetSiteId', String, true)
  public assetSiteId: string | null = null;

  @JsonProperty('attachments', [Attachment], true)
  public attachments: Attachment[] = [];

  @JsonProperty('classStructureId', String, true)
  public classStructureId: string | null = null;

  @JsonProperty('description', String, true)
  public description = '';

  @JsonProperty('descriptionLong', String, true)
  public descriptionLong = '';

  @JsonProperty('hierarchyPath', String, true)
  public hierarchyPath = '';

  @JsonProperty('lead', String, true)
  public lead: string | null = null;

  @JsonProperty('location', String, true)
  public location = '';

  @JsonProperty('relatedRecords', [String], true)
  public relatedRecords: string | null = null;

  @JsonProperty('reportedById', String, true)
  public reportedById: string | null = null;

  @JsonProperty('reportedDate', Date, true)
  public reportedDate: Date | null = null;

  @JsonProperty('reportedPriority', String, true)
  public reportedPriority = '';

  @JsonProperty('sendersysId', String, true)
  public sendersysId: string | null = null;

  @JsonProperty('siteId', String, true)
  public siteId = '';

  @JsonProperty('source', String, true)
  public source: string | null = null;

  @JsonProperty('supervisor', String, true)
  public supervisor: string | null = null;
}
