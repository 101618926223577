import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { TranslateModule } from '@ngx-translate/core';

import { AlDialogModule } from '@al/dialog';
import { AlNavigationModule } from '@al/navigation';

import { SynchroSummaryComponent } from './synchro-summary.component';

@NgModule({
  declarations: [SynchroSummaryComponent],
  imports: [
    CommonModule,
    AlDialogModule,
    AlNavigationModule,
    MatButtonModule,
    MatTableModule,
    MatChipsModule,
    TranslateModule,
    MatIconModule,
  ],
})
export class SynchroSummaryModule {}
