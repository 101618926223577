import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { AlFileModule } from '@al/file';
import { AlAttachmentsComponent } from './al-attachments.component';
import { AlAttachmentItemComponent } from './al-attachment-item.component';

@NgModule({
  declarations: [AlAttachmentsComponent, AlAttachmentItemComponent],
  imports: [
    AlFileModule,
    CommonModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [AlAttachmentsComponent],
})
export class AlAttachmentsModule {}
