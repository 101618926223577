import {
  GtmPut,
  LocalStorageKey,
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse,
} from '@al/entities';
import { AlOnlineService } from '@al/online';
import { Injectable } from '@angular/core';
import { SubmitAndCloseRestService } from '@al/rest';
import {
  SubmitAndCloseCreateCache,
  SubmitAndCloseUpdateCache,
  SubmitAndCompleteCreateHistoryService,
  SubmitAndCompleteUpdateHistoryService,
} from '@al/cache';
import { AlIndexedDbService } from '@al/indexed-db';
import { AbstractSyncService } from './abstract-sync.service';
import { SyncInfoService } from './sync-info.service';
import { GtmSyncService } from './gtm-sync.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitAndCloseSyncService extends AbstractSyncService<
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse
> {
  private getWorkOrder: any;

  public constructor(
    protected restService: SubmitAndCloseRestService,
    protected createCache: SubmitAndCloseCreateCache,
    protected updateCache: SubmitAndCloseUpdateCache,
    protected createCachedHistoryService: SubmitAndCompleteCreateHistoryService,
    protected updateCachedHistoryService: SubmitAndCompleteUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  public setGetWorkOrderHandler(handler: any): void {
    this.getWorkOrder = handler;
  }

  protected fillGtmWithData(value: WorkOrderPut): void {
    // find if existing previous gtm
    const gtm = this.gtmSyncService.getGtmTag(value.uuid);

    this.getWorkOrder(value.number).subscribe((wo: WorkOrder) => {
      if (gtm) {
        gtm.uuid = value.uuid;
        gtm.siteId = wo.siteId;
        gtm.asset = wo.assetNum;
        gtm.location = wo.location;
        gtm.priority = wo.priority.replace(/^P/, '');
        gtm.workType = wo.type;
        gtm.onLine = this.onLineService.onLine();
        gtm.event = 'WO_SUBMISSION';
        gtm.EMV2Source = localStorage.getItem(LocalStorageKey.GTM_EMV2_SOURCE);
        gtm.withWoClosure = true;
        gtm.withFailureReport = !!wo.failureDate;
        this.gtmSyncService.saveGtmTag(gtm);
      }
    });
  }

  // on rajoute les erre
  protected fillGtmWithResponse(
    gtm: GtmPut,
    value: WorkOrderPutResponse
  ): void {
    const cachedGtm = this.gtmSyncService.getGtmTag(gtm.uuid);
    if (cachedGtm) {
      cachedGtm.number = value.workOrderNum;
      this.gtmSyncService.addGenericReponseInformation(cachedGtm, value);
    }
  }

  protected getNewResponse(): WorkOrderPutResponse {
    return new WorkOrderPutResponse();
  }
}
