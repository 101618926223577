import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { TasksListsState, TasksListsStore } from './tasks-list.store';

@Injectable({ providedIn: 'root' })
export class TasksListsQuery extends QueryEntity<TasksListsState> {
  public tasksLists = this.selectAll();

  public constructor(protected store: TasksListsStore) {
    super(store);
  }

  public getProducts(): Observable<any> {
    return this.selectAll();
  }
}
