<div class="al-date-selector">
  <div class="dropdown"
    [ngClass]="{ 'dropdown' : isOnline && isSynchronized && !synchronizing,
    'dropdown-disable' : !(isOnline && isSynchronized && !synchronizing) }">
    <mat-icon>date_range</mat-icon>
    <mat-form-field appearance="fill">
      <mat-select [disabled]="!(isOnline && isSynchronized && !synchronizing)" [(value)]="selectedItemUuid"
        (valueChange)="onValueChange($event)">
        <mat-option *ngFor="let item of items " [value]="item.uuid ">
          {{item.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
