<div class="al-create-sr">
  <form [formGroup]="form" (submit)="submitServiceRequest()">
    <div class="content">

      <div class="side">
        <mat-card>
          <div class="field">
            <mat-label class="required-field">{{ "Priority" | translate }}
            </mat-label>
            <mat-form-field appearance="outline">
              <mat-select type="text" placeholder="{{ 'work-priority' | translate }}" formControlName="srPriority"
                required>
                <mat-option *ngFor="let priority of references.taskPriority" [value]="priority.priority">
                  {{ priority.priority }}{{ priority.label ? ": " : "" }}
                  {{ priority.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label class="required-field">{{ "ReportedDate" | translate }}
            </mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="datetime-local" formControlName="reportedDate" required>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label>{{ "AffectedTo" | translate }} </mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="affectedTo">
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label>{{ "HierarchyPath" | translate }} </mat-label>
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput type="text" placeholder="{{ 'hierarchyPath' | translate }}"
                formControlName="hierarchyPath" [matAutocomplete]="autoInput">
              <mat-autocomplete #autoInput panelWidth="auto" [displayWith]="displayFn">
                <mat-option [value]="null">

                </mat-option>
                <mat-option *ngFor="let classStruct of filteredOptions | async" [value]="classStruct">
                  {{ classStruct.CLASSSTRUCTUREID }}{{ classStruct.label ? ": " : "" }}
                  {{ classStruct.label }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="field">
            <al-attachments [putRequest]="putRequest"></al-attachments>
          </div>
        </mat-card>
      </div>

      <div class="main">

        <mat-card *ngIf="synchroInfo && synchroInfo.status==='KO'">
          <div class="synchroInfo">
            <div class="error">
              <mat-icon class="error">notifications_active</mat-icon>
              <span>{{synchroInfo.message}}</span>
            </div>
          </div>
        </mat-card>

        <mat-card>
          <div class="field-group">
            <div class="field">
              <mat-label class="required-field">{{ "Location" | translate }} </mat-label>
              <mat-form-field appearance="outline">
                <button matPrefix mat-icon-button (click)="displayTree()" type="button">
                  <mat-icon>search</mat-icon>
                </button>
                <input matInput readonly type="text" placeholder="{{'Select location' | translate}}"
                  formControlName="location" (click)="displayTree()">
                <button *ngIf="locationObject" mat-button matSuffix mat-icon-button aria-label="Clear" type="button"
                  (click)="clearLocation()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label>{{ "Asset" | translate }} </mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="asset" readonly>
              </mat-form-field>
            </div>
          </div>

          <div class="field">
            <mat-label class="required-field">
              {{ "Summary" | translate }}
            </mat-label>
            <mat-form-field appearance="outline">
              <textarea matInput rows="5" cols="75" maxlength="DESCRIPTION_MAX_LENGTH" formControlName="summary"
                required></textarea>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label>
              {{ "Description" | translate }}
            </mat-label>
            <mat-form-field appearance="outline">
              <textarea matInput rows="5" cols="75" maxlength="DESCRIPTION_MAX_LENGTH"
                formControlName="details"></textarea>
            </mat-form-field>
          </div>

        </mat-card>
      </div>

    </div>

    <div class="actions">
      <button mat-raised-button color="primary" type="button" (click)="openDialogWithRef(cancelDialog)">
        {{ "cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
        {{ "submit" | translate }}
      </button>
    </div>
  </form>
</div>

<ng-template #cancelDialog>
  <al-confirm-dialog (confirm)='cancel()'>
    <div title>{{ "Cancelling Service Request creation" | translate }}</div>
    <div content>{{ "Are you sure you want to cancel? All unsaved progress will be lost" | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to cancel" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
