import { Component, Input, OnInit } from '@angular/core';
import { Attachment } from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';

@Component({
  selector: 'al-attachment-item',
  templateUrl: './al-attachment-item.component.html',
  styleUrls: ['./al-attachment-item.component.scss'],
})
export class AlAttachmentItemComponent implements OnInit {
  @Input()
  public attachment!: Attachment;

  @Input()
  public editable = true;

  public icon = 'picture_as_pdf';

  public get isDeletable(): boolean {
    if (this.attachment) {
      return !this.attachment.url || this.attachment.url.length === 0;
    }
    return false;
  }

  public get isLocal(): boolean {
    if (this.attachment) {
      return !this.attachment.url || this.attachment.url.length === 0;
    }
    return false;
  }

  public constructor(private alIndexedDbService: AlIndexedDbService) {}

  public delete(): void {
    this.alIndexedDbService.delete(this.attachment.uuid.toString());
  }

  public download(): void {
    if (this.isLocal) {
      this.alIndexedDbService.get(this.attachment.uuid.toString());
    } else {
      const a = document.createElement('a');
      a.href = this.attachment.url;
      a.download = this.attachment.filename;
      a.target = '_blank';
      a.click();
    }
  }

  public ngOnInit(): void {
    this.setIcon();
  }

  /**
   * add to be developped, extension and model for attachment not yet defined
   */
  private setIcon(): void {
    // switch (this.attachment.extension) {
    //   case 'application/pdf':
    //     this.icon = 'picture_as_pdf';
    //     break;
    //   case 'image/png':
    //     this.icon = 'image';
    //     break;
    //   default:
    //     break;
    // }
    if (
      this.attachment.url.indexOf('.png') > -1 ||
      this.attachment.url.indexOf('.jpg') > -1
    ) {
      this.icon = 'image';
    } else if (this.attachment.url.indexOf('.pdf') > -1) {
      this.icon = 'picture_as_pdf';
    } else if (this.attachment.url.indexOf('drive.google.com') > -1) {
      this.icon = 'cloud';
    } else {
      this.icon = 'attachment';
    }
  }
}
