<div class="al-quick-report">
  <form [formGroup]="form" (ngSubmit)="submitQuickReport()">
    <div class="content">

      <div class="side">
        <mat-card>
          <div class="field-group">
            <div class="field">
              <mat-label translate>WO Parent</mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="woParent" placeholder="{{'WO Parent'|translate}}">
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field" translate>Work Type</mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" formControlName="woType" placeholder="{{'Work Type'|translate}}" required>
                  <mat-option *ngFor="let type of references.workType" [value]="type.type">
                    {{ type.type }}
                    {{ type.label ? ": " : "" }}
                    {{ type.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{"Source"|translate}}</mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" formControlName="source" placeholder="{{'Source'|translate}}" required>
                  <mat-option *ngFor="let source of references.workOrderSource" [value]="source.code">
                    {{ source.display }}
                    {{ source.label ? ": " : "" }}
                    {{ source.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="field-group">
            <div class="field">
              <mat-label class="required-field">{{"Domain"|translate}}</mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" formControlName="domain" placeholder="{{ 'Domain' | translate }}" required>
                  <mat-option *ngFor="let domain of references.domain" [value]="domain.domain">
                    {{ domain.display }}
                    {{ domain.label ? ": " : "" }}
                    {{ domain.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{ "Job-type-option" | translate }}</mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" placeholder="{{ 'work-type' | translate }}" formControlName="jobTypeOption"
                  required>
                  <mat-option *ngFor="let jobtype of references.workOrderJobType" [value]="jobtype.type">
                    {{ jobtype.type }}
                    {{ jobtype.label ? ": " : "" }}
                    {{ jobtype.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label>{{ "Priority" | translate }} *</mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" placeholder="{{ 'work-priority' | translate }}" formControlName="priority">
                  <mat-option *ngFor="let priority of references.taskPriority" [value]="priority.priority">
                    {{ priority.priority }}
                    {{ priority.label ? ": " : "" }}
                    {{ priority.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="field">
            <mat-label class="required-field">{{ "Location" | translate }}</mat-label>
            <mat-form-field appearance="outline">
              <button matPrefix mat-icon-button (click)="displayTree()" type="button">
                <mat-icon>search</mat-icon>
              </button>
              <input matInput readonly type="text" placeholder="{{'Select location' | translate}}"
                formControlName="location" required>
              <button *ngIf="locationObject" mat-button matSuffix mat-icon-button aria-label="Clear" type="button"
                (click)="clearLocation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label>{{ "Asset" | translate }} </mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="asset" readonly>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label>{{ "Duration" | translate }}</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="number" formControlName="duration" placeholder="{{ 'duration' | translate }}">
            </mat-form-field>
          </div>

          <div class="field-group">
            <div class="field">
              <mat-label class="required-field">{{ "Date" | translate }}</mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="datetime-local" formControlName="actualStartDateTime"
                  placeholder="{{ 'actual-start' | translate }}" required (change)="checkDateValidators()">
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{ "Objective end date" | translate }}</mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="datetime-local" formControlName="actualFinishDateTime"
                  placeholder="{{ 'actual-finish' | translate }}" required (change)="checkDateValidators()">
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="actualStartDateTime?.invalid || actualFinishDateTime?.invalid" class="alert alert-danger">
            <mat-error *ngIf="actualStartDateTime?.errors?.wrongDate || actualFinishDateTime?.errors?.wrongDate">
              {{ "can't finish before start" | translate }}
            </mat-error>
          </div>

        </mat-card>
      </div>

      <div class="main">

        <mat-card *ngIf="synchroInfo && synchroInfo.status==='KO'">
          <div class="synchroInfo">
            <div class="error">
              <mat-icon class="error">notifications_active</mat-icon>
              <span>{{synchroInfo.message}}</span>
            </div>
          </div>
        </mat-card>

        <mat-card>
          <mat-card-title>{{ "quick-report" | translate }}</mat-card-title>
          <div class="field">
            <mat-label class="required-field">
              {{ "Description" | translate }}
            </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="2" cols="75" maxlength="DESCRIPTION_MAX_LENGTH" formControlName="description"
                required></textarea>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-label> {{ "Long-description" | translate }} </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="12" cols="75" maxlength="LONG_DESCRIPTION_MAX_LENGTH"
                        formControlName="descriptionLong"></textarea>
            </mat-form-field>
          </div>
        </mat-card>
        <mat-card>
          <mat-card-title>{{ "Failure Reporting" | translate }}</mat-card-title>
          <div class="field">
            <mat-label class="required-field">
              {{ "Remarks" | translate }}
            </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="2" cols="75" maxlength="150" formControlName="remarks" required></textarea>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-label> {{ "Long-description" | translate }} </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="12" cols="75" maxlength="LONG_DESCRIPTION_MAX_LENGTH"
                formControlName="longDescription"></textarea>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-checkbox formControlName="failureAcceptable">{{
              "isFailureAcceptable" | translate
              }}</mat-checkbox>
          </div>
        </mat-card>

        <mat-card>
          <al-attachments [putRequest]="putRequest"></al-attachments>
        </mat-card>

      </div>

    </div>

    <div class="actions">
      <button mat-raised-button color="primary" type="button" (click)="openDialogWithRef(cancelDialog)">
        {{ "cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
        {{ "submit" | translate }}
      </button>
    </div>

  </form>
</div>

<ng-template #cancelDialog>
  <al-confirm-dialog (confirm)='cancel()'>
    <div title>{{ "Cancelling Quick Report creation" | translate }}</div>
    <div content>{{ "Are you sure you want to cancel? All unsaved progress will be lost" | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to cancel" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
