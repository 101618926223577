import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AlConfirmDialogComponent } from './al-confirm-dialog.component';

@NgModule({
  declarations: [AlConfirmDialogComponent],
  imports: [MatDialogModule, MatButtonModule],
  exports: [AlConfirmDialogComponent],
})
export class AlDialogModule {}
