import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AlRequestInformationDialogComponent } from './al-request-information-dialog.component';
import { AlWoPutInformationDialogComponent } from './content/al-wo-put-information-dialog.component';
import { AlSrPutInformationDialogComponent } from './content/al-sr-put-information-dialog.component';
import { AlQrPutInformationDialogComponent } from './content/al-qr-put-information-dialog.component';
import { AlFrPutInformationDialogComponent } from './content/al-fr-put-information-dialog.component';
import { AlWoCompInformationDialogComponent } from './content/al-wo-comp-information-dialog.component';
import { AlWoWlPutInformationDialogComponent } from './content/al-wo-wl-put-information-dialog.component';

@NgModule({
  declarations: [
    AlRequestInformationDialogComponent,
    AlWoPutInformationDialogComponent,
    AlWoWlPutInformationDialogComponent,
    AlSrPutInformationDialogComponent,
    AlQrPutInformationDialogComponent,
    AlFrPutInformationDialogComponent,
    AlWoCompInformationDialogComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [AlRequestInformationDialogComponent],
})
export class AlRequestInformationDialogModule {}
