/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'al-card-slider',
  templateUrl: 'al-card-slider.component.html',
  styleUrls: ['al-card-slider.component.scss'],
})
export class AlCardSliderComponent implements OnInit, AfterViewInit, DoCheck {
  public isInit = false;

  public nbCard = 0;

  public onSlide = false;

  protected arrowActive = false;

  private anyCardElement!: HTMLElement;

  private arrowLeft!: HTMLButtonElement;

  private arrowRight!: HTMLButtonElement;

  private clientX!: number;

  private translate = 0;

  private wrapper!: HTMLDivElement;

  public constructor(protected elementRef: ElementRef) {}

  @HostListener('window:mousemove', ['$event'])
  public onMouseMove(e: any): void {
    this.moveSlide(e.clientX);
  }

  @HostListener('window:mouseup', ['$event'])
  public onMouseUp(): void {
    this.onSlide = false;
  }

  public init(): void {
    this.anyCardElement =
      this.elementRef.nativeElement.querySelector('.card-slider-item');
    const all =
      this.elementRef.nativeElement.querySelectorAll('.card-slider-item');
    if (all.length > this.nbCard) {
      this.initArrow();
      this.nbCard = all.length;
    }
  }

  public ngAfterViewInit(): void {
    this.init();
  }

  public ngDoCheck(): void {
    this.init();
  }

  public ngOnInit(): void {
    this.arrowRight =
      this.elementRef.nativeElement.querySelector('.arrow-right');
    this.arrowLeft = this.elementRef.nativeElement.querySelector('.arrow-left');
    this.wrapper = this.elementRef.nativeElement.querySelector(
      '.card-slider-wrapper'
    );
  }

  public onMouseDown(e: any): void {
    this.startSlide(e.clientX);
  }

  public onResize(): void {
    this.translate = 0;
    this.clientX = 0;
    this.wrapper.style.transform = `translateX(${this.translate}px)`;
    this.initArrow();
  }

  public onTouchCancel(): void {
    this.onSlide = false;
  }

  public onTouchEnd(): void {
    this.onSlide = false;
  }

  public onTouchMove(e: any): void {
    this.moveSlide(e.touches[0].clientX);
  }

  public onTouchStart(e: any): void {
    this.startSlide(e.touches[0].clientX);
  }

  public slideLeft(): void {
    this.moveSlide(this.clientX + 150, true);
  }

  public slideRight(): void {
    this.moveSlide(this.clientX - 150, true);
  }

  public startSlide(x: number): void {
    this.onSlide = true;
    this.clientX = x;
  }

  private initArrow(): void {
    this.arrowLeft.classList.remove('visible');
    this.arrowRight.classList.remove('visible');
    if (this.wrapper.scrollWidth - this.wrapper.offsetWidth > 0) {
      this.arrowActive = true;
      this.arrowRight.classList.add('visible');
    }
  }

  private moveSlide(x: number, force = false): void {
    if (this.onSlide || force) {
      const shift: number = this.clientX - x;
      const translateMax = this.wrapper.scrollWidth - this.wrapper.offsetWidth;
      if (this.arrowActive) {
        this.arrowRight.classList.add('visible');
        this.arrowLeft.classList.add('visible');
      }
      if (Math.abs(this.translate) + shift < 10) {
        this.arrowLeft.classList.remove('visible');
      }
      if (Math.abs(this.translate) + shift >= translateMax - 10) {
        this.arrowRight.classList.remove('visible');
      }
      if (Math.abs(this.translate) + shift >= translateMax) {
        this.translate = -translateMax;
        this.arrowRight.classList.remove('visible');
      } else if (Math.abs(this.translate) + shift < 0) {
        this.translate = 0;
        this.arrowLeft.classList.remove('visible');
      } else {
        this.translate -= shift;
      }
      this.wrapper.style.transform = `translateX(${this.translate}px)`;
      this.clientX = x;
    }
  }
}
