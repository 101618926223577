import { Injectable } from '@angular/core';
import { FileSession } from './file.session';

@Injectable({
  providedIn: 'root',
})
export class AppSession {
  public constructor(private fileSession: FileSession) {}

  public get File(): FileSession {
    return this.fileSession;
  }
}
