<div class="actions-container">
  <div class="conditionAC">
    <div *ngIf="currentSubMenu === 'Shape'">
      <div class="top">
        <label for="fill-color"
          ><input
            type="radio"
            id="fill-color"
            name="select-color-type"
            [value]="true"
            checked="checked"
            [(ngModel)]="shapeColorPart"
          />
          {{ "Fill" | translate }}
        </label>

        <label for="stroke-color">
          <input
            type="radio"
            id="stroke-color"
            name="select-color-type"
            [value]="false"
            [(ngModel)]="shapeColorPart"
          />
          {{ "Stroke" | translate }}
        </label>

        <label for="input-check-transparent">
          <input
            *ngIf="shapeColorPart"
            type="checkbox"
            (change)="transparent()"
            [checked]="isTransparent"
            id="input-check-transparent"
          />
          <input
            *ngIf="!shapeColorPart"
            type="checkbox"
            (change)="transparent()"
            [checked]="isTransparentStroke"
            id="input-check-transparent"
          />
          {{ "Transparent" | translate }}
        </label>
      </div>
    </div>

    <div *ngIf="currentSubMenu === 'Drawing'">
      <mat-form-field>
        <input
          matInput
          [ngxMatColorPicker]="picker"
          [formControl]="drawColorControl"
        />
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker [touchUi]="true" #picker></ngx-mat-color-picker>
      </mat-form-field>
    </div>

    <div
      *ngIf="shapeColorPart && currentSubMenu === 'Shape'"
    >
      <mat-form-field>
        <input
          matInput
          [ngxMatColorPicker]="picker"
          [formControl]="shapeColorControl"
        />
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker [touchUi]="true" #picker></ngx-mat-color-picker>
      </mat-form-field>
    </div>

    <div
      *ngIf="!shapeColorPart && currentSubMenu === 'Shape'"
    >
      <mat-form-field>
        <input
          matInput
          [ngxMatColorPicker]="picker"
          [formControl]="shapeColorControlStroke"
        />
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker [touchUi]="true" #picker></ngx-mat-color-picker>
      </mat-form-field>
    </div>

    <div *ngIf="currentSubMenu === 'Icon'">
      <mat-form-field>
        <input
          matInput
          [ngxMatColorPicker]="picker"
          [formControl]="iconColorControl"
        />
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker [touchUi]="true" #picker></ngx-mat-color-picker>
      </mat-form-field>
    </div>

    <div *ngIf="currentSubMenu === 'Text'">
      <mat-form-field>
        <input
          matInput
          [ngxMatColorPicker]="picker"
          [formControl]="textColorControl"
        />
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker [touchUi]="true" #picker></ngx-mat-color-picker>
      </mat-form-field>
    </div>

  </div>

  <div class="constantAC">

    <div class="row" *ngIf="currentSubMenu === 'Shape'">
      {{ "Stroke Size" | translate }}
      <mat-slider
        class="example-margin"
        [max]="50"
        [min]="10"
        [step]="10"
        [tickInterval]="1"
        [value]="shapeWidthControl"
        (change)="shapeWidthChange($event)"
      >
      </mat-slider>
    </div>

    <div class="row" *ngIf="currentSubMenu === 'Drawing'">
      {{ "Brush Size" | translate }}
      <mat-slider
        class="example-margin"
        [max]="50"
        [min]="10"
        [step]="10"
        [tickInterval]="1"
        [value]="drawWidthControl"
        (change)="drawWidthChange($event)"
      >
      </mat-slider>
    </div>

    <div class="row" *ngIf="currentSubMenu === 'Text'">
      {{ "Size" | translate }}
      <mat-slider
        class="example-margin"
        [max]="100"
        [min]="20"
        [step]="10"
        [tickInterval]="1"
        [value]="textWidthControl"
        (change)="textWidthChange($event)"
      >
      </mat-slider>
    </div>

  </div>

  <div class="constantAC">
    <button
      mat-button
      [disabled]="undoDisabled"
      class="mat-raised-button mat-button-base mat-primary disabled top-button"
      (click)="actionUndo()"
    >
      {{ "undo" | translate }}
    </button>
    <button
      mat-button
      [disabled]="redoDisabled"
      class="button disabled mat-raised-button mat-button-base mat-primary top-button"
      (click)="actionRedo()"
    >
    {{ "redo" | translate }}
    </button>
    <button
      mat-button
      class="button mat-raised-button mat-button-base mat-primary top-button"
      (click)="activeRemove()"
    >
      {{ "remove selected" | translate }}
    </button>
  </div>
</div>

<mat-dialog-content>
  <div class="al-photo-editor">
    <!-- Image editor area -->
    <div #tuiImageEditor class="perso-image-editor-canvas-container"></div>
    <!-- Image editor controls - bottom area -->
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="full-wide">
    <ul class="scrollable">
      <li class="menu-item">
        <button
          mat-button
          class="menu-button"
          *ngIf="!isSubMenu"
          (click)="isSubMenu = !isSubMenu; currentSubMenu = 'Crop'; startCrop()"
        >
          {{ "Crop" | translate }}
        </button>
        <div *ngIf="isSubMenu && currentSubMenu === 'Crop'">
          <button mat-button class="btn-prev" (click)="closeSub()">&lt;</button>
          <ul class="scrollable">
            <li class="menu-item">
              <button mat-button class="menu-button" (click)="stopCrop()">
                {{ "Apply" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="menu-item">
        <button
          mat-button
          class="menu-button"
          *ngIf="!isSubMenu"
          (click)="isSubMenu = !isSubMenu; currentSubMenu = 'Orientation'"
        >
          {{ "Orientation" | translate }}
        </button>
        <div *ngIf="isSubMenu && currentSubMenu === 'Orientation'">
          <button mat-button class="btn-prev" (click)="closeSub()">&lt;</button>
          <ul class="scrollable">
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="rotateImage(90)"
              >
                {{ "Rotate" | translate }} +90
              </button>
            </li>
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="rotateImage(-90)"
              >
                {{ "Rotate" | translate }} -90
              </button>
            </li>
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="rotateImageXY('X')"
              >
                {{ "FilpX" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="rotateImageXY('Y')"
              >
                {{ "FilpY" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="menu-item">
        <button
          mat-button
          class="menu-button"
          *ngIf="!isSubMenu"
          (click)="activateDrawing()"
        >
          {{ "Drawing" | translate }}
        </button>
        <div *ngIf="isSubMenu && currentSubMenu === 'Drawing'">
          <button mat-button class="btn-prev" (click)="closeSub()">&lt;</button>
          <ul class="scrollable">
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                [ngClass]="{'pink-button': freeDrawing}"
                (click)="startFreeDrawing()"
              >
                {{ "Free Drawing" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                [ngClass]="{'pink-button': lineDrawing}"
                (click)="startLineDrawing()"
              >
                {{ "Line Drawing" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="menu-item">
        <button
          mat-button
          class="menu-button"
          *ngIf="!isSubMenu"
          (click)="isSubMenu = !isSubMenu; currentSubMenu = 'Shape'"
        >
          {{ "Shape" | translate }}
        </button>
        <div *ngIf="isSubMenu && currentSubMenu === 'Shape'">
          <button mat-button class="btn-prev" (click)="closeSub()">&lt;</button>
          <ul class="scrollable">
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="addRectangle()"
              >
                {{ "Rectangle" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button mat-button class="submenu-button" (click)="addSquare()">
                {{ "Square" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button mat-button class="submenu-button" (click)="addEllipse()">
                {{ "Ellipse" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button mat-button class="submenu-button" (click)="addCircle()">
                {{ "Circle" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button mat-button class="submenu-button" (click)="addTriangle()">
                {{ "Triangle" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="menu-item">
        <button
          mat-button
          class="menu-button"
          *ngIf="!isSubMenu"
          (click)="isSubMenu = !isSubMenu; currentSubMenu = 'Icon'"
        >
          {{ "Icon" | translate }}
        </button>
        <div *ngIf="isSubMenu && currentSubMenu === 'Icon'">
          <button mat-button class="btn-prev" (click)="closeSub()">&lt;</button>
          <ul class="scrollable">
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="addIconArrow()"
              >
                {{ "Arrow Icon" | translate }}
              </button>
            </li>
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="addIconCancel()"
              >
                {{ "Cancel Icon" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="menu-item">
        <button
          mat-button
          class="menu-button"
          *ngIf="!isSubMenu"
          (click)="isSubMenu = !isSubMenu; currentSubMenu = 'Text'; addText()"
        >
          {{ "Text" | translate }}
        </button>
        <div *ngIf="isSubMenu && currentSubMenu === 'Text'">
          <button mat-button class="btn-prev" (click)="closeSub()">&lt;</button>
          <ul class="scrollable">
            <li class="menu-item">
              <button
                mat-button
                class="submenu-button"
                (click)="changeText('bold')"
              >
                <span class="bold">{{ "Bold" | translate }}</span>
              </button>
              <button
                mat-button
                class="submenu-button"
                (click)="changeText('italic')"
              >
                <span class="italic">{{ "Italic" | translate }}</span>
              </button>
              <button
                mat-button
                class="submenu-button"
                (click)="changeText('underline')"
              >
                <span class="underline">{{ "Underline" | translate }}</span>
              </button>
              <!-- </div> -->
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <p class="msg">
      {{ "Menu Scrolling" | translate }}
      <strong>{{ "Left" | translate }} ⇔ {{ "Right" | translate }}</strong>
    </p>
  </div>

  <div class="actions">
    <button
      mat-button
      mat-raised-button
      color="primary"
      type="button"
      (click)="cancel()"
    >
      {{ "cancel" | translate }}
    </button>
    <button
      mat-button
      mat-raised-button
      color="primary"
      type="button"
      (click)="submit()"
    >
      {{ "submit" | translate }}
    </button>
  </div>

  </mat-dialog-actions>
