import { ProductionUnit } from '@al/entities';
import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';

export interface ProductionUnitState
  extends EntityState<ProductionUnit>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'productionUnit', resettable: true })
export class ProductionUnitsStore extends EntityStore<ProductionUnitState> {
  public constructor() {
    super();
  }
}
