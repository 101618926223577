import { UUID } from 'angular2-uuid';
import { JsonObject, JsonProperty } from 'json2typescript';
/**
 * This class modelize uuid value throw child class implementation
 */
@JsonObject('HasUuid')
export abstract class HasUuid {
  @JsonProperty('uuid', String, true)
  public uuid: UUID = UUID.UUID();
}
