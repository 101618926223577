import { AlIndexedDbService } from '@al/indexed-db';
import { Color } from '@angular-material-components/color-picker';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const ImageEditor = require('tui-image-editor');

@Component({
  selector: 'al-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss'],
})
export class PhotoEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tuiImageEditor')
  public tuiImageEditorEl!: ElementRef;

  public currentSubMenu = '';

  public drawColorControl: FormControl = new FormControl(null);

  public drawStyleFree = true;

  public drawWidthControl = 10;

  public freeDrawing = false;

  public iconColorControl: FormControl = new FormControl(null);

  public imgHeight = 0;

  public imgWidth = 0;

  public isSubMenu = false;

  public isTransparent = false;

  public isTransparentStroke = false;

  public lineDrawing = false;

  public redoDisabled = true;

  public shapeColorControl: FormControl = new FormControl(null);

  public shapeColorControlStroke: FormControl = new FormControl(null);

  public shapeColorPart = false;

  public shapeWidthControl = 10;

  public textColorControl: FormControl = new FormControl(null);

  public textWidthControl = 50;

  public undoDisabled = true;

  private activeObjectId!: number;

  private imageEditorInstance: any;

  private ngUnsubscribe = new Subject();

  private options: any;

  public constructor(
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<PhotoEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public newFileName: string,
    private alIndexedDbService: AlIndexedDbService
  ) {
    this.options = {
      cssMaxWidth: 1925,
      cssMaxHeight: 950,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
      usageStatistics: false,
    };
  }

  public actionRedo(): void {
    this.imageEditorInstance.redo();
  }

  public actionUndo(): void {
    this.imageEditorInstance.undo();
  }

  public activateDrawing() {
    this.isSubMenu = !this.isSubMenu;
    this.currentSubMenu = 'Drawing';
    this.startFreeDrawing();
  }

  public activeRemove(): void {
    this.imageEditorInstance.removeObject(this.activeObjectId);
  }

  public addCircle(): void {
    let fill = 'transparent';
    let stroke = '#FA07D7';
    if (this.shapeColorControl.value) {
      fill = `#${this.shapeColorControl.value.hex}`;
    }
    if (this.shapeColorControlStroke.value) {
      stroke = `#${this.shapeColorControlStroke.value.hex}`;
    }
    this.imageEditorInstance.addShape('circle', {
      fill,
      stroke,
      strokeWidth: this.shapeWidthControl,
      rx: 200,
      ry: 200,
      isRegular: true,
    });
  }

  public addEllipse(): void {
    let fill = 'transparent';
    let stroke = '#FA07D7';
    if (this.shapeColorControl.value) {
      fill = `#${this.shapeColorControl.value.hex}`;
    }
    if (this.shapeColorControlStroke.value) {
      stroke = `#${this.shapeColorControlStroke.value.hex}`;
    }
    this.imageEditorInstance.addShape('circle', {
      fill,
      stroke,
      strokeWidth: this.shapeWidthControl,
      rx: 300,
      ry: 200,
    });
  }

  public addIconArrow() {
    let fill = '#FA07D7';
    if (this.iconColorControl.value) {
      fill = `#${this.iconColorControl.value.hex}`;
    }
    this.imageEditorInstance.addIcon('arrow', { fill });
  }

  public addIconCancel() {
    let fill = '#FA07D7';
    if (this.iconColorControl.value) {
      fill = `#${this.iconColorControl.value.hex}`;
    }
    this.imageEditorInstance.addIcon('cancel', { fill });
  }

  public addRectangle(): void {
    let fill = 'transparent';
    let stroke = '#FA07D7';
    if (this.shapeColorControl.value) {
      fill = `#${this.shapeColorControl.value.hex}`;
    }
    if (this.shapeColorControlStroke.value) {
      stroke = `#${this.shapeColorControlStroke.value.hex}`;
    }
    this.imageEditorInstance.addShape('rect', {
      fill,
      stroke,
      strokeWidth: this.shapeWidthControl,
      width: 500,
      height: 300,
    });
  }

  public addSquare(): void {
    let fill = 'transparent';
    let stroke = '#FA07D7';
    if (this.shapeColorControl.value) {
      fill = `#${this.shapeColorControl.value.hex}`;
    }
    if (this.shapeColorControlStroke.value) {
      stroke = `#${this.shapeColorControlStroke.value.hex}`;
    }
    this.imageEditorInstance.addShape('rect', {
      fill,
      stroke,
      strokeWidth: this.shapeWidthControl,
      width: 400,
      height: 400,
      isRegular: true,
    });
  }

  public addText() {
    this.imageEditorInstance.startDrawingMode('TEXT');
    const styleSettings = {
      fontSize: this.textWidthControl,
      fill: '#FA07D7',
    };
    if (this.textColorControl.value) {
      styleSettings.fill = `#${this.textColorControl.value.hex}`;
    }
    this.imageEditorInstance.addText('Double click to add text', {
      styles: styleSettings,
    });
  }

  public addTriangle(): void {
    let fill = 'transparent';
    let stroke = '#FA07D7';
    if (this.shapeColorControl.value) {
      fill = `#${this.shapeColorControl.value.hex}`;
    }
    if (this.shapeColorControlStroke.value) {
      stroke = `#${this.shapeColorControlStroke.value.hex}`;
    }
    this.imageEditorInstance.addShape('triangle', {
      fill,
      stroke,
      strokeWidth: this.shapeWidthControl,
      width: 500,
      height: 400,
      isRegular: true,
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public changeText(text: any): void {
    let styleObjKey = '';
    let styleObj = {};
    switch (text) {
      case 'bold':
        styleObjKey = 'fontWeight';
        break;
      case 'italic':
        styleObjKey = 'fontStyle';
        break;
      case 'underline':
        styleObjKey = 'underline';
        break;
      default:
        styleObjKey = '';
    }

    styleObj = { [styleObjKey]: text };

    this.imageEditorInstance.changeTextStyle(this.activeObjectId, styleObj);
  }

  public changeTextColor() {
    if (this.textColorControl.value) {
      this.imageEditorInstance.changeTextStyle(this.activeObjectId, {
        fill: `#${this.textColorControl.value.hex}`,
      });
    }
  }

  public closeSub(): void {
    this.lineDrawing = false;
    this.freeDrawing = false;
    this.imageEditorInstance.stopDrawingMode();
    this.isSubMenu = false;
    this.currentSubMenu = '';
  }

  public drawWidthChange(event: any) {
    this.drawWidthControl = event.value;

    if (this.drawStyleFree) {
      this.startFreeDrawing();
    } else {
      this.startLineDrawing();
    }
  }

  public iconChangeColor() {
    if (this.iconColorControl.value) {
      this.imageEditorInstance.changeIconColor(
        this.activeObjectId,
        `#${this.iconColorControl.value.hex}`
      );
    }
  }

  public isThisSubOpen(subName: string): boolean {
    return this.isSubMenu && this.currentSubMenu === subName;
  }

  public ngAfterViewInit(): void {
    this.alIndexedDbService
      .getByNewFileName(this.newFileName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          if (result) {
            this.getUrlFromByteArray(
              result.arrayBuffer,
              result.newFileName
            ).then((url: string) => {
              this.options.cssMaxWidth = this.imgWidth;
              this.options.cssMaxHeight = this.imgHeight;
              this.imageEditorInstance = new ImageEditor(
                this.tuiImageEditorEl.nativeElement,
                this.options
              );

              this.imageEditorInstance.loadImageFromURL(url, this.newFileName);
              this.shapeColorControl.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                  this.shapeChangeColor();
                });
              this.shapeColorControlStroke.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                  this.shapeChangeColor();
                });
              this.iconColorControl.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                  this.iconChangeColor();
                });
              this.textColorControl.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                  this.changeTextColor();
                });

              const canvasContainer = document.getElementsByClassName(
                'tui-image-editor-canvas-container'
              ) as HTMLCollectionOf<HTMLElement>;
              canvasContainer[0].style.position = 'absolute';
              canvasContainer[0].style.top = '0';
              canvasContainer[0].style.left = '0';
              canvasContainer[0].style.bottom = '0';
              canvasContainer[0].style.right = '0';
              canvasContainer[0].style.width = 'auto';
              canvasContainer[0].style.height = 'auto';
              const padding = (this.imgHeight / this.imgWidth) * 100;
              this.renderer.setStyle(
                this.tuiImageEditorEl.nativeElement,
                'padding-top',
                `${padding}%`
              );
              this.renderer.setStyle(
                this.tuiImageEditorEl.nativeElement,
                'position',
                'relative'
              );

              const that = this;
              this.imageEditorInstance.on({
                undoStackChanged(lenght: any) {
                  if (lenght > 1) {
                    that.undoDisabled = false;
                  } else {
                    that.undoDisabled = true;
                  }
                },
                redoStackChanged(lenght: any) {
                  if (lenght) {
                    that.redoDisabled = false;
                  } else {
                    that.redoDisabled = true;
                  }
                },
                objectActivated(obj: any) {
                  that.lineDrawing = false;
                  that.freeDrawing = false;

                  if (obj.type !== 'cropzone') {
                    that.closeSub();
                  }
                  that.activeObjectId = obj.id;
                  if (
                    obj.type === 'rect' ||
                    obj.type === 'circle' ||
                    obj.type === 'triangle'
                  ) {
                    that.currentSubMenu = 'Shape';
                    that.isSubMenu = true;
                    that.setShapeToolbar(obj);
                  } else if (obj.type === 'icon') {
                    that.currentSubMenu = 'Icon';
                    that.isSubMenu = true;
                    that.setIconToolbar(obj);
                  } else if (obj.type === 'i-text') {
                    that.currentSubMenu = 'Text';
                    that.isSubMenu = true;
                    that.setTextToolbar(obj);
                  }
                },
              });
            });
          }
        },
      });
    this.drawColorControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.drawStyleFree) {
          this.startFreeDrawing();
        } else {
          this.startLineDrawing();
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public rotateImage(degre: number): void {
    this.imageEditorInstance.rotate(degre);
  }

  public rotateImageXY(side: string): void {
    if (side === 'X') {
      this.imageEditorInstance.flipX();
    }
    if (side === 'Y') {
      this.imageEditorInstance.flipY();
    }
  }

  public shapeWidthChange(event: any): void {
    this.shapeWidthControl = event.value;
    this.imageEditorInstance.changeShape(this.activeObjectId, {
      strokeWidth: event.value,
    });
  }

  public startCrop(): void {
    this.imageEditorInstance.startDrawingMode('CROPPER');
  }

  public startFreeDrawing(): void {
    this.drawStyleFree = true;
    const settings = {
      width: this.drawWidthControl,
      color: `#FA07D7`,
    };
    if (this.drawColorControl.value) {
      settings.color = this.drawColorControl.value.rgba;
    }
    this.imageEditorInstance.stopDrawingMode();
    this.imageEditorInstance.startDrawingMode('FREE_DRAWING', settings);
    this.lineDrawing = false;
    this.freeDrawing = true;
  }

  public startLineDrawing(): void {
    this.drawStyleFree = false;
    const settings = {
      width: this.drawWidthControl,
      color: `#FA07D7`,
    };

    if (this.drawColorControl.value) {
      settings.color = this.drawColorControl.value.rgba;
    }
    this.imageEditorInstance.stopDrawingMode();
    this.imageEditorInstance.startDrawingMode('LINE_DRAWING', settings);
    this.freeDrawing = false;
    this.lineDrawing = true;
  }

  public stopCrop(): void {
    this.imageEditorInstance
      .crop(this.imageEditorInstance.getCropzoneRect())
      .then(() => {
        this.closeSub();
      });
  }

  public submit(): void {
    const canvas: HTMLCanvasElement =
      // eslint-disable-next-line no-underscore-dangle
      this.imageEditorInstance._graphics._canvas.lowerCanvasEl;
    canvas.toBlob(async (blob) => {
      await new Response(blob).arrayBuffer().then((array) => {
        this.alIndexedDbService.updateByteArray(this.newFileName, array);
      });
    });
    this.dialogRef.close();
  }

  public textWidthChange(event: any) {
    this.textWidthControl = event.value;
    this.imageEditorInstance.changeTextStyle(this.activeObjectId, {
      fontSize: event.value,
    });
  }

  public transparent(): void {
    if (!this.shapeColorPart) {
      this.isTransparentStroke = !this.isTransparentStroke;
    }
    if (this.shapeColorPart) {
      this.isTransparent = !this.isTransparent;
    }
    this.shapeChangeColor();
  }

  private dataURItoByteArray(dataURI: string): Uint8Array {
    const base64 = dataURI.split(',')[1];
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i + 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  private getUrlFromByteArray(
    imageData: string | ArrayBuffer,
    filename: string = 'tmp'
  ): Promise<string> {
    return new Promise((resolve: (value: string) => void): void => {
      if (imageData instanceof ArrayBuffer) {
        const uint8Array: Uint8Array = new Uint8Array(imageData);
        const file: File = new File([uint8Array], filename, {
          type: 'image/png',
        });
        // FIXME: récupérer le type MIME de l'image
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (evt: ProgressEvent<FileReader>): void => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            this.imgWidth = img.naturalWidth;
            this.imgHeight = img.naturalHeight;
            if (evt && evt.target && evt.target.result) {
              resolve(evt.target.result.toString());
            }
          };
        };
      } else {
        resolve('');
      }
    });
  }

  private setIconToolbar(obj: any) {
    const hex = obj.fill;
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const color = new Color(r, g, b);
    this.iconColorControl.setValue(color);
  }

  private setShapeToolbar(obj: any) {
    this.shapeWidthControl = obj.strokeWidth;
    if (obj.fill.color === 'transparent') {
      this.isTransparent = true;
    } else {
      this.isTransparent = false;
      const hex = obj.fill.color;
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      const color = new Color(r, g, b);
      this.shapeColorControl.setValue(color, { emitEvent: false });
    }
    if (obj.stroke === 'transparent') {
      this.isTransparentStroke = true;
    } else {
      this.isTransparentStroke = false;
      const hex = obj.stroke;
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      const color = new Color(r, g, b);
      this.shapeColorControlStroke.setValue(color, { emitEvent: false });
    }
  }

  private setTextToolbar(obj: any) {
    this.textWidthControl = obj.fontSize;
    const hex = obj.fill;
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const color = new Color(r, g, b);
    this.textColorControl.setValue(color, { emitEvent: false });
  }

  private shapeChangeColor() {
    let transparent = false;
    if (!this.shapeColorPart) {
      transparent = this.isTransparentStroke;
    }
    if (this.shapeColorPart) {
      transparent = this.isTransparent;
    }
    let color = '';
    if (this.shapeColorControl.value && !transparent && this.shapeColorPart) {
      color = `#${this.shapeColorControl.value.hex}`;
    } else if (
      this.shapeColorControlStroke.value &&
      !transparent &&
      !this.shapeColorPart
    ) {
      color = `#${this.shapeColorControlStroke.value.hex}`;
    } else if (!transparent) {
      if (!this.shapeColorPart) {
        color = '#FA07D7';
      } else {
        color = '#ffffff';
      }
    } else if (transparent) {
      color = 'transparent';
    }
    if (!this.shapeColorPart) {
      this.imageEditorInstance.changeShape(this.activeObjectId, {
        stroke: color,
      });
    } else {
      this.imageEditorInstance.changeShape(this.activeObjectId, {
        fill: color,
      });
    }
  }
}
