import { JsonObject, JsonProperty } from 'json2typescript';
import { Get } from './get.model';
import { DateJsonConverter } from '../../json-converters/date-json-converter';

/**
 * This class represents Equipment model
 */
@JsonObject('Equipment')
export class Equipment extends Get {
  @JsonProperty('description', String, true)
  public description: string | null = null;

  @JsonProperty('eiq', Boolean, true)
  public eiq: Boolean | null = null;

  @JsonProperty('eis', Boolean, true)
  public eis: Boolean | null = null;

  // Id maximo? en plus de l'uuid qui sera notre clef technique?
  @JsonProperty('id', String, true)
  public id = '';

  @JsonProperty('manufacturer', String, true)
  public manufacturer: string | null = null;

  @JsonProperty('model', String, true)
  public model: string | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('o2Cleaning', Boolean, true)
  public o2Cleaning: Boolean | null = null;

  @JsonProperty('originalEquipmentManufacturer', String, true)
  public originalEquipmentManufacturer: string | null = null;

  @JsonProperty('parentId', String, true)
  public parentId: string | null = null;

  @JsonProperty('processGroupId', String, true)
  public processGroupId: string | null = null;

  @JsonProperty('processTag', String, true)
  public processTag: string | null = null;

  @JsonProperty('serialNumber', String, true)
  public serialNumber: string | null = null;

  @JsonProperty('status', String, true)
  public status: string | null = null;

  @JsonProperty('type', String, true)
  public type: string | null = null;

  @JsonProperty('vendor', String, true)
  public vendor: string | null = null;

  @JsonProperty('yearOfManufacturing', DateJsonConverter, true)
  public yearOfManufacturing: Date | null = null;
}
