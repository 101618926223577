/*
 * Public API Surface of al-rest
 */

export * from './lib/al-rest.module';

export * from './lib/services/abstract-rest.service';
export * from './lib/services/attachment-sr-rest.service';
export * from './lib/services/attachment-wo-rest.service';
export * from './lib/services/equipments-rest.service';
export * from './lib/services/failure-report-rest.service';
export * from './lib/services/gtm-rest.service';
export * from './lib/services/process-groups-rest.service';
export * from './lib/services/production-units-rest.service';
export * from './lib/services/quick-report-rest.service';
export * from './lib/services/service-request-rest.service';
export * from './lib/services/start-completion-rest.service';
export * from './lib/services/submit-and-close-rest.service';
export * from './lib/services/submit-and-complete-rest.service';
export * from './lib/services/task-rest.service';
export * from './lib/services/work-order-rest.service';
export * from './lib/services/work-order-work-log-rest.service';
