export class AttachmentItem {
  public arrayBuffer: ArrayBuffer | string = '';

  public file: File | null = null;

  public id? = -1;

  public isUploaded = false;

  public newFileName = '';

  public putRequestUuid = '';

  public site = '';

  public ticketId: number | null = null;

  public workOrderNumber = '';
}
