import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-sr',
  templateUrl: './create-sr.component.html',
  styleUrls: ['./create-sr.component.scss'],
})
export class CreateSRComponent {
  @ViewChild('createChild') public createChild: any;

  public constructor(private router: Router, public dialog: MatDialog) {}

  public openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public returnHome(): void {
    this.createChild.cancel();
  }
}
