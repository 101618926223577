import {
  RightActionEnum,
  RightStatusEnum,
  RightTypeEnum,
  UserRights,
} from '@al/entities';
import { UserRightsCreateCache } from './caches/user-rights-create.cache';

export class SessionRights {
  private rightsList: UserRights[];

  private userRightsCreateCache = new UserRightsCreateCache();

  public constructor() {
    this.rightsList = this.userRightsCreateCache.values();
  }

  public isAllowedAction(
    type: RightTypeEnum,
    action: RightActionEnum
  ): boolean {
    const testList = this.rightsList.filter(
      (right) => right.type === type && right.actions?.includes(action)
    );
    if (testList.length > 0) {
      return true;
    }
    return false;
  }

  public isAllowedStatus(
    type: RightTypeEnum,
    status: RightStatusEnum
  ): boolean {
    const testList = this.rightsList.filter(
      (right) => right.type === type && right.status?.includes(status)
    );
    if (testList.length > 0) {
      return true;
    }
    return false;
  }
}
