import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ResetPasswordModule } from '@eur/reset-password';
import { AlAccountComponent } from './al-account.component';
import { AlAccountResetPasswordDialogComponent } from './al-account-reset-password-dialog.component';

@NgModule({
  declarations: [AlAccountComponent, AlAccountResetPasswordDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
    MatDialogModule,
    ResetPasswordModule,
  ],
  exports: [AlAccountComponent],
})
export class AlAccountModule {}
