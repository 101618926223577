/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AlCardSliderComponent } from './al-card-slider.component';
import { AlCardSliderItemComponent } from './al-card-slider-item.component';

@NgModule({
  imports: [CommonModule, MatCardModule, MatIconModule],
  declarations: [AlCardSliderComponent, AlCardSliderItemComponent],
  exports: [AlCardSliderComponent, AlCardSliderItemComponent],
})
export class AlCardSliderModule {}
