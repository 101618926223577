import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class DateJsonConverter implements JsonCustomConvert<Date | null> {
  public deserialize(date: string): Date | null {
    if (date) {
      // convert date at utc format in localized date
      return new Date(date);
    }
    return null;
  }

  public serialize(date: Date): string | null {
    if (date instanceof Date) {
      return date.toISOString();
    }
    return null;
  }
}
