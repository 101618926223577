import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Workbox } from 'workbox-window';
import { AlWorkboxService } from '@al/workbox';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const sendServiceWorkerState = (message: string) => {
  window.postMessage(message, window.location.href);
};

const sendPostMessage = (message: {
  key: string;
  message: string;
  origin?: string;
}) => {
  window.postMessage(
    {
      ...message,
      ...{ origin: 'sw' },
    },
    window.location.href
  );
};

const loadServiceWorker = (): void => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');

    AlWorkboxService.setWorkBox(wb);

    wb.active.then((e: ServiceWorker) => {
      if (e.state === 'activated') {
        sendPostMessage({
          key: 'activated',
          message: 'Service worker activated!',
        });
      }
    });

    wb.addEventListener('activated', (event) => {
      if (!event.isUpdate) {
        sendPostMessage({
          key: 'activated',
          message: 'Service worker activated for the first time!',
        });
      } else {
        sendPostMessage({
          key: 'activated',
          message: 'Service worker activated!',
        });
      }
      sendServiceWorkerState('activated');
    });

    wb.addEventListener('waiting', () => {
      sendPostMessage({
        key: 'waiting',
        message:
          `A new service worker has installed, but it can't activate` +
          `until all tabs running the current version have fully unloaded.`,
      });
    });

    wb.addEventListener('installed', (event) => {
      if (!event.isUpdate) {
        sendPostMessage({
          key: 'installed',
          message: 'Service worker installed for the first time.',
        });
      } else {
        sendPostMessage({
          key: 'installed',
          message: 'Service worker installed.',
        });
        // TODO: Améliorer le système de PostMessage en ajoutant un type (confirm, menu, message) et un level (danger, info, confirm, success, warning)
        // eslint-disable-next-line no-restricted-globals
        if (confirm('New content is available!. Click OK to refresh')) {
          window.location.reload();
        }
      }
    });

    wb.register();
  }
};

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => loadServiceWorker())
    .catch((err) => {
      // TODO: Utiliser un logger global
      // eslint-disable-next-line no-console
      console.error(err);
    });
});
