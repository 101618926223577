import { LocalStorageKey, ServiceRequestPut } from '@al/entities';
import { Injectable } from '@angular/core';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestCreateCache extends LocalCache<ServiceRequestPut> {
  public constructor() {
    super(LocalStorageKey.SERVICE_REQUEST_CREATE_CACHE);
  }

  protected getClazz(): typeof ServiceRequestPut {
    return ServiceRequestPut;
  }
}
