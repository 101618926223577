<div #overFlowed class="al-work-order-failure-report">
  <div class="header">
    <h1 translate>FAILURE REPORT</h1>
    <ng-container *ngIf="viewDetails; else elseTemplate">
      <span class="details" (click)="viewDetailsSwitch()" translate>Hide Details</span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="details" (click)="viewDetailsSwitch()" translate>View Details</span>
    </ng-template>
  </div>
  <form [formGroup]="form" (submit)="submitWorkOrder()">
    <div class="content" [ngClass]="{ show: viewDetails, hide: !viewDetails }">
      <div class="row">
        <div class="column">
        </div>
      </div>
      <div class="row">
        <div class="column">
        </div>
      </div>
      <div class="row">
        <div class="column">
          <mat-label translate>Root Cause<span *ngIf="areFieldsMandatory">*</span></mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="rootCause" [disabled]="!disableInputs()">
              <mat-option [value]="">
              </mat-option>
              <mat-option *ngFor="let rootCause of references.rootCause" [value]="rootCause">
                {{rootCause.rootCause}} : {{rootCause.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="column">

          <mat-label translate>Failure Class<span *ngIf="areFieldsMandatory">*</span></mat-label>
          <mat-form-field appearance="outline">

            <mat-icon matPrefix>search</mat-icon>
            <input [readonly]="!disableInputs()" matInput type="text" placeholder="{{ 'failure code' | translate }}"
              formControlName="failureCode" [matAutocomplete]="autoInput">
            <mat-autocomplete #autoInput panelWidth="auto" [displayWith]="displayFailureCodeLabel">
              <mat-option *ngFor="let failureCode of  filteredFailureCode | async" [value]="failureCode">
                {{failureCode.failureCode}} : {{failureCode.label}}
              </mat-option>
            </mat-autocomplete>
            <button mat-button matSuffix mat-icon-button aria-label="Clear" type="button" (click)="clearFailureCode()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

        </div>
        <div *ngIf="!isSecondisplay" class="column">
          <mat-label translate>Failure Date<span *ngIf="isFailDateRequired">*</span></mat-label>
          <mat-form-field appearance="outline">
            <input [readonly]="!disableInputs()" matInput type="datetime-local" formControlName="failureDateTime">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" type="button" (click)="clearDate()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="isSecondisplay" class="row">
        <div class="column">
          <mat-label translate>Failure Date<span *ngIf="isFailDateRequired">*</span></mat-label>
          <mat-form-field appearance="outline">
            <input [readonly]="!disableInputs()" matInput type="datetime-local" formControlName="failureDateTime">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" type="button" (click)="clearDate()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-checkbox [disabled]="!disableInputs()" formControlName="checked">
          {{'Is failure acceptable ?' | translate}}
        </mat-checkbox>
      </div>
      <div class="row">
        <div class="column">
          <mat-label class="required-field">{{'Summary' | translate}}</mat-label>
          <mat-form-field appearance="outline">
            <input [readonly]="!disableInputs()" matInput placeholder="{{'Short description' | translate}}" required
              formControlName="summary">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <mat-label>{{'Details' | translate}}</mat-label>
          <mat-form-field appearance="outline">
            <textarea [readonly]="!disableInputs()" matInput placeholder="{{'Comment' | translate}}"
              formControlName="details"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="column buttons">
          <button mat-raised-button color="primary" class="inside-button" [disabled]="!disableButton()">
            {{'SAVE REPORT FAILURE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
