import {
  LocalStorageKey,
  WorkOrderPut,
  WorkOrderPutResponse,
} from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class AddWorkLogCreateHistoryService extends AbstractHistoryService<
  WorkOrderPut,
  WorkOrderPutResponse
> {
  public constructor() {
    super(LocalStorageKey.ADD_WORKLOG_CREATE_REQUEST_HISTORY);
  }
}
