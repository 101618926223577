import { JsonObject, JsonProperty } from 'json2typescript';

import { Get } from './get.model';

/**
 * This class represents ProcessGroup model
 */
@JsonObject('ProcessGroup')
export class ProcessGroup extends Get {
  // Id maximo? en plus de l'uuid qui sera notre clef technique?
  @JsonProperty('id', String, true)
  public id = '';

  @JsonProperty('name', String, true)
  public name: string | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('productionUnitId', String, true)
  public productionUnitId: string | null = null;

  @JsonProperty('type', String, true)
  public type: string | null = null;
}
