import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LocalStorageConfiguration } from '@al/entities';
import { LoginErrorEnum } from './login-error.enum';
import { AppService } from '../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public errorType = '';

  public isServiceWorkerReady: boolean;

  public loginErrorEnum = LoginErrorEnum;

  public previousLoginFailed = false;

  public title: string;

  public constructor(
    private appService: AppService,
    private titleService: Title
  ) {
    this.title = this.titleService.getTitle();

    this.isServiceWorkerReady = false;
    this.appService.isServiceWorkerReady.subscribe((isServiceWorkerReady) => {
      this.isServiceWorkerReady = isServiceWorkerReady;
    });

    const error = localStorage.getItem(LocalStorageConfiguration.USER_ID);
    if (error) {
      if (
        error === LoginErrorEnum.LOGIN_FAILED ||
        error === LoginErrorEnum.SITE_MISSING ||
        error === LoginErrorEnum.MAXIMO_DOWN ||
        error === LoginErrorEnum.MAXIMO_NOT_ENABLED
      ) {
        this.previousLoginFailed = true;
        this.errorType = error;
      }
    }
  }
}
