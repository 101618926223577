import { WorkLog, WorkOrder, WorkOrderPutResponse } from '@al/entities';
import {
  AlRequestInformationDialogComponent,
  AlRequestInformationDialogData
} from '@al/request-information-dialog';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlRequestInformationDialogDataWoWlPut } from 'projects/al-request-information-dialog/src/lib/model/al-request-information-dialog-data-wo-wl-put';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlWorkOrderWorkLogListService {
  private workOrder: WorkOrder | null;
  private ngUnsubscribe = new Subject();

  private workLogList$: BehaviorSubject<WorkLog[]>;
  public workLogList: Observable<WorkLog[]>;

  constructor(
    private dialog: MatDialog,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrderService: WorkOrdersService
  ) {
    this.workLogList$ = new BehaviorSubject<WorkLog[]>([]);
    this.workLogList = this.workLogList$.asObservable();

    this.workOrder = null;
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          // const previousWorkOrder = this.workOrder;
          this.workOrder = res;

          const workLogList =
            this.workOrder && this.workOrder.workLogs
              ? this.workOrder.workLogs
              : [];
          this.workLogList$.next(workLogList);
        }
      });
  }

  public addWorkLogToWorkOrder(workLog: WorkLog): void {
    if (this.workOrder) {
      this.workOrderService.addWorkLog(this.workOrder, workLog).subscribe({
        next: (workOrderPutResponse: WorkOrderPutResponse | null) => {
          if (workOrderPutResponse) {
            // this.displayCreation(workOrderPutResponse);
          }
        },
        error: (e) => {
          console.log('worklog error : ', e);
        },
      });
    }
  }

  private buildDialogInformation(
    res: WorkOrderPutResponse
  ): AlRequestInformationDialogData<AlRequestInformationDialogDataWoWlPut> {
    const { type, error, siteId, status, workOrderId, workOrderNum } = res;
    return {
      title: 'createWorkLog',
      type: 'woWlPut',
      information: {
        action: type,
        error: error ?? undefined,
        siteId,
        status,
        workOrderId: workOrderId ?? undefined,
        workOrderNum,
      },
    };
  }

  protected displayCreation(data: WorkOrderPutResponse): void {
    AlRequestInformationDialogComponent.open<
      WorkOrderPutResponse,
      AlRequestInformationDialogDataWoWlPut
    >(data, this.dialog, this.buildDialogInformation.bind(this));
  }
}
