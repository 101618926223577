import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'al-confirm-dialog',
  templateUrl: './al-confirm-dialog.component.html',
  styleUrls: ['./al-confirm-dialog.component.scss'],
})
export class AlConfirmDialogComponent {
  // TODO: canceling et confirm pourraient être rassemblés en un même EventEmmitter
  //       @Output() public result = new EventEmitter<boolean>();
  @Output() public canceling = new EventEmitter();

  @Output() public confirm = new EventEmitter();

  public cancelAction(): void {
    this.canceling.emit();
  }

  public confirmAction(): void {
    this.confirm.emit();
  }
}
