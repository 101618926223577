import { WorkOrderPut, WorkOrderPutResponse } from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractWoHistoryService extends AbstractHistoryService<
  WorkOrderPut,
  WorkOrderPutResponse
> {
  public clearWorkOrderFromHistory(woNum: string): void {
    this.values()
      .filter((value) => value.response.workOrderNum === woNum)
      .forEach((value) => {
        this.removeByUuid(value.uuid);
      });

    this.values()
      .filter((value) => value.request.number === woNum)
      .forEach((value) => {
        this.removeByUuid(value.uuid);
      });
  }
}
