import { Injectable } from '@angular/core';
import { FailureReportRequestPut, WorkOrder } from '@al/entities';
import { WorkOrdersStore } from './work-orders.store';

@Injectable({
  providedIn: 'root',
})
export class FailureReportService {
  public constructor(private workOrdersStore: WorkOrdersStore) {}

  public create(failureReportRequestPut: FailureReportRequestPut): void {
    if (failureReportRequestPut) {
      let state = {};

      if (failureReportRequestPut.actualStart) {
        state = { ...state, actDateDebut: failureReportRequestPut.actualStart };
      }

      if (failureReportRequestPut.actualFinish) {
        state = { ...state, actDateFin: failureReportRequestPut.actualFinish };
      }
      if (failureReportRequestPut.failureCode) {
        state = { ...state, failureCode: failureReportRequestPut.failureCode };
      }

      if (failureReportRequestPut.failureDate) {
        state = { ...state, failureDate: failureReportRequestPut.failureDate };
      }

      if (failureReportRequestPut.failureAcceptable) {
        state = {
          ...state,
          failureAcceptable: failureReportRequestPut.failureAcceptable,
        };
      }

      if (failureReportRequestPut.remarkDetails) {
        state = {
          ...state,
          remarkDetails: failureReportRequestPut.remarkDetails,
        };
      }

      if (failureReportRequestPut.remark) {
        state = {
          ...state,
          remark: failureReportRequestPut.remark,
        };
      }

      if (failureReportRequestPut.rootCause) {
        state = {
          ...state,
          rootCause: failureReportRequestPut.rootCause,
        };
      }

      this.workOrdersStore.update(
        (wo: WorkOrder) => wo.number === failureReportRequestPut.number,
        state
      );
    }
  }
}

// ,
// failureDate: failureReportRequestPut.failureDate,
// failureMarkDescription: failureReportRequestPut.remark,
// failureMarkDescriptionLong: failureReportRequestPut.remarkDetails,
// rootCause: failureReportRequestPut.rootCause,
// failureAcceptable: failureReportRequestPut.failureAcceptable,
