<div #overFlowed class="al-work-order-dates">
  <div class="header">
    <h1 translate>Actual start & finish dates</h1>
    <ng-container *ngIf="viewDetails; else elseTemplate">
      <span class="details" (click)="viewDetailsSwitch()" translate>Hide Details</span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="details" (click)="viewDetailsSwitch()" translate>View Details</span>
    </ng-template>
  </div>
  <form [formGroup]="form">
    <div class="content" [ngClass]="{ show: viewDetails, hide: !viewDetails }">
      <div class="row">
        <div class="column">
          <mat-label translate>{{'Actual Start Date' | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input [readonly]="isActualStartDateTimeReadOnly()" matInput type="datetime-local" (change)="changeActualStartDateTime()"
              formControlName="actualStartDateTime">
            <mat-error *ngIf="actualStartDateTime?.errors?.wrongDate || actualFinishDateTime?.errors?.wrongDate">
              {{ "can't finish before start" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-label translate>Actual Finish Date</mat-label>
          <mat-form-field appearance="outline">
            <input [readonly]="isActualFinishDateTimeReadOnly()" matInput type="datetime-local" (change)="changeActualFinishDateTime()"
              formControlName="actualFinishDateTime">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
