import { Injectable } from '@angular/core';
import { HistoryElement } from './history.element';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class HistoryCache<P, R> extends LocalCache<HistoryElement<P, R>> {
  protected getClazz(): typeof HistoryElement {
    return HistoryElement;
  }
}
