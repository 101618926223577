import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AlEquipmentComponent } from '@al/equipment';
import { Attachment, Equipment, WorkOrder } from '@al/entities';
import {
  WorkOrdersQuery,
  EquipmentsQuery,
  EquipmentsService,
  WorkOrdersService,
} from '@al/state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'al-work-order-summary',
  templateUrl: 'al-work-order-summary.component.html',
  styleUrls: ['al-work-order-summary.component.scss'],
})
export class AlWorkOrderSummaryComponent implements OnInit, OnDestroy {
  public equipments: Equipment | undefined;

  public workOrder!: WorkOrder;

  private ngUnsubscribe = new Subject();

  public get getWorkOrderAssetNumber(): string | null {
    const equipment = this.equipmentsQuery.getActive();
    if (equipment && equipment.processTag) {
      return equipment.processTag;
    }
    if (equipment && !equipment.processTag && equipment.number) {
      return equipment.number;
    }
    return this.workOrder && this.workOrder.assetNum
      ? this.workOrder.assetNum
      : '';
  }

  public get getWorkOrderAttachements(): Attachment[] {
    if (this.workOrder && this.workOrder.attachments) {
      return this.workOrder.attachments;
    }
    return [];
  }

  public get getWorkOrderLead(): string {
    return this.workOrder && this.workOrder.lead ? this.workOrder.lead : '';
  }

  public get getWorkOrderLocation(): string {
    return this.workOrder && this.workOrder.location
      ? this.workOrder.location
      : '';
  }

  public get getWorkOrderNumber(): string {
    return this.workOrder && this.workOrder.number ? this.workOrder.number : '';
  }

  public get getWorkOrderParentNumber(): string {
    return this.workOrder && this.workOrder.parent ? this.workOrder.parent : '';
  }

  public get getWorkOrderPriority(): string {
    return this.workOrder && this.workOrder.priority
      ? this.workOrder.priority
      : '';
  }

  public get getWorkOrderTargetCompDate(): string {
    return this.workOrder && this.workOrder.targetCompDate
      ? this.workOrder.targetCompDate.toString()
      : '';
  }

  public get getWorkOrderSchedDateFin(): string {
    return this.workOrder && this.workOrder.schedDateFin
      ? this.workOrder.schedDateFin.toString()
      : '';
  }

  public get getWorkOrderActStartDebut(): string {
    return this.workOrder && this.workOrder.actDateDebut
      ? this.workOrder.actDateDebut.toString()
      : '';
  }

  public get getWorkOrderType(): string {
    return this.workOrder && this.workOrder.type ? this.workOrder.type : '';
  }

  public get isChildFrom(): boolean {
    return !!(
      this.workOrder &&
      this.workOrder.parent &&
      this.workOrder.parent.trim().length > 0
    );
  }

  public constructor(
    public dialog: MatDialog,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersService: WorkOrdersService,
    private equipmentsQuery: EquipmentsQuery,
    private equipmentsService: EquipmentsService
  ) {}

  public displayEquipment(): void {
    this.dialog.open(AlEquipmentComponent, {
      height: '90vh',
      width: '90vw',
    });
  }

  public displayParent(): void {
    this.workOrdersService.setActiveByNumber(this.getWorkOrderParentNumber);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
          this.equipmentsService.setActive(this.workOrder.assetNum);
          if (this.equipmentsQuery.getActive()) {
            this.equipments = this.equipmentsQuery.getActive();
          }
        }
      });
  }
}
