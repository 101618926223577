import { JsonObject, JsonProperty } from 'json2typescript';

import { CalibrationStatus } from '../../enums/calibration-status.enum';
import { TaskStatus } from '../../enums/task-status.enum';
import { DateJsonConverter } from '../../json-converters/date-json-converter';

import { PutRequest } from './put-request.model';

@JsonObject('TaskPut')
export class TaskPut extends PutRequest {
  @JsonProperty('deltaAsFound', String, true)
  public deltaAsFound: string | null = null;

  @JsonProperty('deltaAsFoundPc', String, true)
  public deltaAsFoundPc: string | null = null;

  @JsonProperty('deltaAsLeft', String, true)
  public deltaAsLeft: string | null = null;

  @JsonProperty('deltaAsLeftPc', String, true)
  public deltaAsLeftPc: string | null = null;

  @JsonProperty('endDate', DateJsonConverter, true)
  public endDate: Date | null = null;

  @JsonProperty('measurePoint', String, true)
  public measurePoint: string | null = null;

  @JsonProperty('measurementAfterAdj', Number, false)
  public measurementAfterAdj: number | null = null;

  @JsonProperty('measurementDate', DateJsonConverter, true)
  public measurementDate: Date | null = null;

  @JsonProperty('measurementValue', Number, false)
  public measurementValue: number | null = null;

  @JsonProperty('meterName', String, true)
  public meterName: string | null = null;

  @JsonProperty('meterType', String, true)
  public meterType: string | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;

  @JsonProperty('source', String, true)
  public source: string | null = null;

  @JsonProperty('standardCertId', String, true)
  public standardCertId: string | null = null;

  @JsonProperty('standardInput', Number, true)
  public standardInput: number | null = null;

  @JsonProperty('standardNumero', String, true)
  public standardNumero: string | null = null;

  @JsonProperty('standardPhyId', String, true)
  public standardPhyId: string | null = null;

  @JsonProperty('startDate', DateJsonConverter, true)
  public startDate: Date | null = null;

  @JsonProperty('status', String, true)
  public status: TaskStatus | null = null;

  @JsonProperty('status1', String, false)
  public status1: CalibrationStatus | null = null;

  @JsonProperty('status2', String, false)
  public status2: CalibrationStatus | null = null;

  @JsonProperty('taskId', Number, true)
  public taskId: number | null = null;

  @JsonProperty('tolerance', Number, true)
  public tolerance: number | null = null;

  @JsonProperty('uncertainty', Number, true)
  public uncertainty: number | null = null;

  @JsonProperty('wol4', String, true)
  public wol4: string | null = null;
}
