import {
  QuickReportPut,
  ServiceRequestPut,
  Site,
  WorkOrder,
  WorkOrderPut,
} from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { SiteQuery, WorkOrdersQuery } from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileRenamingComponent } from '../file-renaming/file-renaming.component';

export abstract class AbstractCommonComponent {
  public errors: { param: object; text: string }[] = [];

  public get disableButton(): boolean {
    return (
      (!this.workOrderNumber && !this.putRequest) || this.site.length === 0
    );
  }

  protected ngUnsubscribe = new Subject();

  private fileName = '';

  private site: string;

  private workOrderNumber: string | null;

  public abstract maxFileSize: number | null;

  public abstract maxPendingFileTotalSize: number | null;

  public abstract putRequest:
    | (QuickReportPut | ServiceRequestPut | WorkOrderPut)
    | null;

  public constructor(
    protected alIndexedDbService: AlIndexedDbService,
    protected dialog: MatDialog,
    protected siteQuery: SiteQuery,
    protected syncInfoService: SyncInfoService,
    protected workOrdersQuery: WorkOrdersQuery
  ) {
    const activeSite = this.siteQuery.getActive();
    this.site = activeSite ? activeSite.name : '';
    this.siteQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (site: Site) => {
          this.site = site ? site.name : '';
        },
      });

    const activeWorkOrder = this.workOrdersQuery.getActive();
    this.workOrderNumber = activeWorkOrder ? activeWorkOrder.number : null;
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (workOrder: WorkOrder) => {
          this.workOrderNumber = workOrder ? workOrder.number : null;
        },
      });
  }

  public async onFileSelected(
    event: Event,
    callback?: (newFileName: string, dialog: MatDialog) => void
  ): Promise<void> {
    this.errors = [];
    const element = event.currentTarget as HTMLInputElement;
    const { files } = element;
    const file = files && files.length > 0 ? files[0] : null;
    if (file) {
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.errors.push({
          param: {
            currentFileSize: this.returnFileSize(file.size),
            maxFileSize: this.returnFileSize(this.maxFileSize),
          },
          text: 'An attachment must not exceed {{ maxFileSize }}: {{ currentFileSize }}',
        });
        return;
      }

      const tableSize = await this.alIndexedDbService
        .getTableSize()
        .toPromise();
      const totalSize = tableSize + file.size;
      if (
        this.maxPendingFileTotalSize &&
        totalSize > this.maxPendingFileTotalSize
      ) {
        this.errors.push({
          param: {
            totalSize: this.returnFileSize(totalSize),
            maxPendingFileTotalSize: this.returnFileSize(
              this.maxPendingFileTotalSize
            ),
          },
          text: 'The total volume of files pending for upload cannot exceed {{ maxPendingFileTotalSize }}: {{ totalSize }}',
        });
        return;
      }

      this.fileName = file.name;
      const dialogRef = this.dialog.open(FileRenamingComponent, {
        data: this.fileName,
      });

      dialogRef.afterClosed().subscribe((newFileName?: string) => {
        if (newFileName) {
          this.saveFileInIndexedDb(file, newFileName).then(() => {
            if (callback) {
              callback(newFileName, this.dialog);
            }
          });
        }
      });
    }
  }

  private returnFileSize(fileSize: number): string {
    if (fileSize < 1024) {
      return `${fileSize}bytes`;
    }
    if (fileSize >= 1024 && fileSize < 1048576) {
      return `${(fileSize / 1024).toFixed(1)}KB`;
    }
    return `${(fileSize / 1048576).toFixed(1)}MB`;
  }

  private saveFileInIndexedDb(
    file: File,
    newFileName: string
  ): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void): void => {
      const fileReader = new FileReader();
      fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
        if (e && e.target && e.target.result) {
          const arrayBuffer = e.target.result;
          this.alIndexedDbService.add(
            file,
            arrayBuffer,
            this.site,
            this.workOrderNumber || '',
            this.putRequest && this.putRequest.uuid
              ? this.putRequest.uuid.toString()
              : '',
            newFileName,
            null
          );
          resolve(e.target.result.toString());
        }
      };
      fileReader.readAsArrayBuffer(file);
    });
  }
}
