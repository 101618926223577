import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { AlWorkOrderLoadingComponent } from './al-work-order-loading.component';

@NgModule({
  declarations: [AlWorkOrderLoadingComponent],
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
  exports: [AlWorkOrderLoadingComponent],
})
export class AlWorkOrderLoadingModule {}
