import { Injectable } from '@angular/core';
import { Workbox } from 'workbox-window';

@Injectable({
  providedIn: 'root',
})
export class AlWorkboxService {
  private static workBox: Workbox | undefined = undefined;

  public static setWorkBox(workBox: Workbox): void {
    AlWorkboxService.workBox = workBox;
  }

  public isConnected(isNetworkConnected: boolean): void {
    this.sendMessage({
      type: 'NETWORK_STATUS',
      isConnected: isNetworkConnected,
    });
  }

  public sendMessage(message: Object): void {
    if (AlWorkboxService.workBox && AlWorkboxService.workBox.active) {
      AlWorkboxService.workBox?.messageSW(message);
    }
  }
}
