import { WorkOrderLoadingStatus } from '@al/entities';
import { AlSpinnerService } from '@al/spinner';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-work-order-loading',
  templateUrl: './al-work-order-loading.component.html',
  styleUrls: ['./al-work-order-loading.component.scss'],
})
export class AlWorkOrderLoadingComponent implements OnDestroy {
  public btLoadedDisplayed = true;

  public isLoading = true;

  public isLoadingErr = false;

  public numberOfWo: number;

  public numberOfWoComp: number;

  private broadcast: BroadcastChannel;

  private ngUnsubscribe = new Subject();

  public constructor(private alSpinnerService: AlSpinnerService) {
    this.broadcast = new BroadcastChannel('work-order-loading');
    this.numberOfWo = 0;
    this.numberOfWoComp = 0;
    this.broadcast.onmessage = (event) => {
      if (event.data.duration === WorkOrderLoadingStatus.reset) {
        this.numberOfWo = 0;
      }
      if (event.data.duration === WorkOrderLoadingStatus.resetCOMP) {
        this.numberOfWo = 0;
        this.numberOfWoComp = 0;
      }
      if (event.data.duration === WorkOrderLoadingStatus.RLSD) {
        this.numberOfWo += event.data.text;
      }
      if (event.data.duration === WorkOrderLoadingStatus.COMP) {
        this.numberOfWoComp += event.data.text;
      }
      if (event.data.duration === WorkOrderLoadingStatus.err) {
        this.isLoadingErr = true;
      }
    };
    this.alSpinnerService.isWorkOrderProcessing
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isDisplayed) => {
        this.isLoading = isDisplayed;
        if (isDisplayed) {
          this.btLoadedDisplayed = true;
        } else {
          setTimeout(() => {
            this.btLoadedDisplayed = false;
          }, 30000);
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
