import { AlAttachmentsModule } from '@al/attachments';
import { AlReferenceModule } from '@al/reference';
import { AlRequestInformationDialogModule } from '@al/request-information-dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { AlDialogModule } from '@al/dialog';
import { AlCreateBtComponent } from './al-create-bt.component';

@NgModule({
  declarations: [AlCreateBtComponent],
  imports: [
    AlAttachmentsModule,
    AlDialogModule,
    AlReferenceModule,
    AlRequestInformationDialogModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTreeModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [AlCreateBtComponent],
})
export class AlCreateBtModule {}
