import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Task, TaskPut, TaskPutResponse, LocalStorageKey } from '@al/entities';
import { AlEnvironmentService, AwsEndPoint } from '@al/environment';
import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
export class TaskRestService extends AbstractRestService<
  Task,
  TaskPut,
  TaskPutResponse
> {
  protected cacheDelay: number | null = null;

  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  protected get createEndPoint(): string {
    throw new Error('Method not implemented.');
  }

  protected get getEndPoint(): string {
    throw new Error('Method not implemented.');
  }

  protected get updateEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.saveTask}`;
  }

  public constructor(
    protected alEnvironmentService: AlEnvironmentService,
    protected http: HttpClient
  ) {
    super(http);
    this.keyLastDate = LocalStorageKey.TASK_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.TASK_SYNCHRO_LAST_ROWSTAMP;
  }

  protected getClazz(): typeof Task {
    return Task;
  }

  protected getPutRequestClazz(): typeof TaskPut {
    return TaskPut;
  }

  protected getPutResponseClazz(): typeof TaskPutResponse {
    return TaskPutResponse;
  }

  protected updateLastSyncDate(): void {}
}
