<div class="app-dashboard">
  <div class="site-summary" [ngClass]="{'vertical': isSmall}">
    <div class="site-summary-div">
      <al-site-summary></al-site-summary>
    </div>
    <div class="button">
      <button mat-raised-button color="primary" class="inside-button" (click)="displayCreation()">
        <mat-icon aria-label="add">add</mat-icon>
        {{'CREATE' | translate }}
      </button>
    </div>
  </div>
  <div class="work-order-list">
    <al-work-order-list [displayFilters]='true'></al-work-order-list>
  </div>

  <!-- Time filter translations added for extraction -->
  <span style="display: none" translate>SEMAINE</span>
  <span style="display: none" translate>MOIS</span>
  <span style="display: none" translate>MOIS_SUIVANT</span>

  <!-- Status filter added tfor extraction -->
  <span style="display: none" translate>Delayed</span>
  <span style="display: none" translate>Completed</span>
  <span style="display: none" translate>To complete</span>
  <span style="display: none" translate>To finish</span>
</div>
