import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlSpinnerService } from './al-spinner.service';

@Component({
  selector: 'al-spinner',
  templateUrl: './al-spinner.component.html',
  styleUrls: ['./al-spinner.component.scss'],
})
export class AlSpinnerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('overlayTemplate')
  public overlayTemplate!: ComponentPortal<any>;

  public displayProgressSpinner: boolean | undefined;

  public message = '';

  private ngUnsubscribe = new Subject();

  private overlayRef!: OverlayRef;

  public constructor(
    private overlay: Overlay,
    private alSpinnerService: AlSpinnerService,
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {
    this.alSpinnerService.snackBarIsDisplayed
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.text) {
          this.launchSnackbar(res.text, res.duration);
        }
      });

    this.alSpinnerService.message
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((message) => {
        this.message = message;
      });
  }

  public createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      positionStrategy,
    });
    overlayConfig.hasBackdrop = true;
    this.overlayRef = this.overlay.create(overlayConfig);
  }

  public ngAfterViewInit(): void {
    this.createOverlay();
    this.alSpinnerService.isAssetProcessing
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isAssetProcessing) => {
        if (isAssetProcessing && !this.overlayRef.hasAttached()) {
          this.overlayRef.attach(this.overlayTemplate);
        }
        if (!isAssetProcessing && this.overlayRef.hasAttached()) {
          this.overlayRef.detach();
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private launchSnackbar(text: string, duration: number | null): void {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    };
    if (duration) {
      config.duration = duration * 1000;
    }
    this.zone.run(() => {
      this.snackBar.open(text, 'close', config);
    });
  }
}
