import { Site } from '@al/entities';
import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

export interface SiteState extends EntityState<Site>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site', idKey: 'maximoId' })
export class SiteStore extends EntityStore<SiteState> {
  public constructor() {
    super();
  }
}
