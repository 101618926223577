import { DateSelectorItem } from '@al/entities';
import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

export interface DateSelectorState
  extends EntityState<DateSelectorItem>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'date', idKey: 'uuid' })
export class DateSelectorStore extends EntityStore<DateSelectorState> {
  public constructor() {
    super();
  }
}
