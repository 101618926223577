/**
 * WO Status:
 *   - CAN = Cancel
 *   - CLOSE = Close (sera géré dans MX)
 *   - COMP = Completed (fin de notre traitement)
 *   - RLSD = Released (MX)
 *   - TOPLAN = Creation
 */
export enum WorkOrderStatus {
  CAN = 'CAN',
  CLOSE = 'CLOSE', // Submit & close
  COMP = 'COMP', // Submit
  RLSD = 'RLSD',
  TOPLAN = 'TOPLAN',
}
