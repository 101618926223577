import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';

import { EnvironmentOptions } from './environment-options';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class AlEnvironmentModule {
  public static forRoot(options: EnvironmentOptions): ModuleWithProviders {
    return {
      ngModule: AlEnvironmentModule,
      providers: [
        {
          provide: EnvironmentOptions,
          useFactory: (): EnvironmentOptions => {
            return options;
          },
        },
      ],
    };
  }
}
