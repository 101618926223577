import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import {
  Equipment,
  RightActionEnum,
  RightTypeEnum,
  WorkOrder,
} from '@al/entities';
import { EquipmentsService, WorkOrdersService } from '@al/state';
import { SessionRights } from '@al/session';

import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TaskType } from '../../../al-entities/src/lib/enums/task-type.enum';

@Component({
  selector: 'al-work-order-list',
  templateUrl: './al-work-order-list.component.html',
  styleUrls: ['./al-work-order-list.component.scss'],
})
export class AlWorkOrderListComponent implements OnInit, OnDestroy {
  @Input()
  public displayFilters = true;

  public dataSource = new MatTableDataSource<WorkOrder>([]);

  public displayedColumns: string[] = [
    'id',
    'description',
    'assetNum',
    'location',
    'objectiveEndDate',
    'workType',
    'priority',
    'role',
    'gotCalibration',
  ];

  public isLoading = true;

  public rights = new SessionRights();

  public value = '';

  private ngUnsubscribe = new Subject();

  public constructor(
    private router: Router,
    private workOrdersService: WorkOrdersService,
    private equipementsService: EquipmentsService
  ) {}

  public checkForCalibration(element: WorkOrder): boolean {
    const calibration = element.tasks.filter(
      (task) => task.taskType === TaskType.calibration
    );

    if (calibration.length > 0) {
      return true;
    }
    return false;
  }

  public getProcessTag(element: WorkOrder): string | null | undefined {
    let equipment: Equipment | undefined;
    this.equipementsService
      .getById(element.assetNum)
      .pipe(take(1))
      .subscribe((asset) => {
        equipment = asset;
      });
    if (equipment && equipment.processTag) {
      return equipment.processTag;
    }
    return equipment?.number;
  }

  public navigateSummary(row: WorkOrder): void {
    if (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) ||
      this.rights.isAllowedAction(RightTypeEnum.WOTRACK, RightActionEnum.READ)
    ) {
      this.workOrdersService.setActive(row.id);
      this.router.navigate(['/', 'workorder']);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {
    this.workOrdersService.setActive(null);
    this.workOrdersService
      .selectAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (workOrders: WorkOrder[]) => {
          this.isLoading = false;
          if (workOrders) {
            this.dataSource = new MatTableDataSource<WorkOrder>(workOrders);
          } else {
            this.dataSource = new MatTableDataSource<WorkOrder>([]);
          }
        },
        error: () => {
          this.dataSource = new MatTableDataSource<WorkOrder>([]);
        },
      });
  }

  public roleDisplay(element: WorkOrder): string {
    const userId = localStorage.getItem('userId');
    if (element.superviseur === userId && element.lead === userId) {
      return 'Supervisor/Lead';
    }
    if (element.superviseur === userId) {
      return 'Supervisor';
    }
    if (element.lead === userId) {
      return 'Lead';
    }
    return '';
  }
}
