import { NgModule } from '@angular/core';
import { AlReferenceService } from './al-reference.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [AlReferenceService],
})
export class AlReferenceModule {}
