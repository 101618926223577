import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { AlFileComponent } from './file/al-file.component';
import { AlPhotoComponent } from './photo/al-photo.component';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { FileRenamingComponent } from './file-renaming/file-renaming.component';

@NgModule({
  declarations: [
    AlFileComponent,
    AlPhotoComponent,
    FileRenamingComponent,
    PhotoEditorComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMatColorPickerModule,
    MatSliderModule,
    FormsModule,
    MatDialogModule,
  ],
  providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
  exports: [AlFileComponent, AlPhotoComponent],
})
export class AlFileModule {}
