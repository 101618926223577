import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  QuickReport,
  QuickReportPut,
  QuickReportPutResponse,
  LocalStorageKey,
} from '@al/entities';
import { AlEnvironmentService, AwsEndPoint } from '@al/environment';
import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
export class QuickReportRestService extends AbstractRestService<
  QuickReport,
  QuickReportPut,
  QuickReportPutResponse
> {
  protected cacheDelay: number | null = null;

  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  protected get createEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.createQr}`;
  }

  protected get getEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.createQr}`;
  }

  protected get updateEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.createQr}`;
  }

  public constructor(
    protected alEnvironmentService: AlEnvironmentService,
    protected http: HttpClient
  ) {
    super(http);
    this.keyLastDate = LocalStorageKey.QUICK_REPORT_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.QUICK_REPORT_SYNCHRO_LAST_ROWSTAMP;
  }

  protected getClazz(): typeof QuickReport {
    return QuickReport;
  }

  protected getPutRequestClazz(): typeof QuickReportPut {
    return QuickReportPut;
  }

  protected getPutResponseClazz(): typeof QuickReportPutResponse {
    return QuickReportPutResponse;
  }

  protected updateLastSyncDate(): void {}
}
