import { Injectable, OnDestroy } from '@angular/core';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { WorkOrder, WorkOrderPutResponse } from '@al/entities';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlWorkOrderService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  private workOrder: WorkOrder | null;

  public constructor(
    private workOrdersService: WorkOrdersService,
    private workOrdersQuery: WorkOrdersQuery
  ) {
    this.workOrder = null;

    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public submit(): Observable<WorkOrderPutResponse | null> {
    if (this.workOrder) {
      return this.workOrdersService.submitAndComplete(this.workOrder);
    }
    return of(null);
  }

  public submitAndClose(): Observable<WorkOrderPutResponse | null> {
    if (this.workOrder) {
      return this.workOrdersService.submitAndClose(this.workOrder);
    }
    return of(null);
  }
}
