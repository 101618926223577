import { Task } from '@al/entities';
import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';

export interface TasksState extends EntityState<Task>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tasks', idKey: 'uuid' })
export class TasksStore extends EntityStore<TasksState> {
  public constructor() {
    super();
  }
}
