import { LocalStorageKey } from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractWoHistoryService } from './abstract-wo-history.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderCreateHistoryService extends AbstractWoHistoryService {
  public constructor() {
    super(LocalStorageKey.WORK_ORDER_CREATE_REQUEST_HISTORY);
  }
}
