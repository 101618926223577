<div class="al-file">
  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
  <button mat-raised-button class="upload-button" color="primary" type="button" [disabled]="disableButton"
    (click)="fileUpload.click()">
    <mat-icon>attach_file</mat-icon>
    <span translate>Add a file</span>
  </button>
  <mat-error *ngIf="errors.length > 0">
    <span *ngFor="let error of errors">{{ error.text | translate:error.param }}</span>
  </mat-error>
</div>
