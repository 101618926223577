<div class="al-maximo-synchronization">
  <ng-container *ngIf="isSynchroValid; then synchroValidTemplate; else synchroNotValidTemplate"></ng-container>

  <ng-template #synchroValidTemplate>
    <ng-container *ngIf="itemsLength; else elseTemplate">
      <div class="text done">
        <div class="title" translate>Maximo synchronization</div>
        <div class="message element-to-sync" [ngClass]="{ 'disabled' : isDisabled }">
          <span (click)="gotToSynchroSummary()">{itemsLength, plural, =1 {1 {{'element to sync' | translate}} }
            other { {{itemsLength}} {{'elements to sync' | translate}} } }</span>
          <div style="display: none">
<!--            for ngx-translate extraction-->
            {{'element to sync' | translate}}
            {{'elements to sync' | translate}}
          </div>
        </div>
      </div>
      <div class="button done">
        <button [ngClass]="{ 'disabled' : isDisabled }" [disabled]="isDisabled" mat-flat-button (click)="refresh()">
          <mat-icon class="icon">notifications_active</mat-icon>
          <span translate>Refresh</span>
        </button>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="text done">
        <div class="title" translate>Maximo synchronization</div>
        <div class="message" [ngClass]="{ 'disabled' : isDisabled }">
          <span *ngIf="displaySync" (click)="gotToSynchroSummary()">
            {minutes, plural, =0 { {{'Up to date' | translate}} } =1 {1 {{'minute ago' | translate}} }
            other {{{minutes}} {{'minutes ago' | translate}} }}</span>
        </div>
        <div style="display: none">
          <!--            for ngx-translate extraction-->
          {{'Up to date'| translate}}
          {{'minute ago'| translate}}
          {{'minutes ago'| translate}}
        </div>
      </div>
      <div class="button done">
        <button [ngClass]="{ 'disabled' : isDisabled }" [disabled]="isDisabled" mat-icon-button (click)="refresh()">
          <mat-icon>done</mat-icon>
        </button>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #synchroNotValidTemplate>
    <div class="text error">
      <div class="title" translate>Maximo synchronization</div>
      <div class="message element-to-sync" [ngClass]="{ 'disabled' : isDisabled }">
        <span (click)="gotToSynchroSummary()" translate>Synchronization error</span>
      </div>
    </div>
    <div class="button error">
      <button [ngClass]="{ 'disabled' : isDisabled }" [disabled]="isDisabled" mat-flat-button
        (click)="gotToSynchroSummary()">
        <mat-icon class="icon">error_outline</mat-icon>
        <span translate>Review</span>
      </button>
    </div>
  </ng-template>


</div>
