export class AwsEndPoint {
  public static attachmentBt = 'maximo/WorkOrder/AttachmentBt';

  public static attachmentSr = 'maximo/ServiceRequest/AttachmentSr';

  public static createBt = 'maximo/WorkOrder/CreateBt';

  public static createQr = 'maximo/WorkOrder/CreateQr';

  public static createSr = 'maximo/ServiceRequest/Save';

  public static createWl = 'maximo/WorkOrder/CreateWl';

  public static equipmentGet = 'synchro/datalake/equipment';

  public static healthCheck = 'datalake/List/All';

  public static processGroupGet = 'synchro/datalake/processGroup';

  public static productionUnitGet = 'synchro/datalake/productionUnit';

  public static saveFr = 'maximo/WorkOrder/SaveFailureReport';

  public static saveTask = 'maximo/WorkOrder/SaveTask';

  public static startCompletion = 'maximo/WorkOrder/StartCompletion';

  public static submitAndClose = 'maximo/WorkOrder/SubmitAndClose';

  public static submitAndComplete = 'maximo/WorkOrder/SubmitAndComplete';

  public static workOrderGet = 'synchro/workorders';
}
