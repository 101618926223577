import {
  AttachmentSrCreateCache,
  AttachmentSrCreateHistoryService,
  AttachmentSrUpdateCache,
  AttachmentSrUpdateHistoryService,
} from '@al/cache';
import {
  ServiceRequest,
  ServiceRequestPut,
  ServiceRequestPutResponse,
} from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlOnlineService } from '@al/online';
import { AttachmentSrRestService } from '@al/rest';
import { Injectable } from '@angular/core';
import { AbstractSyncService } from './abstract-sync.service';
import { GtmSyncService } from './gtm-sync.service';
import { SyncInfoService } from './sync-info.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSrSyncService extends AbstractSyncService<
  ServiceRequest,
  ServiceRequestPut,
  ServiceRequestPutResponse
> {
  public constructor(
    protected restService: AttachmentSrRestService,
    protected createCache: AttachmentSrCreateCache,
    protected updateCache: AttachmentSrUpdateCache,
    protected createCachedHistoryService: AttachmentSrCreateHistoryService,
    protected updateCachedHistoryService: AttachmentSrUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  protected getNewResponse(): ServiceRequestPutResponse {
    return new ServiceRequestPutResponse();
  }
}
