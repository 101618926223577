/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */
import { Observable } from 'rxjs';

export interface AbstractCard {
  color?: string;
  count?: Observable<number>;
  icon?: string;
  id: string;
  isClickable: boolean;
  message: string;
  onSelect: any;
  onUnselect: any;
}

// export interface KpiCard extends AbstractCard {
//   // id: string;
//   color?: string;
//   count?: Observable<number>;
//   icon?: string;
//   message: string;
//   data: any;
//   method: any;
//   requestId: any;
//   isClickable: boolean;
// }
