import { UUID } from 'angular2-uuid';

import { HasUuid } from '@al/entities';

import { SyncInfoType } from '../enums/sync-info-type.enum';
import { SyncInfoStatus } from '../enums/sync-info-status.enum';

export class SyncInfo extends HasUuid {
  public constructor(
    public uuid: UUID,
    public type: SyncInfoType,
    public title: string,
    public status: SyncInfoStatus,
    public message: string,
    public number: string
  ) {
    super();
  }
}
