import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AlSessionModule } from '@al/session';

import { LoginComponent } from './login.component';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    AlSessionModule,
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class LoginModule {}
