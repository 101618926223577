import { WorkOrder } from '@al/entities';
import { WorkOrdersQuery } from '@al/state';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { WorkLog } from 'projects/al-entities/src/lib/dto/get/work-log.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlWorkOrderWorkLogDescriptionComponent } from './al-work-order-work-log-description/al-work-order-work-log-description.component';
import { AlWorkOrderWorkLogListService } from './al-work-order-work-log-list.service';
import { AlWorkOrderWorkLogComponent } from './al-work-order-work-log.component';

@Component({
  selector: 'al-work-order-work-log-list',
  templateUrl: './al-work-order-work-log-list.component.html',
  styleUrls: ['./al-work-order-work-log-list.component.scss']
})
export class AlWorkOrderWorkLogListComponent implements OnInit {
  public viewDetails: boolean;
  public workOrder!: WorkOrder;
  public workLogList: WorkLog[];
  private ngUnsubscribe = new Subject();
  public dataSource = new MatTableDataSource<WorkLog>([]);

  public isLoading: boolean;

  public displayedColumns = ['createdBy', 'createDate', 'type', 'description'];

  
  constructor(
    private workOrdersQuery: WorkOrdersQuery,
    private alWorkOrderWorkLogListService: AlWorkOrderWorkLogListService,
    private matDialog: MatDialog

  ) { 
    this.viewDetails = false;
    this.workLogList = [];
    this.isLoading = false;
  }
  
  public get workLogCount(): number {
    return this.workLogList.length;
  }


  public ngOnInit(): void {
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          const previousWorkOrder = this.workOrder;
          this.workOrder = res;
          if (
            previousWorkOrder &&
            previousWorkOrder.number !== this.workOrder.number
          ) {
            this.viewDetails = false;
          }
        }
      });

      this.workLogList = [];
      this.alWorkOrderWorkLogListService.workLogList
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((workLogList) => {
          this.workLogList = workLogList;
          if (workLogList) {
            this.dataSource = new MatTableDataSource<WorkLog>(
              workLogList
            );
          } else {
            this.dataSource = new MatTableDataSource<WorkLog>([]);
          }
        });
  }

  public startNewWorkLog(): void {
    this.matDialog.open(AlWorkOrderWorkLogComponent, {width: '40vw'})
  }
  
  public toggleViewDetails(): void {
    this.viewDetails = !this.viewDetails;
  }

  public openDetailsLongDescription(workOrder: WorkOrder): void {
    this.matDialog.open(AlWorkOrderWorkLogDescriptionComponent, {width: '20vw', data:{descriptionLong: workOrder.descriptionLong}})
  }

}
