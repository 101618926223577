import { LocalStorageKey } from '@al/entities';
import { Injectable } from '@angular/core';
import { AbstractSrHistoryService } from './abstract-sr-history.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSrUpdateHistoryService extends AbstractSrHistoryService {
  public constructor() {
    super(LocalStorageKey.ATTACHMENT_UPDATE_REQUEST_HISTORY);
  }
}
