import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isServiceWorkerReady: Observable<boolean>;

  private isServiceWorkerReady$: BehaviorSubject<boolean>;

  public constructor(private snackBar: MatSnackBar) {
    this.isServiceWorkerReady$ = new BehaviorSubject<boolean>(false);
    this.isServiceWorkerReady = this.isServiceWorkerReady$.asObservable();
  }

  public parseMessage(message: MessageEvent): void {
    if (message.data.origin === 'sw') {
      this.displaySnackbar(message);
    }
  }

  public setServiceWorkerState(ready: boolean): void {
    this.isServiceWorkerReady$.next(ready);
  }

  private displaySnackbar(message: MessageEvent): void {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 2000,
    };
    this.snackBar.open(message.data.message, undefined, config);
  }
}
