<div class="al-work-order-task" *ngIf="task">
  <mat-card [ngClass]="{'current-task': isCurrentTask}">

    <div class="title">
      <span>{{index+1 | number: '2.0-0'}}.</span>
      <span>{{task.name}}</span>
    </div>

    <div class="content">

      <div class="description">
        <ng-container *ngIf="task.description; else elseNoDescriptionTemplate">
          <span [innerHTML]="task.description"></span>
        </ng-container>
        <ng-template #elseNoDescriptionTemplate>
          <span translate>No description available</span>
        </ng-template>
      </div>

      <div class="side" *ngIf="!isCalibration">

        <ng-container *ngIf="canEdit; else elsecanNotEditTemplate">

          <span class="edit" (click)="clickOnEdit()" translate>Edit</span>
          <ng-container *ngIf="!getIconStatus(); else elseIsDoneTemplate">
            <mat-icon class="icon icon-skipped">highlight_off</mat-icon>
          </ng-container>
          <ng-template #elseIsDoneTemplate>
            <mat-icon class="icon icon-done">check_circle_outline</mat-icon>
          </ng-template>

        </ng-container>
        <ng-template #elsecanNotEditTemplate>
          <ng-container *ngIf="isCurrentTask">

            <button class="button button-skip" (click)="skip()" mat-raised-button color="primary"
              [title]="'Skip'|translate">
              <mat-icon>clear</mat-icon>
              <span translate>Skip</span>
            </button>
            <button class="button button-done" (click)="done()" mat-raised-button color="primary"
              [title]="'Done'|translate">
              <mat-icon>done</mat-icon>
              <span translate>Done</span>
            </button>

          </ng-container>
        </ng-template>

      </div>

    </div>

    <div class="calibration" *ngIf="isCalibration">

      <div class="calibration-description">
        <div class="row">
          <div class="column">
            <label translate>Cylinder Number</label>
            <span>{{task.standardPhyId}}</span>
          </div>
          <div class="column">
            <label translate>Expire On</label>
            <span>{{task.wol4 | date : 'short' }}</span>
          </div>
          <div class="column">
            <label translate>Certificate Number</label>
            <span>{{task.standardCertId}}</span>
          </div>
        </div>
        <div class="row">
          <label translate>Standard Description</label>
          <span>{{task.name}}</span>
        </div>
        <div class="row">
          <div class="column">
            <label translate>Standard Number</label>
            <span>{{task.standardNumero}}</span>
          </div>
          <div class="column">
            <span>
            </span>
          </div>
          <div class="column">
            <label translate>Meter Name</label>
            <span>{{task.meterName}}</span>
          </div>
        </div>
      </div>

      <div class="calibration-form">
        <mat-card>
          <al-work-order-task-calibration [workOrder]="workOrder" [task]="task" [editable]="isCurrentTask && !displaySecondCalibration"
            [calibrationIndex]="0">
          </al-work-order-task-calibration>
        </mat-card>
        <mat-card *ngIf="displaySecondCalibration">
          <al-work-order-task-calibration [workOrder]="workOrder" [task]="task" [editable]="isCurrentTask && displaySecondCalibration"
            [calibrationIndex]="1">
          </al-work-order-task-calibration>
        </mat-card>
      </div>

    </div>

  </mat-card>
</div>
