import {
  FailureReportPutResponse,
  FailureReportRequestPut,
  WorkOrder,
} from '@al/entities';
import { WorkOrdersService, WorkOrdersStore } from '@al/state';
import { FailureReportSyncService } from '@al/sync-services';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlWorkOrderFailureReportService {
  public failureReportIsModified: Observable<boolean>;

  private failureReportIsModified$: BehaviorSubject<boolean>;

  public constructor(
    private failureReportSynchroService: FailureReportSyncService,
    private workOrdersService: WorkOrdersService,
    private workOrdersStore: WorkOrdersStore
  ) {
    this.failureReportIsModified$ = new BehaviorSubject<boolean>(false);
    this.failureReportIsModified = this.failureReportIsModified$.asObservable();
  }

  public create(
    failureReport: FailureReportRequestPut
  ): Observable<FailureReportPutResponse> {
    // update the store of work orders with failure report data
    this.workOrdersStore.update(
      (wo: WorkOrder) => wo.number === failureReport.number,
      {
        rootCause: failureReport.rootCause,
        failureCode: failureReport.failureCode,
        failureMarkDescription: failureReport.remark,
        failureMarkDescriptionLong: failureReport.remarkDetails,
        failureDate: failureReport.failureDate,
        failureAcceptable: failureReport.failureAcceptable,
      }
    );

    this.setActiveWorkOrderInCache();
    // and send request to synchro service (create use but update could be too)
    return this.failureReportSynchroService.create(failureReport);
  }

  public setFailureReportIsModified(failureReportIsModified: boolean): void {
    this.failureReportIsModified$.next(failureReportIsModified);
  }

  private setActiveWorkOrderInCache(): void {
    this.workOrdersService.setActiveWorkOrderInCache();
  }
}
