/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */
import { Injectable } from '@angular/core';

import { LocalStorageConfiguration, WorkOrderStatus } from '@al/entities';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { AlWorkOrderTaskListService } from '@al/work-order-task-list';

import { KpiCard } from './kpi-card';

@Injectable({
  providedIn: 'root',
})
export class AlSiteSummaryService {
  public constructor(
    private workOrderQuery: WorkOrdersQuery,
    private alWorkOrderTaskListService: AlWorkOrderTaskListService
  ) {}

  /**
   * This function return the KpiCards array for the component, used as mock for the moment
   */
  public getKpiCards(): KpiCard[] {
    const kpiCards: KpiCard[] = [];
    const templateCard: KpiCard = {
      data: [],
      id: '1',
      message: '',
      isClickable: true,
      onSelect: () => {},
      onUnselect: () => {},
    };
    // to complete card config
    const toCompleteCard: KpiCard = {
      ...templateCard,
      color: '#49A02E',
      count: this.workOrderQuery.selectCount(
        ({ status }) => status === WorkOrderStatus.RLSD
      ),
      message: 'To complete',
      data: WorkOrderStatus.RLSD,
      onSelect: (workOrderService: WorkOrdersService) => {
        localStorage.setItem(
          LocalStorageConfiguration.FILTER_STATUS,
          WorkOrderStatus.RLSD
        );
        workOrderService.setFilter('status', WorkOrderStatus.RLSD);
      },
      onUnselect: (workOrderService: WorkOrdersService) => {
        workOrderService.removeFilter(`status`);
        localStorage.setItem(LocalStorageConfiguration.FILTER_STATUS, '');
      },
    };

    // delayed card config
    const delayedCard: KpiCard = {
      ...templateCard,
      color: '#D7001E',
      count: this.workOrderQuery.selectCount(
        ({ status, schedDateFin }) =>
          status === WorkOrderStatus.RLSD &&
          schedDateFin != null &&
          new Date() > new Date(schedDateFin)
      ),
      message: 'Delayed',
      data: 'DELAYED',
      onSelect: (workOrderService: WorkOrdersService) => {
        localStorage.setItem(
          LocalStorageConfiguration.FILTER_STATUS,
          'DELAYED'
        );
        workOrderService.setDelayedFilter();
      },
      onUnselect: (workOrderService: WorkOrdersService) => {
        workOrderService.removeFilter(`DELAYED`);
        localStorage.setItem(LocalStorageConfiguration.FILTER_STATUS, '');
      },
    };

    // to finish card config
    const toFinishCard: KpiCard = {
      ...templateCard,
      color: '#F7AD00',
      // @ts-ignore
      count: this.workOrderQuery.selectCount(
        ({ status, actDateDebut }) =>
          !!(status === WorkOrderStatus.RLSD && actDateDebut)
      ),
      // count: new Observable(0),
      message: 'To finish',
      data: 'TO_FINISH',

      onSelect: (workOrderService: WorkOrdersService) => {
        localStorage.setItem(
          LocalStorageConfiguration.FILTER_STATUS,
          'TO_FINISH'
        );
        workOrderService.setToFinishFilter();
      },

      onUnselect: (workOrderService: WorkOrdersService) => {
        workOrderService.removeFilter(`TO_FINISH`);
        localStorage.setItem(LocalStorageConfiguration.FILTER_STATUS, '');
      },
    };

    // complete card config
    const completedCard: KpiCard = {
      ...templateCard,
      color: '#375F9B',
      count: this.workOrderQuery.selectCount(
        ({ status }) => status === WorkOrderStatus.COMP
      ),
      message: 'Completed',
      data: WorkOrderStatus.COMP,

      onSelect: (workOrderService: WorkOrdersService) => {
        localStorage.setItem(
          LocalStorageConfiguration.FILTER_STATUS,
          WorkOrderStatus.COMP
        );
        workOrderService.setFilter('status', WorkOrderStatus.COMP);
      },

      onUnselect: (workOrderService: WorkOrdersService) => {
        workOrderService.removeFilter(`status`);
        localStorage.setItem(LocalStorageConfiguration.FILTER_STATUS, '');
      },
    };

    kpiCards.push(toCompleteCard);
    kpiCards.push(delayedCard);
    kpiCards.push(toFinishCard);
    kpiCards.push(completedCard);

    return kpiCards;
  }
}
