import { JsonObject, JsonProperty } from 'json2typescript';

import { Get } from './get.model';

/**
 * This class represents Site model
 */
@JsonObject('Site')
export class Site extends Get {
  @JsonProperty('maximoId', String, true)
  public maximoId = '';

  @JsonProperty('name', String, true)
  public name = '';
}
