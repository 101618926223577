import { Injectable } from '@angular/core';
import { DomainReference } from './al-reference-domain';
import { AlReferenceType } from './al-reference-type';
import { WorkOrderJobTypeReference } from './al-reference-work-order-job-type';
import { TaskPriorityReference } from './al-reference-task-priority';
import { WorkOrderSourceReference } from './al-reference-work-order-source';
import { WorkTypeReference } from './al-reference-work-type';
import { AlReferenceData } from './model/al-reference-data.model';
import { AlReference } from './model/al-reference.model';
import { ClassStructureReference } from './al-reference-class-structure';
import { FailureCodeReference } from './al-reference-failure-code';
import { RootCauseReference } from './al-reference-root-cause';

@Injectable({
  providedIn: 'root',
})
export class AlReferenceService {
  private referenceData: ReadonlyArray<
    [AlReferenceType, ReadonlyArray<AlReferenceData>]
  > = [
    ['domain', DomainReference],
    ['workOrderJobType', WorkOrderJobTypeReference],
    ['taskPriority', TaskPriorityReference],
    ['workOrderSource', WorkOrderSourceReference],
    ['workType', WorkTypeReference],
    ['classStructure', ClassStructureReference],
    ['failureCode', FailureCodeReference],
    ['rootCause', RootCauseReference],
  ];

  public getReferenceData(
    references: ReadonlyArray<AlReferenceType>
  ): AlReference {
    const requestedReferential = this.referenceData.filter(([type]) =>
      references.includes(type)
    );
    return requestedReferential.reduce(
      (o, [key, value]) => ({ ...o, [key]: value }),
      {}
    );
  }
}
