import { JsonObject, JsonProperty } from 'json2typescript';
import { DateJsonConverter } from '../../json-converters/date-json-converter';

import { PutRequest } from './put-request.model';

/**
 * This class represents Failure Report model
 */
@JsonObject('FailureRequestPut')
export class FailureReportRequestPut extends PutRequest {
  @JsonProperty('actualFinish', DateJsonConverter, true)
  public actualFinish: Date | null = null;

  @JsonProperty('actualStart', DateJsonConverter, true)
  public actualStart: Date | null = null;

  @JsonProperty('failureAcceptable', Boolean)
  public failureAcceptable = false;

  @JsonProperty('failureCode', String, true)
  public failureCode: string | null = null;

  @JsonProperty('failureDate', DateJsonConverter, true)
  public failureDate: Date | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('remark', String, true)
  public remark: string | null = null;

  @JsonProperty('remarkDetails', String, true)
  public remarkDetails: string | null = null;

  @JsonProperty('rootCause', String, true)
  public rootCause: string | null = null;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;

  @JsonProperty('source', String, true)
  public source: string | null = null;
}
