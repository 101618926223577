import { Injectable } from '@angular/core';
import { Equipment, Site } from '@al/entities';
import { EquipmentsRestService } from '@al/rest';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EquipmentSyncService {
  public constructor(protected restService: EquipmentsRestService) {}

  public getFromSite(site: Site | undefined): Observable<Equipment[]> {
    if (site) {
      return this.restService.getFromSite(site).pipe(
        map((data: Equipment[]) => {
          return data;
        })
      );
    }
    return of([]);
  }
}
