import { ProductionUnit, Site } from '@al/entities';
import { ProductionUnitSyncService } from '@al/sync-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductionUnitsQuery } from './production-unit.query';
import {
  ProductionUnitState,
  ProductionUnitsStore,
} from './production-unit.store';
import { SiteService } from './site.service';

@Injectable({ providedIn: 'root' })
export class ProductionUnitsService {
  private TAG = 'ProductionUnitsService>>>';

  private filtersProductionUnit: AkitaFiltersPlugin<ProductionUnitState>;

  public constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private productionUnitQuery: ProductionUnitsQuery,
    private productionUnitStore: ProductionUnitsStore,
    private productionUnitSynchroService: ProductionUnitSyncService
  ) {
    this.filtersProductionUnit = new AkitaFiltersPlugin<ProductionUnitState>(
      this.productionUnitQuery
    );
  }

  public get(): Observable<void> {
    return this.productionUnitSynchroService
      .getFromSite(
        this.siteService.getActive() ? this.siteService.getActive() : new Site()
      )
      .pipe(
        map((data: ProductionUnit[]) => {
          // update store with back return
          this.productionUnitStore.reset();
          this.productionUnitStore.set(data);
        })
      );
  }

  public getActive(): ProductionUnit | undefined {
    return this.productionUnitQuery.getActive();
  }

  public getAll(): Observable<ProductionUnit[]> {
    return this.productionUnitQuery.selectAll();
  }

  public getById(ids: string | null): Observable<ProductionUnit | undefined> {
    return this.productionUnitQuery.selectEntity((entity: ProductionUnit) => {
      return entity.id === ids;
    });
  }

  public setActive(id: ID): void {
    this.productionUnitStore.setActive(id);
  }
}
