<div class="container">
  <div mat-dialog-title>
    <div class="header">
      <h1>{{ data.title | translate }}</h1>
      <mat-icon [mat-dialog-close]="data.information.status">
        close
      </mat-icon>
    </div>
  </div>
  <div mat-dialog-content>
    <ng-container [ngSwitch]="data.type">
      <al-wo-put-information-dialog *ngSwitchCase="'woPut'" [data]="data"></al-wo-put-information-dialog>
      <al-wo-wl-put-information-dialog *ngSwitchCase="'woWlPut'" [data]="data"></al-wo-wl-put-information-dialog>
      <al-sr-put-information-dialog *ngSwitchCase="'srPut'" [data]="data"></al-sr-put-information-dialog>
      <al-qr-put-information-dialog *ngSwitchCase="'qrPut'" [data]="data"></al-qr-put-information-dialog>
      <al-fr-put-information-dialog *ngSwitchCase="'frPut'" [data]="data"></al-fr-put-information-dialog>
      <al-wo-comp-information-dialog *ngSwitchCase="'woCompClose'" [data]="data"></al-wo-comp-information-dialog>
    </ng-container>
  </div>
  <div class="actions" mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="data.information.status">
      {{ "ok" | translate }}
    </button>
  </div>
</div>
