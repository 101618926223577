<div class="al-work-order-task-calibration">

  <div class="row">
    <label [title]="'Measure Type'|translate" translate>Measure Type</label>
    <span>{{getTaskType}}</span>
  </div>

  <div class="row">
    <div class="column column-h">
      <label [title]="'Standard Input'|translate" translate>Standard Input</label>
      <ng-container *ngIf="task.standardInput !== null && task.standardInput !== undefined; else elseNoValue">
        <span>{{task.standardInput | number:'1.0-3'}}</span>
      </ng-container>
    </div>
    <div class="column column-h">
      <label [title]="'Tolerance'|translate" translate>Tolerance</label>
      <ng-container *ngIf="task.tolerance !== null && task.tolerance !== undefined; else elseNoValue">
        <span>{{task.tolerance | number:'1.0-3'}}</span>
      </ng-container>
    </div>
    <div class="column column-h">
      <label [title]="'Uncertainty'|translate" translate>Uncertainty</label>
      <ng-container *ngIf="task.uncertainty !== null && task.uncertainty !== undefined; else elseNoValue">
        <span>{{task.uncertainty}}</span>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <label [title]="'Measurement Value'|translate" translate>Measurement Value</label>
    <ng-container *ngIf="calibrationMeasure !== null && calibrationMeasure !== undefined; else elseNoValue">
      <span *ngIf="!editable">{{calibrationMeasure}} {{getTaskUnit}}</span>
    </ng-container>
  </div>

  <div class="row">

    <form *ngIf="editable" [formGroup]="form">
      <mat-form-field>
        <input matInput formControlName="measure" type="number" [placeholder]="'Enter measure'|translate"
        (blur)="checkCalibrationMeasure()">
        <span matSuffix>
          <button mat-icon-button (click)="measurePlus()">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button (click)="measureMinus()">
            <mat-icon>remove</mat-icon>
          </button>
           {{getTaskUnit}}</span>
      </mat-form-field>
    </form>

    <div class="column">
      <label [title]="'Delta as found'|translate" translate>Delta as found</label>
      <span>{{delta | number:'1.0-3'}}</span>
    </div>

    <div class="column">
      <label [title]="'% Delta as found/scale'|translate">{{'% Delta as found/scale:' | translate}}</label>
      <span>{{deltaPercent | number:'1.0-2'}}</span>
    </div>

  </div>

  <div class="row">

    <div class="column">
      <label [title]="'Status'|translate" translate>Status</label>
      <span>{{ calibrationStatus ? calibrationStatus : ''|translate }}</span>
    </div>

    <div class="column">
      <span>{{AlarmMin}} ({{ 'ALARM'|translate }}) / {{AdjustmentMin}} ({{ 'ADJUSTMENT'|translate }})
        / {{ 'CHECKED'|translate }} / {{AdjustmentMax}}
         ({{ 'ADJUSTMENT'|translate }}) / {{AlarmMax}} ({{ 'ALARM'|translate }})
      </span>
    </div>

    <span *ngIf="!editable && task.measurementDate">{{ task.measurementDate | date : 'short'}}</span>

  </div>

  <div class="row buttons">
    <button *ngIf="editable" [disabled]="!form.dirty || !form.valid"
      [ngClass]="{'disabled' : !form.dirty || !form.valid}" [title]="'Validate'|translate" (click)="validate()"
      class="icon-ok" mat-raised-button>
      {{'Validate' | translate}}
    </button>

    <span class="edit" *ngIf="!editable && (calibrationMeasure || calibrationMeasure === 0)"
      (click)="startEdition()">{{'Edit' | translate}}</span>
  </div>


</div>

<ng-template #elseNoValue>
  <span>-</span>
</ng-template>
