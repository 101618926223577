import { LocalStorageKey, QuickReportPut } from '@al/entities';
import { Injectable } from '@angular/core';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class QuickReportCreateCache extends LocalCache<QuickReportPut> {
  public constructor() {
    super(LocalStorageKey.QUICK_REPORT_CREATE_CACHE);
  }

  protected getClazz(): typeof QuickReportPut {
    return QuickReportPut;
  }
}
