import { LocalStorageKey, WorkOrderPut } from '@al/entities';
import { Injectable } from '@angular/core';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class AddWorkLogCreateCache extends LocalCache<WorkOrderPut> {
  public constructor() {
    super(LocalStorageKey.ADD_WORKLOG_CREATE_CACHE);
  }

  protected getClazz(): typeof WorkOrderPut {
    return WorkOrderPut;
  }
}
