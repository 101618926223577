import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { AlWorkOrderTaskComponent } from './al-work-order-task.component';
import { AlWorkOrderTaskListComponent } from './al-work-order-task-list.component';
import { AlWorkOrderTaskCalibrationComponent } from './al-work-order-task-calibration.component';

@NgModule({
  declarations: [
    AlWorkOrderTaskListComponent,
    AlWorkOrderTaskComponent,
    AlWorkOrderTaskCalibrationComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],

  exports: [AlWorkOrderTaskListComponent],
})
export class AlWorkOrderTaskListModule {}
