import { LocalStorageConfiguration } from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlOnlineService } from '@al/online';
import { WorkOrdersStore } from '@al/state';
import { AlSyncService } from '@al/sync';
import { SyncInfo, SyncInfoService, SyncInfoType } from '@al/sync-services';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-synchro-summary',
  templateUrl: './synchro-summary.component.html',
  styleUrls: ['./synchro-summary.component.scss'],
})
export class SynchroSummaryComponent implements OnInit, OnDestroy {
  public currenSynchroInfo!: SyncInfo;

  public displayedColumns: string[] = ['type', 'title', 'status', 'review'];

  public errorDataSource = new MatTableDataSource<SyncInfo>([]);

  public isOnline: boolean;

  public pending = 'pending';

  public pendingAttachementsDataSource = new MatTableDataSource<SyncInfo>([]);

  public pendingDataSource = new MatTableDataSource<SyncInfo>([]);

  private ngUnsubscribe = new Subject();

  public constructor(
    public dialog: MatDialog,
    private alSyncService: AlSyncService,
    private onLineService: AlOnlineService,
    private router: Router,
    private synchroInfoService: SyncInfoService,
    private workOrdersStore: WorkOrdersStore,
    private indexedDbService: AlIndexedDbService
  ) {
    this.isOnline = false;
  }

  public deleteHistory(element: SyncInfo): void {
    this.synchroInfoService.deleteFromHistory(element);
  }

  public deletePending(element: SyncInfo): void {
    this.synchroInfoService.deleteFromPending(element);
  }

  public deletePendingAttachment(element: SyncInfo): void {
    this.synchroInfoService.deleteFromPending(element);
    this.indexedDbService.delete(element.uuid.toString());
  }

  public getErrorCount(): Observable<number> {
    return this.synchroInfoService.getErrorsCount();
  }

  public getPendingAttachmentCount(): Observable<number> {
    return this.synchroInfoService.getPendingAttachmentCount();
  }

  public getPendingCount(): Observable<number> {
    return this.synchroInfoService.getPendingCount();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: boolean) => {
        this.isOnline = res;
      });
    this.loadPendingFromCache();
    this.loadErrorFromCache();
  }

  public openDialogWithRef(ref: TemplateRef<any>, synchroInfo: SyncInfo) {
    this.currenSynchroInfo = synchroInfo;
    this.dialog.open(ref);
  }

  public refresh(): void {
    this.alSyncService.sync();
  }

  public returnHome(): void {
    this.router.navigate(['/']);
  }

  public review(synchroInfo: SyncInfo): void {
    switch (synchroInfo.type) {
      case SyncInfoType.QR_CREATION:
        this.router.navigate(['/', 'review-qr', synchroInfo.uuid]);
        break;
      case SyncInfoType.SR_CREATION:
        this.router.navigate(['/', 'review-sr', synchroInfo.uuid]);
        break;
      case SyncInfoType.WO_CREATION:
        this.router.navigate(['/', 'review-bt', synchroInfo.uuid]);
        break;
      case SyncInfoType.WO_UPDATE:
        this.workOrdersStore.setActive(synchroInfo.uuid);
        localStorage.setItem(
          LocalStorageConfiguration.CURRENT_WORK_ORDER_ID,
          synchroInfo.uuid.toString()
        );
        this.router.navigate(['/', 'workorder']);
        break;
      default:
        break;
    }
  }

  public setPending(value: string): void {
    this.pending = value;
  }

  public showErrors(): boolean {
    return this.pending === 'err';
  }

  public showPending(): boolean {
    return this.pending === 'pending';
  }

  public showPendingAttachments(): boolean {
    return this.pending === 'file';
  }

  private loadErrorFromCache(): void {
    this.synchroInfoService
      .getErrors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (pendings: SyncInfo[]) => {
          if (pendings) {
            this.errorDataSource = new MatTableDataSource<SyncInfo>(pendings);
          } else {
            this.errorDataSource = new MatTableDataSource<SyncInfo>([]);
          }
        },
        error: () => {
          this.errorDataSource = new MatTableDataSource<SyncInfo>([]);
        },
      });
  }

  private loadPendingFromCache(): void {
    this.synchroInfoService
      .getPending()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (pendings: SyncInfo[]) => {
          if (pendings) {
            const minusAttachemnts = pendings.filter(
              (pending) => pending.type !== 'ATTACHMENT'
            );
            const onlyAttachments = pendings.filter(
              (pending) => pending.type === 'ATTACHMENT'
            );
            this.pendingDataSource = new MatTableDataSource<SyncInfo>(
              minusAttachemnts
            );
            if (minusAttachemnts.length === 0 && onlyAttachments.length > 0) {
              this.setPending('file');
            }
            this.pendingAttachementsDataSource =
              new MatTableDataSource<SyncInfo>(onlyAttachments);
          } else {
            this.pendingDataSource = new MatTableDataSource<SyncInfo>([]);
            this.pendingAttachementsDataSource =
              new MatTableDataSource<SyncInfo>([]);
          }
        },
        error: () => {
          this.pendingDataSource = new MatTableDataSource<SyncInfo>([]);
          this.pendingAttachementsDataSource = new MatTableDataSource<SyncInfo>(
            []
          );
        },
      });
  }
}
