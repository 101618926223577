import { Injectable } from '@angular/core';
import { ProcessGroup, Site } from '@al/entities';
import { ProcessGroupsRestService } from '@al/rest';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProcessGroupSyncService {
  public constructor(protected restService: ProcessGroupsRestService) {}

  public getFromSite(site: Site | undefined): Observable<ProcessGroup[]> {
    if (site) {
      return this.restService.getFromSite(site).pipe(
        map((data: ProcessGroup[]) => {
          return data;
        })
      );
    }
    return of([]);
  }
}
