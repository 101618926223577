import { AlEnvironmentService } from '@al/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TasksListsQuery } from './tasks-lists.query';
import { TasksListsStore } from './tasks-list.store';

@Injectable({ providedIn: 'root' })
export class TasksListsService {
  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private http: HttpClient,
    private tasksListsQuery: TasksListsQuery,
    private tasksListsStore: TasksListsStore
  ) {}

  public get(): Observable<any> {
    const endpoint = `${this.alEnvironmentService.env.apiUrl}rootcauses`;
    const request = this.http.get<any>(endpoint).pipe(
      tap((entities) => {
        this.tasksListsStore.set(entities);
      })
    );
    return this.tasksListsQuery.getHasCache() === false
      ? request
      : new Observable<any>();
  }
}
