import {
  DateSelectorItem,
  GtmPut,
  Site,
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse,
} from '@al/entities';
import { WorkOrderRestService } from '@al/rest';
import { AlOnlineService } from '@al/online';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  WorkOrderCache,
  WorkOrderCreateCache,
  WorkOrderCreateHistoryService,
  WorkOrderUpdateCache,
  WorkOrderUpdateHistoryService,
} from '@al/cache';

import { map } from 'rxjs/operators';
import { AlIndexedDbService } from '@al/indexed-db';
import { AbstractSyncService } from './abstract-sync.service';
import { SyncInfoService } from './sync-info.service';
import { GtmSyncService } from './gtm-sync.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderSyncService extends AbstractSyncService<
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse
> {
  private workOrderCache = new WorkOrderCache();

  public constructor(
    protected restService: WorkOrderRestService,
    protected createCache: WorkOrderCreateCache,
    protected updateCache: WorkOrderUpdateCache,
    protected createCachedHistoryService: WorkOrderCreateHistoryService,
    protected updateCachedHistoryService: WorkOrderUpdateHistoryService,
    protected onLineService: AlOnlineService,
    protected syncInfoService: SyncInfoService,
    protected gtmSyncService: GtmSyncService,
    protected alIndexedDbService: AlIndexedDbService
  ) {
    super(
      restService,
      createCache,
      updateCache,
      createCachedHistoryService,
      updateCachedHistoryService,
      onLineService,
      syncInfoService,
      gtmSyncService,
      alIndexedDbService
    );
  }

  public addToCache(workorder: WorkOrder): void {
    this.workOrderCache.put(workorder);
  }

  public getCacheValue(): WorkOrder[] {
    return this.workOrderCache.values();
  }

  // le get fait appel au au rest via le package sync
  // pas d'appel direct du package rest sans passer par al-sync-helper

  public getFromSiteAndPeriod(
    site: Site | undefined,
    period: DateSelectorItem | undefined,
    refresh: boolean = false
  ): Observable<WorkOrder[]> {
    if (site && period) {
      return this.restService.getFromSiteAndPeriod(site, period, refresh).pipe(
        map((data: WorkOrder[]) => {
          const workOrderCache = new WorkOrderCache();
          workOrderCache.clear();
          return data;
        })
      );
    }

    return of([]);
  }

  protected fillGtmWithData(value: WorkOrderPut): void {
    // find if existing previous gtm
    const gtm = this.gtmSyncService.getGtmTag(value.uuid);
    if (gtm) {
      gtm.uuid = value.uuid;
      gtm.siteId = value.siteId;
      gtm.asset = value.assetNum;
      gtm.location = value.location;
      if (value.priority) {
        gtm.priority = value.priority.replace(/^P/, '');
      } else {
        gtm.priority = value.priority;
      }
      gtm.workType = value.type;
      gtm.jobType = value.jobType;
      gtm.onLine = this.onLineService.onLine();
      gtm.event = 'WO_CREATION';
      this.gtmSyncService.saveGtmTag(gtm);
    }
  }

  // on rajoute les erre
  protected fillGtmWithResponse(
    gtm: GtmPut,
    value: WorkOrderPutResponse
  ): void {
    const cachedGtm = this.gtmSyncService.getGtmTag(gtm.uuid);
    if (cachedGtm) {
      cachedGtm.number = value.workOrderNum;
      this.gtmSyncService.addGenericReponseInformation(cachedGtm, value);
    }
  }

  protected getNewResponse(): WorkOrderPutResponse {
    return new WorkOrderPutResponse();
  }
}
