import { Equipment, Site } from '@al/entities';
import { EquipmentSyncService } from '@al/sync-services';
import { Injectable } from '@angular/core';
import { ID, Order } from '@datorama/akita';
import { AkitaFilter, AkitaFiltersPlugin } from 'akita-filters-plugin';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EquipmentsQuery } from './equipments.query';
import { EquipmentState, EquipmentsStore } from './equipments.store';
import { SiteService } from './site.service';

@Injectable({ providedIn: 'root' })
export class EquipmentsService {
  private TAG = 'EquipmentsService>>>';

  private filtersEquipment: AkitaFiltersPlugin<EquipmentState>;

  public constructor(
    private siteService: SiteService,
    private equipmentsQuery: EquipmentsQuery,
    private equipmentsStore: EquipmentsStore,
    private equipmentSynchroService: EquipmentSyncService
  ) {
    this.filtersEquipment = new AkitaFiltersPlugin<EquipmentState>(
      this.equipmentsQuery
    );
  }

  public add(equipment: Equipment): void {
    this.equipmentsStore.add(equipment);
  }

  public get(): Observable<void> {
    return this.equipmentSynchroService
      .getFromSite(
        this.siteService.getActive() ? this.siteService.getActive() : new Site()
      )
      .pipe(
        map((data: Equipment[]) => {
          // update store with back return
          this.equipmentsStore.reset();
          this.equipmentsStore.set(data);
        })
      );
  }

  public getActive(): Equipment | undefined {
    return this.equipmentsQuery.getActive();
  }

  public getAll(): Observable<Equipment[]> {
    return this.equipmentsQuery.selectAll();
  }

  public getById(id: string | null): Observable<Equipment | undefined> {
    return this.equipmentsQuery.selectEntity(id);
  }

  public getByParent(id: string | null): Observable<Equipment[]> {
    return this.equipmentsQuery.selectAll({
      filterBy: (index) =>
        index.processGroupId === id && index.parentId === null,
    });
  }

  public getFilterValue(id: string): any | null {
    return this.filtersEquipment.getFilterValue(id);
  }

  public getParent(ids: string | null): Observable<Equipment | undefined> {
    // return this.processGroupQuery.selectEntity(
    //   (entity: ProcessGroup) => entity.number === ids
    // );
    return this.equipmentsQuery.selectEntity((entity: Equipment) => {
      return entity.id === ids;
    });
  }

  public getSortValue(): string | null {
    const sortValue = this.filtersEquipment.getSortValue();
    if (!sortValue) {
      return '+title';
    }
    const order = sortValue.sortByOrder === Order.ASC ? '+' : '-';
    return sortValue.sortBy ? order + sortValue.sortBy : '+title';
  }

  public getSubAssetByParent(id: string | null): Observable<Equipment[]> {
    return this.equipmentsQuery.selectAll({
      filterBy: (index) => index.parentId === id,
    });
  }

  public hasEntity(id: string | null): boolean {
    return this.equipmentsQuery.hasEntity(
      (entity: Equipment) => entity.processGroupId === id
    );
  }

  public hasEntityParentId(id: string | null): boolean {
    return this.equipmentsQuery.hasEntity(
      (entity: Equipment) => entity.parentId === id
    );
  }

  public remove(id: ID): void {
    this.equipmentsStore.remove(id);
  }

  public removeAllFilter(): void {
    this.filtersEquipment.clearFilters();
  }

  public removeFilter(id: string): void {
    this.filtersEquipment.removeFilter(id);
  }

  public selectAll(): Observable<Equipment[]> {
    return this.filtersEquipment.selectAllByFilters() as Observable<
      Equipment[]
    >;
  }

  public selectFilters(): Observable<AkitaFilter<EquipmentState>[]> {
    return this.filtersEquipment.selectFilters();
  }

  public setActive(id: ID): void {
    this.equipmentsStore.setActive(id);
  }

  public setFilter(filter: AkitaFilter<EquipmentState>): void {
    this.filtersEquipment.setFilter(filter);
  }

  public setOrderBy(by: any, order: string | '+' | '-'): void {
    this.filtersEquipment.setSortBy({
      sortBy: by,
      sortByOrder: order === '+' ? Order.ASC : Order.DESC,
    });
  }

  public update(id: ID, equipment: Partial<Equipment>): void {
    this.equipmentsStore.update(id, equipment);
  }
}
