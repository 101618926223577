import { LocalStorageConfiguration } from '@al/entities';
import { AlOnlineService } from '@al/online';
import { SessionQuery, UserRightsCreateCache } from '@al/session';
import { AlSpinnerService } from '@al/spinner';
import { AlSyncService } from '@al/sync';
import { SyncInfoService } from '@al/sync-services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { Auth } from 'aws-amplify';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AlAccountResetPasswordDialogComponent } from './al-account-reset-password-dialog.component';

@Component({
  selector: 'al-account',
  templateUrl: './al-account.component.html',
  styleUrls: ['./al-account.component.scss'],
})
export class AlAccountComponent implements OnInit, OnDestroy {
  public buttonStyle: { 'background-image': string };

  public email: string;

  public fullName: string;

  public hasImage!: boolean;

  public isOnline!: boolean;

  public rights = new UserRightsCreateCache();

  private isSynchroValid = false;

  private ngUnsubscribe = new Subject();

  public constructor(
    private alSyncService: AlSyncService,
    private sessionQuery: SessionQuery,
    private translateService: TranslateService,
    private onLineService: AlOnlineService,
    private spinnerService: AlSpinnerService,
    private synchroInfoService: SyncInfoService,
    public dialog: MatDialog
  ) {
    this.fullName = '';
    this.email = '';

    this.sessionQuery.fullName.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (fullName: string) => {
        this.fullName = fullName || '';
      },
      error: () => {
        this.fullName = '';
      },
    });

    this.sessionQuery.email.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (email: string) => {
        this.email = email || '';
      },
      error: () => {
        this.email = '';
      },
    });

    this.buttonStyle = { 'background-image': 'none' };
    this.sessionQuery.imageUrl.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (profileImageUrl) => {
        if (
          profileImageUrl &&
          typeof profileImageUrl === 'string' &&
          profileImageUrl.trim().length > 0
        ) {
          this.buttonStyle = { 'background-image': `url(${profileImageUrl})` };
          this.hasImage = true;
        } else {
          this.buttonStyle = { 'background-image': 'none' };
          this.hasImage = false;
        }
      },
      error: () => {
        this.buttonStyle = { 'background-image': 'none' };
        this.hasImage = false;
      },
    });
    this.synchroInfoService
      .getErrorsCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: number) => {
        this.isSynchroValid = count === 0;
      });
  }

  public logout(): void {
    // TODO: A décomposer, Spinner + Disconnection Message [16]
    const uuid = UUID.UUID();
    this.spinnerService.startAssetProcess(uuid, 'Disconnecting, please wait!');
    this.alSyncService.pushBeforeLogout().subscribe((res) => {
      if (res) {
        if (this.isSynchroValid) {
          this.rights.clear();
          Auth.signOut().then(() => {
            this.spinnerService.stopAssetProcess(uuid);
          });
        } else {
          this.spinnerService.stopAssetProcess(uuid);
          this.spinnerService.launchSnackBar(
            'Synchronization failed due to some errors, please have a look before disconnecting',
            5
          );
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isOnline = res;
      });
  }

  public openResetPasswordDialog(): void {
    this.dialog.open(AlAccountResetPasswordDialogComponent, {
      data: { email: this.email },
    });
  }

  public setLanguage(lang: string): void {
    localStorage.setItem(LocalStorageConfiguration.LANGUAGE_KEY, lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }
}
