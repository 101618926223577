import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { AlSiteSelectorComponent } from './al-site-selector.component';

@NgModule({
  declarations: [AlSiteSelectorComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, MatSelectModule],
  exports: [AlSiteSelectorComponent],
})
export class AlSiteSelectorModule {}
