import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AwsEndPoint } from '@al/environment';
import { AlSyncComponent } from '@al/sync';

import { AuthGuard } from './core/auth.guard';
import { routes as createBtRoutes } from './create-bt/create-bt.routes';
import { routes as createSrRoutes } from './create-sr/create-sr.routes';
import { routes as dashboardRoutes } from './dashboard/dashboard.routes';
import { routes as loginRoutes } from './login/login.routes';
import { routes as quickReportRoutes } from './quick-report/quick-report.routes';
import { routes as synchroSummaryRoutes } from './synchro-summary/synchro-summary.routes';
import { routes as workOrderRoutes } from './work-order/work-order.routes';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'callback',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'create-bt',
    children: createBtRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-sr',
    children: createSrRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    children: dashboardRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    children: loginRoutes,
  },
  {
    path: 'logout',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'quick-report',
    children: quickReportRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'review-bt/:synchroInfoUuid',
    children: createBtRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'review-qr/:synchroInfoUuid',
    children: quickReportRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'review-sr/:synchroInfoUuid',
    children: createSrRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'synchrosummary',
    children: synchroSummaryRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'workorder',
    children: workOrderRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: `${AwsEndPoint.equipmentGet}/:site`,
    component: AlSyncComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${AwsEndPoint.workOrderGet}/:site/:period`,
    component: AlSyncComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
