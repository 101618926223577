import { Component, Input, OnInit } from '@angular/core';

import { Equipment } from '@al/entities';
import { EquipmentsService, ProcessGroupsService } from '@al/state';

@Component({
  selector: 'al-equipment-details',
  templateUrl: './al-equipment-details.component.html',
  styleUrls: ['./al-equipment-details.component.scss'],
})
export class AlEquipmentDetailsComponent implements OnInit {
  @Input()
  public equipment!: Equipment | null;

  @Input()
  public location!: string | null | undefined;

  public parentLabel = '';

  public constructor(
    private processGroupsService: ProcessGroupsService,
    private equipmentsService: EquipmentsService
  ) {}

  public ngOnInit(): void {
    this.getParentEquipementLabelIfExist();
  }

  private getParentEquipementLabelIfExist(): void {
    if (
      this.equipment?.parentId !== null &&
      this.equipment?.parentId !== undefined
    ) {
      this.equipmentsService
        .getParent(this.equipment?.parentId)
        .subscribe((value) => {
          if (value) {
            this.parentLabel = `${value?.processTag} / ${value?.number}`;
          } else {
            this.getParentProcessGroupLabelIfExist();
          }
        });
    }
  }

  private getParentProcessGroupLabelIfExist(): void {
    if (
      this.equipment?.parentId !== null &&
      this.equipment?.parentId !== undefined
    ) {
      this.processGroupsService
        .getByNumber(this.equipment?.parentId)
        .subscribe((value) => {
          this.parentLabel = `${value?.number}`;
        });
    }
  }
}
