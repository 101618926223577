import { WorkOrder } from '@al/entities';
import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';

export interface WorkOrdersState extends EntityState<WorkOrder>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'work-orders', resettable: true })
export class WorkOrdersStore extends EntityStore<WorkOrdersState> {
  public constructor() {
    super();
  }
}
