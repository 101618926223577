import { Injectable, OnDestroy } from '@angular/core';
import {
  Attachment,
  AttachmentItem,
  ServiceRequestPut,
  ServiceRequestPutResponse,
} from '@al/entities';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs/operators';
import {
  AttachmentSrSyncService,
  ServiceRequestSyncService,
} from '@al/sync-services';

@Injectable({ providedIn: 'root' })
export class ServiceRequestService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  public constructor(
    private attachmentSrSyncService: AttachmentSrSyncService,
    private sync: ServiceRequestSyncService
  ) {}

  public addAttachment(
    attachmentItem: AttachmentItem,
    googleDriveFileId: string,
    googleDriveFileName: string
  ): Observable<boolean> {
    const obs = new BehaviorSubject(false);
    const attachmentUrl = `https://drive.google.com/file/d/${googleDriveFileId}/view`;
    const attachment = new Attachment();
    attachment.filename = googleDriveFileName;
    attachment.url = attachmentUrl;
    attachment.uuid = UUID.UUID();
    const requestPut = new ServiceRequestPut();
    if (attachmentItem.site) {
      requestPut.siteId = attachmentItem.site;
    }
    if (attachmentItem.ticketId) {
      requestPut.ticketId = attachmentItem.ticketId;
    }
    requestPut.attachments = [attachment];
    this.attachmentSrSyncService
      .update(requestPut)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        obs.next(true);
      });
    return obs.asObservable();
  }

  public create(
    request: ServiceRequestPut
  ): Observable<ServiceRequestPutResponse> {
    return this.sync.create(request);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
