import { Injectable } from '@angular/core';

import { EnvironmentOptions } from './environment-options';

@Injectable({
  providedIn: 'root',
})
export class AlEnvironmentService {
  public env: EnvironmentOptions;

  public constructor(environment: EnvironmentOptions) {
    this.env = environment;
  }
}
