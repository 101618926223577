import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { AlWorkOrderDescriptionComponent } from './al-work-order-description.component';

@NgModule({
  declarations: [AlWorkOrderDescriptionComponent],
  imports: [CommonModule, TranslateModule],
  exports: [AlWorkOrderDescriptionComponent],
})
export class AlWorkOrderDescriptionModule {}
