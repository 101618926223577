<div class="al-photo">
  <input type="file" accept="image/*" capture class="file-input" (change)="onFileSelected($event, editPhoto)"
    #fileUpload>
  <button mat-raised-button class="upload-button" color="primary" type="button" [disabled]="disableButton"
    (click)="fileUpload.click()">
    <mat-icon>add_a_photo</mat-icon>
    <span translate>Take a photo</span>
  </button>
  <mat-error *ngIf="errors.length > 0">
    <span *ngFor="let error of errors">{{ error.text | translate:error.param }}</span>
  </mat-error>
</div>
