import { JsonObject, JsonProperty } from 'json2typescript';
import { PutResponse } from './put-response.model';

/**
 * This class represents Work Order model
 */
@JsonObject('TaskPutResponse')
export class TaskPutResponse extends PutResponse {
  @JsonProperty('workOrderId', Number, true)
  public workOrderId = null;

  @JsonProperty('workOrderNum', String, true)
  public workOrderNum = '';
}
