import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { SessionState, SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  public email = this.select('email');

  public fullName = this.select('name');

  public imageUrl = this.select('imageUrl');

  public isLoggedIn = this.select((state) => !!state.token);

  public constructor(protected store: SessionStore) {
    super(store);
  }
}
