import { JsonObject, JsonProperty } from 'json2typescript';
import { DateJsonConverter } from '../../json-converters/date-json-converter';

@JsonObject('WorkLog')
export class WorkLog {
  @JsonProperty('classWorkLog', String)
  public classWorkLog: 'WORKORDER' = 'WORKORDER';

  @JsonProperty('createDate', DateJsonConverter)
  public createDate: Date | null = null;

  @JsonProperty('createdBy', String)
  public createdBy = '';

  @JsonProperty('description', String)
  public description = '';

  @JsonProperty('descriptionLong', String)
  public descriptionLong = '';

  @JsonProperty('id', Number, true)
  public id = null;

  @JsonProperty('logType', String)
  public logType = '';
}
