import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse,
  LocalStorageKey,
  Site,
  DateSelectorItem,
} from '@al/entities';
import { AlEnvironmentService, AwsEndPoint } from '@al/environment';

import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitAndCloseRestService extends AbstractRestService<
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse
> {
  public activeDate!: DateSelectorItem;

  public activeSite!: Site;

  protected cacheDelay: number | null = null;

  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  protected get createEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.submitAndClose}`;
  }

  protected get getEndPoint(): string {
    return '';
  }

  protected get updateEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.submitAndClose}`;
  }

  public constructor(
    protected alEnvironmentService: AlEnvironmentService,
    protected http: HttpClient
  ) {
    super(http);
    this.keyLastDate = LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP;
  }

  protected getClazz(): typeof WorkOrder {
    return WorkOrder;
  }

  protected getPutRequestClazz(): typeof WorkOrderPut {
    return WorkOrderPut;
  }

  protected getPutResponseClazz(): typeof WorkOrderPutResponse {
    return WorkOrderPutResponse;
  }

  protected updateLastSyncDate(): void {}
}
