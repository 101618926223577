import { Injectable } from '@angular/core';
import { LocalStorageKey, TaskPut, TaskPutResponse } from '@al/entities';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class TaskUpdateHistoryService extends AbstractHistoryService<
  TaskPut,
  TaskPutResponse
> {
  public constructor() {
    super(LocalStorageKey.TASK_UPDATE_REQUEST_HISTORY);
  }

  public clearWorkOrderFromHistory(woNum: string): void {
    this.values()
      .filter((value) => value.response.workOrderNum === woNum)
      .forEach((value) => {
        this.removeByUuid(value.uuid);
      });

    this.values()
      .filter((value) => value.request.number === woNum)
      .forEach((value) => {
        this.removeByUuid(value.uuid);
      });
  }
}
