import {
  DateSelectorItem,
  LocalStorageConfiguration,
  LocalStorageKey,
  WorkOrderLoadingStatus,
} from '@al/entities';
import { AlOnlineService } from '@al/online';
import { AlSpinnerService } from '@al/spinner';
import {
  DateSelectorService,
  DateSelectorQuery,
  WorkOrdersService,
} from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-date-selector',
  templateUrl: './al-date-selector.component.html',
  styleUrls: ['./al-date-selector.component.scss'],
})
export class AlDateSelectorComponent implements OnInit, OnDestroy {
  public isOnline!: boolean;

  public isSynchronized!: boolean;

  public items: DateSelectorItem[];

  public selectedItemUuid: string | null;

  public synchronizing = true;

  private broadcast = new BroadcastChannel('work-order-loading');

  private ngUnsubscribe = new Subject();

  private selectedItem!: DateSelectorItem;

  public constructor(
    private dateSelectorQuery: DateSelectorQuery,
    private dateSelectorService: DateSelectorService,
    private onLineService: AlOnlineService,
    private synchroInfoService: SyncInfoService,
    private workOrdersService: WorkOrdersService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.selectedItemUuid = null;
    this.items = [];
    this.isSynchronized = false;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.alSpinnerService.isWorkOrderProcessing
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isDisplayed) => {
        this.synchronizing = isDisplayed;
      });

    const uuid = UUID.UUID();
    this.alSpinnerService.startAssetProcess(uuid);
    this.dateSelectorQuery
      .selectAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (items) => {
          this.items = items;
          if (this.items.length > 0) {
            const activeDate = this.dateSelectorQuery.getActive();
            this.selectedItem = activeDate || this.items[0];
            this.selectedItemUuid = this.selectedItem.uuid.toString();
            this.dateSelectorService.setActive(this.selectedItem.uuid);
          }
          this.alSpinnerService.stopAssetProcess(uuid);
        },
      });

    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isOnline = res;
      });

    this.synchroInfoService.isSynchronized
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isSynchronized = res;
      });
  }

  public onValueChange(uuid: UUID): void {
    this.broadcast.postMessage({
      text: 0,
      duration: WorkOrderLoadingStatus.resetCOMP,
    });

    this.dateSelectorService.setActive(uuid);
    localStorage.setItem(
      LocalStorageConfiguration.FILTER_DATE,
      uuid.toString()
    );
    localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP);
    localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE);
    this.workOrdersService.clear();
    this.workOrdersService
      .get()
      .pipe()
      .subscribe({
        error: (error) => {
          // TODO: Utiliser un logger global
          // eslint-disable-next-line no-console
          console.error('load by date Error', error);
          this.broadcast.postMessage({
            text: 0,
            duration: WorkOrderLoadingStatus.err,
          });
        },
      });
  }
}
