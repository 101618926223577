import {
  LocalStorageKey,
  ServiceRequestPut,
  ServiceRequestPutResponse,
} from '@al/entities';

import { Injectable } from '@angular/core';
import { AbstractHistoryService } from './abstract-history.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestCreateHistoryService extends AbstractHistoryService<
  ServiceRequestPut,
  ServiceRequestPutResponse
> {
  public constructor() {
    super(LocalStorageKey.SERVICE_REQUEST_CREATE_REQUEST_HISTORY);
  }
}
