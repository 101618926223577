import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { SyncInfo } from './sync-info.model';

export interface SyncErrorInfoState
  extends EntityState<SyncInfo>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'sync-info-error-state',
  idKey: 'uuid',
  resettable: true,
})
export class SyncErrorInfoStore extends EntityStore<SyncErrorInfoState> {
  public constructor() {
    super();
  }
}
