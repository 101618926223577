import { FailureReportRequestPut, LocalStorageKey } from '@al/entities';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { LocalCache } from '../local.cache';

@Injectable({
  providedIn: 'root',
})
export class FailureReportCreateCache extends LocalCache<FailureReportRequestPut> {
  public constructor() {
    super(LocalStorageKey.FAILURE_REPORT_CREATE_CACHE);
  }

  public put(value: FailureReportRequestPut): void {
    // remove previous cache element for this Task
    const cacheFiltered = this.cache.filter(
      (cache: FailureReportRequestPut) => {
        return !(cache.number === value.number);
      }
    );
    this.cache = cacheFiltered;
    this.cache.push({ ...value, uuid: UUID.UUID() });
    this.storageManager.store(this.cache, this.getClazz());
  }

  protected getClazz(): typeof FailureReportRequestPut {
    return FailureReportRequestPut;
  }
}
